import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Menu,
  IconButton,
  Card,
  Box,
  CardContent,
  Link,
  Paper,
  Autocomplete,
  Tooltip,
  Collapse,
} from '@mui/material'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import DeleteIcon from '@mui/icons-material/Delete'
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RefreshIcon from '@mui/icons-material/Refresh'
import React, { useEffect, useMemo, useState } from 'react'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import ActionPage from '../../../ui-components/ActionPage'
import {
  AddCoreEntityAttribute,
  CoreEntity,
  CreateJobParams,
  CreateJobResponse,
  CustomEntityData,
  DataAttribute,
  DataTransformer,
  DataTransformerDetail,
  Entity,
  EntityReconData,
  EntityRelationship,
  FabricNotebookCreateRequest,
  GenBusinessKey,
  JobClusterConfig,
  JobDetails,
  LocalLLmEntityData,
  LocalLLmRelationship,
  NewEntityData,
  NewEntityRelationShip,
  NewParent,
  RelationShipResponse,
  RunNewJobParams,
  RunStatusResponse,
  UpdateEntityNameBody,
  coreEntityAttributeData,
  getCoreEntityAttributeResponse,
} from '../../../businessObjects'
import { isValidDescription, isValidEntry } from '../../../utils/constants'
import {
  useAddCustomEntity,
  useAddNewEntity,
  useDeleteEntity,
  useGetCoreEntity,
  useGetCoreEntityByID,
  useGetCustomEntityRecon,
  useGenerateDataEntityRelations,
  useGenerateDataEntityRecommendation,
  useGetEntities,
  useGetEntityByName,
  useGetEntityNB,
  useSaveEntityNB,
  useUpdateCustomEntity,
  useUpdateEntityNB,
  useUpdateEntityName,
  useGetCoreEntityWithMethodImmediateData,
  useGetAllEntitiesByDataPodIdByMethod,
} from '../../../hooks/entityHooks'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ProgressBar } from '../../../ui-components/ProgressBar'
import {
  useAddEntityRecon,
  useAddEntityRelationship,
  useAddMultipleEntityRelationships,
  useCustomEnrichedGetEntityRelationships,
  useDeleteEntityRecon,
  useDeleteEntityRelation,
  useGetEnrichedEntityRelations,
  useUpdateEntityRelationship,
} from '../../../hooks/applicationHooks'
import { LucidJupyter } from '../../../ui-components/JupyterComponents/LucidJupyter'
import { ROUTES } from '../../../Routes/constants'
import { AddOutlined, EditNote, HomeOutlined, Insights } from '@mui/icons-material'
import { SparkCode, useGenerateEnrichedModel } from '../../../hooks/sparkHooks'
import {
  useAddNewCustomAttributeTransformer,
  useGetCustomEntityDataTransformer,
  useGetDataEntityTransformer,
  useUpdateDataTransformer,
} from '../../../hooks/dataTransformerHooks'
import DataPodDetailRibbon from '../../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { usePostCreateDatabricksJob, usePostDatabricksJobRunNew } from '../../../hooks/databricksHooks'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../../features/notification/notificationSlice'
import ResultStatus from '../../../ui-components/ResultStatus'
import { useGetServiceConnection } from '../../../hooks/dataEngineeringHooks'
import {
  useAddMultipleCoreEntityAttributes,
  useDeleteEnrichedEntity,
  useGetCoreEntityAttributes,
  useGetCoreEntityAttributesDatapod,
  useSyncCoreAttributes,
  useUpdateCoreAttributeDetails,
  useUpdateCoreAttributeDetailsBulk,
} from '../../../hooks/coreEntityAttributeHooks'
import {
  useGetDataAttributesByEntityId,
  useGetDataAttributesByMultipleEntityId,
} from '../../../hooks/dataAttrbutesHooks'
import { paddingLeft } from '../../../styles/globalStyles'
import { enrichedCoreAttributes, useGenerateCustomEnrichedEntityDataFromFile, useGenerateEmbeddings, useGetGenAiEnrichedCoreEntityAttributes } from '../../../hooks/genAIApiHooks'
// import { useGetGenAiCoreEntityAttributes } from '../../../hooks/genAIApiHooks'
import constants from '../../constants'
import LoadingButton from '@mui/lab/LoadingButton'
import { info } from 'console'
import SettingsIcon from '@mui/icons-material/Settings'
import { DeletePopUp } from '../DataMapping/DataMappingPopUps'
import { isTemplateExpression } from 'typescript'
import { ConsoleCallbackHandler } from 'langchain/dist/callbacks'
import { useCreateAndRunNewJob, useGetCreateJobPayload, useGetRunNewJobPayload, useRunNewJob } from 'hooks/executionHooks'
import { useGetDataHubEntityMaps, useGetEntityAttributes } from 'hooks/dataHubHooks'
import ErrorDisplay from 'ui-components/ErrorDisplay'
import CodeMirror from '@uiw/react-codemirror'
import { useGetConfigurationValue } from 'hooks/lucidConfigurationHooks'
import { useGenerateBusinessKey } from 'hooks/generateDatapodHooks'
import SearchDropDown from 'ui-components/SearchDropDown'
import { LucidTextLabel } from 'ui-components/LucidTextLabel'
import TreeView from 'ui-components/TreeView'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
  customEntityId: string
}

type EntityType = {
  id: number
  type: string
}

type FunctionsType = {
  id: number
  function: string
}

type SelectedAttribute = {
  attributeName: string
  attributeDescription: string
  attributeType: string
  entityId: number
}
type GeneratedEnrichedAttribute = {
  attributeName: string
  attributeDescription: string
}

type UpdatedDataAttribute = DataAttribute & {
  dataEntityName: string | undefined | null
}

type Item = {
  entity: string;
  attribute: string | undefined;
}
const initCoreEntity = {
  coreEntity: true,
  profileName: 'clientApp',
  description: '',
  dataEntityName: '',
  entityType: undefined,
  dataLakeZone: 2,
}
const entityTypes = [
  { id: 1, value: 'Reference' },
  { id: 2, value: 'Transaction' },
]

const promptTextFunctions = [
  { id: 1, function: 'aggregate' },
  { id: 2, function: 'groupBy' },
  { id: 3, function: 'filter' },
]

export const CustomEnrichedModel = () => {
  const { dataPodId, customEntityId } = useParams<ViewResultsParams>()
  // const currentEntityId = customEntityId ? Number(customEntityId) : undefined
  const [currentEntityId, setCurrentEntityId] = useState(customEntityId ? Number(customEntityId) : undefined)
  const [enrichedTableData, setEnrichedTableData] = useState<NewEntityData>(initCoreEntity)
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [userInput, setUserInput] = useState<string>('')
  const [userInput2, setUserInput2] = useState<string>('')
  const [userInput3, setUserInput3] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null)
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null)
  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [beforeCustomEntitySave, setBeforeCustomEntitySave] = useState<boolean>(false)

  const [coreEntityEdit, setCoreEntityEdit] = useState<boolean>(false)
  //const [coreEntityDisplay, setCoreEntityDisplay] = useState<boolean>(false)
  const { saveNewEntity } = useAddNewEntity(dataPodId ?? '')
  const [entityData, setEntityData] = useState<Entity[]>([])
  const [entityDataAll, setEntityDataAll] = useState<Entity[]>([])
  const [coreEntityAttribute, setCoreEntityAttributeData] = useState<UpdatedDataAttribute[]>([])
  const [currentChildEntityIds, setCurrentChildEntityIds] = useState<EntityReconData[]>([])
  const { entities: fetchedEntities } = useGetEntities(dataPodId)
  // const [coreEntities] = useGetCoreEntity(dataPodInfo?.id)
  const [coreEntities , setCoreEntities] = useState<Entity[]>([])
  const [coreEntity, fetchCoreEntity] = useGetCoreEntityWithMethodImmediateData()
  const [fetchAllEntity, entity] = useGetAllEntitiesByDataPodIdByMethod()
  const [isHavingEnriched, setIsHavingEnriched] = useState<boolean>(false)
  const { addEntityRecon } = useAddEntityRecon(dataPodId)

  const { getCreateJobPayload } = useGetCreateJobPayload()
  const { createAndRunNewJob } = useCreateAndRunNewJob()

  const [updateEntityName, newEntityName] = useUpdateEntityName()
  const [notebookString, setNotebookString] = useState<string>()
  const [commitMessage, setcommitMessage] = useState<string>('initial commit')
  const [processingReq, setProcessingReq] = useState<boolean>(false)

  const [query, setQuery] = useState<SparkCode>()
  const [newCoreEntityId, setNewCoreEntityId] = useState<number>()
  const { updateCustomEntity, errorUpdatingEntity } = useUpdateCustomEntity()
  const { confirmDeleteEntityRecon } = useDeleteEntityRecon()
  const { entity: customEntity } = useGetCoreEntityByID(dataPodId, currentEntityId)
  const { fetchCustomEntityChildren } = useGetCustomEntityRecon(dataPodId)

  const { saveNewCustomEntity, addCustomEntityError } = useAddCustomEntity(dataPodId)
  const { genEnrichedSparkCode } = useGenerateEnrichedModel(dataPodId)
  const { entityNBstring, error } = useGetEntityNB(dataPodId, currentEntityId)
  const { saveNoteBoook } = useSaveEntityNB(dataPodId)
  const { updateEntityNB } = useUpdateEntityNB(dataPodId)
  const { addTransformer } = useAddNewCustomAttributeTransformer(dataPodId)
  const { updateTransformer } = useUpdateDataTransformer()
  // const { dataTransformers } = useGetDataEntityTransformer(dataPodId, currentEntityId)
  const { dataTransformer } = useGetCustomEntityDataTransformer(dataPodId, currentEntityId)
  const { getConnections } = useGetServiceConnection()
  const [entityId, setEntityId] = useState<number[]>([])
  const [attributeSelected, setAttributeSelected] = useState<String[]>([])
  const [attributesToDelete, setAttributesToDelete] = useState<string[]>([])
  const fetchCustomEntityRelationships = useCustomEnrichedGetEntityRelationships()
  const { getRelationShipData, loadingEnrichedRelationships, errorFetching } = useGetEnrichedEntityRelations(dataPodId)
  const [fetchedRelations, setFetchedRelations] = useState<RelationShipResponse[]>([])
  const [relationTableFlag, setRelationTableFlag] = useState<boolean>(false)
  const navigate = useNavigate()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [customEntId, setCustomEntId] = useState<number>()
  const [customEntName, setCustomEntName] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const [successMessage, setSuccessMessage] = useState<string>()
  const [successState, setSuccessState] = useState<boolean>(false)

  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null)

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const activeFabricRunStatus = useAppSelector((state: RootState) => state.notification.fabricRunStatus)
  // const { fetchCoreEntityAttributes } = useGetGenAiCoreEntityAttributes()
  const { fetchEnrichedCoreEntityAttributes } = useGetGenAiEnrichedCoreEntityAttributes()
  const [fetchCoreAttributes, coreAttributes] = useGetCoreEntityAttributes(dataPodId)
  const [fetchCoreAttributesDatapod, coreAttributesDatapod] = useGetCoreEntityAttributesDatapod(dataPodId)
  const [getDataHubEntityAttributes] = useGetEntityAttributes()
  const [getDataHubEntities] = useGetDataHubEntityMaps()
  const { refetchAttributes } = useGetDataAttributesByMultipleEntityId()
  const [attributeChanged, setAttributeChanged] = useState<boolean>(false)
  const [entityChanged, setEntityChanged] = useState<boolean>(false)
  const [submitNewAttribute] = useAddMultipleCoreEntityAttributes()
  const { syncCoreEntityAttibuteFunc } = useSyncCoreAttributes()
  const [selectedAttributes, setSelectedAttributes] = useState<SelectedAttribute[]>([])
  const [generatedEnrichedAttributes, setGeneratedEnrichedAttributes] = useState<any[]>([])
  const [nbStringPresent, setnbStringPresent] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [DataSystemEntityIds, setDataSystemEntityIds] = useState<any>([])
  const [fetchLoading, setFetchLoading] = useState<boolean>(false)
  const [info, setInfo] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string>('')
  const [loadToWarehouse, setLoadToWarehouse] = useState<boolean>(false)
  const [runId, setRunId] = useState<number | string>()
  const [collapseForm, setCollapseForm] = useState<boolean>(true)
  const [codeGenerating, setCodeGenerating] = useState<boolean>(false)
  const { isEntityRecommendationGenerating, errorGeneratingEntityRecommendation, generateEntityRecommendation } =
    useGenerateDataEntityRecommendation(dataPodId)
  const { isEntityRelationsGenerating, errorGeneratingEntityRelations, generateEntityRelations } =
    useGenerateDataEntityRelations(dataPodId)

  const { addMultipleEntityRelations } = useAddMultipleEntityRelationships(dataPodId)
  // const { fetchEntityByName } = useGetEntityByName()

  const [showAddRelationPopUp, setShowAddRelationPopUp] = useState<boolean>(false)
  const [showEditRelationPopUp, setShowEditRelationPopUp] = useState<boolean>(false)
  const [showDeleteRelationWarning, setShowDeleteRelationWarning] = useState<boolean>(false)
  const [selectedEntityRelation, setSelectedEntityRelation] = useState<RelationShipResponse>()
  const [coreAttributeDictionary, setCoreAttributeDictionary] = useState<Record<number, Set<string>>>({});
  const [addedItems, setAddedItems] = useState<string[]>([]);
  const [removedItemList, setRemovedItems] = useState<string[]>([]);
  const [transformer, setTransformer] = useState<DataTransformer>()
  const [createdBodies, setCreatedBodies] = useState(new Set<string>());
  const [coreAttributesErrors, setCoreAttributesErrors] = useState<any[]>([])
  const [enrichedRelationshipErrors, setEnrichedRelationShipError] = useState<any[]>([])
  const [errorBlockFlagCoreAttributes, setErrorBlockFlagCoreAttributes] = useState<boolean>(false)
  const [errorBlockFlagRelationships, setErrorBlockFlagRelationships] = useState<boolean>(false)
  const [errorBlockExpanded, setErrorBlockExpanded] = useState<boolean>(false)
  const [errorBlockExpandedRelations, setErrorBlockExpandedRelations] = useState<boolean>(false)
  const { runNewJob } = useRunNewJob()
  const { getRunNewJobPayload } = useGetRunNewJobPayload();
  const { getEntityDataByFile, isGetEntityDataByFileLoading } = useGenerateCustomEnrichedEntityDataFromFile()
  const { addEmbeddings, isGeneratingEmbedding } = useGenerateEmbeddings()

  const [uploadedFile, setUploadedFile] = useState<File | undefined>()
  const [uploadedFileContent, setUploadedFileContent] = useState<string>('')

  const [isCompareFileClicked, setIsCompareFileClicked] = useState<boolean>(false)
  const { getConfigurationValue } = useGetConfigurationValue()
  const [repoUrl, setRepoUrl] = useState<string>()
  const [isAzureRpoSelected, setIsAzureRepoSelected] = useState<boolean>(false)
  const entityRelationSuggestion: Entity[] = useMemo(() => {
    if (entityId.length == 0) {
      return []
    }

    return entityDataAll.filter((entity) => entityId.includes(entity.id))
  }, [entityId, entityDataAll])
  const { generateBusinessKey } = useGenerateBusinessKey()
  const [showDeltaKeyPopup, setShowDeltaKeyPopup] = useState(false);
  const [deltaKeyUserInp, setDeltaKeyUserInp] = useState<string>('');
  const [selectedDeltaKeys, setSelectedDeltaKeys] = useState<number[]>([]);
  const [deltaKeyValue, setDeltaKeyValue] = useState<string | null>(null)
  const { updateCoreEntityAttibuteFuncBulk } = useUpdateCoreAttributeDetailsBulk()
  const [treeViewString, setTreeViewString] = useState<string>('')
  const [updateFromTreeView, setUpdateFromTreeView] = useState<boolean>(false)
  const [updateFromParent, setUpdateFromParent] = useState<boolean>(false)

  useEffect(() => {
    if (errorGeneratingEntityRelations) {
      setErrorMessage(errorGeneratingEntityRelations)
      setErrorState(true)
    }
  }, [errorGeneratingEntityRelations])

  useEffect(() => {
    if (treeViewString.length && updateFromTreeView) {
      console.log(`treeViewString: ${treeViewString}`);
      console.log(`userInput3: ${userInput3}`);
      setUserInput3(treeViewString)
      setUpdateFromTreeView(false)
    }
  }, [treeViewString, treeViewString.length, updateFromTreeView])
  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
          const parsedJson = JSON.parse(jsonTemplate)
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
            jobId: 0,
            notebookParams: {}
          }
          setJobClusterParams(params)
        }
      }
    }

    fetchJobClusterParams()
  }, [])
  useEffect(() => {
    if (customEntId) {
      setCurrentEntityId(customEntId)
    }
  }, [customEntId])

  useEffect(() => {
    if (customEntity && !newCoreEntityId) {
      setEnrichedTableData({
        coreEntity: true,
        profileName: customEntity.profileName,
        description: customEntity.description,
        dataEntityName: customEntity.dataEntityName,
        entityType: customEntity.entityType,
        dataLakeZone: 2,
      })
      setCustomEntId(customEntity.id)
      setCustomEntName(customEntity.dataEntityName)
      fetchRepoData()
    }
  }, [customEntity, newCoreEntityId])

  useEffect(() => {
    if (coreAttributesErrors.length) {
      setErrorBlockFlagCoreAttributes(true)
    } else {
      setErrorBlockFlagCoreAttributes(false)
    }
  }, [coreAttributesErrors])
  useEffect(() => {
    if (enrichedRelationshipErrors.length) {
      setErrorBlockFlagRelationships(true)
    } else {
      setErrorBlockFlagRelationships(false)
    }
  }, [enrichedRelationshipErrors])
  const getEntityChilds = async (coreEntityId: number) => {
    const childEntites = await fetchCustomEntityChildren(coreEntityId)
    // console.log({ childEntites })
    if (childEntites) {
      setCurrentChildEntityIds(childEntites)
      const usrInpt = childEntites.map((ce) => `@${ce.child}`).join(' ')
      setUserInput(usrInpt)
      const ids = childEntites.map((item) => item.dataSystemEntityId)
      // getEntityRelations(ids)
      setEntityId(ids)
    }
  }

  const processItem = (item: any, coreEntityAttribute: any[], entityDataAll: any[]) => {
    let updatedItem = { ...item };

    // Check if the item contains entityId or attributeId, and replace them with names
    if (item.leftEntity && item.rightEntity) {
      const entityLeft = entityDataAll.find(ent => ent.id === item.leftEntity);
      const entityRight = entityDataAll.find(ent => ent.id === item.rightEntity);

      if (entityLeft && entityRight) {
        updatedItem = `${entityLeft.dataEntityName} - ${entityRight.dataEntityName}`;
      } else {
        updatedItem = `${item.leftEntity} - ${item.rightEntity}`; // Fallback if names are not found
      }
    }

    // If item has a message, handle message replacement
    if (item.message) {
      const attributeIdRegex = /attribute[\s]?id|attribuite[\s]?id/i;
      const attributeIdMatch = item.message.match(attributeIdRegex);

      if (attributeIdMatch) {
        const numberMatch = item.message.match(/\d+/); // Match the first numeric value
        if (numberMatch) {
          const attribute = coreEntityAttribute.find(attr => attr.id === Number(numberMatch[0]));
          if (attribute) {
            updatedItem.message = item.message.replace(numberMatch[0], attribute.dataAttributeName);
          }
        }
      }

      return updatedItem.message; // Return the modified message
    }

    // If no special structure, return the object as a string
    return JSON.stringify(item);
  };

  const onClickGenerateRelations = async () => {
    if (entityId.length >= 2) {
      // console.log("Inside the use effect")
      const uniqueIds = new Set(entityId)
      // const attributeNames = userInput3
      //   .split('@')
      //   .filter((name) => name.trim().length > 0)
      //   .map((name) => name.trim())
      const attributeNames = userInput3
        .split('@')  // Split by '@' to get each entry
        .filter((name) => name.trim().length > 0)
        .map((name) => {
          const parts = name.trim().split('.');
          return parts[1] ? parts[1].trim() : '';
        })
        .filter((attr) => attr.length > 0);
      // console.log({ attributeNames })
      const response = await generateEntityRelations(Array.from(uniqueIds), attributeNames)

      if (response?.relationshipData.length) {
        // console.log({ response })
        if (response.enrichedResponse) {
          const { entityIds, columnProfileLog, warningText } = response.enrichedResponse;

          // Start with an empty mergedData array
          let mergedData = [];

          // Add warningText as a message if it exists, placing it before entityIds
          if (warningText) {
            mergedData.push({ message: warningText });
          }

          // Merge entityIds and columnProfileLog
          mergedData = [
            ...mergedData, // Keep warningText at the beginning
            ...(entityIds || []),
            ...(Array.isArray(columnProfileLog) ? columnProfileLog.map(log => ({ message: log })) : []),
          ];

          // Process each item using the helper function `processItem`
          const processedData = mergedData.map(item => processItem(item, coreEntityAttribute, entityDataAll));

          // console.log('Processed Data:', processedData);
          if (processedData) {
            setEnrichedRelationShipError(processedData);
          }
        }
        const uniqueRelations = mergeUniqueRelations(response.relationshipData)
        // const relations = response.map((item, index) => ({ ...item, id: index }))
        setRelationTableFlag(true)
        setFetchedRelations(uniqueRelations)
        setSuccessMessage('Generated relations successfully.')
        setSuccessState(true)
      } else if (response?.relationshipData && response.relationshipData.length == 0) {
        if (response.enrichedResponse) {
          const { entityIds, columnProfileLog, warningText } = response.enrichedResponse;

          // Start with an empty mergedData array
          let mergedData = [];

          // Add warningText as a message if it exists, placing it before entityIds
          if (warningText) {
            mergedData.push({ message: warningText });
          }

          // Merge entityIds and columnProfileLog
          mergedData = [
            ...mergedData, // Keep warningText at the beginning
            ...(entityIds || []),
            ...(Array.isArray(columnProfileLog) ? columnProfileLog.map(log => ({ message: log })) : []),
          ];

          // Process each item using the helper function `processItem`
          const processedData = mergedData.map(item => processItem(item, coreEntityAttribute, entityDataAll));

          // console.log('Processed Data:', processedData);
          if (processedData) {
            setEnrichedRelationShipError(processedData);
          }
        }
        else {
          setErrorBlockExpandedRelations(false)
        }
        setSuccessMessage("Gen AI couldn't generate any relationships.")
        setSuccessState(true)
      } else if (errorGeneratingEntityRelations) {
        let errorArray = []
        setErrorMessage(errorGeneratingEntityRelations)
        setErrorState(true)
        setErrorBlockExpandedRelations(true)
        if (response?.error) {
          errorArray.push({ 'error': errorGeneratingEntityRelations })
          setEnrichedRelationShipError(errorArray)
        }
      }
    } else {
      setRelationTableFlag(false)
      setErrorBlockExpandedRelations(false)
    }
  }

  const mergeUniqueRelations = (input: RelationShipResponse[]) => {
    const newFetchedRelations = fetchedRelations

    if (input.length == 0) {
      // console.log("Inside the if")
      return newFetchedRelations
    }

    input.forEach((inputRelation) => {
      const relationExists = fetchedRelations.some(
        (relation) =>
          relation.leftEntityId === inputRelation.leftEntityId &&
          relation.rightEntityId === inputRelation.rightEntityId &&
          relation.leftAttributeId === inputRelation.leftAttributeId &&
          relation.rightAttributeId === inputRelation.rightAttributeId &&
          relation.cardinality === inputRelation.cardinality,
      )

      if (relationExists) {
        return
      }

      const length = newFetchedRelations.length

      newFetchedRelations.push({ ...inputRelation, id: -(length == 0 ? 1 : length) })
    })

    return newFetchedRelations
  }

  const getEntityRelations = async (entityIds: any) => {
    // console.log("Inside get entity relations")
    const entityArray: number[] = Array.from(entityIds)
    if (currentEntityId) {
      const response = await getRelationShipData(currentEntityId)
      if (response && response.length) {
        setRelationTableFlag(true)
        setFetchedRelations((prevRelations) => {
          let updatedRelations = [...prevRelations]

          response.forEach((responseRelation) => {
            const relationIndex = updatedRelations.findIndex((prevRelation) => prevRelation.id === responseRelation.id)
            if (relationIndex > -1) {
              // Update existing relation
              updatedRelations[relationIndex] = responseRelation
            } else {
              // Add new relation if not found
              updatedRelations.push(responseRelation)
            }
          })

          return updatedRelations
        })
      }
    }
  }

  const getAttributes = async (coreEntityId: number) => {
    if (dataPodId) {
      await fetchCoreAttributes(coreEntityId)
    }
  }

  // const concatenateAttributeNames = (attributes: getCoreEntityAttributeResponse[]): string => {
  //   return attributes
  //     .map((attribute) => {
  //       // const entityName = fetchedEntities.find((e) => e.id === attribute.dataEntityId)?.dataEntityName
  //       if (entityDataAll.length) {
  //         const entityName = attribute.dataEntityId
  //           ? entityDataAll.find((e) => e.id === attribute.dataEntityId)?.dataEntityName
  //           : entityDataAll.find((e) => e.id === attribute.dataCoreEntityId)?.dataEntityName;
  //         if (entityName) {
  //           return `@${entityName}.${attribute.dataCoreAttributeName}`;
  //         } else {
  //           return `@${attribute.dataCoreAttributeName}`;
  //         }
  //       }
  //     })
  //     .join(' ');
  // }

  const concatenateAttributeNames = (attributes: getCoreEntityAttributeResponse[]): string => {
    const concatenatedString = attributes
      .map((attribute) => {
        if (entityDataAll.length) {
          const entityName = attribute.dataEntityId
            ? entityDataAll.find((e) => e.id === attribute.dataEntityId)?.dataEntityName
            : entityDataAll.find((e) => e.id === attribute.dataCoreEntityId)?.dataEntityName;
          return entityName ? `@${entityName}.${attribute.dataCoreAttributeName}` : `@${attribute.dataCoreAttributeName}`;
        }
      })
      .filter(Boolean)
      .join(' ');

    if (concatenatedString === userInput3) return concatenatedString;

    const difference = concatenatedString
      .split(' ')
      .filter((part) => !userInput3.includes(part))
      .join(' ');
    // console.log({difference}, {userInput3}, {concatenatedString})
    // Step 4: Append the difference to the original userInput3 and return it
    const updatedString = `${userInput3} ${difference}`.trim();
    setTreeViewString(updatedString)
    return updatedString;
  };
  // const getAttributeEntityNameByAttributeName = (attributeName: string, attributeProfiles: DataAttribute[]) => {
  //   // if(attributeProfiles){
  //   const attribute = attributeProfiles.find((attr) => attr.dataAttributeName === attributeName);
  //   if (!attribute) {
  //     return null;
  //   }

  //   const entity = fetchedEntities.find((ent) => ent.id === attribute.dataEntityId);
  //   if (!entity) {
  //     return null;
  //   }

  //   return `${entity.dataEntityName}.${attribute.dataAttributeName}`;
  //   // }

  // }

  // const fetchAllAttributesOfEntities = async (dataSystemEntityIds: number[]) => {
  //   const attributeProfiles = await refetchAttributes(dataSystemEntityIds)
  //   // console.log({attributeProfiles})
  //   // console.log({fetchedEntities})
  //   if (attributeProfiles?.length) {
  //     console.log({attributeProfiles})
  //     setCoreEntityAttributeData(attributeProfiles)
  //   }
  // }


  const fetchAllAttributesOfEntities = async (dataSystemEntityIds: number[], datapodId: string) => {
    if (!dataSystemEntityIds.length) {
      setCoreEntityAttributeData([]);
      return;
    }

    const enrichedFlag = entityDataAll.filter((fe) => fe.coreEntity == true && dataSystemEntityIds.includes(fe.id));

    let poolAttributes: UpdatedDataAttribute[] = [];

    const hubFlag = fetchedEntities.filter(fe => fe.dataHubMapId && fe.dataHubCloneTypeId && dataSystemEntityIds.includes(fe.id));
    let newHubAttributes: any[] = [];
    let hubEntityData: { hubEntityId: number, sourceId: number, entityName: string }[] = [];

    for (const hubEnt of hubFlag) {
      const resp = await getDataHubEntityAttributes(datapodId, hubEnt.id);
      const hubMap = await getDataHubEntities(undefined, hubEnt.dataHubMapId);

      if (resp) {
        newHubAttributes = [...newHubAttributes, ...resp];
      }

      if (hubMap) {
        hubMap.forEach((hubMapEntry: any) => {
          if (hubMapEntry.id === hubEnt.dataHubMapId) {
            hubEntityData.push({
              hubEntityId: hubEnt.id,
              sourceId: hubMapEntry.dataEntityId,
              entityName: hubEnt.dataEntityName,
            });
          }
        });
      }
    }

    // Map newHubAttributes to UpdatedDataAttribute format
    const updatedHubAttributes: UpdatedDataAttribute[] = newHubAttributes.map(attr => ({
      id: attr.id,
      dataEntityId: attr.dataEntityId,
      dataAttributeName: attr.dataAttributeName,
      dataAttributeDataType: attr.dataAttributeDataType,
      description: attr.description,
      isBusinessKey: attr.isBusinessKey,
      dataClassification: attr.dataClassification,
      dataAttributeType: attr.attributeDataType,
      dataPodId: attr.dataPodId,
      isActive: attr.isActive,
      dataEntityName: attr.entityName,
    }));

    // Add hub attributes that match sourceId in hubEntityData to poolAttributes
    updatedHubAttributes.forEach(attr => {
      const matchingHubEntity = hubEntityData.find(hub => hub.sourceId === attr.dataEntityId);
      if (matchingHubEntity) {
        poolAttributes.push(attr);
      }
    });

    // Fetch attribute profiles
    const attributeProfiles = await refetchAttributes(dataSystemEntityIds);
    if (attributeProfiles?.length) {
      const entityMap = new Map<number, string>();
      fetchedEntities.forEach((entity) => {
        entityMap.set(entity.id, entity.dataEntityName);
      });

      const enrichedAttributes = attributeProfiles.map(attr => ({
        ...attr,
        dataEntityName: entityMap.get(attr.dataEntityId) || 'Unknown',
      }));

      // Add enriched attributes to poolAttributes
      enrichedAttributes.forEach(attr => {
        if (dataSystemEntityIds.includes(attr.dataEntityId)) {
          poolAttributes.push(attr);
        }
      });
    }

    if (enrichedFlag) {
      const specificIds = fetchedEntities.map(item => item.id);
      const excludedItems = entityId.filter(item => !specificIds.includes(item));
      const existingIds = new Set(coreEntityAttribute.map(attr => attr.dataEntityId));
      const itemsToFetch = excludedItems.filter(id => !existingIds.has(id));

      let newCoreAttributes: getCoreEntityAttributeResponse[] = [];

      // Fetch core attributes for items that need to be fetched
      for (const id of itemsToFetch) {
        const fetchedAttributes = await fetchCoreAttributesDatapod(id);
        if (fetchedAttributes) {
          newCoreAttributes = [...newCoreAttributes, ...fetchedAttributes];
        }
      }

      if (newCoreAttributes.length) {
        const entityNamesMap = new Map(coreEntities.map(({ id, dataEntityName }) => [id, dataEntityName]));

        const coreAttributes = newCoreAttributes.map(({
          id, dataCoreEntityId, dataCoreAttributeName, description,
          dataCoreAttributeDataType, dataCoreClassification, dataPodId,
          isBusinessKey, isActive
        }) => ({
          id,
          dataEntityId: dataCoreEntityId ?? null,
          dataAttributeName: dataCoreAttributeName,
          dataAttributeDataType: dataCoreAttributeDataType,
          dataPodId,
          description: description ?? '',
          isBusinessKey: isBusinessKey ?? false,
          dataClassification: dataCoreClassification ?? '',
          dataAttributeType: '',
          isActive: isActive ?? true,
          dataEntityName: entityNamesMap.get(dataCoreEntityId),
        }));

        // Add core attributes to poolAttributes
        coreAttributes.forEach(attr => {
          if (dataSystemEntityIds.includes(attr.dataEntityId)) {
            poolAttributes.push(attr);
          }
        });
      }
      setIsHavingEnriched(false);
    }

    // Ensure unique attributes using a Map
    const uniqueAttributesMap = new Map<number, UpdatedDataAttribute>();
    poolAttributes.forEach(attr => {
      uniqueAttributesMap.set(attr.id, attr);  // This will ensure uniqueness by overwriting duplicates
    });

    const uniqueAttributes = Array.from(uniqueAttributesMap.values());

    // Set the final core entity attribute data
    setCoreEntityAttributeData(uniqueAttributes);
  };


  useEffect(() => {
    if (!showEditRelationPopUp) {
      getEntityRelations(entityId)
    }
  }, [showEditRelationPopUp])

  useEffect(() => {
    if (!showAddRelationPopUp) {
      getEntityRelations(entityId)
    }
  }, [showAddRelationPopUp])

  useEffect(() => {
    if (coreAttributes?.length && fetchedEntities.length) {
      // console.log("Coming inside this use effect")
      // console.log({coreAttributes})
      const attributesString = concatenateAttributeNames(coreAttributes)
      setSelectedDeltaKeys(
        coreAttributes
          .filter((col) => col.isBusinessKey === true) // Filter attributes with isBusinessKey === true
          .map((col) => col.id) // Map to extract only the id
      );
      const deltaKeys = coreAttributes
        .filter((col) => col.isBusinessKey === true) // Same filter as above
        .map((col) => col.dataCoreAttributeName) // Extract the names instead of IDs
        .join(', ');
      setDeltaKeyValue(deltaKeys)
      // console.log(attributesString)
      if (attributesString) {
        setUserInput3(attributesString)
        // const dictionary = coreAttributes.reduce((acc, current) => {
        //   const { dataEntityId, dataCoreAttributeName } = current;
        //   if (dataEntityId !== null && dataEntityId !== undefined) {
        //     if (!acc[dataEntityId]) {
        //       acc[dataEntityId] = new Set<string>();
        //     }
        //     acc[dataEntityId].add(dataCoreAttributeName);
        //   }

        //   return acc;
        // }, {} as Record<number, Set<string>>);
        // console.log({dictionary})
        // setCoreAttributeDictionary(dictionary)
      }
    } else if (!coreAttributes?.length) {
      setErrorBlockFlagCoreAttributes(false)
      setErrorBlockExpanded(false)
    }
  }, [coreAttributes, coreAttributes?.length, , fetchedEntities, entityDataAll])
  useEffect(() => {
    if (customEntityId && !userInput) {
      getEntityChilds(Number(customEntityId))
    }
  }, [customEntityId, userInput])
  // useEffect(() => {
  //   if (coreAttributes) {
  //     const selectedAttributeNames = new Set(attributeSelected);
  //     const currentAttributeNames = new Set(coreAttributes.map(attr => attr.dataCoreAttributeName));

  //     // Attributes to be deleted are in coreAttributes but not in attributeSelected
  //     const toDelete = Array.from(currentAttributeNames).filter(attr => !selectedAttributeNames.has(attr));
  //     console.log('Attributes to delete:', toDelete);
  //     setAttributesToDelete(toDelete);
  //   }
  // }, [coreAttributes, attributeSelected, userInput3.length]);

  useEffect(() => {
    if (entityId && dataPodId) {
      const tempEntArray = Array.from(new Set(entityId.map((item) => item)))
      fetchAllAttributesOfEntities(tempEntArray, dataPodId)
    }
  }, [entityId, entityId.length])

  useEffect(() => {
    if (dataPodId) {
      getAllEntities(dataPodId)
  }
  }, [])

  const getAllEntities = async (podId: string) => {
    const entities = await fetchAllEntity({}, podId)
    const coreEntities = await fetchCoreEntity(podId)
    if (entities?.length && coreEntities?.length) {
      const dataSystemEntityIds = entities.map((entity) => entity.id)
      setDataSystemEntityIds(dataSystemEntityIds)
      const temp1 = [...entities, ...coreEntities]
      setCoreEntities(coreEntities)
      // console.log({ temp1 })
      const allEntIds = temp1.map((t) => { return t.id })
      setEntityData(temp1)
      // console.log({temp})
      setEntityDataAll(temp1)
      setIsHavingEnriched(true)
    } else if (entities?.length && !coreEntities?.length) {
      const dataSystemEntityIds = entities.map((entity) => entity.id)
      setDataSystemEntityIds(dataSystemEntityIds)
      const temp2 = [...entities]
      setEntityData(temp2)
      setEntityDataAll(temp2)
      setIsHavingEnriched(false)
    }
    // console.log({ entities })
    // console.log({ coreEntities })
  }
  // useEffect(()=>{
  //   if(fetchedRelations){
  //     console.log({fetchedRelations})
  //   }
  // },[fetchedRelations])

  useEffect(() => {
    if (fetchedEntities && !entityData.length) {
      const dataSystemEntityIds = fetchedEntities.map((entity) => entity.id)
      // console.log(entityId)
      // console.log(dataSystemEntityIds)
      setDataSystemEntityIds(dataSystemEntityIds)
      setEntityData(fetchedEntities)
      // setEntityDataAll(fetchedEntities)
    }
  }, [fetchedEntities, fetchedEntities.length, entityId])

  useEffect(() => {
    if (entityNBstring && !notebookString) {
      if (entityNBstring !== 'Notebook not available!') {
        setNotebookString(entityNBstring)
        setnbStringPresent(true)
      }
    }
    if (error) {
      console.log(error)
    }
  }, [customEntityId, entityNBstring, error])

  useEffect(() => {
    if (notebookString?.length) {
      // console.log("the code already is present")
    } else {
      // console.log("code is not present")
    }
  }, [notebookString])

  useEffect(() => {
    // console.log("Inside the use effect of the data transformers")
    if (dataTransformer && !userInput2) {
      //console.log({ dataTransformers })
      setUserInput2(dataTransformer.transformerText)
      setTransformer(dataTransformer)
    }
  }, [dataTransformer])

  useEffect(() => {
    // console.log(entityId)
    if (entityId.length >= 2) {
      // console.log("Inside the use effect")
      const uniqueIds = new Set(entityId)
      getEntityRelations(uniqueIds)
    } else {
      setRelationTableFlag(false)
    }
  }, [entityId, entityId.length])
  useEffect(() => {
    if (userInput && entityDataAll.length) {
      syncEntityIds()
    } else {
      setRelationTableFlag(false)
    }
  }, [userInput, userInput.length, entityDataAll])
  useEffect(() => {
    if (currentEntityId) {
      // console.log("current entity id", currentEntityId)
      getAttributes(currentEntityId)
    }
  }, [newCoreEntityId, currentEntityId])
  useEffect(() => {
    if (errorGeneratingEntityRecommendation?.length) {
      setErrorMessage(errorGeneratingEntityRecommendation)
      setErrorState(true)
    }
  }, [errorGeneratingEntityRecommendation])
  useEffect(() => {
    if (userInput3.length === 0) {
      setCoreAttributeDictionary({})
    }
  }, [userInput3, userInput3.length])

  useEffect(() => {
    updateUploadedFileContents()
  }, [uploadedFile])

  const updateUploadedFileContents = async () => {
    if (uploadedFile) {
      const content = await uploadedFile.text()

      setUploadedFileContent(content)
      return
    }

    setUploadedFileContent('')
  }
  // useEffect(() => {
  //   let typingTimer: any = 0
  //   function handleKeyUp(event: any) {
  //     const { key } = event
  //     if (key === 'Enter' || key === 'Tab') {
  //       setIsTyping(false)
  //       setSaveData(true)
  //       console.log('Enter or Tab is pressed')
  //     } else {
  //       setIsTyping(true)
  //       clearTimeout(typingTimer)
  //       typingTimer = setTimeout(() => {
  //         setIsTyping(false)
  //       }, 30000)
  //     }
  //   }
  //   const inputElement = document.getElementById('enrichedTableDescription')
  //   if (inputElement) {
  //     inputElement.addEventListener('keyup', handleKeyUp)

  //     return () => {
  //       inputElement.removeEventListener('keyup', handleKeyUp)
  //       clearTimeout(typingTimer)
  //     }
  //   }
  // }, [])
  // useEffect(() => {
  //   if (
  //     enrichedTableData?.dataEntityName &&
  //     enrichedTableData?.entityType &&
  //     enrichedTableData?.description &&
  //     saveData == true &&
  //     isTyping == false &&
  //     !newCoreEntity
  //   ) {
  //     onConfirmSave()
  //   }
  // }, [enrichedTableData, saveData])
  // useEffect(() => {
  //   if (newCoreEntityEdit && newCoreEntity) {
  //     const updatedCoreEntity = newCoreEntity
  //     if (
  //       enrichedTableData &&
  //       enrichedTableData.dataEntityName &&
  //       enrichedTableData.entityType &&
  //       enrichedTableData.description &&
  //       saveData == true &&
  //       isTyping == false
  //     ) {
  //       updatedCoreEntity.dataEntityName = enrichedTableData?.dataEntityName
  //       updatedCoreEntity.entityType = enrichedTableData.entityType
  //       updatedCoreEntity.description = enrichedTableData.description
  //       console.log(updatedCoreEntity)
  //       setNewCoreEntity(updatedCoreEntity)
  //     }
  //   }
  // }, [enrichedTableData, isTyping])

  const findDelta = (
    attributeString: string,
    prevAttributeString: string
  ) => {
    const newItems = attributeString.split(' ').filter(Boolean);
    const oldItems = prevAttributeString.split(' ').filter(Boolean);

    // Find items that are in oldString but not in newString (removed items)
    let removedItems = oldItems.filter(item => !newItems.includes(item));

    // Remove any items from removedItems that are also in addedItems
    removedItems = removedItems.filter(item => !addedItems.includes(item));

    let consolidatedRemovedItems = [...removedItems, ...removedItemList]
    return {
      consolidatedRemovedItems
    };
  };

  const fetchRepoData = async () => {
    const data = await getConfigurationValue("IsAzureDevopsRepoSelected")
    if (data === "true") {
      setIsAzureRepoSelected(true)
      const AzureDevopsOrganizationName = await getConfigurationValue("AzureDevopsOrganizationName")
      const AzureDevopsProjectName = await getConfigurationValue("AzureDevopsProjectName")
      const AzureDevopsRepositoryID = await getConfigurationValue("AzureDevopsRepositoryID")
      const fileName = customEntity?.dataEntityName
      if (fileName) {
        // console.log("Inside the file name issue")
        const repo = `https://dev.azure.com/${AzureDevopsOrganizationName}/${AzureDevopsProjectName}/_git/${AzureDevopsRepositoryID}?path=${dataPodId}/custom-entities/${fileName}&version=GBmain`
        setRepoUrl(repo)
      }
    } else {
      setIsAzureRepoSelected(false)
      const GitOwner = await getConfigurationValue("GitOwner")
      const GitRepositoryName = await getConfigurationValue("GitRepositoryName")
      const fileName = customEntity?.dataEntityName
      const repo = `https://github.com/${GitOwner}/${GitRepositoryName}/blob/main/${dataPodId}/custom-entities/${fileName}`
      setRepoUrl(repo)
    }
  }

  const generateCodeHandler = async () => {
    if (customEntId && userInput2.length) {
      setCodeGenerating(true)
      const pycode = await genEnrichedSparkCode(userInput2, customEntId)
      if (pycode?.data) {
        setNewCoreEntityId(customEntId)
        setNotebookString(pycode.data)
        // console.log(notebookString)
        // console.log(newCoreEntityId)
        // console.log(commitMessage)
        // console.log("about to call save Notebook")
        // const success = await saveNoteBoook(res.id, pycode.data, commitMessage)
        // success && success && onClickHome()
        setCodeGenerating(false)
      } else {
        console.log('error: generate code')
      }
    }
  }

  const saveEnrichedModel = async () => {
    setLoading(true)
    setIsEditMode(false)
    if (enrichedTableData?.dataEntityName && enrichedTableData?.entityType && enrichedTableData?.description) {
      const res = await saveNewCustomEntity(enrichedTableData)
      if (res?.id) {
        setCustomEntId(res.id)
        setCustomEntName(res.dataEntityName)
        // console.log(res)
        // console.log(userInput)
        const selectedEntitiesStrings = userInput
          .split('@')
          .filter((uv) => uv.trim().length > 0)
          .map((cs) => cs.trim())
        const selen = entityDataAll.filter((ed) =>
          selectedEntitiesStrings.some((ss) => ss.toLowerCase() === ed.dataEntityName.toLowerCase()),
        )
        const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        let allEntityReconIds: number[] = []
        for (let i = 0; i < uniqEn.length; i++) {
          const reqBody = {
            dataCoreEntityId: res.id,
            dataSystemEntityId: uniqEn[i].id,
            profileName: 'string',
          }
          const enRecon = await addEntityRecon(reqBody)
          if (enRecon?.id) {
            allEntityReconIds = [...allEntityReconIds, enRecon?.id]
          }
        }
        getEntityChilds(res.id)
        if (userInput3.length && dataPodId && res && coreEntityAttribute.length) {
          const attributeNames = userInput3
            .split('@')
            .filter((name) => name.trim().length > 0)
            .map((name) => name.trim())
          // console.log({ attributeNames })
          // console.log({ coreEntityAttribute })
          const combinedBody: AddCoreEntityAttribute[] | boolean = generateEnrichedCoreAttributesBodySave(
            selectedAttributes,
            generatedEnrichedAttributes,
            dataPodId,
            res.id,
          )
          // if (combinedBody && combinedBody.length) {
          // console.log(combinedBody)
          // const response = await submitNewAttribute(combinedBody)
          const response = await syncCoreEntityAttibuteFunc(dataPodId, userInput3, res.id)
          // console.log(response)
          // } else if (!combinedBody) {
          // console.log('No need to create attributes')
          // }
          // }
        }
        if (res && fetchedRelations.length > 0) {
          await saveMultipleCoreEntityRelationships(res.id)
        }
        if (allEntityReconIds.length === selectedEntitiesStrings.length) {
          // console.log('Inside the if of the transformers')
          const transformBody = {
            dataAttributeMapId: allEntityReconIds[0],
            transformerText: userInput2,
            transformerQuery: '',
            dataEntityId: res.id,
            transformerTypeName: 'Enriched',
          }
          const success = await addTransformer(transformBody)
          if (success && success) {
            setTransformer(success.newTransformer)
            // const pycode = await genEnrichedSparkCode(userInput2, res?.id)
            // if (pycode?.data) {
            //   setNewCoreEntityId(res.id)
            //   setNotebookString(pycode.data)
            //   setTransformer(success.newTransformer)
            //   // console.log(notebookString)
            //   // console.log(newCoreEntityId)
            //   // console.log(commitMessage)
            //   // console.log("about to call save Notebook")
            //   // const success = await saveNoteBoook(res.id, pycode.data, commitMessage)
            //   // success && success && onClickHome()
            // } else {
            //   console.log('error: generate code')
            // }
          }
        } else {
          console.log('Error: EntityRecon')
        }
      } else {
        setErrorState(true)
        setErrorMessage("Failed to add enriched data entity.")
      }
    }
    setLoading(false)
  }

  const saveMultipleCoreEntityRelationships = async (enrichedEntityId: number) => {
    const payload: NewEntityRelationShip[] = []

    fetchedRelations.forEach((relation) => {
      if (relation.id > 0) {
        return
      }

      const newEntityRelationship: NewEntityRelationShip = {
        dataCoreEntityId: relation.leftEntityId,
        dataSystemEntityId: relation.rightEntityId,
        joinCoreAttributeId: relation.leftAttributeId,
        joinSystemAttributeId: relation.rightAttributeId,
        similarityScoreByName: relation.similarityScoreByName,
        similarityScoreByData: relation.similarityScoreByData,
        runId: 0,
        profileName: 'generateRelationships',
        cardinality: relation.cardinality,
        enrichedEntityId,
        overlapPercentage: relation.sampleOverlapPercentage || null,
        schemaCompatibility: (relation.schemaCompatibility == 1 ? true : false) || null,
      }

      payload.push(newEntityRelationship)
    })

    if (!payload.length) {
      return
    }

    const response = await addMultipleEntityRelations(payload)

    if (response) {
      setFetchedRelations((prevRelations) => {
        let updatedRelations = [...prevRelations]

        response.forEach((responseRelation) => {
          const relationIndex = updatedRelations.findIndex(
            (prevRelation) =>
              prevRelation.leftEntityId === responseRelation.leftEntityId &&
              prevRelation.rightEntityId === responseRelation.rightEntityId &&
              prevRelation.leftAttributeId === responseRelation.leftAttributeId &&
              prevRelation.rightAttributeId === responseRelation.rightAttributeId,
          )
          if (relationIndex > -1) {
            // Update existing relation
            updatedRelations[relationIndex] = responseRelation
          } else {
            // Add new relation if not found
            updatedRelations.push(responseRelation)
          }
        })

        return updatedRelations
      })
    }
  }

  const updateEnrichedModel = async () => {
    if (
      enrichedTableData?.dataEntityName &&
      enrichedTableData?.entityType &&
      enrichedTableData.entityType.length &&
      enrichedTableData?.description &&
      currentEntityId
    ) {
      setLoading(true)
      const updateSuccess = await updateCustomEntity({
        id: currentEntityId,
        ...enrichedTableData,
        profileState: 'update',
      })
      if (updateSuccess) {
        const selectedEntitiesStrings = userInput
          .split('@')
          .filter((uv) => uv.trim().length > 0)
          .map((cs) => cs.trim())

        // const selen = entityData.filter((ed) => selectedEntitiesStrings.some((ss) => ss === ed.dataEntityName))
        const selen = entityDataAll.filter((ed) =>
          selectedEntitiesStrings.some((ss) => ss.toLowerCase() === ed.dataEntityName.toLowerCase()),
        )
        const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        // const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        let allEntityReconIds: number[] = []

        // 1. eval newChild entites with ref current child entites
        // 2. eval child entites toDelete that are not included in the update
        const currentChildEIDS = currentChildEntityIds.map((ce) => ce.dataSystemEntityId)

        const selectedEIds = uniqEn.map((ue) => ue.id)

        const newEntitiesToAdd = uniqEn.filter((uq) => !currentChildEIDS.includes(uq.id))

        const childEntitiesToDelete = currentChildEntityIds.filter(
          (cID) => !selectedEIds.includes(cID.dataSystemEntityId),
        )

        for (let i = 0; i < newEntitiesToAdd.length; i++) {
          const reqBody = {
            dataCoreEntityId: currentEntityId,
            dataSystemEntityId: newEntitiesToAdd[i].id,
            profileName: 'string',
          }
          const enRecon = await addEntityRecon(reqBody)
          if (enRecon?.id) {
            allEntityReconIds = [...allEntityReconIds, enRecon?.id]
          }
        }
        getEntityChilds(currentEntityId)
        if (userInput3.length && dataPodId && currentEntityId && coreEntityAttribute.length) {
          const attributeNames = userInput3
            .split('@')
            .filter((name) => name.trim().length > 0)
            .map((name) => name.trim())
          // const combinedBody: AddCoreEntityAttribute[] | boolean = generateEnrichedCoreAttributesBodySave(
          //   selectedAttributes,
          //   generatedEnrichedAttributes,
          //   dataPodId,
          //   currentEntityId
          // )
          // if (combinedBody && combinedBody.length) {
          // console.log({ combinedBody })
          // const response = await submitNewAttribute(combinedBody)
          const response = await syncCoreEntityAttibuteFunc(dataPodId, userInput3, currentEntityId)
          // console.log(response)
          // } else if (!combinedBody) {
          //   console.log('No need to create attributes')
          // }
        }
        const toDeleteReconIds = childEntitiesToDelete.map((ct) => ct.id)
        // console.log({ toDeleteReconIds })
        if (toDeleteReconIds.length) {
          const deleteReconSuccess = await confirmDeleteEntityRecon(toDeleteReconIds)
          // console.log({ deleteReconSuccess })
        }
        await saveMultipleCoreEntityRelationships(currentEntityId)
        if (
          allEntityReconIds.length === newEntitiesToAdd.length &&
          newEntitiesToAdd.length &&
          dataPodId?.length &&
          transformer
        ) {
          const transformBody = {
            id: transformer.id,
            dataAttributeMapId: allEntityReconIds.length != 0 ? allEntityReconIds[0] : transformer.dataAttributeMapId,
            transformerText: userInput2,
            transformerQuery: '',
            dataEntityId: currentEntityId,
            transformerTypeName: 'Enriched',
          }
          const success = await updateTransformer(transformBody, dataPodId)
          // if (success && success) {
          // const pycode = await genEnrichedSparkCode(userInput2, currentEntityId)
          // if (pycode?.data) {
          //   //setNewCoreEntityId(res.id)
          //   setNotebookString(pycode.data)
          //   setCoreEntityEdit(false)
          // } else {
          //   console.log('error: generate code')
          // }
          // }
        }
        if (!newEntitiesToAdd.length && !allEntityReconIds.length && transformer) {
          const transformBody = {
            id: transformer.id,
            dataAttributeMapId: allEntityReconIds.length != 0 ? allEntityReconIds[0] : transformer.dataAttributeMapId,
            transformerText: userInput2,
            transformerQuery: '',
            dataEntityId: currentEntityId,
            transformerTypeName: 'Enriched',
          }
          if (dataPodId?.length) {
            const success = await updateTransformer(transformBody, dataPodId)
          }
          // const pycode = await genEnrichedSparkCode(userInput2, currentEntityId)
          // if (pycode?.data) {
          //   //setNewCoreEntityId(res.id)
          //   setNotebookString(pycode.data)
          //   // if (notebookString && newCoreEntityId && commitMessage) {
          //   //   // console.log("about to call save Notebook")
          //   //   const success = await saveNoteBoook(newCoreEntityId, notebookString, commitMessage)
          //   //   // success && success && onClickHome()
          //   // }
          //   // if(!dataTransformers.length){
          //   // }
          //   setCoreEntityEdit(false)
          // } else {
          //   console.log('error: generate code')
          // }
        } else {
          if (currentEntityId && !transformer && userInput2.length && currentChildEntityIds) {
            const transformBody = {
              dataAttributeMapId: currentChildEntityIds[0].id,
              transformerText: userInput2,
              transformerQuery: '',
              dataEntityId: currentEntityId,
              transformerTypeName: 'Enriched',
            }
            const success = await addTransformer(transformBody)
            if (success) {
              // const pycode = await genEnrichedSparkCode(userInput2, currentEntityId)
              // if (pycode) {
              //   setNotebookString(pycode.data)
              //   // setUserInput2('')
              //   setTransformer(success.newTransformer)
              // }
            }
          }
        }
      } else {
        console.log('Error: addNew entity')
      }
      setLoading(false)
    } else {
      console.log('No custom enriched entity Id')
    }
  }

  const addEnrichedModelData = async (name: string, value: string) => {
    // if(name == 'dataEntityName' && dataPodId){
    //   console.log(value)
    //   const ent = await fetchEntityByName(dataPodId,value)
    //   if(ent){
    //     console.log("True")
    //   }else{
    //     console.log("False")
    //   }
    // }
    setEnrichedTableData(
      (prev) =>
      ({
        ...prev,
        [name]: value,
      } as NewParent),
    )
    if (currentEntityId) {
      setCoreEntityEdit(true)
    }
  }
  const handleSelectMention = (id: number, entityName: string) => {
    const tempEntities = userInput.replace(/@$/, `@${entityName}`)
    if (!entityId.includes(id)) {
      setEntityId([...entityId, id])
    }
    setEntityChanged(true)
    setUserInput(tempEntities)
    // if (newCoreEntity && id) {
    //   const reqBody = {
    //     dataCoreEntityId: newCoreEntity.id,
    //     dataSystemEntityId: id,
    //     profileName: 'string',
    //   }
    //   saveNewEntityRecon(reqBody)
    // }
    setAnchorEl(null)
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }

  const syncEntityIds = () => {
    const mentionPattern = /@(\w+)/g
    let matches = userInput.match(mentionPattern)
    let currentMentions = matches ? matches.map((mention) => mention.substring(1)) : []
    const filteredEntityIds = entityId.filter((id, index) => {
      const entity = entityDataAll.find((ent) => ent.id === id)
      return entity && currentMentions.includes(entity.dataEntityName)
    })
    setEntityId(filteredEntityIds)
    const tempEntities = entityDataAll.filter((ent) => !entityId.includes(ent.id))
    setEntityData(tempEntities)
  }
  const sourceDataRow = useMemo(() => {
    if (entityId.length || fetchedRelations.length) {
      return fetchedRelations.map((relation) => ({
        id: `${relation.id}`,
        values: [
          relation.leftEntityName,
          relation.leftAttributeName,
          relation.rightEntityName,
          relation.rightAttributeName,
          relation.cardinality,
          relation.sampleOverlapPercentage,
          (relation.schemaCompatibility == true ? 1 : 0) || null,
          'EDIT',
          'DELETE',
        ],
      })) as DataTableRow[]
    }
    return []
  }, [fetchedRelations, fetchedRelations.length, entityId, entityId.length])
  const sourcePopUpHeaders = useMemo(() => {
    return [
      {
        label: 'Left Entity',
        sortableColumn: true,
      },
      {
        label: 'Left Attribute',
        sortableColumn: true,
      },
      {
        label: 'Right Entity',
        sortableColumn: true,
      },
      {
        label: 'Right Attribute',
        sortableColumn: true,
      },
      {
        label: 'Cardinality',
      },
      {
        label: 'Sample Overlap Percentage',
      },
      {
        label: 'SchemaCompatibility',
      },
      {
        label: 'Edit',
      },
      {
        label: 'Delete',
      },
    ]
  }, [])

  // const saveNewEntityRecon = async (addReconData: AddEntityRecon) => {
  //   if (addReconData) {
  //     const enRecon = await addEntityRecon(addReconData)
  //     console.log(userEnteredEntityIds)
  //     if (enRecon && userEnteredEntityIds.length >= 2) {
  //     }
  //   }
  // }
  // console.log(userEnteredEntityIds)
  const transformationTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // const entityNamesArray = value.split(',')
    // entityNamesArray.forEach((entityName) => {
    //   const matchingEntities = fetchedEntities.filter((ent) => `@${ent.dataEntityName}` === entityName)
    //   if (matchingEntities.length > 0) {
    //     tempIds.push(...matchingEntities.map((ent) => ent.id))
    //   }
    // })

    // console.log(tempIds)
    // setUserEnteredEntityIds([...tempIds])
    if (value == '') {
      setEntityId([])
    }
    if (value != '') {
      const splitArray = value
        .split('@')
        .map((item) => item.trim())
        .filter((item) => item !== '')

      const userEntityIds: number[] = []

      splitArray.map((name) => {
        const entity = entityDataAll.find((entity) => entity.dataEntityName === name)
        if (entity) {
          if (entity.entityType === 'Transaction' && enrichedTableData.entityType !== '2') {
            addEnrichedModelData('entityType', '2')
          }
          if (!entityId.includes(entity.id)) {
            userEntityIds.push(entity.id)
          }
        }
      })

      setEntityId([...entityId, ...userEntityIds])
    }

    setUserInput(value)
    // setShowColumnList(value.endsWith('@'))

    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }
  const transformationTextChangeHandler2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUserInput2(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl2(e.currentTarget)
    } else {
      setAnchorEl2(null)
    }
  }
  const transformationTextChangeHandler3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUserInput3(value)
    // const { consolidatedRemovedItems } = findDelta(value, userInput3)
    // setRemovedItems(consolidatedRemovedItems);

    // setShowColumnList(value.endsWith('@'))

    if (value.endsWith('@')) {
      setAnchorEl3(e.currentTarget)
    } else {
      setAnchorEl3(null)
    }
  }
  const handleSelectMention2 = (columnName: string) => {
    const promptText = userInput2.replace(/@$/, `@${columnName}`)
    setUserInput2(promptText)
    // const tempId = userEnteredEntityIds.includes(id)
    //   ? userEnteredEntityIds.filter((ix) => {
    //       return ix !== id
    //     })
    //   : [...userEnteredEntityIds, id]
    // const tmpUserInput = entities
    //   .filter((e) => tempId.includes(e.id))
    //   .map((ec) => `@${ec.dataEntityName}`)
    //   .join(',')
    // setUserInput(tmpUserInput)
    // setUserEnteredEntityIds(tempId)
    setAnchorEl2(null)
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }
  const handleSelectMention3 = (
    attributeName: string,
    attributeId: number,
    description: string,
    type: string,
    entityId: number,
    entityName: string | null | undefined
  ) => {
    const promptText = userInput3.replace(/@$/, `@${entityName}.${attributeName}`)
    const addedAttribute = `@${entityName}.${attributeName}`
    let tempAdded = addedItems
    tempAdded.push(addedAttribute)
    setAddedItems(tempAdded)

    const selectedCoreAttribute = coreEntityAttribute.find((attr) => attr.id === attributeId)
    const isAttrDuplicate = !!selectedAttributes.find(
      (attr) => attr.entityId == entityId && attr.attributeName == attributeName,
    )
    if (selectedCoreAttribute && !isAttrDuplicate) {
      setSelectedAttributes((prevAttributes) => [
        ...prevAttributes,
        { attributeName, attributeDescription: description, attributeType: type, entityId },
      ])
      setAttributeChanged(true)
      setUserInput3(promptText)
    }
    // const tempId = userEnteredEntityIds.includes(id)
    //   ? userEnteredEntityIds.filter((ix) => {
    //       return ix !== id
    //     })
    //   : [...userEnteredEntityIds, id]
    // const tmpUserInput = entities
    //   .filter((e) => tempId.includes(e.id))
    //   .map((ec) => `@${ec.dataEntityName}`)
    //   .join(',')
    // setUserInput(tmpUserInput)
    // setUserEnteredEntityIds(tempId)
    setAnchorEl3(null)
    // if (currentEntityId && !coreEntityEdit) {
    //   setCoreEntityEdit(true)
    // }
  }
  // const handleSelectMention3 = (attributeName: string, attributeId: number) => {
  //   const promptText = userInput3.replace(/@$/, `@${attributeName}`);

  //   // Find the selected attribute in the coreEntityAttribute array
  //   const selectedAttribute = coreEntityAttribute.find(attr => attr.id === attributeId);

  //   if (selectedAttribute) {
  //     setAttributeSelected(prevSelected => {
  //       if (!prevSelected.includes(attributeName)) {
  //         return [...prevSelected, attributeName];
  //       }
  //       return prevSelected;
  //     });

  //     setAttributeDict(prevDict => {
  //       if (!prevDict[attributeId]) {
  //         return {
  //           ...prevDict,
  //           [attributeId]: {
  //             AttributeName: attributeName,
  //             AttributeDescription: selectedAttribute.dataAttributeDescription // Use the correct property for description
  //           }
  //         };
  //       }
  //       return prevDict;
  //     });

  //     setAttributeChanged(true);
  //   }

  //   setUserInput3(promptText);
  //   setAnchorEl3(null);
  // };

  // const handleEditClick = () => {
  //   console.log('inside the edit click')
  //   setCoreEntityDisplay(!coreEntityDisplay)
  //   setNewCoreEntityEdit(true)
  //   // updateEntityName(newCoreEntity as UpdateEntityNameBody)
  // }

  // const handleDeleteClick = async () => {
  //   console.log('inside the delete click')
  //   const entityId: number[] = []
  //   if (newCoreEntity) {
  //     entityId.push(newCoreEntity?.id)
  //   }
  //   const res = await deleteEntity(entityId)
  //   if (res) {
  //   }
  // }

  const onClickHome = () => {
    const enrichedModelPage = generatePath(ROUTES.DataModelingEnterpriseEnrichedTables, {
      dataPodId: dataPodId,
    })
    navigate(enrichedModelPage)
  }
  const onButtonClick = () => {
    const enrichedModelToRelations = generatePath(ROUTES.DataModelingEntityRelationships, { dataPodId: dataPodId })
    navigate(enrichedModelToRelations)
  }

  const onActionClick = (rowId: string, actionType: ShadowTableAction) => {
    const tbID = Number(rowId)
    const selRow = fetchedRelations.find((r) => r.id === tbID)

    if (selRow) {
      setSelectedEntityRelation(selRow)
    }
    if (actionType === ShadowTableAction.Editable) {
      setShowEditRelationPopUp(true)
      // console.log({selectedEntityRelation})
      // console.log({sourceDataRow})
    }
    if (actionType === ShadowTableAction.Deletable) {
      setShowDeleteRelationWarning(true)
    }
  }

  // const clickNextAction = () => {
  //   console.log("Inside click next action")
  //   console.log(currentEntityId)
  // }
  const disableContinue = useMemo(() => {
    const disableCon = !(
      isValidEntry(enrichedTableData?.dataEntityName) &&
      isValidDescription(enrichedTableData?.description) &&
      userInput &&
      userInput2
    )
    if (!newCoreEntityId) return disableCon || loading
    if (newCoreEntityId) return !notebookString
    if (currentEntityId) return loading
    return
  }, [
    enrichedTableData?.dataEntityName,
    enrichedTableData?.description,
    userInput,
    userInput2,
    loading,
    newCoreEntityId,
    currentEntityId,
  ])

  const clickHandler = async () => {
    if (!currentEntityId && clickUpdateLabel == 'Save') {
      // console.log("there is no core entity id present")
      // if (notebookString && newCoreEntityId && commitMessage) {
      //   // console.log("about to call save Notebook")
      //   const success = await saveNoteBoook(newCoreEntityId, notebookString, commitMessage)
      //   success && success && onClickHome()
      // } else {
      // console.log("note book else")
      // console.log("inside else of notebook string new core entity id and commit message")
      await saveEnrichedModel()
      // }
    } else {
      // if (!coreEntityEdit && notebookString && commitMessage) {
      //   setShowPopUp(!showPopUp)
      // } else {
      // console.log("inside corentityedit else")

      // if (transformer) {
      await updateEnrichedModel()
      // }else if(currentEntityId && !transformer && userInput2.length){

      // }

      // }
    }
  }
  const clickUpdateLabel = useMemo(() => {
    if (!nbStringPresent && !customEntId) {
      return 'Save'
    } else {
      return 'Update'
    }
  }, [isEditMode, nbStringPresent, customEntId])

  const SaveUpdateLabel = useMemo(() => {
    if (nbStringPresent) {
      return 'Update Notebook'
    }
    return 'Save Notebook'
  }, [isEditMode, nbStringPresent])

  // const clickUpdateSaveOrEdit = useMemo(() => {
  //   if (attributeChanged) {
  //     return 'Save'
  //   }
  // }, [attributeChanged,coreAttributes?.length, attributeSelected])

  const handleNext = async () => {
    // console.log('comes inside the handle next')
    // console.log(notebookString, commitMessage, currentEntityId, coreEntityEdit)
    if (!coreEntityEdit && notebookString && commitMessage && currentEntityId) {
      setShowPopUp(false)
      const success = await updateEntityNB(currentEntityId, notebookString, commitMessage)

      if (!success) {
        setErrorMessage('Failed to update notebook.')
        setErrorState(true)
      } else if (success) {
        setSuccessMessage('Updated notebook successfully.')
        setSuccessState(true)
      }
      // success && success && onClickHome()
    }
  }
  const onTextChange = (name: string, value: string) => {
    setcommitMessage(value)
  }

  const executeNotebook = async () => {
    if (dataPodId && currentEntityId && customEntName && notebookString) {
      const databricksParams: CreateJobParams = {
        dataPodId,
        customEntityID: currentEntityId
      }

      const fabricParams: FabricNotebookCreateRequest = {
        workspaceId: '',
        displayName: dataPodId + "_" + customEntName || '',
        description: `Executing measure ${customEntName} for datapodId ${dataPodId}`,
        accessToken: '',
        code: notebookString,
        executionData: {},
        environmentId: '',
      }

      const routePage = generatePath(ROUTES.EditCustomEnrichedModel, {
        dataPodId: dataPodId,
        customEntityId: currentEntityId,
      })

      const jobDetails: JobDetails = {
        triggerType: 'Enriched Entity Code Execution',
        parameters: {
          id: currentEntityId,
          name: customEntName,
          route: routePage,
        },
      }
      try {
        const payload = await getCreateJobPayload(dataPodId, databricksParams, fabricParams)

        if (payload) {
          const resp = await createAndRunNewJob(jobDetails, payload)
          if (resp) {
            setRunId(resp)
          }
        } else {
          setErrorMessage('Failed to create Databricks Job')
          setErrorState(true)
        }
      } catch (err: any) {
        setErrorMessage(err.message)
        setErrorState(true)
      }
    }
  }

  const runCustomEnrichedEntityProfiling = async (datapodId: string, customEnrichedEntityId: number, fileType: string) => {
    if (datapodId && customEnrichedEntityId) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify(customEnrichedEntityId),
        i_data_sample_size: '0.9',
        i_file_type: 'parquet'

      };

      const jobParams = {
        name: customEntName || '',
        route: generatePath(ROUTES.EditCustomEnrichedModel, {
          dataPodId: dataPodId,
          customEntityId: customEnrichedEntityId

        })
      }
      const payload = await getRunNewJobPayload(datapodId, requestBody, 'customEnrichedTableAndAdvancedTableScan_Job_Id');

      if (payload) {
        // console.log({ payload })
        const runId = await runNewJob(activeRunIds, jobParams, 'Custom Enriched Table And Column Profiling', payload);

        if (runId) {
          setRunId(runId)
        }
      }
    }
  }

  const closeErrorMessage = () => {
    setErrorState(false)
    setErrorMessage(undefined)
  }

  const closeSuccessMessage = () => {
    setSuccessState(false)
    setSuccessMessage(undefined)
  }
  // const generateEnrichedCoreAttributesBodySave = (
  //   userEnteredAttribute: SelectedAttribute[],
  //   genAIGeneratedAttributes: any,
  //   dataPodId: string,
  //   coreEntityId: number
  // ) => {
  //   let attrNames: string[] = [];

  //   // Collect all attribute names from genAIGeneratedAttributes
  //   genAIGeneratedAttributes.forEach((response: any) => {
  //     attrNames = [...attrNames, ...response.attributes];
  //   });

  //   // Combine user entered and AI generated attributes
  //   const attributes = [...(userEnteredAttribute ?? []), ...(genAIGeneratedAttributes ?? [])];

  //   console.log({ attributes });

  //   // Return false if no user-entered attributes or no AI generated attributes
  //   if (!userEnteredAttribute || !genAIGeneratedAttributes.length) {
  //     return false;
  //   }

  //   // Function to check if an attribute already exists in coreAttributes
  //   const isAttributeInCore = (entityId: number, attributeName: string) => {
  //     if (!coreAttributes) return false; // If coreAttributes is not defined, no attributes are in core

  //     return coreAttributes.some(
  //       (coreAttr) => coreAttr.dataEntityId === entityId && coreAttr.dataCoreAttributeName === attributeName
  //     );
  //   };

  //   // Function to create a unique key for tracking created bodies
  //   const createTrackingKey = (entityId: number, attributeName: string) => {
  //     return `${entityId}:${attributeName}`;
  //   };

  //   // Create the body only for attributes not present in coreAttributes and not already created
  //   const newBodies = attributes.flatMap((entry: any) => {
  //     if (entry.attributes && Array.isArray(entry.attributes)) {
  //       // Create body only for new attributes not in coreAttributes and not already created
  //       return entry.attributes
  //         .filter((attribute: any) => {
  //           const trackingKey = createTrackingKey(entry.entityId, attribute);
  //           if (isAttributeInCore(entry.entityId, attribute) || createdBodies.has(trackingKey)) {
  //             return false; // Skip if already in core or created
  //           }
  //           return true; // Include new attribute
  //         })
  //         .map((attribute: any) => ({
  //           dataCoreEntityId: coreEntityId,
  //           dataCoreAttributeName: attribute,
  //           dataPodId: dataPodId,
  //           dataCoreAttributeDataType: 'string',
  //           dataCoreClassification: 'string',
  //           description: attribute,
  //           isBusinessKey: true,
  //           dataAttributeType: 'string',
  //           dataEntityId: entry.entityId,
  //         }));
  //     } else {
  //       // Check if the single attributeName is new and create the body
  //       const trackingKey = createTrackingKey(entry.entityId, entry.attributeName);
  //       if (entry.attributeName && !isAttributeInCore(entry.entityId, entry.attributeName) && !createdBodies.has(trackingKey)) {
  //         return [{
  //           dataCoreEntityId: coreEntityId,
  //           dataCoreAttributeName: entry.attributeName,
  //           dataPodId: dataPodId,
  //           dataCoreAttributeDataType: 'string',
  //           dataCoreClassification: 'string',
  //           description: entry.attributeDescription || entry.attributeName,
  //           isBusinessKey: true,
  //           dataAttributeType: entry.attributeType || 'string',
  //           dataEntityId: entry.entityId,
  //         }];
  //       } else {
  //         return [];
  //       }
  //     }
  //   });

  //   // Update the createdBodies state to include the new bodies
  //   setCreatedBodies((prevCreatedBodies) => {
  //     const updatedBodies = new Set(prevCreatedBodies);
  //     newBodies.forEach((body) => {
  //       const key = createTrackingKey(body.dataEntityId, body.dataCoreAttributeName);
  //       updatedBodies.add(key);
  //     });
  //     return updatedBodies;
  //   });

  //   return newBodies;
  // };
  const generateEnrichedCoreAttributesBodySave = (
    userEnteredAttribute: SelectedAttribute[],
    genAIGeneratedAttributes: any,
    dataPodId: string,
    coreEntityId: number
  ) => {
    let attrNames: string[] = [];

    // Collect all attribute names from genAIGeneratedAttributes
    genAIGeneratedAttributes.forEach((response: any) => {
      attrNames = [...attrNames, ...response.attributes];
    });

    // Combine user-entered and AI-generated attributes into a single list
    const attributes = [...(userEnteredAttribute ?? []), ...(genAIGeneratedAttributes ?? [])];

    // console.log({ attributes });

    // Return false if no user-entered attributes or no AI-generated attributes
    if (!userEnteredAttribute || !genAIGeneratedAttributes.length) {
      return false;
    }

    // Function to check if an attribute already exists in coreAttributes
    const isAttributeInCore = (entityId: number, attributeName: string) => {
      if (!coreAttributes) return false;
      return coreAttributes.some(
        (coreAttr) =>
          (coreAttr.dataEntityId === entityId || coreAttr.dataCoreEntityId === entityId) &&
          coreAttr.dataCoreAttributeName === attributeName
      );
    };

    // Function to create a unique key for tracking created bodies
    const createTrackingKey = (entityId: number | undefined, attributeName: string) => {
      const id = entityId ?? coreEntityId; // Fallback to dataCoreEntityId if entityId is not present
      return `${id}:${attributeName}`;
    };

    // Use a Set to track processed attributes and avoid duplicates
    const processedAttributes = new Set<string>();

    // Create the body only for attributes not present in coreAttributes and not already created
    const newBodies = attributes.flatMap((entry: any) => {
      const entityId = entry.entityId ?? coreEntityId; // Use coreEntityId if entityId is missing

      if (entry.attributes && Array.isArray(entry.attributes)) {
        return entry.attributes
          .filter((attribute: string) => {
            const trackingKey = createTrackingKey(entityId, attribute);

            // Deduplicate using processedAttributes Set
            if (
              processedAttributes.has(trackingKey) ||
              isAttributeInCore(entityId, attribute) ||
              createdBodies.has(trackingKey)
            ) {
              return false;
            }

            // Mark as processed
            processedAttributes.add(trackingKey);
            return true;
          })
          .map((attribute: string) => ({
            dataCoreEntityId: coreEntityId,
            dataCoreAttributeName: attribute,
            dataPodId: dataPodId,
            dataCoreAttributeDataType: 'string',
            dataCoreClassification: 'string',
            description: attribute,
            isBusinessKey: true,
            dataAttributeType: 'string',
            dataEntityId: entityId,
          }));
      } else {
        const trackingKey = createTrackingKey(entityId, entry.attributeName);

        // Check if the single attributeName is new and not duplicated
        if (
          entry.attributeName &&
          !processedAttributes.has(trackingKey) &&
          !isAttributeInCore(entityId, entry.attributeName) &&
          !createdBodies.has(trackingKey)
        ) {
          processedAttributes.add(trackingKey);
          return [
            {
              dataCoreEntityId: coreEntityId,
              dataCoreAttributeName: entry.attributeName,
              dataPodId: dataPodId,
              dataCoreAttributeDataType: 'string',
              dataCoreClassification: 'string',
              description: entry.attributeDescription || entry.attributeName,
              isBusinessKey: true,
              dataAttributeType: entry.attributeType || 'string',
              dataEntityId: entityId,
            },
          ];
        } else {
          return [];
        }
      }
    });

    // Update the createdBodies state to include the new bodies
    setCreatedBodies((prevCreatedBodies) => {
      const updatedBodies = new Set(prevCreatedBodies);
      newBodies.forEach((body) => {
        const key = createTrackingKey(body.dataEntityId, body.dataCoreAttributeName);
        updatedBodies.add(key);
      });
      return updatedBodies;
    });

    return newBodies;
  };
  // const saveNewlyAddedCoreAttributes = async () => {
  //   if (attributeChanged) {
  //     if (selectedAttributes && dataPodId && currentEntityId) {
  //       // console.log({selectedAttributes})
  //       const cummulativeBody = generateCoreAttributesBody(selectedAttributes, dataPodId, currentEntityId)
  //       // console.log({cummulativeBody})
  //       const response = await submitNewAttribute(cummulativeBody)
  //       if (response) {
  //         getAttributes(currentEntityId)
  //         setAttributeChanged(false)
  //       }
  //     }
  //     // const resp = await updateCoreEntityAttibuteFunc()
  //   }
  // }
  const handleToggleExpand = () => {
    setErrorBlockExpanded(!errorBlockExpanded)
  }
  const handleToggleExpandRelation = () => {
    setErrorBlockExpandedRelations(!errorBlockExpandedRelations)
  }
  const replaceEntityIdsWithNames = (message: string | undefined): string | undefined => {
    if (typeof message === 'string') {
      const entityMap: Record<number, string> = entityDataAll.reduce((acc, entity) => {
        acc[entity.id] = entity.dataEntityName;
        return acc;
      }, {} as Record<number, string>);

      const entityIdPattern = /\d+/g;
      const updatedMessage = message.replace(entityIdPattern, (matchedId) => {
        const id = Number(matchedId);
        return entityMap[id] || matchedId;
      });

      return updatedMessage;
    }
    return undefined;
  };

  const getGenAiEnrichedCoreEntityAttributes = async () => {
    if (enrichedTableData && dataPodId && entityId.length) {
      setFetchLoading(true);

      if (DataSystemEntityIds) {
        try {
          const attributeProfiles = await refetchAttributes(DataSystemEntityIds);
          let attributes: enrichedCoreAttributes[] = [];
          let messagesArray: string[] = [];

          if (attributeProfiles && enrichedTableData.entityType !== undefined) {
            const response = await fetchEnrichedCoreEntityAttributes(
              dataPodId,
              enrichedTableData.dataEntityName,
              enrichedTableData.description,
              enrichedTableData.entityType,
              entityId,
            );

            if (response?.result === 'Success') {
              setUserInput3('')
              if (response.data) {
                attributes = response.data;
                setGeneratedEnrichedAttributes(response.data)
              }

              if (response.warning) {
                const updatedString = replaceEntityIdsWithNames(response.warning);
                if (updatedString) messagesArray.push(updatedString);
              }

              if (response.missingLog) {
                const processedMissingLogs = response.missingLog.map((log: any) => {
                  const { EntityId, Reason } = log;
                  const entityIds = EntityId.split(',');
                  let updatedReason = Reason;

                  entityIds.forEach((id: string) => {
                    const entity = entityDataAll.find((item) => item.id === Number(id.trim()));
                    const entityName = entity ? entity.dataEntityName : `Unknown Entity ${id.trim()}`;
                    updatedReason = updatedReason.replace(id.trim(), entityName);
                  });

                  return `Category: ${log.EntityCategory}, Reason: ${updatedReason}`;
                });

                messagesArray.push(...processedMissingLogs);
                setCoreAttributesErrors(messagesArray);
              }
            } else {
              if (response?.error) {
                let coreAttrErr = []
                coreAttrErr.push(response.error)
                setCoreAttributesErrors(coreAttrErr)
                setErrorMessage(response.error || 'Failed to fetch data');
                setErrorState(true);
              }
            }
          }
          if (attributes.length > 0) {
            const addedItemsSet = new Set(addedItems); // Create a Set from addedItems to avoid duplicates
            let attributesString = '';

            // Step 1: Process the addedItemsSet (this ensures no duplicates)
            if (addedItemsSet.size > 0) {
              addedItemsSet.forEach((item) => {
                attributesString += `${item} `;
              });
            }

            // Step 2: Process and append coreAttributes (concatenating and avoiding duplicates)
            if (coreAttributes && coreAttributes.length > 0) {
              const coreAttributesString = concatenateAttributeNames(coreAttributes);
              // console.log({coreAttributesString})

              // Split the concatenated coreAttributes into individual attribute strings
              const coreAttributesArray = coreAttributesString.trim().split(/\s+/);

              coreAttributesArray.forEach((coreAttr) => {
                if (!addedItemsSet.has(coreAttr)) {
                  // Only append if it's not already in addedItemsSet
                  attributesString += `${coreAttr} `;
                  addedItemsSet.add(coreAttr); // Add to the set to avoid duplicates later
                }
              });
            }

            // Step 3: Process the attributes and append new ones (if not already in addedItemsSet)
            attributes.forEach((data) => {
              const entityName = entityDataAll.find((fe) => fe.id === data.entityId)?.dataEntityName;

              if (entityName) {
                data.attributes.forEach((attribute) => {
                  const formattedAttribute = `@${entityName}.${attribute}`; // Format the attribute

                  // Only add the formattedAttribute if it hasn't been added already
                  if (!addedItemsSet.has(formattedAttribute)) {
                    attributesString += `${formattedAttribute} `;
                    addedItemsSet.add(formattedAttribute); // Add to set to track it
                  }
                });
              }
            });

            // Step 4: Trim the final attributesString to remove any trailing spaces
            attributesString = attributesString.trim();
            console.log(`attributeString:${attributesString}`)
            // Step 5: Set the user input with the final string if non-empty
            if (attributesString) {
              setUserInput3(attributesString);
              if (!customEntityId) {
                setBeforeCustomEntitySave(true)
                setTreeViewString(attributesString)
              } else {
                setBeforeCustomEntitySave(false)
                setUpdateFromParent(true)
              }
            }
          } else {
            setErrorMessage('Failed to generate core attributes.');
            setErrorState(true);
          }
        } catch (error) {
          console.error('Error fetching enriched attributes:', error);
          setErrorMessage('Failed to fetch enriched attributes.');
          setErrorState(true);
        } finally {
          setFetchLoading(false);
        }
      }
    }
  };
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files; // This is a FileList object

    if (files && files.length > 0 && dataPodId) {
      const file = files[0]; // Access the first file
      setUploadedFile(file)
      try {
        const respEmbedding = await addEmbeddings(dataPodId)
        // Call the API to get the entity data by file
        let resp: LocalLLmEntityData | undefined
        if (respEmbedding) {
          resp = await getEntityDataByFile(dataPodId, file);
        } else {
          resp = undefined
        }

        // Assuming the response contains data that maps to your NewEntityData
        if (resp) {
          // Update the enriched table data
          const newData: NewEntityData = {
            dataEntityName: resp.entity_name,
            profileName: resp.entity_type,
            description: resp.entity_description,
            coreEntity: true, // Adjust based on actual data
            entityType: resp.entity_type,
            dataLakeZone: 1, // Adjust based on actual data
            canonicalLoad: false, // Adjust based on actual data
          };

          // Update the state with the new data
          setEnrichedTableData(newData);

          // Set userInput2 with the transformation string
          if (resp.transformations) {
            setUserInput2(resp.transformations);
          }

          // Assuming `relationships` is the key in your response data that contains the relationships
          const fetchedRelations = resp.relationships;

          if (fetchedRelations) {
            // Update your relationships state
            const tempRelations = transformRelationships(fetchedRelations, dataPodId);
            setFetchedRelations(tempRelations);
          }

          // Process and set entity data similar to your previous logic
          let userInputEntitiesTemp = '';
          let canonicalEntitiesTemp = [...entityId]; // Assuming entityId is a state variable

          resp.children_entities.forEach((name: string) => {
            const entity = entityDataAll.find((ent) => ent.dataEntityName === name);

            if (entity) {
              // Check and update enrichedTableData.entityType
              if (entity.entityType === 'Transaction' && enrichedTableData.entityType !== '2') {
                addEnrichedModelData('entityType', '2'); // Assuming this function exists
              }

              if (resp && resp.children_attributes) {
                let attributesString = '';

                // Loop through each entity in children_attributes
                if (resp && resp.children_attributes.hasOwnProperty(entity.dataEntityName)) {
                  const attributes = resp.children_attributes[entity.dataEntityName];

                  // Concatenate attributes for each entity like `@EntityName.Attribute`
                  attributes.forEach((attribute: string) => {
                    attributesString += `@${entity.dataEntityName}.${attribute} `
                    const selectedCoreAttribute = coreEntityAttribute.find(
                      (attr) => attr.dataAttributeName === attribute && attr.dataEntityId === entity.id,
                    )
                    const isAttrDuplicate = !!selectedAttributes.find(
                      (attr) => attr.entityId == entity.id && attr.attributeName == attribute,
                    )
                    if (selectedCoreAttribute && !isAttrDuplicate) {
                      setSelectedAttributes((prevAttributes) => [
                        ...prevAttributes,
                        {
                          attributeName: attribute,
                          attributeDescription: selectedCoreAttribute.description,
                          attributeType: selectedCoreAttribute.dataAttributeDataType,
                          entityId: entity.id,
                        },
                      ])
                    }
                  })
                }

                // Update userInput3 with the concatenated attributes string
                setUserInput3(attributesString.trim()); // Remove any trailing spaces
              }


              // Add new entities to canonicalEntitiesTemp and userInputEntitiesTemp
              if (!canonicalEntitiesTemp.includes(entity.id)) {
                canonicalEntitiesTemp.push(entity.id);
                userInputEntitiesTemp += '@' + entity.dataEntityName + ' ';
              }
            }
          });

          // Update states after processing the entities
          setEntityId(canonicalEntitiesTemp);
          setEntityChanged(true);
          setUserInput(userInput + userInputEntitiesTemp); // Assuming userInput is a state variable

          // Process `children_attributes` for attributes string and update userInput3
          // if (resp.children_attributes) {
          //   let attributesString = '';

          //   // Loop through each entity in children_attributes
          //   for (const entityName in resp.children_attributes) {
          //     if (resp.children_attributes.hasOwnProperty(entityName)) {
          //       const attributes = resp.children_attributes[entityName];

          //       // Concatenate attributes for each entity like `@EntityName.Attribute`
          //       attributes.forEach((attribute: string) => {
          //         attributesString += `@${entityName}.${attribute} `;
          //         const selectedCoreAttribute = coreEntityAttribute.find((attr) => attr.dataAttributeName === attribute)
          //         const isAttrDuplicate = !!selectedAttributes.find(
          //           (attr) => attr. == entityId && attr.attributeName == attribute,
          //         )
          //         if (selectedCoreAttribute && !isAttrDuplicate) {
          //           setSelectedAttributes((prevAttributes) => [
          //             ...prevAttributes,
          //             { attributeName, attributeDescription: description, attributeType: type, entityId },
          //           ])
          //       });
          //     }
          //   }

          //   // Update userInput3 with the concatenated attributes string
          //   setUserInput3(attributesString.trim()); // Remove any trailing spaces
          // }
        }

        // console.log("File uploaded:", file);
        // console.log("File name:", file.name);
        // console.log("File size:", file.size);
        // console.log("File type:", file.type);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const transformRelationships = (
    localRelations: LocalLLmRelationship[],
    dataPodId: string
  ): RelationShipResponse[] => {
    return localRelations.map((relation, idx) => {
      // Access state variables directly (entityDataAll and coreEntityAttribute)
      const leftEntity = entityDataAll.find(entity => entity.dataEntityName === relation.LeftEntity);
      const rightEntity = entityDataAll.find(entity => entity.dataEntityName === relation.RightEntity);

      // Fetch leftAttributeId and rightAttributeId using coreEntityAttribute
      const leftAttribute = coreEntityAttribute.find(
        attribute => attribute.dataAttributeName === relation.LeftAttribute && attribute.dataEntityId === leftEntity?.id
      );
      const rightAttribute = coreEntityAttribute.find(
        attribute => attribute.dataAttributeName === relation.RightAttribute && attribute.dataEntityId === rightEntity?.id
      );

      // Set cardinality to 'one-to-one' by default
      const cardinality = 'one-to-one';

      return {
        id: idx, // Assuming you're generating IDs based on the index
        database: "", // Replace with actual value
        dataTableId: 0, // Replace with actual value
        dataTableName: "", // Replace with actual value
        leftEntityId: leftEntity?.id ?? 0, // Default to 0 if not found
        leftEntityName: relation.LeftEntity, // Use LeftEntity from relation
        leftAttributeId: leftAttribute?.id ?? 0, // Default to 0 if not found
        leftAttributeName: relation.LeftAttribute, // Use LeftAttribute from relation
        rightEntityId: rightEntity?.id ?? 0, // Default to 0 if not found
        rightEntityName: relation.RightEntity, // Use RightEntity from relation
        rightAttributeId: rightAttribute?.id ?? 0, // Default to 0 if not found
        rightAttributeName: relation.RightAttribute, // Use RightAttribute from relation
        cardinality: cardinality, // Default to 'one-to-one'
        similarityScoreByName: 0.9, // Default value, adjust if needed
        similarityScoreByData: 0.85, // Default value, adjust if needed
        dataPodId: dataPodId, // Use provided dataPodId
        schemaCompatibility: true, // Default value, adjust if needed
        sampleOverlapPercentage: 50, // Default value, adjust if needed
      };
    });
  };

  const onClickBack = (event: any): void => {
    onClickHome()
  }

  const handleNotebookString = async () => {
    // console.log('Inside the handle notebook string')
    // console.log(nbStringPresent)
    if (!nbStringPresent) {
      // console.log('Inaside the nbStrint present if')
      // console.log(notebookString, commitMessage, currentEntityId)
      let cstId: number | undefined
      if (customEntId) {
        cstId = customEntId
      } else if (currentEntityId) {
        cstId = currentEntityId
      }

      if (notebookString && commitMessage && cstId) {
        // console.log("about to call save Notebook")
        const success = await saveNoteBoook(cstId, notebookString, commitMessage)
        // console.log('It is in the save')
        setSuccessMessage('Saved notebook successfully.')
        setSuccessState(true)
        setIsEditMode(true)
        success && setnbStringPresent(true)
        // success && success && onClickHome()
      }
    } else {
      if (!coreEntityEdit && notebookString && commitMessage) {
        setShowPopUp(!showPopUp)
      }
    }
  }

  const onPreviewButtonClick = async () => {
    const enrichedPreviewRoute = generatePath(ROUTES.DataModelingEnrichedDataPreview, {
      dataPodId: dataPodId,
      customEntityID: customEntityId,
    })
    navigate(enrichedPreviewRoute)
  }
  const loadOptions = [{ value: 'Data Warehouse Load', label: 'Data Warehouse Load' }]

  const handleClick = (event: any) => {
    setAnchorEl4(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl4(null)
  }

  useEffect(() => {
    if (activeRunIds && runId) {
      const temp = activeRunIds.find((element) => element.runId === runId)
      // console.log({ temp })
      if (temp && temp.state.resultState === 'SUCCESS') {
        // console.log({ temp })
        if (temp.runName?.includes('LDH_LoadToDataWarehouse') && customEntityId && dataPodId) {
          setLoadToWarehouse(false)
        }
        if (temp.jobDetails?.triggerType.includes('Enriched Entity Code Execution') && customEntityId && dataPodId) {
          runCustomEnrichedEntityProfiling(dataPodId, Number(customEntityId), 'parquet')
        }
        if (temp.jobDetails?.triggerType.includes('Custom Enriched Table And Column Profiling') && customEntityId && dataPodId) {
          generateBusinessKeyForCustomEnrichedEntity(dataPodId, Number(customEntityId))
        }
      }
    }
  }, [activeRunIds])

  useEffect(() => {
    if (activeFabricRunStatus && runId) {
      const temp = activeFabricRunStatus.find((element) => element.id === runId)
      if (temp && temp.status === 'Completed') {
        // console.log({ temp })
        if (temp.jobDetails?.triggerType === 'Data Warehouse Load' && customEntityId && dataPodId) {
          setLoadToWarehouse(false)
        }
      }
    }
  }, [activeFabricRunStatus])

  const runDatawarehouseLoadJob = async () => {
    if (dataPodId && customEntityId) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify(customEntityId),
      };

      const jobParams = {
        name: customEntName || '',
        route: generatePath(ROUTES.EditCustomEnrichedModel, {
          dataPodId: dataPodId,
          customEntityId: customEntityId

        }),
      };

      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataWarehouse_Job_Id');

        if (payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'Data Warehouse Load', payload);

          if (runId) {
            setRunId(runId)
          }
        }
      } catch (error: any) {
        setErrorMessage(error.message)
        setErrorState(true)
      }
    }
  };

  const handleSubmit = (value: string) => {
    if (customEntityId) {
      // console.log(value)
      handleClose()
      switch (value) {
        case 'Data Warehouse Load':
          runDatawarehouseLoadJob()
          break
      }
    }
  }

  const onClickEntityRecommendation = async () => {
    const entityType = entityTypes.find((type) => type.id == Number(enrichedTableData.entityType))

    const response = await generateEntityRecommendation(
      enrichedTableData.dataEntityName,
      entityType?.value || entityTypes[0].value,
      enrichedTableData.description,
    )

    if (response && response.length > 0) {
      // console.log("Got the response")
      let userInputEntitiesTemp = ''
      let canonicalEntitiesTemp = entityId
      response.forEach((id) => {
        const entity = entityDataAll.find((ent) => ent.id == id)

        if (entity) {
          // console.log(enrichedTableData.entityType)
          if (entity.entityType === 'Transaction' && enrichedTableData.entityType !== '2') {
            addEnrichedModelData('entityType', '2')
          }
          if (!canonicalEntitiesTemp.includes(id)) {
            canonicalEntitiesTemp.push(id)
            userInputEntitiesTemp += '@' + entity.dataEntityName + ' '
          }
        }
      })
      setEntityId(canonicalEntitiesTemp)
      setEntityChanged(true)
      setUserInput(userInput + userInputEntitiesTemp)
      setSuccessMessage('Generated recommendations successfully.')
      setSuccessState(true)
    } else if (response && response.length == 0) {
      setSuccessMessage('Gen AI did not generate any recommendations.')
      setSuccessState(true)
    }
    // } else if (!response && errorGeneratingEntityRecommendation) {
    //   setErrorMessage(errorGeneratingEntityRecommendation)
    //   setErrorState(true)
    // }
  }
  const generateBusinessKeyForCustomEnrichedEntity = async (dataPodId: string, entityId: number) => {
    const genKeyBody: GenBusinessKey = {
      dataPodId: dataPodId,
      entityId: entityId,
    }
    const isSuccess = await generateBusinessKey(genKeyBody)
    if (isSuccess) {
      setSuccessMessage("Business Keys Generated")
      setSuccessState(true)
    } else {
      setErrorMessage("Error While Generating Business Keys")
      setErrorState(true)
    }
  }
  const handleDeltaKeyClose = () => {
    setShowDeltaKeyPopup(false);
  };
  const handleDeltaKeyConfirm = async () => {
    // Loop through coreAttributes and update the isBusinessKey field
    if (coreAttributes?.length) {
      const updatedAttributes: getCoreEntityAttributeResponse[] = coreAttributes.map((attribute) => {
        // Check if the attribute's ID is in the selectedDeltaKeys
        const isBusinessKey = selectedDeltaKeys.includes(attribute.id);
        // console.log(isBusinessKey)

        // Return a new object that matches the getCoreEntityAttributeResponse type
        return {
          id: attribute.id,
          dataCoreEntityId: attribute.dataCoreEntityId,
          dataCoreAttributeName: attribute.dataCoreAttributeName,
          dataPodId: attribute.dataPodId,
          dataCoreAttributeDataType: attribute.dataCoreAttributeDataType,
          dataCoreClassification: attribute.dataCoreClassification,
          dataEntityId: attribute.dataEntityId,
          description: attribute.description,
          isBusinessKey: isBusinessKey, // Update based on selectedDeltaKeys
          isActive: attribute.isActive,
          dataAttributeType: attribute.dataAttributeType,
        };
      });
      // console.log(updatedAttributes)

      // Make API calls for each updated attribute
      // for (const attribute of updatedAttributes) {
      try {
        // Make the API call using your hook
        await updateCoreEntityAttibuteFuncBulk(updatedAttributes);
        // console.log(`Successfully updated attribute`);
      } catch (error) {
        console.error(`Error updating attribute with ID`);
      }
      // }

      // Close the popup after updating
      setShowDeltaKeyPopup(false);
    }

  };
  const dataColumnMap = useMemo(() => {
    if (coreAttributes && coreAttributes.length && customEntityId) {
      // console.log(coreAttributes)
      return coreAttributes.map(col => {
        return {
          id: col.id,
          name: col.dataCoreAttributeName
        }
      })
    }
    return []
  }, [coreAttributes, coreAttributes?.length])
  return (
    <>
      {errorMessage && (
        <ResultStatus
          showStatus={errorState}
          alertMessage={errorMessage}
          severtiy="error"
          closeStatus={() => closeErrorMessage()}
        />
      )}
      {successState && (
        <ResultStatus
          showStatus={successState}
          alertMessage={successMessage}
          severtiy="success"
          closeStatus={() => closeSuccessMessage()}
        />
      )}
      <ActionPage>
        {/* <CardContent>
          <DataPodDetailRibbon
            dataPodId={dataPodId}
            dataPodName={dataPodInfo?.name}
            dataPodIndustryName={dataPodInfo?.industryName}
          />
        </CardContent> */}
        {/* <Grid
          item
          container
          xs={15}
          alignItems="center"
          spacing={20}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Grid item xs={10}>
            <Typography variant="h3" color="primary" gutterBottom>
              Enriched Data Entity
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>
        </Grid> */}
        <Grid
          item
          container
          xs={15}
          alignItems="center"
          spacing={20}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          {/* Conditionally render the grid layout based on the showUploadButton condition */}
          <Grid item xs={!currentEntityId ? 3.4 : 10}>
            <Typography variant="h3" color="primary" gutterBottom>
              Enriched Data Entity
            </Typography>
          </Grid>

          {!currentEntityId && (
            <Grid item container xs={3} display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
              <Grid item>
                <input
                  accept="*/*"
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  multiple={false}
                  onChange={(event) => handleFileUpload(event)}
                />
                <label htmlFor="file-upload">
                  <LoadingButton
                    loading={isGetEntityDataByFileLoading}
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                  </LoadingButton>
                </label>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={!uploadedFileContent}
                  onClick={() => setIsCompareFileClicked(!isCompareFileClicked)}
                >
                  {isCompareFileClicked ? 'Back to form' : 'Compare File'}
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid item xs={1.6}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>
        </Grid>

        {/* <Grid item xs={2.5}>
              <IconButton onClick={onClickHome} size="small" title="Data Modelling ">
                <HomeOutlined />
              </IconButton>
            </Grid> */}
        {/* <Grid item xs={4}> */}

        {/* <ProgressBar loading={loading} /> */}
        {/* <AnimatedLoader height="50%" width="40%" loading={loading} /> */}
        <Grid container item xs={14} alignItems={'stretch'} alignContent={'center'} spacing={2} mt={2}>
          {!isCompareFileClicked ? (
            <Grid item xs={collapseForm ? 8 : 4} container alignItems={'stretch'} alignContent={'baseline'} spacing={1}>
              <Grid container item sx={{ display: 'flex' }} spacing={2} xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Enriched Entity Name
                  </Typography>
                  <TextField
                    placeholder="E.g Department Totals"
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="outlined-basic"
                    // label="Enter Enriched Table Name"
                    required
                    onChange={({ target }) => addEnrichedModelData('dataEntityName', target.value)}
                    value={enrichedTableData.dataEntityName}
                    error={!isValidEntry(enrichedTableData?.dataEntityName)}
                    disabled={currentEntityId || customEntId ? true : false}
                    InputProps={{
                      title: enrichedTableData?.dataEntityName.length > 25 ? enrichedTableData.dataEntityName : '',
                    }}
                  />
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    A clear and concise name for the enriched entity name. Eg: "Customer Churn Prediction"
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Entity Type
                  </Typography>

                  <TextField
                    fullWidth
                    id="outlined-select-entityType"
                    select
                    required
                    size="small"
                    value={enrichedTableData?.entityType ?? ''}
                    onChange={({ target }) => addEnrichedModelData('entityType', target.value)}
                    error={enrichedTableData.entityType === undefined}
                  //disabled={coreEntityDisplay}
                  >
                    {entityTypes.map((name, ix) => (
                      <MenuItem key={name.id} value={name.id}>
                        {name.value}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    A clear distinction based on transaction data or reference data involves specifying the nature of
                    the data for enriched entity.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} />
              <Grid item spacing={2} container sx={{ display: 'flex', flexDirection: 'column' }} xs={12}>
                <Grid item>
                  <Typography gutterBottom>Description</Typography>

                  <TextField
                    placeholder="E.g Department Totals"
                    id="enrichedTableDescription"
                    required
                    size="small"
                    fullWidth
                    multiline
                    value={enrichedTableData?.description}
                    onChange={({ target }) => addEnrichedModelData('description', target.value)}
                    rows={3}
                    variant="outlined"
                  //disabled={coreEntityDisplay}
                  />
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    A detailed explanation of the enriched entity name, including its objectives, importance, Outcome &
                    Reporting. Eg: "This enriched entity aims to identify customers who are likely to churn based on
                    their interaction history and demographic data. The objectives is to reduce customer churn rate by
                    10% over the next quarter. The expected outcome is weekly churn prediction, monthly impact
                    assessment on churn rates."
                  </Typography>
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <Tooltip title="Generate Canonical Entities">
                    <span>
                      <LoadingButton
                        loading={isEntityRecommendationGenerating}
                        onClick={onClickEntityRecommendation}
                        color="primary"
                        variant="outlined"
                        disabled={
                          !enrichedTableData.dataEntityName ||
                          !enrichedTableData.description ||
                          !enrichedTableData.entityType
                        }
                      >
                        {<RefreshIcon fontSize="small" />}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12} />

              <Grid item xs={12}>
                <Grid item xs={10} alignItems={'center'}>
                  <Typography variant="h6" gutterBottom>
                    Source Entities
                  </Typography>
                </Grid>
                <TextField
                  placeholder="@Sales, @Department"
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  value={userInput}
                  onChange={transformationTextChangeHandler}
                />
                <Menu
                  PaperProps={{
                    style: {
                      width: '20%',
                    },
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {/* {entityData.map((ent) => (
                    <MenuItem key={ent.id} onClick={() => handleSelectMention(ent.id, ent.dataEntityName)}>
                      {ent.dataEntityName}
                    </MenuItem>
                  ))} */}
                  <Autocomplete
                    options={entityData}
                    getOptionLabel={(option) => `${option.dataEntityName}`}
                    onChange={(event, ent) => {
                      if (ent) {
                        handleSelectMention(ent.id, ent.dataEntityName)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search..." variant="outlined" size="small" fullWidth />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem
                        {...props}
                        key={option.id}
                        onClick={() => {
                          handleSelectMention(option.id, option.dataEntityName)
                        }}
                      >
                        {option.dataEntityName}
                      </MenuItem>
                    )}
                    style={{ width: '100%' }}
                  />
                </Menu>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  A detailed list of entities (tables) needed for the enriched entity. Eg. @Transactions @Customers
                  @Subscriptions @CustomerSupportInteractions
                </Typography>
              </Grid>
              {/* <Grid item xs={12} /> */}
              {/* <Grid item xs={12} /> */}
              <br></br>
              <Grid
                item
                container
                xs={12}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                style={{ marginTop: '2%' }}
              >
                <Typography variant="h6" gutterBottom>
                  Source Attributes
                </Typography>
                <Tooltip title="Refresh Core Attributes">
                  <span>
                    <LoadingButton
                      color="primary"
                      onClick={() => getGenAiEnrichedCoreEntityAttributes()}
                      variant="outlined"
                      loading={fetchLoading}
                      disabled={
                        !enrichedTableData.coreEntity ||
                        !enrichedTableData.entityType ||
                        !enrichedTableData.dataEntityName ||
                        !enrichedTableData.description ||
                        !userInput.length
                      }
                    >
                      {/* Refresh Core Attributes */}
                      {<RefreshIcon fontSize="small" />}
                    </LoadingButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                {/* {treeViewString.length > 0 && */}
                  <TreeView
                    attributeString={userInput3}
                    onAttributeStringChange={setTreeViewString}
                    podData={dataPodId}
                    coreEntityId={customEntityId}
                    updateNotificationToParent={setUpdateFromTreeView}
                    beforeSave={beforeCustomEntitySave}
                    selectedEntityString={userInput}
                    refetchHappened={updateFromParent}
                  />
                {/* } */}
                {/* <TextField
                  placeholder="@Id, @Name"
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  value={userInput3}
                  onChange={transformationTextChangeHandler3}
                /> */}
                {/* <Menu
                  anchorEl={anchorEl3}
                  open={Boolean(anchorEl3)}
                  onClose={() => setAnchorEl3(null)}
                  PaperProps={{
                    style: {
                      width: '20%',
                    },
                  }}
                >
                  <Autocomplete
                    options={coreEntityAttribute}
                    getOptionLabel={(option) => `${option.dataEntityName}:${option.dataAttributeName}`}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleSelectMention3(
                          newValue.dataAttributeName,
                          newValue.id,
                          newValue.description,
                          newValue.dataAttributeType,
                          newValue.dataEntityId,
                          newValue.dataEntityName,
                        )
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search..." variant="outlined" size="small" fullWidth />
                    )}
                    renderOption={(props, option) => (
                      <Tooltip title={`${option.dataEntityName}:${option.dataAttributeName}`}>
                        <MenuItem
                          {...props}
                          key={option.id}
                          onClick={() => {
                            handleSelectMention3(
                              option.dataAttributeName,
                              option.id,
                              option.description,
                              option.dataAttributeType,
                              option.dataEntityId,
                              option.dataEntityName,
                            )
                          }}
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {option.dataEntityName}:{option.dataAttributeName}
                        </MenuItem>
                      </Tooltip>
                      // <MenuItem
                      //   {...props}
                      //   key={option.id}
                      //   onClick={() => {
                      //     handleSelectMention3(
                      //       option.dataAttributeName,
                      //       option.id,
                      //       option.description,
                      //       option.dataAttributeType,
                      //       option.dataEntityId,
                      //       option.dataEntityName
                      //     )
                      //   }}
                      // >
                      //   {option.dataEntityName}:{option.dataAttributeName}
                      // </MenuItem>
                    )}
                    style={{ width: '100%' }}
                  />
                </Menu> */}
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Specific list of data attributes needed for the enriched entity. Eg. @CustomerID @TransactionDate
                  @TransactionAmount @SubscriptionStart @@SubscriptionEnd, @InteractionHistory @CustomerDemographics
                </Typography>
                {userInput.length > 0 && coreAttributesErrors.length > 0 && (
                  <ErrorDisplay
                    errors={coreAttributesErrors}
                    hasWarnings={errorBlockFlagCoreAttributes}
                    expanded={errorBlockExpanded}
                    onToggleExpand={handleToggleExpand}
                  />
                )}
              </Grid>
              <Grid container item xs={12} display={'flex'} justifyContent={'space-between'}>
                <Grid item xs={12}>
                  <Grid display={'flex'} flexDirection={'row'}>
                    <LucidTextLabel labelText="Business Keys" />
                    <IconButton
                      size="small"
                      onClick={() => setShowDeltaKeyPopup(true)}
                      sx={{ padding: '2px' }}
                      title="Edit Business Keys"
                    >
                      <EditNote fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Typography>{deltaKeyValue}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!fetchedRelations.length && (
                  <Grid justifyContent={'center'}>
                    <Typography variant="body1" color="textSecondary">
                      Warning: No relationships have been discovered between the selected entities. Code generation is
                      disabled until valid relationships are defined.
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '5%' }}
                >
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Entity Relationships
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={() => setShowAddRelationPopUp(true)}>Add New Relationship</Button>
                    <Tooltip title="Generate Entity Relationships">
                      <span>
                        <LoadingButton
                          loading={isEntityRelationsGenerating}
                          disabled={entityId.length >= 2 ? false : true}
                          onClick={() => onClickGenerateRelations()}
                          color="primary"
                          variant="outlined"
                        >
                          {<RefreshIcon fontSize="small" />}
                        </LoadingButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  {/* {currentEntityId && <Button onClick={() => onButtonClick()}>Edit In Relationship Page</Button>} */}
                </Grid>
                <ShadowTable
                  rows={sourceDataRow}
                  columns={sourcePopUpHeaders}
                  // selectedRow={`${userSelectedTarget?.targetColumnId ?? 0}`}
                  tableActionParams={{
                    onButtonClick: onActionClick,
                    actions: [ShadowTableAction.Editable, ShadowTableAction.Deletable],
                  }}
                />
                {userInput.length > 0 && (
                  <ErrorDisplay
                    errors={enrichedRelationshipErrors}
                    hasWarnings={errorBlockFlagRelationships}
                    expanded={errorBlockExpandedRelations}
                    onToggleExpand={handleToggleExpandRelation}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Enter the Prompt text
                </Typography>

                <TextField
                  placeholder="possible to use @Aggregate, @groupby & @filter"
                  size="medium"
                  fullWidth
                  multiline
                  // rows={3}
                  InputProps={{
                    style: { resize: 'vertical' }
                  }}
                  style={{ overflow: 'auto' }}
                  variant="outlined"
                  value={userInput2}
                  onChange={transformationTextChangeHandler2}
                />
                <Menu anchorEl={anchorEl2} open={Boolean(anchorEl2)} onClose={() => setAnchorEl2(null)}>
                  {promptTextFunctions.map((ent) => (
                    <MenuItem key={ent.id} onClick={() => handleSelectMention2(ent.function)}>
                      {ent.function}
                    </MenuItem>
                  ))}
                </Menu>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Specify the additional information such as implementation steps, aggregation logic, granularity of
                  data, range, and any other instruction that are important logic for generating code.
                </Typography>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} container justifyContent={'flex-end'} spacing={1}>
                <Grid item xs={3}>
                  <Button variant="contained" fullWidth onClick={onClickHome}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item />
                <Grid item xs={5}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    onClick={clickHandler}
                    loading={loading || processingReq}
                    disabled={
                      !enrichedTableData.coreEntity ||
                      !enrichedTableData.dataEntityName ||
                      !enrichedTableData.description ||
                      !entityId.length ||
                      (entityId.length >= 2 && !fetchedRelations.length) ||
                      !isValidEntry(enrichedTableData.dataEntityName) ||
                      !userInput2 ||
                      !userInput3.length
                    }
                  >
                    {clickUpdateLabel}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={6} container alignItems={'stretch'} alignContent={'baseline'} spacing={1}>
              <Card style={{ width: '100%', height: '100%' }}>
                <CodeMirror
                  value={uploadedFileContent}
                  maxHeight="100%"
                  maxWidth="100%"
                  width="100%"
                  editable
                  basicSetup={{
                    foldGutter: false,
                    dropCursor: false,
                    indentOnInput: true,
                    lineNumbers: true,
                  }}
                // extensions={[pythonLanguage]}
                // onChange={(value) => onChange(cl.id, value)}
                />
              </Card>
            </Grid>
          )}
          <Grid item container xs={isCompareFileClicked ? 6 : collapseForm ? 4 : 8}>
            <Card>
              {!isCompareFileClicked && (
                <IconButton onClick={() => setCollapseForm(!collapseForm)} size="small">
                  {collapseForm ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              )}
              {/* <ProgressBar loading={processingReq} /> */}
              <AnimatedLoader height="50%" width="40%" loading={processingReq} />
              <Grid container item display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Grid item xs={3}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={generateCodeHandler}
                    startIcon={<Insights />}
                    disabled={!currentEntityId || !userInput3.length}
                    loading={codeGenerating}
                  >
                    Generate Code
                  </LoadingButton>
                </Grid>
                <Grid item xs={3}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleNotebookString}
                    startIcon={<AddOutlined />}
                    disabled={!notebookString}
                    loading={processingReq}
                  >
                    {SaveUpdateLabel}
                  </LoadingButton>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="outlined" onClick={onPreviewButtonClick} disabled={!customEntityId}>
                    Data Preview
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    color="primary"
                    aria-controls={anchorEl ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    disabled={!customEntityId}
                  >
                    <SettingsIcon />
                  </IconButton>
                  <Menu id="long-menu" anchorEl={anchorEl4} open={Boolean(anchorEl4)} onClose={handleClose}>
                    {loadOptions.map((loadOption) => (
                      <MenuItem
                        key={loadOption.value}
                        onClick={() => handleSubmit(loadOption.value)}
                        selected={loadOption.value === selectedValue}
                      >
                        {loadOption.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
              <LucidJupyter
                executeNotebook={executeNotebook}
                noteBookString={notebookString}
                headingText={constants.codeHeaderText}
                updateNoteBookString={setNotebookString}
                commitMessage={commitMessage}
                updateCommitMessage={setcommitMessage}
              />
            </Card>
          </Grid>
        </Grid>

        <LucidPopUp
          showCloseIcon
          closePopup={setShowPopUp}
          openPopUp={showPopUp}
          onConfirm={handleNext}
          headingText=""
          confirmText="Commit"
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Link href={repoUrl ? repoUrl : '#'} target="_blank" rel="noopener noreferrer">
                {isAzureRpoSelected ? 'Azure Repos' : 'Git Hub Repo'}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={'10px'} variant="h6" gutterBottom>
                {repoUrl ? repoUrl : 'No repo url configured'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter Commit Message"
                variant="outlined"
                color="secondary"
                required
                rows={2}
                multiline
                value={commitMessage}
                onChange={({ target }) => onTextChange('commitMessage', target.value)}
              />
            </Grid>
          </Grid>
        </LucidPopUp>
        <LucidPopUp
          openPopUp={showDeltaKeyPopup}
          closePopup={handleDeltaKeyClose}
          headingText="Edit Business Keys"
          showCloseIcon
          confirmText="CONFIRM"
          cancelText="CANCEL"
          onConfirm={handleDeltaKeyConfirm}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SearchDropDown
                parentList={dataColumnMap}
                filteredChildren={[]}
                loading={false}
                error={null}
                selectionInput={deltaKeyUserInp}
                setSelectionInput={setDeltaKeyUserInp}
                selectedChildren={{}}
                selectedParents={selectedDeltaKeys}
                setSelectedParents={setSelectedDeltaKeys}
                filterIcon={false}
              />
            </Grid>
          </Grid>
        </LucidPopUp>
        {selectedEntityRelation && dataPodId && (
          <EditEntityRelationPopup
            openPopUp={showEditRelationPopUp}
            closePopUp={setShowEditRelationPopUp}
            showDeleteWarning={showDeleteRelationWarning}
            setShowDeleteWarning={setShowDeleteRelationWarning}
            dataPodId={dataPodId}
            currentEntityId={currentEntityId}
            selectedCoreEntityName={enrichedTableData?.dataEntityName}
            selectedEntityRelation={selectedEntityRelation}
            setSelectedEntityRelation={setSelectedEntityRelation}
            allEntities={entityRelationSuggestion}
            entityRelationData={fetchedRelations}
            setEntityRelationData={setFetchedRelations}
          />
        )}
        {showAddRelationPopUp && dataPodId && (
          <AddNewEntityRelation
            openPopUp={showAddRelationPopUp}
            closePopUp={setShowAddRelationPopUp}
            dataPodId={dataPodId}
            currentEntityId={currentEntityId}
            selectedCoreEntityName={''}
            allEntities={entityRelationSuggestion}
            entityRelationData={fetchedRelations}
            setEntityRelationData={setFetchedRelations}
          />
        )}
      </ActionPage>
    </>
  )
}

interface EditEntityRelationPopupProps {
  openPopUp: boolean
  closePopUp: React.Dispatch<React.SetStateAction<boolean>>
  dataPodId: string
  currentEntityId: number | undefined
  selectedCoreEntityName: string
  setSelectedEntityRelation: React.Dispatch<React.SetStateAction<RelationShipResponse | undefined>>
  entityRelationData: RelationShipResponse[]
  selectedEntityRelation: RelationShipResponse
  allEntities: Entity[]
  showDeleteWarning: boolean
  setShowDeleteWarning: React.Dispatch<React.SetStateAction<boolean>>
  setEntityRelationData: React.Dispatch<React.SetStateAction<RelationShipResponse[]>>
}

const EditEntityRelationPopup: React.FC<EditEntityRelationPopupProps> = ({
  openPopUp,
  closePopUp,
  dataPodId,
  currentEntityId,
  selectedCoreEntityName,
  selectedEntityRelation,
  setSelectedEntityRelation,
  allEntities,
  showDeleteWarning,
  setShowDeleteWarning,
  entityRelationData,
  setEntityRelationData,
}) => {
  const { updateEntityRelation, updateRelationError } = useUpdateEntityRelationship(dataPodId)
  const { confirmDeleteFunc } = useDeleteEntityRelation()

  const { attributeProfiles: leftAttributes } = useGetDataAttributesByEntityId(selectedEntityRelation.leftEntityId)
  const { attributeProfiles: rightAttributes } = useGetDataAttributesByEntityId(selectedEntityRelation.rightEntityId)
  const [alertInfoState, setAlertInfoState] = useState<boolean>(false)
  const [alertInfoMessage, setAlertInfoMessage] = useState<string>('')
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successState, setSuccessState] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const leftEntitySuggestions = useMemo(() => {
    if (!selectedEntityRelation?.rightEntityId) {
      return allEntities
    }

    return allEntities.filter((entity) => entity.id !== selectedEntityRelation.rightEntityId)
  }, [selectedEntityRelation?.rightEntityId])

  const rightEntitySuggestions = useMemo(() => {
    if (!selectedEntityRelation?.leftEntityId) {
      return allEntities
    }

    return allEntities.filter((entity) => entity.id !== selectedEntityRelation.leftEntityId)
  }, [selectedEntityRelation?.leftEntityId])
  // const [fetchCoreAttributes, coreAttributes] = useGetCoreEntityAttributesDatapod(dataPodId)
  const [fetchCoreAttributesDatapod, coreAttributesDatapod] = useGetCoreEntityAttributesDatapod(dataPodId)
  const [coreEntities] = useGetCoreEntity(dataPodId)
  const [rightCoreOrHubAttributes, setRightCoreOrHubAttributes] = useState<DataAttribute[]>([])
  const [leftCoreOrHubAttributes, setLeftCoreOrHubAttributes] = useState<DataAttribute[]>([])
  const [coreOrHubFlag, setCoreOrHubFlag] = useState<boolean>(false)
  const [getDataHubEntityAttributes] = useGetEntityAttributes()

  useEffect(() => {
    if (selectedEntityRelation) {
      checkForHubOrCustomEnriched(selectedEntityRelation.leftEntityId, 'left')
      checkForHubOrCustomEnriched(selectedEntityRelation.rightEntityId, 'right')
      // console.log({selectedEntityRelation})
    }
  }, [selectedEntityRelation])
  // useEffect(()=>{
  //   if(entityRelationData){
  //     console.log({entityRelationData})
  //   }
  // },[entityRelationData])

  const onClickUpdateRelation = async () => {
    //Checking validation
    if (
      selectedEntityRelation.leftEntityId == 0 ||
      selectedEntityRelation.rightEntityId == 0 ||
      selectedEntityRelation.leftAttributeId == 0 ||
      selectedEntityRelation.rightAttributeId == 0 ||
      selectedEntityRelation.cardinality == ''
    ) {
      setAlertInfoState(true)
      setAlertInfoMessage('Please fill out all the fields.')
      return
    }

    // Checking if relation already exists
    const relationExists = entityRelationData.some(
      (relation) =>
        relation.leftEntityId === selectedEntityRelation.leftEntityId &&
        relation.rightEntityId === selectedEntityRelation.rightEntityId &&
        relation.leftAttributeId === selectedEntityRelation.leftAttributeId &&
        relation.rightAttributeId === selectedEntityRelation.rightAttributeId &&
        relation.cardinality === selectedEntityRelation.cardinality,
    )

    if (relationExists) {
      setAlertInfoState(true)
      setAlertInfoMessage('This relation already exists.')
      return
    }

    //If validation success and enriched entity exists make an api call to update.
    //Else if its new entity, update the relationship in the state itself.
    if (currentEntityId && selectedEntityRelation.id > 0) {
      await callUpdateEntityRelation()
    } else {
      // console.log({entityRelationData})
      setEntityRelationData((prevData) => {
        const res = prevData.map((relation) =>
          relation.id === selectedEntityRelation.id ? selectedEntityRelation : relation,
        )
        return res
      })
      setSelectedEntityRelation(undefined)
      closePopUp(false)
    }
  }

  const callUpdateEntityRelation = async () => {
    if (!currentEntityId) {
      return
    }

    const payload: EntityRelationship = {
      dataCoreEntityId: selectedEntityRelation.leftEntityId,
      dataSystemEntityId: selectedEntityRelation.rightEntityId,
      joinCoreAttributeId: selectedEntityRelation.leftAttributeId,
      joinSystemAttributeId: selectedEntityRelation.rightAttributeId,
      similarityScoreByName: selectedEntityRelation.similarityScoreByName,
      similarityScoreByData: selectedEntityRelation.similarityScoreByData,
      runId: 0,
      profileName: '',
      cardinality: selectedEntityRelation.cardinality,
      enrichedEntityId: currentEntityId,
      overlapPercentage: selectedEntityRelation.sampleOverlapPercentage || null,
      schemaCompatibility: (selectedEntityRelation.schemaCompatibility == 1 ? true : false) || null,
      datapodId: dataPodId,
      id: selectedEntityRelation.id,
    }

    const updatedEntity = await updateEntityRelation({
      ...payload,
      datapodId: dataPodId,
    })

    if (updateRelationError && !updatedEntity) {
      setErrorState(true)
      setErrorMessage(updateRelationError)
    } else if (updatedEntity) {
      // console.log(successState)
      setSuccessState(true)
      setSuccessMessage('Relationship updated successfully.')
      setSelectedEntityRelation(undefined)
      closePopUp(false)
      return updatedEntity
    }
  }

  const closeEntityRelation = () => {
    setSelectedEntityRelation(undefined)
    closePopUp(false)
  }

  const handleEditEntityRelation = (name: string, value: unknown) => {
    // console.log({ name })
    // console.log({ value })
    setSelectedEntityRelation((prevData) => ({ ...prevData, [name]: value } as RelationShipResponse))
  }

  const onConfirmDelete = async () => {
    //If enriched entity exist make an api call to delete
    //Else skip this if block and update in the state itself.
    if (selectedEntityRelation?.id > 0 && currentEntityId) {
      const deleteMapSuccess = await confirmDeleteFunc([selectedEntityRelation?.id])
      if (!deleteMapSuccess && selectedEntityRelation?.id) {
        setErrorState(true)
        setErrorMessage('Failed to delete the relationship.')
        return
      } else {
        setSuccessState(true)
        setSuccessMessage('Deleted the relationship successfully.')
      }
    }

    const filteredRows = entityRelationData.filter((r) => r.id !== selectedEntityRelation.id)
    setEntityRelationData(filteredRows)
    closeDeleteWarning()
  }

  const closeDeleteWarning = () => {
    setShowDeleteWarning(false)
  }

  const closeAlertInfo = () => {
    setAlertInfoState(false)
  }
  const closeErrorInfo = () => {
    setErrorState(false)
  }
  const closeSuccessInfo = () => {
    setSuccessState(false)
  }
  const checkForHubOrCustomEnriched = async (entityId: number, side: string) => {
    const extractedEnt = allEntities.find((ae) => ae.id == entityId)
    // console.log({ extractedEnt })
    if (extractedEnt?.coreEntity && !extractedEnt.dataHubMapId) {
      const data = await fetchCoreAttributesDatapod(entityId)
      if (data) {
        const entityNamesMap = new Map(
          coreEntities.map(({ id, dataEntityName }) => [id, dataEntityName])
        );
        const newAttributes = data.map(({
          id, dataCoreEntityId, dataCoreAttributeName, description,
          dataCoreAttributeDataType, dataCoreClassification, dataPodId,
          isBusinessKey, isActive
        }) => ({
          id,
          dataEntityId: dataCoreEntityId ?? null,
          dataAttributeName: dataCoreAttributeName,
          dataAttributeDataType: dataCoreAttributeDataType,
          dataPodId,
          description: description ?? '',
          isBusinessKey: isBusinessKey ?? false,
          dataClassification: dataCoreClassification ?? '',
          dataAttributeType: '',
          isActive: isActive ?? true,
          dataEntityName: entityNamesMap.get(dataCoreEntityId),
        }));
        if (side === 'left') {
          // console.log({ newAttributes })
          setLeftCoreOrHubAttributes(newAttributes)
        } else if (side === 'right') {
          // console.log({ newAttributes })
          // console.log({ coreOrHubFlag })
          setRightCoreOrHubAttributes(newAttributes)
        }
        // console.log(data)
      }

      setCoreOrHubFlag(true)
    } else if (extractedEnt?.dataHubMapId && extractedEnt.dataHubCloneTypeId) {
      // console.log("It is a Hub entity")
      const resp = await getDataHubEntityAttributes(extractedEnt.datapodId, extractedEnt.id);
      if (side === 'left') {
        setLeftCoreOrHubAttributes(resp)
      } else if (side === 'right') {
        setRightCoreOrHubAttributes(resp)
      }
      setCoreOrHubFlag(true)
    } else {
      setCoreOrHubFlag(false)
    }
  }
  // useEffect(()=>{
  //   if(!coreOrHubFlag){
  //     console.log({leftAttributes})
  //   }
  // },[coreOrHubFlag])
  return (
    <>
      <ResultStatus
        showStatus={alertInfoState}
        alertMessage={alertInfoMessage}
        severtiy="info"
        closeStatus={() => closeAlertInfo()}
      />
      <ResultStatus
        showStatus={errorState}
        alertMessage={errorMessage}
        severtiy="error"
        closeStatus={() => closeErrorInfo()}
      />
      <ResultStatus
        showStatus={successState}
        alertMessage={successMessage}
        severtiy="success"
        closeStatus={() => closeSuccessInfo()}
      />
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={onConfirmDelete}
        closeDeleteWarning={closeDeleteWarning}
        itemToDelete={''}
        relationToDelete={entityRelationData.find((ed) => ed.id === selectedEntityRelation.id)}
      />
      <LucidPopUp
        headingText={<Typography variant="h4">Edit New Relationship</Typography>}
        openPopUp={openPopUp}
        onConfirm={onClickUpdateRelation}
        closePopup={closeEntityRelation}
        confirmText={'Save'}
        solidCancelButton
        cancelText={'Cancel'}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h5">Core Entity Name :</Typography>
          </Grid>
          <Grid item xs={6} textAlign={'center'}>
            <Typography variant="h6">{selectedCoreEntityName}</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Left</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-left-entity"
                  options={leftEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === selectedEntityRelation?.leftEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEditEntityRelation('leftEntityId', newValue?.id || 0)
                    handleEditEntityRelation('leftEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Left Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource"
                  // options={leftAttributes}
                  options={
                    coreOrHubFlag
                      ? (leftAttributes.length > 0 ? leftAttributes : leftCoreOrHubAttributes)
                      : leftAttributes
                  }
                  getOptionLabel={(option) => option.dataAttributeName}
                  // value={leftAttributes.find((attr) => attr.id === selectedEntityRelation?.leftAttributeId) || null}
                  value={
                    coreOrHubFlag
                      ? (leftAttributes.length > 0
                        ? leftAttributes.find((attr) => attr.id === selectedEntityRelation?.leftAttributeId) || null
                        : leftCoreOrHubAttributes.find((attr) => attr.id === selectedEntityRelation?.leftAttributeId) || null)
                      : leftAttributes.find((attr) => attr.id === selectedEntityRelation?.leftAttributeId) || null
                  }
                  onChange={(event, newValue) => {
                    handleEditEntityRelation('leftAttributeId', newValue?.id || 0)
                    handleEditEntityRelation('leftAttributeName', newValue?.dataAttributeName || 0)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Left Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Right</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right-entity"
                  options={rightEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === selectedEntityRelation?.rightEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEditEntityRelation('rightEntityId', newValue?.id || 0)
                    handleEditEntityRelation('rightEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right"
                  // options={rightAttributes}
                  options={
                    coreOrHubFlag
                      ? (rightAttributes.length > 0 ? rightAttributes : rightCoreOrHubAttributes)
                      : rightAttributes
                  }
                  getOptionLabel={(option) => option.dataAttributeName}
                  // value={rightAttributes.find((attr) => attr.id === selectedEntityRelation?.rightAttributeId) || null}
                  value={
                    coreOrHubFlag
                      ? (rightAttributes.length > 0
                        ? rightAttributes.find((attr) => attr.id === selectedEntityRelation?.rightAttributeId) || null
                        : rightCoreOrHubAttributes.find((attr) => attr.id === selectedEntityRelation?.rightAttributeId) || null)
                      : rightAttributes.find((attr) => attr.id === selectedEntityRelation?.rightAttributeId) || null
                  }
                  onChange={(event, newValue) => {
                    handleEditEntityRelation('rightAttributeId', newValue?.id || 0)
                    handleEditEntityRelation('rightAttributeName', newValue?.dataAttributeName || 0)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={selectedEntityRelation?.cardinality ?? ''}
                  onChange={({ target }) => handleEditEntityRelation('cardinality', target.value)}
                  required
                  size="small"
                  label="Cardinality"
                  select
                >
                  {['one-to-many', 'one-to-one', 'many-to-one', 'many-to-many'].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LucidPopUp>
    </>
  )
}

type AddNewEntityRelationProps = {
  openPopUp: boolean
  closePopUp: React.Dispatch<React.SetStateAction<boolean>>
  selectedCoreEntityName: string
  dataPodId: string
  currentEntityId: number | undefined
  allEntities: Entity[]
  entityRelationData: RelationShipResponse[]
  setEntityRelationData: React.Dispatch<React.SetStateAction<RelationShipResponse[]>>
}

const AddNewEntityRelation: React.FC<AddNewEntityRelationProps> = ({
  openPopUp,
  closePopUp,
  dataPodId,
  currentEntityId,
  selectedCoreEntityName,
  allEntities,
  entityRelationData,
  setEntityRelationData,
}) => {
  const [alertInfoState, setAlertInfoState] = useState<boolean>(false)
  const [alertInfoMessage, setAlertInfoMessage] = useState<string>('')

  const [newEntityRelation, setNewEntityRelation] = useState<RelationShipResponse | undefined>({
    id: entityRelationData.length == 0 ? 1 : entityRelationData.length,
    database: '',
    dataTableId: 0,
    dataTableName: '',
    leftEntityId: 0,
    leftEntityName: '',
    leftAttributeId: 0,
    leftAttributeName: '',
    rightEntityId: 0,
    rightEntityName: '',
    rightAttributeId: 0,
    rightAttributeName: '',
    cardinality: '',
    similarityScoreByName: 1,
    similarityScoreByData: 1,
    dataPodId: '',
    sampleOverlapPercentage: undefined,
    schemaCompatibility: undefined,
  })

  const { attributeProfiles: leftAttributes } = useGetDataAttributesByEntityId(newEntityRelation?.leftEntityId)
  const { attributeProfiles: rightAttributes } = useGetDataAttributesByEntityId(newEntityRelation?.rightEntityId)

  const [getDataHubEntityAttributes] = useGetEntityAttributes()
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successState, setSuccessState] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')


  const { addEntityRelation } = useAddEntityRelationship(dataPodId)

  const [rightCoreOrHubAttributes, setRightCoreOrHubAttributes] = useState<DataAttribute[]>([])
  const [leftCoreOrHubAttributes, setLeftCoreOrHubAttributes] = useState<DataAttribute[]>([])
  const [coreOrHubFlag, setCoreOrHubFlag] = useState<boolean>(false)
  // const [fetchCoreAttributes, coreAttributes] = useGetCoreEntityAttributesDatapod(dataPodId)
  const [fetchCoreAttributesDatapod, coreAttributesDatapod] = useGetCoreEntityAttributesDatapod(dataPodId)
  const [coreEntities] = useGetCoreEntity(dataPodId)

  const leftEntitySuggestions = useMemo(() => {
    if (!newEntityRelation?.rightEntityId) {
      return allEntities
    }

    return allEntities.filter((entity) => entity.id !== newEntityRelation.rightEntityId)
  }, [newEntityRelation?.rightEntityId])

  const rightEntitySuggestions = useMemo(() => {
    if (!newEntityRelation?.leftEntityId) {
      return allEntities
    }

    return allEntities.filter((entity) => entity.id !== newEntityRelation.leftEntityId)
  }, [newEntityRelation?.leftEntityId])

  const handleEntityRelationInp = (name: string, value: unknown) => {
    setNewEntityRelation((prevData) => ({ ...prevData, [name]: value } as RelationShipResponse))
  }

  const saveNewEntityRelation = async () => {
    if (newEntityRelation == undefined) {
      return
    } else if (
      newEntityRelation.leftEntityId == 0 ||
      newEntityRelation.rightEntityId == 0 ||
      newEntityRelation.leftAttributeId == 0 ||
      newEntityRelation.rightAttributeId == 0 ||
      newEntityRelation.cardinality == ''
    ) {
      setAlertInfoState(true)
      setAlertInfoMessage('Please fill out all the fields.')
      return
    }

    // Checking if relation already exists
    const relationExists = entityRelationData.some(
      (relation) =>
        relation.leftEntityId === newEntityRelation.leftEntityId &&
        relation.rightEntityId === newEntityRelation.rightEntityId &&
        relation.leftAttributeId === newEntityRelation.leftAttributeId &&
        relation.rightAttributeId === newEntityRelation.rightAttributeId &&
        relation.cardinality === newEntityRelation.cardinality,
    )

    if (relationExists) {
      setAlertInfoState(true)
      setAlertInfoMessage('This relation already exists.')
      return
    }

    if (currentEntityId) {
      callAddEntityRelation()
    } else {
      const length = entityRelationData.length

      // setEntityRelationData((prevData) => [...prevData, { ...newEntityRelation, id: length == 0 ? -1 : -length }])
      setEntityRelationData((prevData) => {
        // Find the smallest (most negative) ID
        const minId = prevData.length > 0 ? Math.min(...prevData.map((item) => item.id)) : 0;

        // Assign the new ID as one less than the minimum ID
        const newId = minId - 1;

        return [...prevData, { ...newEntityRelation, id: newId }];
      });
      setNewEntityRelation(undefined)
      closePopUp(false)
    }
  }

  const callAddEntityRelation = async () => {
    if (currentEntityId && newEntityRelation) {
      const payload: EntityRelationship = {
        dataCoreEntityId: newEntityRelation.leftEntityId,
        dataSystemEntityId: newEntityRelation.rightEntityId,
        joinCoreAttributeId: newEntityRelation.leftAttributeId,
        joinSystemAttributeId: newEntityRelation.rightAttributeId,
        similarityScoreByName: newEntityRelation.similarityScoreByName,
        similarityScoreByData: newEntityRelation.similarityScoreByData,
        runId: 0,
        profileName: 'manually-added',
        cardinality: newEntityRelation.cardinality,
        enrichedEntityId: currentEntityId,
        overlapPercentage: newEntityRelation.sampleOverlapPercentage || null,
        schemaCompatibility: (newEntityRelation.schemaCompatibility == 1 ? true : false) || null,
        datapodId: dataPodId,
        id: newEntityRelation.id,
      }

      const enRelation = await addEntityRelation({
        ...payload,
        similarityScoreByData: 1,
        similarityScoreByName: 1,
      })

      if (!enRelation) {
        setErrorState(true)
        setErrorMessage('Failed to add the relationship.')
      } else {
        setSuccessState(true)
        setSuccessMessage('Added the relationship successfully.')
        setNewEntityRelation(undefined)
        closePopUp(false)
      }
    }
  }

  const closeAlertInfo = () => {
    setAlertInfoState(false)
  }

  const closeErrorInfo = () => {
    setErrorState(false)
  }

  const closeSuccessInfo = () => {
    setSuccessState(false)
  }
  const checkForHubOrCustomEnriched = async (entityId: number, side: string) => {
    const extractedEnt = allEntities.find((ae) => ae.id == entityId)
    // console.log({ extractedEnt })
    if (extractedEnt?.coreEntity && !extractedEnt.dataHubMapId) {
      const data = await fetchCoreAttributesDatapod(entityId)
      if (data) {
        const entityNamesMap = new Map(
          coreEntities.map(({ id, dataEntityName }) => [id, dataEntityName])
        );
        const newAttributes = data.map(({
          id, dataCoreEntityId, dataCoreAttributeName, description,
          dataCoreAttributeDataType, dataCoreClassification, dataPodId,
          isBusinessKey, isActive
        }) => ({
          id,
          dataEntityId: dataCoreEntityId ?? null,
          dataAttributeName: dataCoreAttributeName,
          dataAttributeDataType: dataCoreAttributeDataType,
          dataPodId,
          description: description ?? '',
          isBusinessKey: isBusinessKey ?? false,
          dataClassification: dataCoreClassification ?? '',
          dataAttributeType: '',
          isActive: isActive ?? true,
          dataEntityName: entityNamesMap.get(dataCoreEntityId),
        }));
        if (side === 'left') {
          // console.log({ newAttributes })
          setLeftCoreOrHubAttributes(newAttributes)
        } else if (side === 'right') {
          // console.log({ newAttributes })
          // console.log({ coreOrHubFlag })
          setRightCoreOrHubAttributes(newAttributes)
        }
        // console.log(data)
      }

      setCoreOrHubFlag(true)
    } else if (extractedEnt?.dataHubMapId && extractedEnt.dataHubCloneTypeId) {
      // console.log("It is a Hub entity")
      const resp = await getDataHubEntityAttributes(extractedEnt.datapodId, extractedEnt.id);
      if (side === 'left') {
        setLeftCoreOrHubAttributes(resp)
      } else if (side === 'right') {
        setRightCoreOrHubAttributes(resp)
      }
      setCoreOrHubFlag(true)
    } else {
      setCoreOrHubFlag(false)
    }
  }

  useEffect(() => {
    if (newEntityRelation?.leftEntityId) {
      checkForHubOrCustomEnriched(newEntityRelation?.leftEntityId, 'left')
    }
    if (newEntityRelation?.rightEntityName) {
      checkForHubOrCustomEnriched(newEntityRelation?.rightEntityId, 'right')
    }

  }, [newEntityRelation?.leftEntityId, newEntityRelation?.rightEntityId])

  return (
    <>
      <ResultStatus
        showStatus={alertInfoState}
        alertMessage={alertInfoMessage}
        severtiy="info"
        closeStatus={() => closeAlertInfo()}
      />
      <ResultStatus
        showStatus={errorState}
        alertMessage={errorMessage}
        severtiy="error"
        closeStatus={() => closeErrorInfo()}
      />
      <ResultStatus
        showStatus={successState}
        alertMessage={successMessage}
        severtiy="success"
        closeStatus={() => closeSuccessInfo()}
      />
      <LucidPopUp
        headingText={<Typography variant="h4">Add New Relationship</Typography>}
        openPopUp={openPopUp}
        onConfirm={saveNewEntityRelation}
        closePopup={closePopUp}
        confirmText="Save"
        solidCancelButton
        cancelText="Cancel"
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h5">Core Entity Name :</Typography>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Typography variant="h6">{selectedCoreEntityName}</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Left</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-left-entity"
                  options={leftEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === newEntityRelation?.leftEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('leftEntityId', newValue?.id || '')
                    handleEntityRelationInp('leftEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Left Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource"
                  options={
                    coreOrHubFlag
                      ? (leftAttributes.length > 0 ? leftAttributes : leftCoreOrHubAttributes)
                      : leftAttributes
                  }
                  getOptionLabel={(option) => option.dataAttributeName}
                  value={
                    coreOrHubFlag
                      ? (leftAttributes.length > 0
                        ? leftAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null
                        : leftCoreOrHubAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null)
                      : leftAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null
                  }
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('leftAttributeId', newValue?.id || 0)
                    handleEntityRelationInp('leftAttributeName', newValue?.dataAttributeName || '')
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.dataAttributeName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="left Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Right</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right-entity"
                  options={rightEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === newEntityRelation?.rightEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('rightEntityId', newValue?.id || 0)
                    handleEntityRelationInp('rightEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right"
                  options={
                    coreOrHubFlag
                      ? (rightAttributes.length > 0 ? rightAttributes : rightCoreOrHubAttributes)
                      : rightAttributes
                  }
                  getOptionLabel={(option) => option.dataAttributeName}
                  value={
                    coreOrHubFlag
                      ? (rightAttributes.length > 0
                        ? rightAttributes.find((attr) => attr.id === newEntityRelation?.rightAttributeId) || null
                        : rightCoreOrHubAttributes.find((attr) => attr.id === newEntityRelation?.rightAttributeId) || null)
                      : rightAttributes.find((attr) => attr.id === newEntityRelation?.rightAttributeId) || null
                  }
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('rightAttributeId', newValue?.id || 0)
                    handleEntityRelationInp('rightAttributeName', newValue?.dataAttributeName || '')
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.dataAttributeName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?.cardinality ?? ''}
                  onChange={({ target }) => handleEntityRelationInp('cardinality', target.value)}
                  required
                  size="small"
                  label="cardinality"
                  select
                >
                  <MenuItem key="empty" value="">
                    {/* Empty item for placeholder */}
                  </MenuItem>
                  {['one-to-many', 'one-to-one', 'many-to-one', 'many-to-many'].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LucidPopUp>
      {/* <LucidPopUp
        headingText={<Typography variant="h4">Add New Relationship</Typography>}
        openPopUp={openPopUp}
        onConfirm={saveNewEntityRelation}
        closePopup={closePopUp}
        confirmText="Save"
        solidCancelButton
        cancelText="Cancel"
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h5">Core Entity Name :</Typography>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Typography variant="h6">{selectedCoreEntityName}</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Left</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-left-entity"
                  options={leftEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === newEntityRelation?.leftEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('leftEntityId', newValue?.id || '')
                    handleEntityRelationInp('leftEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Left Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource"
                  options={
                    coreOrHubFlag
                      ? (leftAttributes.length > 0 ? leftAttributes : leftCoreOrHubAttributes)
                      : leftAttributes
                  }
                  getOptionLabel={(option) => option.dataAttributeName}
                  value={
                    coreOrHubFlag
                      ? (leftAttributes.length > 0
                        ? leftAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null
                        : leftCoreOrHubAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null)
                      : leftAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null
                  }
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('leftAttributeId', newValue?.id || 0)
                    handleEntityRelationInp('leftAttributeName', newValue?.dataAttributeName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="left Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Right</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right-entity"
                  options={rightEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === newEntityRelation?.rightEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('rightEntityId', newValue?.id || 0)
                    handleEntityRelationInp('rightEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right"
                  options={
                    coreOrHubFlag
                      ? (rightAttributes.length > 0 ? rightAttributes : rightCoreOrHubAttributes)
                      : rightAttributes
                  }
                  getOptionLabel={(option) => option.dataAttributeName}
                  value={
                    coreOrHubFlag
                      ? (rightAttributes.length > 0
                        ? rightAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null
                        : rightCoreOrHubAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null)
                      : rightAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null
                  }
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('rightAttributeId', newValue?.id || 0)
                    handleEntityRelationInp('rightAttributeName', newValue?.dataAttributeName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?.cardinality ?? ''}
                  onChange={({ target }) => handleEntityRelationInp('cardinality', target.value)}
                  required
                  size="small"
                  label="cardinality"
                  select
                >
                  {['one-to-many', 'one-to-one', 'many-to-one', 'many-to-many'].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LucidPopUp> */}
    </>
  )
}

export default CustomEnrichedModel
