import { useMemo, useEffect, useState } from 'react'
import { Button, Grid, Skeleton } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import DataTable from '../../../ui-components/DataTable/DataTable'
import {
  ServiceConnectionType,
  useQueryServiceConnections,
  usePostServiceConnection,
  useGetServiceConnectionsByDataPod,
} from '../../../hooks/serviceConnectionHooks'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import AddServiceConnection from './AddServiceConnection'
import ActionPage from '../../../ui-components/ActionPage'
import ResultStatus from '../../../ui-components/ResultStatus'
import ServiceNameWithIcon from '../../../ui-components/ServiceNameWithIcon'
import LucidDataPodDebounceSearch from '../../../ui-components/LucidDataPodDebounceSearch'
import { NewServiceConnection, OperationState } from 'businessObjects'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import { PreviousPage } from 'ui-components/Button/PreviousPage'

export const ListServiceConnection = () => {
  const [onAdd, setOnAdd] = useState<boolean>(false)
  const [selectedConnection, selectServiceConnection] = useState<NewServiceConnection>()
  const [serviceConnections, setServiceConnections] = useState<DataTableRow[]>([])
  const [dataPodId, setDataPodId] = useState<string>('')

  const [selectedConnectionId, setSelectedConnectionId] = useState<number>()

  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const navigate = useNavigate()
  const {
    connections,
    getServiceConnections: refreshConnections,
    connectionsLoading,
  } = useGetServiceConnectionsByDataPod(dataPodId)
  const { deleteServiceConnection, postError, deleteStatus } = usePostServiceConnection(dataPodId)
  const [resultState, setResultState] = useState<OperationState>()
  const dataPodColumns: DataTableColumn[] = useMemo(() => {
    return [
      {
        label: 'Id',
      },
      {
        label: 'Connection Name',
        sortableColumn: true,
      },
      {
        label: 'Connection Type',
        sortableColumn: true,
      },
      {
        label: 'Service Name',
        sortableColumn: true,
      },
    ]
  }, [])

  useEffect(() => {
    const tRows = connections?.map((conn: any) => ({
      id: `${conn.id}`,
      values: [
        conn.id,
        conn.connectionName,
        conn.connectionType,
        <ServiceNameWithIcon serviceName={conn.serviceTypeName} />,
      ],
    })) as DataTableRow[]
    setServiceConnections(tRows)
  }, [connections])

  const handleDataPodId = (val: string) => {
    setDataPodId(val)
    // getServiceConnectionsByPodId(val)
  }

  const clickTableButton = (id: string, action: DataItemAction): void => {
    if (id && action) {
      if (action === 'delete') {
        deleteServiceConnection(parseInt(id))
      } else {
        setSelectedConnectionId(Number(id))
        const selected = connections.find((c) => c.id === parseInt(id))
        if (selected?.dataPodId) {
          selectServiceConnection({ ...selected })
        }
      }
    }
  }

  const handleCloseAddForm = (podId?: string) => {
    setOnAdd(false)
    setSelectedConnectionId(undefined)
    selectServiceConnection(undefined)
    if (podId) {
      handleDataPodId(podId)
    }
  }
  const handleService = (serviceConnId?: number) => {
    if (serviceConnId) {
      refreshConnections()
      setResultState({
        display: true,
        message: 'Success: Updated/New Service connections',
        result: 'success',
      })
    } else {
      setResultState({
        display: true,
        message: 'Error: Failed to Add/Update Service Connection',
        result: 'error',
      })
    }
  }

  if ((selectedConnectionId && selectedConnection) || onAdd) {
    return (
      <AddServiceConnection
        dataPodId={dataPodId}
        selectedConnection={selectedConnection}
        closeAddForm={handleCloseAddForm}
        serviceConnectionId={selectedConnectionId}
        handleService={handleService}
        connections={connections}
      />
    )
  }

  return (
    <ActionPage>
      <ResultStatus
        showStatus={!!resultState?.display}
        alertMessage={resultState?.message ?? ''}
        severtiy={resultState?.result}
        closeStatus={() => setResultState({ display: undefined, message: undefined, result: undefined })}
      />
      <Grid item xs={3} mb={3}>
        <PreviousPage onClick={() => navigate(generatePath(ROUTES.Settings))} pageName={'Back'} />
      </Grid>
      <Grid item xs={12} justifyContent={'space-between'} display={'flex'}>
        <Grid item xs={8}>
          <>
            <LucidDataPodDebounceSearch defaultVal={dataPodId} selectPod={handleDataPodId} />
          </>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} justifyContent={'end'}>
          <Button variant="contained" color="primary" startIcon={<AddOutlined />} onClick={() => setOnAdd(true)}>
            New Service Connection
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
        {connectionsLoading ? (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Skeleton variant="rectangular" height={40} sx={{ p: 1 }} />
            <Skeleton variant="rectangular" height={60} sx={{ p: 1 }} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <DataTable
              columns={dataPodColumns}
              rows={serviceConnections}
              allowedActions={{ viewable: true, editable: true, deletable: true }}
              fromPage={'service'}
              onButtonClicked={clickTableButton}
            />
          </Grid>
        )}
      </Grid>
    </ActionPage>
  )
}

export default ListServiceConnection
