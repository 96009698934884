import { useLucidAuthContext } from 'LucidAuthContext/LucidAuthContext'
import { dataLoads } from '../apiServices/dataLoad'
import { useEffect, useState } from 'react'
import { addNotebookRequest } from 'businessObjects'
import { set } from 'zod'

type DynamicObject = {
  [key: string]: any
}

export const useGetCanonicalData = (dataPodId?: string, dataEntityId?: number) => {
  const { getBearerToken } = useLucidAuthContext()
  const [fetchedCanonicalData, setFetchedCanonicalData] = useState<DynamicObject[]>([])

  const fetchCanonicalDataByDatapodId = async (pageNumber?: number, entityName?: string) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataEntityId) {
        const { data, status } = await dataLoads.getCanonicalData(
          bearerToken,
          dataPodId,
          dataEntityId,
          entityName,
          pageNumber,
        )
        if (status === 200 && data.length > 0) {
          // Assuming data.canonicalData is an array of objects with dynamic keys
          setFetchedCanonicalData(data)
        }
      }
    } catch (err) {
      console.log('Error fetching canonical data:', err)
    }
    return false
  }

  useEffect(() => {
    // Example initial fetch with default datapodId
    fetchCanonicalDataByDatapodId()
  }, [dataPodId, dataEntityId])

  return { fetchCanonicalDataByDatapodId, fetchedCanonicalData }
}

export const useGetEnrichedlData = (dataPodId?: string, dataEntityId?: number) => {
  const { getBearerToken } = useLucidAuthContext()
  const [fetchedEnrichedData, setFetchedEnrichedData] = useState<DynamicObject[]>([])

  const fetchEnrichedDataByDatapodId = async (pageNumber?: number, entityName?: string) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataEntityId) {
        const { data, status } = await dataLoads.getEnrichedData(
          bearerToken,
          dataPodId,
          dataEntityId,
          entityName,
          pageNumber,
        )
        if (status === 200 && data.length > 0) {
          // Assuming data.canonicalData is an array of objects with dynamic keys
          setFetchedEnrichedData(data)
        }
      }
    } catch (err) {
      console.log('Error fetching canonical data:', err)
    }
    return false
  }

  useEffect(() => {
    // Example initial fetch with default datapodId
    fetchEnrichedDataByDatapodId()
  }, [dataPodId, dataEntityId])

  return { fetchEnrichedDataByDatapodId, fetchedEnrichedData }
}

export const useGetSemanticData = (dataPodId?: string, measureId?: number) => {
  const { getBearerToken } = useLucidAuthContext()
  const [fetchedSemanticData, setFetchedSemanticData] = useState<DynamicObject[]>([])

  const fetchSemanticDataByDatapodId = async (pageNumber?: number, measureName?: string) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && measureId) {
        const { data, status } = await dataLoads.getSemanticData(
          bearerToken,
          dataPodId,
          measureId,
          measureName,
          pageNumber,
        )
        if (status === 200 && data.length > 0) {
          // Assuming data.canonicalData is an array of objects with dynamic keys
          setFetchedSemanticData(data)
        }
      }
    } catch (err) {
      console.log('Error fetching canonical data:', err)
    }
    return false
  }

  useEffect(() => {
    // Example initial fetch with default datapodId
    fetchSemanticDataByDatapodId()
  }, [dataPodId, measureId])

  return { fetchSemanticDataByDatapodId, fetchedSemanticData }
}

export const useGetSourceScriptMetaData = (dataPodId?: string, connectionId?: number) => {
  const { getBearerToken } = useLucidAuthContext()
  const [fetchedSourceMetaData, setFetchedSourceMetaData] = useState<DynamicObject[]>([])

  const fetchSourceScriptMetadata = async (dataPodId?: string, connectionId?: number) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && connectionId) {
        const { data, status } = await dataLoads.getSourceFileMetaData(
          bearerToken,
          dataPodId,
          connectionId,
        )
        if (status === 200 && data.length > 0) {
          setFetchedSourceMetaData(data)
        }
      }
    } catch (err) {
      console.log('Error fetching canonical data:', err)
    }
    return false
  }

  useEffect(() => {
    // Example initial fetch with default datapodId
    fetchSourceScriptMetadata()
  }, [dataPodId, connectionId])

  return { fetchSourceScriptMetadata, fetchedSourceMetaData }
}

export const useAddNoteBook = (dataPodId?: string) => {
  const { getBearerToken } = useLucidAuthContext()

  const [flag,setFlag] = useState<boolean>(false)
  
  const addNoteBookConvertedScript = async (body?: addNotebookRequest, connectionId?: number) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && body) {
        const { data, status } = await dataLoads.addNotebook(
          bearerToken,
          dataPodId,
          body,
          connectionId,
        )
        if (status === 200 && data.length > 0) {
          setFlag(true)
          return true
        }
      }
    } catch (err) {
      console.log('Error adding notebook:', err)
      setFlag(false)
    }
    return false
  }

  return { addNoteBookConvertedScript, flag }
}

export const useUpdateNoteBook = (dataPodId?: string) => {
  const { getBearerToken } = useLucidAuthContext()

  const [flag,setFlag] = useState<boolean>(false)
  
  const updateNotebook = async (body?: addNotebookRequest, connectionId?: number) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && body) {
        const { data, status } = await dataLoads.updateNotebook(
          bearerToken,
          dataPodId,
          body,
          connectionId,
        )
        if (status === 200 && data.length > 0) {
          setFlag(true)
          return true
        }
      }
    } catch (err) {
      console.log('Error updating notebook:', err)
      setFlag(false)
    }
    return false
  }

  return { updateNotebook, flag }
}

export const useGetNoteBook = (dataPodId?: string) => {
  const { getBearerToken } = useLucidAuthContext()
  const [fetchedNotebook, setFetchedNotebook] = useState<string>()
  
  const getNotebook = async (filePath: string, connectionId?: number) => {
    try {
      const bearerToken = await getBearerToken()
      setFetchedNotebook(undefined)
      if (bearerToken && dataPodId && filePath) {
        const { data, status } = await dataLoads.getNotebook(
          bearerToken,
          dataPodId,
          filePath,
          connectionId,
        )
        if (status === 200) {
          setFetchedNotebook(data)
          return {data, status}
        } else {
          return {data, status}
        }
      } else {
        return {data: 'Required parameters are missing for the server call.', status: 500}
      }
    } catch (err) {
      return {data: 'Internal server error: Failed to fetch notebook.', status: 500}
    }
  }

  return { getNotebook, fetchedNotebook }
}