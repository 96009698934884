import { Button, CardContent, Grid, Modal, Box, Typography, TextField, MenuItem, Checkbox, Autocomplete } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'
import { addNotebookRequest, ConversionMetricsRequest, ConvertedFiles, CoreEntity, GenericConversionMetricsRequest, getConversionMetricsResponse, ServiceConnections } from '../../../businessObjects'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useGetCoreEntity, useSaveEntityNB } from '../../../hooks/entityHooks'
import { PreviousPage } from 'ui-components/Button/PreviousPage'
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable'
import { AddOutlined, Edit, CompareArrows } from '@mui/icons-material'
import { useGetSessionPodInfo } from 'hooks/getSessionPodInfo'
import { useGetProfileInputs } from 'hooks/dataProfilingHooks'
import { useGetSubjectArea } from 'hooks/subjectAreaHooks'
import { useGetDataSystemByDataSystemId } from 'hooks/dataSystemHooks'
import { useGetServiceConnection } from 'hooks/dataEngineeringHooks'
import { useAddNoteBook, useGetNoteBook, useGetSourceScriptMetaData } from 'hooks/dataLoadHooks'
import { useGetGenericPySparkCodeMetrics, useGetPySparkCodeForSourceScript, useGetPySparkCodeMetrics } from 'hooks/genAIApiHooks'
import { LoadingButton } from '@mui/lab'
import ResultStatus from 'ui-components/ResultStatus'
import { useUpateServiceConnection } from 'hooks/serviceConnectionHooks'
import { useAddConvertedFiles, useGetConvertedFiles } from 'hooks/convertedFilesHooks'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}

type ConvertedFile = {
  fileName: string;
  fileUrl: string;
};

export const DataModelingLakeMigrationModels = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  // let datapodID: string = 'F269DB96-4FD9-4AB5-A180-26843B292C24'
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  const [selectedConnectionId, setSelectedConnectionId] = useState<number>()
  const [selectedTargetConnectionId, setSelectedTargetConnectionId] = useState<number>()
  const [selectedContainer, setSelectedContainer] = useState<string>()
  const [selectedTargetFilePath, setSelectedTargetFilePath] = useState<string>()
  const [coreEntityData, setCoreEntities] = useState<CoreEntity[]>([])
  const [selectedCoreEntity, setSelectedCoreEntity] = useState<CoreEntity[]>([])
  const dataPodInfo = useGetSessionPodInfo()
  const [coreEntities] = useGetCoreEntity(dataPodInfo?.id)
  const { fetchSourceScriptMetadata, fetchedSourceMetaData } = useGetSourceScriptMetaData()
  const [open, setOpen] = useState(false)
  const [jsonInput, setJsonInput] = useState<string>(`{
      "subscriptionId": "your-subscription-id",
      "clientId": "your-client-id",
      "clientSecret": "your-client-secret",
      "tenantId": "your-tenant-id",
      "resourceGroup": "your-resource-group",
      "storageAccountName": "your-storage-account-name",
      "containerName": "your-target-container-name"
    }`)
  const { profileInputs } = useGetProfileInputs(dataPodId ?? undefined)
  const { dataSystemInfo } = useGetDataSystemByDataSystemId(selectedSourceID)
  const { subjectAreaInfo } = useGetSubjectArea(dataSystemInfo?.subjectAreaId)
  const { getConnections } = useGetServiceConnection()
  const [sourceData, setSourceData] = useState<ServiceConnections[]>([])
  const [targetData, setTargetData] = useState<ServiceConnections[]>([])
  const { getPySparkCodeByFile, isGetPySparkCodeLoading } = useGetPySparkCodeForSourceScript()
  const { getPySparkCodeMetrics, isGetPySparkCodeMetricsLoading } = useGetPySparkCodeMetrics()
  const { getGenericCodeMetrics, isGetGenericCodeMetricsLoading } = useGetGenericPySparkCodeMetrics()
  const [containers, setContainers] = useState<{ id: number, containerName: string }[]>([]);
  const [targetFilePaths, setTargetFilePath] = useState<{ id: number, filePath: string }[]>([]);
  const [sourceJson, setSourceJson] = useState<string>()
  const [targetJson, setTargetJson] = useState<string>()
  const { addNoteBookConvertedScript } = useAddNoteBook(dataPodId)
  const [loadingFiles, setLoadingFiles] = useState<string[]>([])
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [convertedFiles, setConvertedFiles] = useState<ConvertedFile[]>([]);
  const [isSource, setIsSource] = useState<boolean>()
  const [fileMetrics, setFileMetrics] = useState<{
    [key: string]: { similarity_score: number; llmLogicalSimilarity: number, reason: string }
  }>({})
  const { updateServiceConnectionFn, isUpdatingConnection } = useUpateServiceConnection(dataPodId)

  const [isCoversionLoading, setIsConversionLoading] = useState<boolean>(false)
  const [isCoversionSelectedLoading, setIsConversionSelectedLoading] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [successMessageStatus, setSuccessMessageStatus] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorMessageStatus, setErrorMessageStatus] = useState<boolean>(false)

  const { fetchConvertedFiles } = useGetConvertedFiles(dataPodId)
  const { addConvertedFiles, isAddingConvertedFiles, successAddingConvertedFiles, errorAddingConvertedFiles } = useAddConvertedFiles(dataPodId)

  const [convertedFilesMap, setConvertedFilesMap] = useState<Record<string, ConvertedFiles>>({});
  const [promptInput, setPromptInput] = useState<string>('');

  // State for the selected code type
  const [selectedCodeType, setSelectedCodeType] = useState<string | null>(null);
  const fileExtensionsMap: { [key: string]: string } = {
    PySpark: '.py',
    Python: '.py',
    PyNB: '.ipynb',
    PySQL: '.sql',
    'Databricks Workflow Job Json': '.json',
  };

  const { getNotebook, fetchedNotebook } = useGetNoteBook(dataPodId)
  // States for buttons

  useEffect(() => {
    if (dataPodId) {
      // console.log(dataPodId)
      getSourceConnections(dataPodId)
    }
  }, [])

  // useEffect(()=>{
  //   if(targetJson){
  //     console.log(targetJson)
  //   }
  // },[targetJson])

  useEffect(() => {
    if (selectedConnectionId || successAddingConvertedFiles)
      getDbConvertedFiles()
  }, [selectedConnectionId, successAddingConvertedFiles])

  useEffect(() => {
    if (fetchedSourceMetaData) {
      // console.log(fetchedSourceMetaData)
    }
  }, [fetchedSourceMetaData])

  const dataSources = useMemo(() => {
    if (!profileInputs) return []
    return profileInputs
  }, [profileInputs])

  const getDbConvertedFiles = async () => {
    const data = await fetchConvertedFiles(selectedConnectionId)

    if (data) {
      const newMap = data.reduce((acc, file) => {
        acc[file.fileName] = file;
        return acc;
      }, {} as Record<string, ConvertedFiles>);

      setConvertedFilesMap(newMap);
      console.log({ targetJson })
    }
  }

  const handleCompareFiles = async (rowId: string) => {
    try {
      // Find the source file data from your table
      if (dataPodId) {
        // const targetTemp = await getConnections(dataPodId, undefined, 'CDT')
        const sourceFile = fetchedSourceMetaData.find(file => file.fileName === rowId);
        const convertedFileInfo = convertedFilesMap[rowId];
        if (sourceFile) {
          const filePath = convertedFileInfo.convertedLink.split(`${dataPodId}`)
          const finalFilePath = `${dataPodId}` + filePath[1].split('&')[0]
          // console.log({finalFilePath})
          const code = await getNotebook(finalFilePath, selectedTargetConnectionId)
          const comparisonData = {
            sourceCode: sourceFile.fileContent,
            convertedCode: code.data, // Make sure this is the actual code, not just the link
            sourceFileName: sourceFile.fileName,
            metrics: {
              lucidConfidenceScore: convertedFileInfo.lucidConfidenceScore || 0,
              reason: convertedFileInfo.reasonConversionLog || 'No analysis available'
            }
          };
  
          sessionStorage.setItem('comparisonData', JSON.stringify(comparisonData));
          // console.log('Stored comparison data:', sessionStorage.getItem('comparisonData'));
  
          const comparisonRoute = generatePath(ROUTES.ComparingMigrationFiles, {
            dataPodId: dataPodId,
            dataSystemID: dataSystemID
          });
          navigate(comparisonRoute);
        }
        if (!sourceFile || !convertedFileInfo) {
          setErrorMessageStatus(true);
          setErrorMessage('Source or converted file not found');
          return;
        }

        // console.log('Source File:', sourceFile);
        // console.log('Converted File Info:', convertedFileInfo);

      }
    }
    catch (error) {
      // console.error('Error preparing comparison:', error);
      setErrorMessageStatus(true);
      setErrorMessage('Failed to prepare comparison data');
    }
  };

  const onClickBack = () => {
    const dataModelingRoute = generatePath(ROUTES.DataModeling, {
      dataPodId: dataPodInfo?.id,
    })
    navigate(dataModelingRoute)
  }

  const handleCheckboxChange = (fileName: string) => {
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(fileName)) {
        // If the file is already selected, remove it
        return prevSelectedFiles.filter((file) => file !== fileName);
      } else {
        // If the file is not selected, add it
        return [...prevSelectedFiles, fileName];
      }
    });
  };

  const tableColumns = useMemo(() => {
    return [
      { label: '', sortableColumn: false },
      { label: 'File name', sortableColumn: true },
      { label: 'File Size', sortableColumn: true },
      { label: 'File Type', sortableColumn: true },
      { label: 'Last Modified Time', sortableColumn: true },
      // { label: 'Similarity score', sortableColumn: true },
      { label: 'Lucid Confidence score', sortableColumn: true },
      { label: 'Reason/Comparision Log', sortableColumn: true },
      { label: 'Link' },
      { label: 'Compare File' }
    ]
  }, [])
  const formatFileSize = (sizeInBytes: number): string => {
    if (sizeInBytes === 0) return '0 Bytes'
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024))
    return `${(sizeInBytes / Math.pow(1024, i)).toFixed(2)} ${units[i]}`
  }
  // const tableRows = useMemo(() => {
  //     return fetchedSourceMetaData.map((ce) => ({
  //         id: String(ce.id),
  //         values: [ce.fileName, `${formatFileSize(ce.fileSize)}`, ce.fileType, ce.lastModified, 'some-link'],
  //     }));
  // }, [fetchedSourceMetaData, selectedConnectionId]);
  // const tableRows = useMemo(() => {
  //   return fetchedSourceMetaData.map((ce) => {
  //     const metrics = fileMetrics[ce.fileName] || {
  //       similarity_score: 'Yet To Calculate',
  //       llmLogicalSimilarity: 'Yet To Calculate',
  //     } // Default to 'N/A' if metrics are not available

  //     return {
  //       id: String(ce.id),
  //       values: [
  //         ce.fileName,
  //         `${formatFileSize(ce.fileSize)}`,
  //         ce.fileType,
  //         ce.lastModified,
  //         metrics.similarity_score,
  //         metrics.llmLogicalSimilarity,
  //         //   'some-link',
  //       ],
  //     }
  //   })
  // }, [fetchedSourceMetaData, selectedConnectionId, fileMetrics])
  const tableRows = useMemo(() => {
    return fetchedSourceMetaData.map((ce) => {
      const metrics = fileMetrics[ce.fileName] || {
        llmLogicalSimilarity: 'Yet To Calculate',
        reason: 'NA',
      };

      const isSelected = selectedFiles.includes(ce.fileName); // Check if this file is selected

      // Extract the base file name without extension
      const fileNamece = ce.fileName.split('.')[0];

      // Check if the file is in the convertedFiles array
      const convertedFile = convertedFiles.find((cf) => cf.fileName.split(".")[0] === fileNamece);

      // Get converted file information from convertedFilesMap
      const convertedFileLink = convertedFilesMap[ce.fileName]?.convertedLink;
      const convertedFileLucidScore = convertedFilesMap[ce.fileName]?.lucidConfidenceScore;
      const convertedFileReason = convertedFilesMap[ce.fileName]?.reasonConversionLog;

      // Determine what to show in the link column
      let linkDisplay;
      if (convertedFile) {
        // If there's a convertedFile, use its fileUrl as the link
        linkDisplay = (
          <a href={convertedFile.fileUrl} target="_blank" rel="noopener noreferrer">
            Converted File
          </a>
        );
      } else if (convertedFileLink) {
        // If there's a convertedFileLink, use it as the link
        linkDisplay = (
          <a href={convertedFileLink} target="_blank" rel="noopener noreferrer">
            Converted File
          </a>
        );
      } else {
        // Otherwise, show "Not Converted"
        linkDisplay = 'Not Converted';
      }

      // Logic to display either the LLM score or Lucid score, fallback to "Yet to Calculate"
      const displaySimilarityScore =
        convertedFileLucidScore !== undefined
          ? convertedFileLucidScore
          : metrics.llmLogicalSimilarity;

      const displayReason =
        convertedFileReason !== undefined
          ? convertedFileReason
          : metrics.reason;

      return {
        id: String(ce.id),
        values: [
          <Checkbox
            checked={isSelected}
            onChange={() => handleCheckboxChange(ce.fileName)} // Handle checkbox change
          />,
          ce.fileName,
          `${formatFileSize(ce.fileSize)}`,
          ce.fileType,
          ce.lastModified,
          displaySimilarityScore || 'Yet to Calculate', // Display similarity score or fallback
          displayReason || 'NA', // Display reason or fallback
          linkDisplay, // Display the link or 'Not Converted'
          <Button
            onClick={() => handleCompareFiles(ce.fileName)}
            startIcon={<CompareArrows />}
            variant="contained"
            color="primary"
            size="small"
            sx={{
              minWidth: '50px',
              fontSize: '0.75rem',
              padding: '6px 16px',
              backgroundColor: 'primary.main',
              color: 'white',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
              boxShadow: 1,
              borderRadius: '4px',
            }}>
            Compare
          </Button>
        ],
      };
    });
  }, [fetchedSourceMetaData, selectedConnectionId, fileMetrics, selectedFiles, convertedFiles, convertedFilesMap]);

  const getSourceConnections = async (dataPodId: string) => {
    const sourcetemp = await getConnections(dataPodId, undefined, 'CDS')
    const targetTemp = await getConnections(dataPodId, undefined, 'CDT')
    if (sourcetemp?.length) {
      setSourceData(sourcetemp)
      setSourceJson(sourcetemp[0].serviceConnectionJsonTemplate)
      const tempSourceContainerName = JSON.parse(sourcetemp[0].serviceConnectionJsonTemplate)
      setSelectedContainer(tempSourceContainerName.containerName)
      const tempContainers = sourcetemp.map(item => {
        const parsedTemplate = JSON.parse(item.serviceConnectionJsonTemplate); // Parse the JSON template
        return {
          id: item.id, // Use the id from sourcetemp
          containerName: parsedTemplate.containerName // Extract the containerName from the parsed object
        };
      });
      setContainers(tempContainers);
      setSelectedConnectionId(sourcetemp[0].id)
      await fetchSourceScriptMetadata(dataPodId, sourcetemp[0].id)
    }
    if (targetTemp?.length) {
      setTargetData(targetTemp)
      const tempTargetFilePath = targetTemp.map(item => {
        const parsedTemplate = JSON.parse(item.serviceConnectionJsonTemplate); // Parse the JSON template
        return {
          id: item.id, // Use the id from sourcetemp
          filePath: parsedTemplate.FilePath // Extract the containerName from the parsed object
        };
      });
      // console.log({ tempTargetFilePath })
      setTargetFilePath(tempTargetFilePath)
      setTargetJson(targetTemp[0].serviceConnectionJsonTemplate)
      const tempTarget = JSON.parse(targetTemp[0].serviceConnectionJsonTemplate)
      // console.log(tempTarget)
      setSelectedTargetFilePath(tempTarget.FilePath)
      setSelectedTargetConnectionId(targetTemp[0].id)
    }
  }
  const handleSourceChange = async (containerName: string) => {
    // console.log('Inside the handle source change')
    // console.log(containerName)
    setSelectedContainer(containerName)
    const connectionId = containers.find((sc) => sc.containerName === containerName)?.id
    const jsonData = sourceData.find((sd) => sd.id === connectionId)?.serviceConnectionJsonTemplate
    setSourceJson(jsonData)
    // console.log(connectionId)
    setSelectedConnectionId(connectionId)
    setSelectedFiles([])
    await fetchSourceScriptMetadata(dataPodId, connectionId)
  }
  const handleTargetChange = async (filePath: string) => {
    // console.log('Inside the handle source change')
    // console.log(filePath)
    setSelectedTargetFilePath(filePath)
    const connectionId = targetFilePaths.find((tp) => tp.filePath === filePath)?.id
    const jsonData = targetData.find((td) => td.id === connectionId)?.serviceConnectionJsonTemplate
    setTargetJson(jsonData)
    // console.log(connectionId)
    setSelectedTargetConnectionId(connectionId)
  }

  const handleUploadClick = (mode: 'source' | 'target') => {
    if (mode === 'source' && sourceJson) {
      setIsSource(true)
      setJsonInput(sourceJson)
    } else if (mode === 'target' && targetJson) {
      setIsSource(false)
      setJsonInput(targetJson)
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClear = () => {
    setJsonInput('')
  }

  const handleSave = async () => {
    try {
      // console.log('Saved JSON:', jsonInput);

      let updatedServiceConnection;

      if (isSource) {
        // Find the service connection using the selectedConnectionId from sourceData
        const selectedSourceConnection = sourceData.find(
          (connection) => connection.id === selectedConnectionId
        );

        if (selectedSourceConnection) {
          // Modify the source service connection's JSON template
          updatedServiceConnection = {
            ...selectedSourceConnection,  // Spread the existing source connection
            serviceConnectionJsonTemplate: jsonInput // Update the JSON template
          };
        }
        setSourceJson(jsonInput)
      } else {
        // Find the service connection using the selectedTargetConnectionId from targetData
        const selectedTargetConnection = targetData.find(
          (connection) => connection.id === selectedTargetConnectionId
        );

        if (selectedTargetConnection) {
          // Modify the target service connection's JSON template
          updatedServiceConnection = {
            ...selectedTargetConnection,  // Spread the existing target connection
            serviceConnectionJsonTemplate: jsonInput // Update the JSON template
          };
          setTargetJson(jsonInput)
        }
      }

      if (updatedServiceConnection) {
        // Call the update service connection function with the updated service connection
        await updateServiceConnectionFn(updatedServiceConnection);
        setSuccessMessageStatus(true)
        setSuccessMessage('Service connection updated successfully')
        // console.log('Service connection updated successfully');
      } else {
        // console.error('No service connection found to update.');
      }
    } catch (error) {
      // console.error('Error updating service connection:', error);
    }

    // Close the modal after saving
    handleClose();
  };

  useEffect(() => {
    if (dataSystemID && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemID))
    }
  }, [dataSystemID])

  useEffect(() => {
    if (coreEntities.length && !coreEntityData.length) {
      setCoreEntities(coreEntities)
      setSelectedCoreEntity([coreEntities[0]])
    }
  }, [coreEntities])

  const navigate = useNavigate()

  // const handleConvert = async () => {
  //     try {
  //         const results = [];
  //         for (const metaData of fetchedSourceMetaData) {
  //             setLoadingFiles((prev) => [...prev, metaData.fileName]); // Start loading

  //             const fileBlob = new Blob([metaData.fileContent], { type: metaData.fileType });
  //             const file = new File([fileBlob], metaData.fileName, {
  //                 type: metaData.fileType,
  //                 lastModified: new Date(metaData.lastModified).getTime(),
  //             });

  //             const pySparkCodeResponse = await getPySparkCodeByFile(file);
  //             if (pySparkCodeResponse) {
  //                 results.push({
  //                     fileName: metaData.fileName,
  //                     pySparkCode: pySparkCodeResponse,
  //                 });
  //                 const body:ConversionMetricsRequest =  {
  //                     source_code:pySparkCodeResponse.original_script,
  //                     source_codeType:'sas',
  //                     pyspark_code:pySparkCodeResponse.pyspark_code
  //                 }
  //                 // const nbBody:addNotebookRequest = {
  //                 //     filePath: `cds/`
  //                 // }
  //                 // const success = await addNoteBookConvertedScript()
  //                 const metrics = await getPySparkCodeMetrics(body)
  //                 if(metrics){
  //                     // console.log({metrics})
  //                     // setCurrentMetrics(metrics.similarity_score)
  //                     // setLLmMetrics(metrics.llmLogicalSimilarity)
  //                     setFileMetrics((prevMetrics) => ({
  //                         ...prevMetrics,
  //                         [metaData.fileName]: {
  //                             similarity_score: metrics.similarity_score,
  //                             llmLogicalSimilarity: metrics.llmLogicalSimilarity,
  //                         },
  //                     }));
  //                 }

  //             }

  //             setLoadingFiles((prev) => prev.filter((f) => f !== metaData.fileName)); // Stop loading
  //         }
  //         console.log(results)
  //     } catch (error) {
  //         console.error('Error during conversion:', error);
  //     }
  // }
  function downloadStringAsFile(fileName: string, content: string, mimeType = 'text/plain') {
    // Create a blob with the content
    const blob = new Blob([content], { type: mimeType })

    // Create a temporary link element
    const link = document.createElement('a')

    // Create a URL for the blob and set it as href
    const url = window.URL.createObjectURL(blob)
    link.href = url

    // Set the download attribute to the desired file name
    link.download = fileName

    // Programmatically click the link to trigger the download
    link.click()

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url)
  }

  const getFilePath = (fileName: string) => {
    if (targetJson) {
      const parsedJson = JSON.parse(targetJson)
      const userDefinedPath: string = parsedJson?.FilePath || 'convertedFiles'

      const targetConnectionJson = JSON.parse(targetJson)
      return `https://dev.azure.com/${targetConnectionJson.AzureDevopsOrganizationName}/${targetConnectionJson.AzureDevopsProjectName}/_git/${targetConnectionJson.AzureDevopsRepositoryID}?path=${dataPodId}/${userDefinedPath}/${fileName}&version=GBmain`
    }
  }

  const saveConvertedFile = async (fileName: string, content: string, addFolder: boolean) => {
    if (targetJson) {
      const parsedJson = JSON.parse(targetJson)
      // console.log({ parsedJson })
      let azureDevops: boolean
      if (parsedJson.AzureDevopsOrganizationName) {
        azureDevops = true
      } else {
        azureDevops = false
      }
      const userDefinedPath: string = parsedJson?.FilePath || 'convertedFiles'

      const addNotebookRequest: addNotebookRequest = {
        filePath: `${dataPodId}/${userDefinedPath}/${fileName}`,
        notebook: content,
        commitMessage: `Added or updated ${fileName} after conversion.`
      }

      const isFileSaved = await addNoteBookConvertedScript(addNotebookRequest, selectedTargetConnectionId);
      if (isFileSaved) {
        setSuccessMessageStatus(true);
        setSuccessMessage(`${fileName} saved to target location.`);
        const targetConnectionJson = JSON.parse(targetJson)
        let fileUrl: string
        if (addFolder) {
          // console.log("add folder is true")
          if (azureDevops) {
            fileUrl = `https://dev.azure.com/${targetConnectionJson.AzureDevopsOrganizationName}/${targetConnectionJson.AzureDevopsProjectName}/_git/${targetConnectionJson.AzureDevopsRepositoryID}?path=${dataPodId}/${userDefinedPath}/${fileName}&version=GBmain`
          } else {
            fileUrl = `https://github.com/${targetConnectionJson.GitOwner}/${targetConnectionJson.GitRepositoryName}/blob/main/${dataPodId}/${userDefinedPath}/${fileName}`
          }
          // fileUrl = `https://dev.azure.com/${targetConnectionJson.AzureDevopsOrganizationName}/${targetConnectionJson.AzureDevopsProjectName}/_git/${targetConnectionJson.AzureDevopsRepositoryID}?path=${dataPodId}/${userDefinedPath}/${fileName.split('.')[0]}/${fileName}&version=GBmain`

        } else {
          if (azureDevops) {
            fileUrl = `https://dev.azure.com/${targetConnectionJson.AzureDevopsOrganizationName}/${targetConnectionJson.AzureDevopsProjectName}/_git/${targetConnectionJson.AzureDevopsRepositoryID}?path=${dataPodId}/${userDefinedPath}/${fileName}&version=GBmain`
          } else {
            fileUrl = `https://github.com/${targetConnectionJson.GitOwner}/${targetConnectionJson.GitRepositoryName}/blob/main/${dataPodId}/${userDefinedPath}/${fileName}`
          }
        }
        // console.log(fileUrl, fileName)
        setConvertedFiles((prevConvertedFiles) => [
          ...prevConvertedFiles,
          { fileName, fileUrl },
        ]);
        return true
      } else {
        setErrorMessageStatus(true);
        setErrorMessage('Internal server error. Failed to save the file.')
        return false
      }
    }

  }

  const handleConvert = async (mode: 'all' | 'selected') => {
    try {
      const results = [];

      // Determine whether to process all files or only selected ones
      const filesToConvert = mode === 'all'
        ? fetchedSourceMetaData
        : fetchedSourceMetaData.filter((metaData) => selectedFiles.includes(metaData.fileName));
      if (mode === "all") {
        setIsConversionLoading(true);
      } else {
        setIsConversionSelectedLoading(true)
      }

      const convertedFilesBody: ConvertedFiles[] = []

      for (const metaData of filesToConvert) {
        setLoadingFiles((prev) => [...prev, metaData.fileName]);

        const fileBlob = new Blob([metaData.fileContent], { type: metaData.fileType });
        const file = new File([fileBlob], metaData.fileName, {
          type: metaData.fileType,
          lastModified: new Date(metaData.lastModified).getTime(),
        });

        if (selectedCodeType && promptInput) {
          const pySparkCodeResponse = await getPySparkCodeByFile(file, selectedCodeType, promptInput);
          if (pySparkCodeResponse) {
            results.push({
              fileName: metaData.fileName,
              pySparkCode: pySparkCodeResponse,
            });

            // downloadStringAsFile(
            //   `${metaData.fileName.replace('.sas', '')}.py`,
            //   pySparkCodeResponse.pyspark_code,
            //   'text/x-python'
            // );
            let addFolder: boolean
            // console.log(selectedCodeType.includes("+"))
            // console.log(selectedCodeType.split('+')[0])
            // console.log(fileExtensionsMap[selectedCodeType.split('+')[0].trim()],"it should be taking the right extension")
            if (selectedCodeType.includes("+")) {
              addFolder = true
            } else {
              addFolder = false
            }
            const selExt = addFolder ? fileExtensionsMap[selectedCodeType.split('+')[0].trim()] : fileExtensionsMap[selectedCodeType]
            // console.log({selExt})
            const selectedExtension = selExt || '.py';
            const updatedFileName = metaData.fileName.replace(/\.[^/.]+$/, selectedExtension);
            // console.log({updatedFileName})
            const respSave = await saveConvertedFile(`${updatedFileName}`, pySparkCodeResponse.pyspark_code, addFolder)
            if (pySparkCodeResponse.Databricksjson && pySparkCodeResponse.generated_filename && respSave) {
              saveConvertedFile(pySparkCodeResponse.generated_filename, pySparkCodeResponse.Databricksjson, addFolder)
            }

            const body: ConversionMetricsRequest = {
              source_code: pySparkCodeResponse.original_script,
              source_codeType: 'sas',
              pyspark_code: pySparkCodeResponse.pyspark_code,
            };

            setSuccessMessageStatus(true);
            setSuccessMessage(`${metaData.fileName} is successfully converted!!!`);

            let metrics: getConversionMetricsResponse | undefined
            let metricsCode: getConversionMetricsResponse | undefined
            if (metaData.fileName.split('.')[1] === 'sas') {
              metrics = await getPySparkCodeMetrics(body);
            } else {
              let genericBody: GenericConversionMetricsRequest
              if (addFolder) {
                genericBody = {
                  source_code: pySparkCodeResponse.original_script,
                  source_codeType: `${metaData.fileName.split('.')[1]}`,
                  target_code: pySparkCodeResponse.pyspark_code,
                  target_type: selectedCodeType.split('+')[0]
                }
              } else {
                genericBody = {
                  source_code: pySparkCodeResponse.original_script,
                  source_codeType: `${metaData.fileName.split('.')[1]}`,
                  target_code: pySparkCodeResponse.pyspark_code,
                  target_type: selectedCodeType
                }
              }
              metrics = await getGenericCodeMetrics(genericBody)
              if (addFolder && pySparkCodeResponse.Databricksjson && metrics) {
                const genericBody = {
                  source_code: pySparkCodeResponse.original_script,
                  source_codeType: `${metaData.fileName.split('.')[1]}`,
                  target_code: pySparkCodeResponse.Databricksjson,
                  target_type: selectedCodeType.split('+')[1]
                }
                metricsCode = await getGenericCodeMetrics(genericBody)
                // console.log({ metricsCode })
                if (metricsCode) {
                  setFileMetrics((prevMetrics) => {
                    const currentFileMetrics = prevMetrics[metaData.fileName] || {};

                    return {
                      ...prevMetrics,
                      [metaData.fileName]: {
                        llmLogicalSimilarity: `${currentFileMetrics.llmLogicalSimilarity}, ${metricsCode?.llmLogicalSimilarity || 0}`,
                        reason: `${currentFileMetrics.reason || 'No reason available'}, ${metricsCode?.reason || 'No additional reason'}`,
                      },
                    };
                  });
                }
              }
            }
            if (metrics) {
              setFileMetrics((prevMetrics) => ({
                ...prevMetrics,
                [metaData.fileName]: {
                  llmLogicalSimilarity: metricsCode
                    ? `${metrics?.llmLogicalSimilarity || 0}, ${metricsCode?.llmLogicalSimilarity || 0}`
                    : metrics?.llmLogicalSimilarity || 0,
                  reason: metrics?.reason || 'No reason available',
                },
              }));
              if (dataPodId && selectedConnectionId) {
                const updatedFileName = metaData.fileName.replace(/\.[^/.]+$/, selectedExtension);
                convertedFilesBody.push({
                  id: 0,
                  dataPodId: dataPodId,
                  serviceConnectionId: selectedConnectionId,
                  fileName: metaData.fileName,
                  convertedLink: getFilePath(`${updatedFileName}`) || '',
                  isActive: true,
                  lucidConfidenceScore: metrics?.llmLogicalSimilarity,
                  reasonConversionLog: metrics?.reason
                })
              }
            } else {
              if (dataPodId && selectedConnectionId) {
                const updatedFileName = metaData.fileName.replace(/\.[^/.]+$/, selectedExtension);
                convertedFilesBody.push({
                  id: 0,
                  dataPodId: dataPodId,
                  serviceConnectionId: selectedConnectionId,
                  fileName: metaData.fileName,
                  convertedLink: getFilePath(`${updatedFileName}`) || '',
                  isActive: true,
                  lucidConfidenceScore: 0,
                  reasonConversionLog: "Failed to generate the Lucid Confidence Score try again."
                })
              }
            }
          }
        }
        setLoadingFiles((prev) => prev.filter((f) => f !== metaData.fileName));
      }

      addConvertedFiles(convertedFilesBody)
      // console.log(results);
    } catch (error) {
      console.error('Error during conversion:', error);
    } finally {
      if (mode === 'all') {
        setIsConversionLoading(false)
      } else {
        setIsConversionSelectedLoading(false)
      }
    }
  };
  const handlePromptInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromptInput(event.target.value);
  };

  // Handle the change for the code type dropdown
  const handleCodeTypeChange = (event: React.SyntheticEvent, newValue: string | null) => {
    setSelectedCodeType(newValue);
  };

  return (
    <div>
      <ResultStatus
        severtiy={'success'}
        showStatus={successMessageStatus}
        closeStatus={setSuccessMessageStatus}
        alertMessage={successMessage}
      />
      <ResultStatus
        severtiy={'error'}
        showStatus={errorMessageStatus}
        closeStatus={setErrorMessageStatus}
        alertMessage={errorMessage}
      />
      <Grid item xs={3} mb={4}>
        <PreviousPage onClick={onClickBack} pageName={'Back'} />
      </Grid>
      <Box style={cardContainer}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={4} alignItems={'center'}>
                <Grid item container xs={15} spacing={1}>
                  <Grid item xs={5}>
                    <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                      Data Scripts Container Names
                    </Typography>
                  </Grid>
                  <Grid item xs={0.5} />
                  <Grid item xs={5}>
                    <Typography component="span" variant="subtitle1" sx={{ fontSize: 14, marginLeft: '10%' }}>
                      Target Location Configuration
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />

                  <Grid item xs={12} md={15} />

                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id="outlined-select-dataSource"
                        select
                        variant="outlined"
                        value={selectedContainer ?? ''}
                        required
                        size="small"
                        // type="number"
                        onChange={({ target }) => handleSourceChange(target.value)}
                      >
                        {containers.map((d) => (
                          <MenuItem key={d.id} value={d.containerName}>
                            <Typography fontSize={'small'}> {d.containerName}</Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        startIcon={<Edit fontSize="small" />}
                        variant="contained"
                        onClick={() => handleUploadClick('source')}
                        style={{ width: '2%' }}
                      ></Button>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id="outlined-select-dataSource"
                        select
                        variant="outlined"
                        value={selectedTargetFilePath ?? ''}
                        required
                        size="small"
                        // type="number"
                        onChange={({ target }) => handleTargetChange(target.value)}
                      >
                        {targetFilePaths.map((d) => (
                          <MenuItem key={d.id} value={d.filePath}>
                            <Typography fontSize={'small'}> {d.filePath}</Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        startIcon={<Edit fontSize="small" />}
                        variant="contained"
                        onClick={() => handleUploadClick('target')}
                        style={{ width: '2%' }}
                      ></Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={9} />
          <Grid item xs={12}>
            <ShadowTable
              rows={tableRows}
              columns={tableColumns}
              tableActionParams={{
                onButtonClick: (rowId: string, actionType: ShadowTableAction) => {
                  console.log(`Button clicked on row ${rowId}, action type: ${actionType}`)
                },
                actions: [ShadowTableAction.Viewable],
              }}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              label="Prompt Input"
              multiline
              rows={4} // Adjust the number of rows for multiline input
              variant="outlined"
              fullWidth
              placeholder="Enter your prompt here..."
              value={promptInput}
              onChange={handlePromptInputChange} // Handle prompt input changes
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Autocomplete
              disablePortal
              options={['PySpark', 'Python', 'PyNB', 'PySQL', 'Pyspark + Databricks Workflow Job Json', 'Python + Databricks Workflow Job Json', 'PyNB + Databricks Workflow Job Json', 'PySQL + Databricks Workflow Job Json']}
              renderInput={(params) => <TextField {...params} label="Code Type" />}
              fullWidth
              value={selectedCodeType}
              onChange={handleCodeTypeChange} // Handle code type selection
            />
          </Grid>
          {/* Convert All Button */}
          <LoadingButton
            loading={isCoversionLoading}
            variant="contained"
            color="primary"
            onClick={() => handleConvert('all')}
            sx={{ mr: 3 }}
            disabled={
              selectedFiles.length > 0 || // Disable if there are selected files
              !promptInput.length || // Disable if promptInput is empty
              !selectedCodeType ||
              isCoversionSelectedLoading // Disable if no code type is selected
            }
          >
            Convert All
          </LoadingButton>

          {/* Convert Selected Button */}
          <LoadingButton
            loading={isCoversionSelectedLoading}
            variant="contained"
            color="primary"
            onClick={() => handleConvert('selected')}
            disabled={
              selectedFiles.length === 0 || // Disable if no files are selected
              !promptInput.length || // Disable if promptInput is empty
              !selectedCodeType || // Disable if no code type is selected
              isCoversionLoading
            }
          >
            Convert Selected
          </LoadingButton>
        </Grid>
      </CardContent>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Enter the JSON Format String
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={12}
            variant="outlined"
            label="JSON Input"
            value={jsonInput}
            onChange={(e) => setJsonInput(e.target.value)}
          />
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button variant="outlined" color="primary" onClick={handleClear}>
              Clear
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
