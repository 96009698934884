import { generatePath, useNavigate, useParams } from 'react-router-dom'
import ResultStatus from 'ui-components/ResultStatus'
import { useEffect, useState } from 'react'
import ActionPage from 'ui-components/ActionPage'

import { Button, Card, CardHeader, Checkbox, Collapse, Container, Divider, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from '@mui/material'
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import { ROUTES } from 'Routes/constants'
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp'
import { useGetEntity } from 'hooks/entityHooks'
import {
  useGetActiveRules,
  useGetDataQualityRules,
} from 'hooks/dataQualityHooks'
import {
  CreateJobResponse,
  DataQualityNBRequest,
  DataQualityRule,
  OperationState,
  RunStatusResponse,
} from 'businessObjects'
import { LucidJupyter } from 'ui-components/JupyterComponents/LucidJupyter'
import constants from 'pages/constants'
import { LoadingButton } from '@mui/lab'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { filterActiveRunIds, initiateActiveRunId } from 'features/notification/notificationSlice'
import { RootState } from 'app/store'
import AnimatedLoader from 'ui-components/AnimatedLoader'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { green } from '@mui/material/colors'

type CombinedDataQualitiesParams = {
  dataPodId: string
  entityID: string
}

export const CombinedDataQualities = () => {
  const { dataPodId, entityID } = useParams<CombinedDataQualitiesParams>()

  const [isDataQualitiesAsc, setIsDataQualitiesAsc] = useState<boolean>(false)
  const [isDataQualitiesCollapsed, setIsDataQualitiesCollapsed] = useState<boolean>(false)

  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [popupPromiseResolver, setPopupPromiseResolver] = useState<((value: boolean) => void) | null>(null)

  const [commitMessage, setCommitMessage] = useState<string>(constants.initialCommitMessage)

  const [notebookStr, setNotebookStr] = useState<string>()
  const [proccessingReq, setProcessingReq] = useState<boolean>(false)
  const [selectedDataQualityIds, setSelectedDataQualityIds] = useState<number[]>([])

  const [resultState, setResultState] = useState<OperationState>()

  const [entities] = useGetEntity({}, dataPodId, undefined, undefined)

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { dataQualityRules, isDataQualityRulesLoading, isDataQualityRulesError } =
    useGetDataQualityRules(entityID ? Number(entityID) : undefined)

  const [sortedDataQualityRules, setSortedDataQualityRules] = useState<DataQualityRule[]>([])

  useEffect(() => {
    if(dataQualityRules.length) {
      const sortedRules = [...dataQualityRules].sort((rule1, rule2) => {
        if (isDataQualitiesAsc) {
          return rule1.name.localeCompare(rule2.name);
        } else {
          return rule2.name.localeCompare(rule1.name);
        }
      });

      setSortedDataQualityRules(sortedRules)
    }
  },[dataQualityRules, isDataQualitiesAsc])

  const onClickHome = () => {
    const dataAttributePage = generatePath(ROUTES.DataModelingCanonicalModel, {
      dataPodId: dataPodId,
    })
    navigate(dataAttributePage)
  }

  const saveNotebook = async (id: number) => {
    await new Promise((resolve) => setTimeout(resolve, 0))

    const payload: DataQualityNBRequest = {
      dataPodId: dataPodId || '',
      dataQualityRuleId: id,
      notebookToRun: notebookStr || constants.defaultNotebookString,
      commitMessage: commitMessage,
    }
  }

  const handlePopupConfirm = () => {
    if (popupPromiseResolver) {
      popupPromiseResolver(true)
      setPopupPromiseResolver(null)
      // setProcessingReq(false)
    }
    setShowPopUp(false)
  }

  const handlePopupCancel = (close: boolean) => {
    if (popupPromiseResolver) {
      popupPromiseResolver(close)
      setPopupPromiseResolver(null)
      setProcessingReq(false)
    }
    setShowPopUp(close)
    setProcessingReq(false)
  }

  const onGenerateCode = async () => {
  }

  const executeNotebook = async () => {
    setProcessingReq(true)
  }

  const updateRunIdState = (result: CreateJobResponse) => {
    const activeRunId = activeRunIds.find((element) => element.runId === result.runId)
    const routePage = generatePath(ROUTES.DataModelingCanonicalModelCombinedDataQuality, {
      dataPodId: dataPodId,
      entityID: entityID
    })
    if (!activeRunId) {
      const newRunId: RunStatusResponse = {
        RunStaturesponsesId: Date.now(),
        runId: result.runId,
        state: {
          lifeCycleState: 'INITIATED',
          resultState: 'LOADING',
        },
        params: {
          runId: result.runId,
          url: result.url,
          token: result.token,
        },
        jobDetails: {
          triggerType: 'Data Quality Code Execution',
          parameters: {
            id: entityID,
            name: 'sample name',
            route: routePage,
          },
        },
      }

      dispatch(filterActiveRunIds())
      dispatch(initiateActiveRunId(newRunId))
    }
  }
  const onClickBack = (event: any): void => {
    onClickHome()
  }

  const onPreviewButtonClick = async () => {
    const columProfileRoute = generatePath(ROUTES.DataModelingCanonicalDataPreview, {
      dataPodId: dataPodId,
      dataEntityID: Number(entityID),
    })
    navigate(columProfileRoute)
  }

  const handleOnCheckboxClicked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, dataQualityId: number) => {
    if(checked) {
      if(selectedDataQualityIds.includes(dataQualityId))
        return

      setSelectedDataQualityIds(prevSelected => [...prevSelected, dataQualityId])
    }
    else if(!checked) {
      if(!selectedDataQualityIds.includes(dataQualityId))
        return

      setSelectedDataQualityIds(prevSelected => prevSelected.filter(id => id !== dataQualityId))
    }
  }

  return (
    <>
      <ResultStatus
        showStatus={!!resultState?.display}
        alertMessage={resultState?.message}
        severtiy={resultState?.result}
        closeStatus={() => setResultState({ display: undefined, message: undefined, result: undefined })} 
      />

      <ActionPage>
        <AnimatedLoader height={'30%'} width={'40%'} loading={proccessingReq} />
        <Grid
          item
          container
          xs={15}
          alignItems="center"
          spacing={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-start'}
        >
          <Grid item xs={2}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h3" color="primary" gutterBottom>
              Data Quality
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container sx={{mr:1, ml:1}} item xs={16} > */}
        <Grid container item xs={16} alignItems={'stretch'} alignContent={'center'} spacing={2} paddingTop={'10px'}>
          <Grid item xs={15} />
          <Grid item xs={3.5} alignItems={'stretch'}>
            <Card sx={{ backgroundColor: 'white', marginLeft: 0 }}>
              <CardHeader
                disableTypography
                sx={{ backgroundColor: 'grey' }}
                title={
                  <Grid item xs={12} container alignItems="baseline" display={'flex'} justifyContent={'space-between'} spacing={1}>
                    <Grid item xs={8}>
                      <Typography variant="body2" sx={{ fontSize: 16 }} gutterBottom>
                        {'Data Quality Rules'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        title={`sort ${isDataQualitiesAsc ? 'asc' : 'desc'}`}
                        onClick={() => setIsDataQualitiesAsc(!isDataQualitiesAsc)}
                      >
                        {isDataQualitiesAsc ? <ArrowDownwardOutlined fontSize="small" /> : <ArrowUpwardOutlined fontSize="small" />}
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              />
              <Divider />
              <Grid item display={'flex'} justifyContent={'flex-end'} flexDirection={'row'}>
                <IconButton onClick={() => setIsDataQualitiesCollapsed(!isDataQualitiesCollapsed)}>
                  {isDataQualitiesCollapsed ? <><Typography>Expand</Typography><ArrowDownwardOutlined fontSize="small" /></> :
                    <><Typography>Collapse</Typography><ArrowUpwardOutlined fontSize="small" /></>}
                </IconButton>
              </Grid>
              <Collapse in={!isDataQualitiesCollapsed} timeout="auto" unmountOnExit>
                <List dense>
                  {sortedDataQualityRules.map((p, ix) => (
                    <ListItem
                      key={ix}
                      // sx={{
                      //   color: selectedEntityID === p.id ? 'blue' : 'inherit',
                      // }}
                    >
                      <Checkbox
                        sx={{
                          color: 'green',
                          '&.Mui-checked': {
                            color: green[600],
                          },
                        }}
                        edge="start"
                        tabIndex={-1}
                        checked={selectedDataQualityIds.includes(p.id)}
                        onChange={(event, checked) => handleOnCheckboxClicked(event, checked, p.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        // disabled={p.canonicalLoad}
                        disableRipple
                      />
                      <ListItemButton
                        // onClick={() => {
                        //   setNotebookString('')
                        //   setSelectedEntityID(p.id)
                        // }}
                      >
                        <ListItemText primary={p.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Card>
          </Grid>
          <Grid item xs={8.5} alignItems={'stretch'}>
            <Card sx={{ width: '100%' }}>
              <Grid display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <LoadingButton
                    loading={true}
                    variant="contained"
                    color="primary"
                    // disabled={!dataQualityId}
                    onClick={onGenerateCode}
                  >
                    Generate code
                  </LoadingButton>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="outlined" onClick={onPreviewButtonClick} disabled={!entityID}>
                    Data Preview
                  </Button>
                </Grid>
              </Grid>
              <Container>
                <LucidJupyter
                  executeNotebook={executeNotebook}
                  noteBookString={notebookStr || constants.defaultNotebookString}
                  headingText={constants.codeHeaderText}
                  updateNoteBookString={setNotebookStr}
                  commitMessage={commitMessage}
                  updateCommitMessage={setCommitMessage}
                />
              </Container>
            </Card>
          </Grid>
        </Grid>

        <LucidPopUp
          showCloseIcon
          closePopup={handlePopupCancel}
          openPopUp={showPopUp}
          onConfirm={handlePopupConfirm}
          headingText=""
          confirmText="Commit"
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Link href={'https://github.com/Lucid-Data-Hub/gen-datalake-dev'} target="blank">
                Git Hub Repository
              </Link>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter Commit Message"
                variant="outlined"
                color="secondary"
                required
                rows={2}
                multiline
                value={commitMessage}
                onChange={({ target }) => setCommitMessage(target.value)}
              />
            </Grid>
          </Grid>
        </LucidPopUp>
      </ActionPage>
    </>
  )
}
