import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, ClickAwayListener, Grow, IconButton, Paper, Popper, Typography } from "@mui/material"
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { act, useEffect, useState } from "react";

import { useGetDatabricksRunStatus } from "../../hooks/databricksHooks";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addActiveRunIds, addFabricRunStatus, filterActiveRunIds, filterFabricRunStatus, filterGenAIApiStatus, removeActiveRunId, removeAllFailureNotification } from "../../features/notification/notificationSlice";
import { ApiStatus, FabricJobStatusRequest, FabricJobStatusResponse, GenAIApiStatus, GenericFailureNotification, RunIdStatus, RunStatusResponse } from "../../businessObjects";
import { useGetFabricJobStatus } from "hooks/fabricHooks";
import { LucidPopUp } from "ui-components/LucidPopUp/LucidPopUp";

type NotificationTypes = GenAIApiStatus | RunStatusResponse | FabricJobStatusResponse | GenericFailureNotification;

type Props = {
  handleToggle: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shouldNotify: boolean;
  setShouldNotify: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.MutableRefObject<null>
}

const isGenAIApiStatusType = (notification: any): notification is GenAIApiStatus => {
  return "GenAIApiStatusId" in notification;
};

const isFailureNotificationType = (notification: any): notification is GenericFailureNotification => {
  return "failureNotificationId" in notification;
};

const isRunStatusResponseType = (notification: any): notification is RunStatusResponse => {
  return "runId" in notification && "state" in notification;
};

const isFabricRunStatusType = (notification: any): notification is FabricJobStatusResponse => {
  return "workspaceId" in notification && "status" in notification;
};

export const NotificationPopper = ({ handleToggle, isOpen,setIsOpen, anchorRef, shouldNotify, setShouldNotify }: Props) => {
  const [runStatus, setRunStatus] = useState<RunStatusResponse[]>([]);

  const [notifications, setNotifications] = useState<(NotificationTypes)[]>([]);

  const { fetchRunStatus } = useGetDatabricksRunStatus();

  const { fetchFabricJobStatus } = useGetFabricJobStatus();

  
  let activeRunIds = useAppSelector((state) => state.notification.activeRunIds)
  const fabricRunStatus = useAppSelector((state) => state.notification.fabricRunStatus)
  const genAIApiStatus = useAppSelector((state) => state.notification.genAIApiStatus)
  const failureNotification = useAppSelector((state) => state.notification.failureNotification)
  const dispatch = useAppDispatch()

  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [detiailsHeader, setDetailsHeader] = useState<string>('');
  const [detailsBody, setDetailsBody] = useState<string>('');

  useEffect(() => {
    if (activeRunIds?.length > 0 || genAIApiStatus?.length > 0 || fabricRunStatus?.length > 0) {

      setNotifications((prevNotifications) => {
        const newSet = new Set([...activeRunIds, ...fabricRunStatus, ...genAIApiStatus])
          const newNotifications = Array.from(newSet)
          const hasChange =
          newNotifications.length !== prevNotifications.length ||
          !newNotifications.every((newItem, index) => {
            const prevItem = prevNotifications[index];
     
            if (!prevItem) return false;
     
            // Ensure both objects have the same keys
            const newKeys = Object.keys(newItem);
            const prevKeys = Object.keys(prevItem);
     
            if (newKeys.length !== prevKeys.length) return false; // Different number of keys
     
            const areKeysEqual = newKeys.every((key) => prevKeys.includes(key));
            if (!areKeysEqual) return false; // Keys mismatch
     
            // Compare values for matching keys
            return newKeys.every((key) => (newItem as any)[key] === (prevItem as any)[key]);
          });
     
        if (hasChange) {
          if (!isOpen) {
            setShouldNotify(true);
          }
          return newNotifications; // Update notifications only if there is a change
        }
        return prevNotifications; // No change, keep the previous state
      });

      let interval = setInterval(async () => {
        Promise.allSettled([getRunStatus(), getFabricJobStatus()])
          .then(results => {
            results.forEach(result => {
              if (result.status === 'fulfilled') {
                // console.log('Success:', result.value);
              } else {
                console.error('Failed:', result.reason);
              }
            });
          });
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
    else {
      setShouldNotify(false)
    }
    
  }, [activeRunIds, genAIApiStatus, fabricRunStatus]);

  useEffect(() => {
    if (failureNotification?.length) {
      setNotifications((prevArray) => {
        const newSet = new Set([...failureNotification, ...prevArray]); // Merge existing and new entries
        return Array.from(newSet);
      });
      dispatch(removeAllFailureNotification())
      if (!isOpen)
        setShouldNotify(true)
    }
  }, [failureNotification]);

  useEffect(() => {
    if (shouldNotify) {
      notifications.forEach((notification) => {
        if (isGenAIApiStatusType(notification)) {
          setIsOpen(true)
          return
        } else if (isRunStatusResponseType(notification)) {
          const initiated = notification.state.lifeCycleState === 'INITIATED'
          const runningStatus = notification.state.lifeCycleState === 'RUNNING'
          const success = notification.state.lifeCycleState === 'TERMINATED'
          if (initiated) {
            console.log("status", notification.state)
            setIsOpen(true)
            return
          }
          else if (runningStatus) {
            console.log("status", notification.state)
            setIsOpen(false)
          }
          else if (success) {
            console.log("status", notification.state);
            setIsOpen(true)
            return
          }
        } else if (isFabricRunStatusType(notification)) {
          console.log(notification)
          const inProgress = notification.status === 'InProgress' || notification.status === 'NotStarted'
          // const success = notification.status === 'Completed' || notification.status === 'Failed'
          if (inProgress) {
            setIsOpen(true)
            return
          }
        } else if (isFailureNotificationType(notification)) {
          setIsOpen(true)
          return
        }
        else {
          setIsOpen(false)
        }
      })
    }
    setShouldNotify(false)
  }, [shouldNotify]);


  const getRunStatus = async () => {
    //Filter the run status of failed or success. So, we don't need status of them again.
    const filteredRunStatus = activeRunIds.filter(run => {
      const status = run.state.resultState
      return status !== 'FAILED' && status !== 'SUCCESS';
    })

    const payload: RunIdStatus[] = filteredRunStatus.map(run => {
      return {
        runId: run.runId,
        status: run.state.lifeCycleState,
        url: run.params.url,
        token: run.params.token
      }
    })
    
    if (payload.length) {
      const result = await fetchRunStatus(payload.filter((param)=>param.runId!=undefined));

      if (result) {
        dispatch(addActiveRunIds([...result]))
      }
    }
  }

  const getFabricJobStatus = async () => {
    //Filter the run status of failed or success. So, we don't need status of them again.
    const filteredFabricJobStatus = fabricRunStatus.filter(run => {
      return (run.status ==='InProgress' || run.status === 'NotStarted');
    })

    const payload: FabricJobStatusRequest[] = filteredFabricJobStatus.map(job => {
      return {
        workspaceId: job.workspaceId,
        itemId: job.itemId,
        JobInstanceId: job.id,
        jobType: job.jobType,
        token: job.token
      }
    })

    if (payload.length) {
      const result = await fetchFabricJobStatus(payload);

      if (result) {
        dispatch(addFabricRunStatus([...result]))
      }
    }
  }

  const renderByType = (notification: NotificationTypes, index: number) => {
    if(isGenAIApiStatusType(notification)) {
      const element = notification
      return (
        <Card
          key={index}
          sx={{
            marginBottom: 1,
            boxShadow: 4,
            borderRadius: 1,
          }}
        >
          <Box display="flex" alignItems="center">
          <CircularProgress 
            variant={(element.status === 'Initiated') ? "indeterminate" : "determinate"}
            size={24} 
            sx={{marginLeft: 1}}
          />
          <CardHeader
            title={`${element.title}`}
          />
          </Box>
          <CardContent>
            <Typography variant="h6">
              {`${element.description}`}
            </Typography>
            <Typography variant="h6">
              {`Status: ${element.status}`}
            </Typography>
            {/* <Typography variant="body1">
              {`API URL: ${element.apiUrl}`}
            </Typography>
            <Typography variant="body1">
              {`Redirect URL: ${element.redirectUrl}`}
            </Typography> */}
          </CardContent>
        </Card> )
    } else if(isRunStatusResponseType(notification)) {
      const element = notification
      return (
        <Card
          key={index}
          sx={{
            marginBottom: 1,
            boxShadow: 4,
            borderRadius: 1,
          }}
        >
          <Box display="flex" alignItems="center">
            <CircularProgress 
              variant={(element.state.resultState === "LOADING") ? "indeterminate" : "determinate"}
              size={24} 
              sx={{marginLeft: 1}}
            />
            <CardHeader
              title={`JOB ${element.state.resultState === "LOADING" ? element.state.lifeCycleState : element.state.resultState}`}
            />
          </Box>          
          <CardContent>
            <Typography variant="h6">
              {`Job Type: ${element.jobDetails?.triggerType}`}
            </Typography>
            <Typography variant="h6">
              {`Name: ${element.jobDetails?.parameters.name}`}
            </Typography>
            <Typography variant="body1">
              {`Run ID: ${element.runId}`}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: (element.state.resultState==='SUCCESS' || element.state.resultState==='FAILED')? 'space-between':'flex-end',
            }}>
            {element.runPageUrl && (
              <> 
              {(element.state.resultState==='SUCCESS' || element.state.resultState==='FAILED')&& 
                <Button
                  variant="contained"
                  href={element.jobDetails?.parameters.route ?? '/'}
                  rel="noopener noreferrer"
                  disabled={(element.state.resultState !== "SUCCESS" && element.state.resultState !== "FAILED")}
                >
                  Go To Page
                </Button>}
                <Button
                  variant="contained"
                  href={element.runPageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO JOB
                </Button>
              </>
            )}
          </CardActions>
        </Card> )
    } else if(isFabricRunStatusType(notification)) {
      const element = notification
      return (
        <Card
          key={index}
          sx={{
            marginBottom: 1,
            boxShadow: 4,
            borderRadius: 1,
          }}
        >
          <Box display="flex" alignItems="center">
            <CircularProgress 
              variant={(element.status === "InProgress") ? "indeterminate" : "determinate"}
              size={24} 
              sx={{marginLeft: 1}}
            />         
          <CardHeader
            title={`JOB ${element.status}`}
          />
          </Box>
          <CardContent>
            <Typography variant="h6">
              {`Job Type: ${element.jobDetails?.triggerType}`}
            </Typography>
            <Typography variant="h6">
              {`Name: ${element.jobDetails?.parameters.name}`}
            </Typography>
            <Typography variant="body1">
              {`Run ID: ${element.id}`}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: !(element.status ==='InProgress' || element.status === 'NotStarted') ? 'space-between' : 'flex-end',
            }}>
            {/* {element.runPageUrl && ( */}
              <> 
              {!(element.status ==='InProgress' || element.status === 'NotStarted') && 
                <Button
                  variant="contained"
                  href={element.jobDetails?.parameters.route ?? '/'}
                  rel="noopener noreferrer"
                  disabled={(element.status ==='InProgress' || element.status === 'NotStarted')}
                >
                  Go To Page
                </Button>}
                <Button
                  variant="contained"
                  href={"https://app.fabric.microsoft.com/monitoringhub?experience=data-warehouse"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO JOB
                </Button>
              </>
            {/* )} */}
          </CardActions>
        </Card> )
    } else if(isFailureNotificationType(notification)) {
      const element = notification
      return (
        <Card
          key={index}
          sx={{
            marginBottom: 1,
            boxShadow: 4,
            borderRadius: 1,
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon color="error" sx={{ marginRight: 1 }} />
                Initiation Failed
              </Typography>
            }
          />
          <CardContent>
            <Typography key={notification.failureNotificationId} variant="body2">{`Id: ${notification.failureNotificationId}`}</Typography>
            {Object.entries(notification.displayInformation).map(([key, value]) => (
              <Typography key={key} variant="body2">{`${key}: ${value}`}</Typography>
            ))}
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: element.redirectUrl ? 'space-between' : 'flex-end',
            }}>
            {element.redirectUrl && 
              <Button
                variant="contained"
                href={element.redirectUrl}
                rel="noopener noreferrer"
              >
                GO TO PAGE
              </Button>
            }
            <Button
              variant="contained"
              onClick={() => {
                setDetailsHeader('Failure Details');
                setDetailsBody(notification.message);
                setIsOpenDetails(true);
              }}
            >
              VIEW DETAILS
            </Button>
          </CardActions>
        </Card> )
    }
  }

  const onClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setNotifications([])
    dispatch(filterActiveRunIds())
    dispatch(filterFabricRunStatus())
    dispatch(filterGenAIApiStatus())
  }

  return (
    <>
      {/* Data Quality Recommendation View Pop Up */}
      <LucidPopUp
        openPopUp={isOpenDetails}
        closePopup={() => setIsOpenDetails(!isOpenDetails)}
        headingText={
          <Typography variant="h4" color={'WindowText'}>
            {detiailsHeader}
          </Typography>
        }
        confirmText="Close"
        onConfirm={() => setIsOpenDetails(!isOpenDetails)}
      >
        <Box>
          <Typography variant="body1">
            {detailsBody}
          </Typography>
        </Box>
      </LucidPopUp>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper id="menu-list-grow"
              elevation={4}
              style={{
                height: '100vh',
                width: '22vw',
                overflowY: 'auto',
                minWidth: '22vw'
              }}>
              <ClickAwayListener onClickAway={handleToggle}>
                <>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
                    <Typography variant="h2" align="left">
                      Notifications
                    </Typography>
                    <IconButton onClick={handleToggle}>
                      <CancelPresentationOutlinedIcon />
                    </IconButton>
                  </Box>

                  <Button onClick={onClear}>Clear</Button>

                  <Box style={{ padding: '8px' }}>
                    {notifications
                    .sort((a, b) => {
                      const getId = (item: any) => {
                        if (isGenAIApiStatusType(item)) {
                          return item.GenAIApiStatusId;
                        } else if (isRunStatusResponseType(item)) {
                          return item.RunStaturesponsesId;
                        }
                        return 0; // or any default value if the item is neither type
                      };
                    
                      const idA = getId(a);
                      const idB = getId(b);
                      return idB - idA;
                    })
                    .map((element, index) => (
                      renderByType(element, index)
                    ))}
                  </Box>
                </>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}