import { useEffect, useState } from 'react'
import ActionPage from 'ui-components/ActionPage'

import {
  AppProfileAttribute,
  attributeMappingDataBody,
  AttributeProfile,
  DataColumn,
  NewAttribute,
  NewAttributeMapBody,
  UpdateAttributeData,
  UpdateAttributeMapBody,
} from 'businessObjects'

import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable'
import { DataTableRow } from 'ui-components/DataTable/DataTableTypes'

import {
  useAddNewDataAttribute,
  useDeleteAttributeMap,
  useGetDataAttributesByEntityId,
  useGetDataAttributesMappingByTableId,
  useUpdateDataAttribute,
} from 'hooks/dataAttrbutesHooks'
import { useAddNewAttributeMap, useUpdateAttributeProfileMap } from 'hooks/attributeProfileHooks'
import ResultStatus from 'ui-components/ResultStatus'
import { DataMappingAttributesEditPopUp, DeletePopUp } from 'pages/DataProcess/DataMapping/DataMappingPopUps'
import { useGetDataColumnsByTableId } from 'hooks/dataColumnHooks'
import { useGetProfileAttributesByEntityId } from 'hooks/dataProfilingHooks'
import { Box, Typography } from '@mui/material'
import KeyIcon from '@mui/icons-material/VpnKey';
import StarIcon from '@mui/icons-material/Star';

type EditOptions = 'View column profiling' | 'Edit Business details' | 'Delete Business details'

type Props = {
  dataPodId: string
  dataSystemId: number | undefined
  entityId: number
  tableId: number
  onColumnProfiling: (colId: number) => void
  mappingGenerateSuccess: boolean
  primaryKeyUserInp:string
  foreignKeyColumnNames:string
}

export const DataProfilingColumnDetails = (props: Props) => {
  const { dataPodId, foreignKeyColumnNames,dataSystemId, entityId, tableId, onColumnProfiling, mappingGenerateSuccess ,primaryKeyUserInp} = props
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  const [entityAttributes, setEntityAttributes] = useState<AppProfileAttribute[]>([])
  const [selectedAttributeRow, setSelectedAttributeRow] = useState<AttributeProfile>()
  const [showPopUp, setShowPop] = useState<boolean>(false)
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const [editWarning, setEditWarning] = useState<boolean>(false)
  const [editWarningMessage, setEditWarningMessage] = useState<string>()
  const [tableRows, setTableRows] = useState<DataTableRow[]>()
  const [attributeUpdated, setAttributeUpdated] = useState<boolean>(false)
  const [editOptions, setEditOptions] = useState<'editContents' | 'editMapping' | 'newAttribute'>('editContents')
  const [newAttibute, setNewAttribute] = useState<NewAttribute>()
  const [selectAttributeId, setSelectAttributeId] = useState<number>()

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const [successState, setSuccessState] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>()

  const [dataColumns, setDataColumns] = useState<DataColumn[]>()
  const [mappingDataDictionary, setMappingDataDictionary] = useState<{ [key: number]: attributeMappingDataBody }>({})

  const { getDataColumns } = useGetDataColumnsByTableId()
  const [fetchAttributesMappingData, attributesMappingData] = useGetDataAttributesMappingByTableId()
  const [newAttributeFunc] = useAddNewDataAttribute()
  const [updateAttribFunc] = useUpdateDataAttribute()

  const { profileAttributes, refetch } = useGetProfileAttributesByEntityId(dataPodId, undefined, entityId)

  const { attributeProfiles, refetchAttributes } = useGetDataAttributesByEntityId(entityId ? Number(entityId) : 0)

  const [updateAttrMapFunc] = useUpdateAttributeProfileMap()
  const [addAttributeMapFunc, newAttributeMap, loading, createError] = useAddNewAttributeMap()

  const { deleteAttributeMapId } = useDeleteAttributeMap()
  const options = ['View column profiling', 'Edit Business details', 'Delete Business details']

  useEffect(() => {
    if (dataSystemId && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemId))
    }
  }, [dataSystemId])

  useEffect(() => {
    fetchDataColumns()
    fetchAttributesMappingData(dataPodId, tableId)
  }, [tableId,primaryKeyUserInp,foreignKeyColumnNames])

  useEffect(() => {
    if(mappingGenerateSuccess) {
      fetchAttributesMappingData(dataPodId, tableId)
    }
  }, [mappingGenerateSuccess])

  useEffect(() => {
    setEntityAttributes(profileAttributes)
  }, [profileAttributes])

  useEffect(() => {
    if (attributesMappingData && attributesMappingData.length) {
      const dictionary = attributesMappingData.reduce((acc, current) => {
        acc[current.dataColumnId] = current
        return acc
      }, {} as { [key: number]: attributeMappingDataBody })

      setMappingDataDictionary(dictionary)
    }
  }, [attributesMappingData])

  useEffect(() => {
    if (dataColumns && dataColumns.length && mappingDataDictionary) {
      const mapList = dataColumns.map((column) => ({
        id: `${column.id}`,
        values: [
          <>
            <Box display="flex" alignItems="center">
              <Typography component="span">
                {column.dataColumnName}{' '}
              </Typography>
              {column.isPrimary && (
                <Box display="flex" alignItems="center" ml={1}>
                  <Typography component="span" style={{ color: 'green', fontWeight: 'bold' }}>
                    PK
                  </Typography>
                  <KeyIcon sx={{ fontSize: 20, color: 'green', ml: 0.5 }} />
                </Box>
              )}
              {column.isUnique == 1 && ( // Display UK only if it's unique and not a primary key
                <Box display="flex" alignItems="center" ml={1}>
                  <Typography component="span" style={{ color: 'gray', fontWeight: 'bold' }}>
                    <sub>UK</sub>
                  </Typography>
                  <StarIcon sx={{ fontSize: 20, color: 'gray', ml: 0.5 }} />
                </Box>
              )}
                {column?.isForeignKey && (
                  <Box display="flex" alignItems="center" ml={1}>
                    <Typography component="span" style={{ color: 'blue', fontWeight: 'bold' }}>
                      FK
                    </Typography>
                    <KeyIcon sx={{ fontSize: 20, color: 'blue', ml: 0.5 }} />
                  </Box>
                )}
            </Box>
          </>,
          mappingDataDictionary[column.id]?.dataAttributeName || '',
          mappingDataDictionary[column.id]?.description || '',
          mappingDataDictionary[column.id]?.dataClassification || '',
          mappingDataDictionary[column.id]?.dataAttributeDataType || '',
          column.isUnique == 1 ? 'True' : 'False',
            column.isForeignKey == 1 ? 'True' : 'False', 
          'EDITSELECTION',
        ] as string[],
      }))
      setTableRows(mapList)
    }
  }, [dataColumns, mappingDataDictionary])

  const tableColumns = [
    {
      label: 'Column Name',
      sortableColumn: true,
    },
    {
      label: 'Business Attribute',
      sortableColumn: true,
    },
    {
      label: 'Business Description',
      sortableColumn: true,
    },
    {
      label: 'Business Classification',
      sortableColumn: true,
    },
    {
      label: 'Business Data Type',
      sortableColumn: true,
    },
    {
      label: 'IsUnique',
      sortableColumn: true,
    },
    {
      label: 'IsForeign',
      sortableColumn: true,
    },
    { label: 'Actions' },
  ]

  const fetchDataColumns = async () => {
    const response = await getDataColumns(tableId)

    if (response) {
      setDataColumns(response)
    }
  }

  const onButtonClick = (rowId: string, actionType: ShadowTableAction, option: EditOptions) => {
    const colId = Number(rowId)

    if (!mappingDataDictionary) {
      return
    } else if (option === 'View column profiling') {
      onColumnProfiling(colId)
      return
    }

    const selRow = mappingDataDictionary[colId]

    if (mappingDataDictionary) {
      setSelectedAttributeRow({
        id: selRow?.dataAttributeId ?? 0,
        dataColumnId: colId,
        dataAttributeMapID: selRow?.id ?? 0,
        dataEntityId: selRow?.dataEntityId ?? 0,
        entityName: selRow?.dataEntityName ?? '',
        dataAttributeName: selRow?.dataAttributeName ?? '',
        dataColumnName: selRow?.dataColumnName ?? '',
        dataPodId: selRow?.dataPodId ?? '',
        dataAttributeDataType: selRow?.dataAttributeDataType ?? '',
        dataClassification: selRow?.dataClassification ?? '',
        description: selRow?.description ?? '',
        isBusinessKey: false,
      })

      switch (option) {
        case 'Edit Business details':
          setEditWarning(true)
          setEditWarningMessage(`Warning: Editing Attribute "${selRow?.dataAttributeName}" will impact corresponding dependencies.`)
          setShowPop(true)
          break
        case 'Delete Business details':
          if(!selRow) {
            setEditWarning(true)
            setEditWarningMessage('No mapping attribute found to delete.')
            break
          }
          setShowDeleteWarning(true)
          break
      }
    }
  }

  const closePopup = (close: boolean) => {
    setShowPop(close)
    setSelectedAttributeRow(undefined)
    setNewAttribute(undefined)
    setEditOptions('editContents')
  }

  const onChange = (name: string, value: unknown) => {
    setNewAttribute(undefined)
    setSelectedAttributeRow(
      (prv) =>
      ({
        ...prv,
        [name]: value,
      } as AttributeProfile),
    )
  }

  const changeBusinessKey = (value: boolean) => {
    setNewAttribute(undefined)
    setSelectedAttributeRow(
      (prv) =>
      ({
        ...prv,
        isBusinessKey: value,
      } as AttributeProfile),
    )
  }

  const onChangeNewAttribute = (name: string, value: string) => {
    setNewAttribute((prv) => ({ ...prv, [name]: value } as NewAttribute))
  }

  const saveNewAttribute = async () => {
    if (dataPodId && newAttibute && entityId) {
      const newAttribVal: NewAttribute = {
        dataAttributeName: newAttibute?.dataAttributeName ?? '',
        dataEntityId: entityId,
        description: newAttibute?.description ?? '',
        isBusinessKey: Boolean(newAttibute.isBusinessKey),
        dataClassification: newAttibute.dataClassification ?? '',
        dataAttributeDataType: newAttibute?.dataAttributeDataType ?? '',
        dataAttributeType: 'simple',
      }
      const res = await newAttributeFunc(newAttribVal, dataPodId)

      if (res) {
        const body: NewAttributeMapBody = {
          dataEntityId: entityId,
          dataColumnId: selectedAttributeRow?.dataColumnId || 0,
          dataTableId: tableId,
          dataAttributeId: res?.id || 0,
          dataEntityMapId: 0,
          runid: 0,
        }

        const addedAttrMap = await addAttributeMapFunc(body, dataPodId)

        if (addedAttrMap && selectedAttributeRow) {
          fetchAttributesMappingData(dataPodId, tableId)

          setAttributeUpdated(true)
          setSuccessState(true)
          setSuccessMessage('Mapping is successful.')
        }
      } else if (!res) {
        setErrorState(true)
        setErrorMessage('Failed to add new Attribute.')
      }
    }
  }

  const onSubmit = async () => {
    if (tableRows) {
      if (editOptions == 'editContents' && dataPodId) {
        const params: UpdateAttributeData = {
          id: selectedAttributeRow?.id ?? 0,
          name: selectedAttributeRow?.dataAttributeName ?? '',
          description: selectedAttributeRow?.description ?? '',
          dataAttributeDataType: selectedAttributeRow?.dataAttributeDataType ?? '',
          dataClassification: selectedAttributeRow?.dataClassification ?? '',
          isBusinessKey: String(selectedAttributeRow?.isBusinessKey) == 'true' ? true : false,
          coreEntity: false,
          entityType: '',
        }
        const updatedAttibutes = await updateAttribFunc(params, dataPodId)
        if (updatedAttibutes) {
          const {
            dataAttributeName,
            description,
            dataColumnName,
            dataClassification,
            isBusinessKey,
            dataAttributeDataType,
          } = updatedAttibutes

          setMappingDataDictionary((prevDictionary) => {
            const updatedDictionary = { ...prevDictionary }

            if (selectedAttributeRow?.dataColumnId && selectedAttributeRow.dataColumnId in updatedDictionary) {
              updatedDictionary[selectedAttributeRow.dataColumnId] = {
                ...updatedDictionary[selectedAttributeRow.dataColumnId],
                dataAttributeName: dataAttributeName,
                description: description,
                dataClassification: dataClassification,
                isBusinessKey: isBusinessKey,
                dataAttributeDataType: dataAttributeDataType,
              }
            }

            return updatedDictionary
          })

          setAttributeUpdated(true)
        }
      }
      if (editOptions == 'editMapping' && selectAttributeId && attributesMappingData && dataColumns) {
        if (selectedAttributeRow?.dataAttributeMapID) {
          const { dataAttributeMapID } = selectedAttributeRow
          // const toUpdate = attributeProfiles.find((ea) => ea.id === dataAttributeMapID)

          const upd: UpdateAttributeMapBody = {
            id: dataAttributeMapID ?? 0,
            dataTableId: tableId,
            dataEntityId: entityId,
            dataColumnId: selectedAttributeRow.dataColumnId ?? 0,
            dataAttributeId: selectAttributeId ?? 0,
            dataEntityMapId: 0,
          }

          const updatedAttrMap = await updateAttrMapFunc(upd)
          if (updatedAttrMap) {
            fetchAttributesMappingData(dataPodId, tableId)
          }
        } else if (dataPodId) {
          const toUpdate = attributeProfiles.find((attr) => attr.id === selectAttributeId)

          const body: NewAttributeMapBody = {
            dataEntityId: entityId,
            dataColumnId: selectedAttributeRow?.dataColumnId || 0,
            dataTableId: tableId,
            dataAttributeId: toUpdate?.id || 0,
            dataEntityMapId: 0,
            runid: 0,
          }

          const addedAttrMap = await addAttributeMapFunc(body, dataPodId)
          if (addedAttrMap) {
            fetchAttributesMappingData(dataPodId, tableId)
          }
        }
      }
      if (editOptions == 'newAttribute') {
        saveNewAttribute()
      }
    }
    // refetch()
    closePopup(!showPopUp)
  }

  const closeDeleteWarning = () => {
    setSelectedAttributeRow(undefined)
    setShowDeleteWarning(false)
  }

  const confirmDeleteAttributeMap = async () => {
    if (selectedAttributeRow?.dataAttributeMapID) {
      const deleteMapSuccess = await deleteAttributeMapId(selectedAttributeRow?.dataAttributeMapID)
      if (deleteMapSuccess) {
        setMappingDataDictionary((prevDictionary) => {
          const updatedDictionary = { ...prevDictionary }

          if (selectedAttributeRow?.dataColumnId && selectedAttributeRow.dataColumnId in updatedDictionary) {
            delete updatedDictionary[selectedAttributeRow.dataColumnId]
          }

          return updatedDictionary
        })

        setDeleteSuccess(true)
        closeDeleteWarning()
      }
    }
  }

  return (
    <ActionPage>
      <ResultStatus
        severtiy="success"
        showStatus={deleteSuccess}
        closeStatus={setDeleteSuccess}
        alertMessage={`SUCCESS: Deleted Attribute Map`}
      />
      <ResultStatus
        severtiy="success"
        showStatus={successState}
        closeStatus={setSuccessState}
        alertMessage={successMessage}
      />
      <ResultStatus
        severtiy="warning"
        showStatus={editWarning}
        closeStatus={setEditWarning}
        alertMessage={editWarningMessage}
      />
      <ResultStatus severtiy="error" showStatus={errorState} closeStatus={setErrorState} alertMessage={errorMessage} />
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={confirmDeleteAttributeMap}
        closeDeleteWarning={closeDeleteWarning}
        itemToDelete={selectedAttributeRow?.dataAttributeName}
      />
      <DataMappingAttributesEditPopUp
        showPopUp={showPopUp}
        onSubmit={onSubmit}
        closePopup={closePopup}
        editOptions={editOptions}
        setEditOptions={setEditOptions}
        selectedAttributeRow={selectedAttributeRow}
        onChange={onChange}
        changeBusinessKey={changeBusinessKey}
        setSelectAttributeId={setSelectAttributeId}
        newAttibute={newAttibute}
        onChangeNewAttribute={onChangeNewAttribute}
        attributeUpdated={attributeUpdated}
        setAttributeUpdated={setAttributeUpdated}
        entityIdArgument={entityId}
      />
      <ShadowTable
        rows={tableRows ?? []}
        columns={tableColumns}
        selectionOptions={options}
        tableActionParams={{
          onButtonClick: (rowId: string, actionType: ShadowTableAction) => {},
          onEditSelectionClick: onButtonClick,
          actions: [],
        }}
      />
    </ActionPage>
  )
}
