import React, { useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import { useGetCoreEntity, useGetCoreEntityWithMethod } from '../../../hooks/entityHooks'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { ROUTES } from '../../../Routes/constants'
import ActionPage from '../../../ui-components/ActionPage'
import { Button, CardContent, Grid } from '@mui/material'
import { useGetSessionPodInfo } from '../../../hooks/getSessionPodInfo'
import DataPodDetailRibbon from '../../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { AddOutlined } from '@mui/icons-material'
import { PreviousPage } from 'ui-components/Button/PreviousPage'
import { useDeleteEnrichedEntity } from 'hooks/coreEntityAttributeHooks'
import ResultStatus from 'ui-components/ResultStatus'
import { ResultStatusSeverity } from 'businessObjects'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}

const CustomEnrichedModelTable = (props: Props) => {
  const dataPodInfo = useGetSessionPodInfo()
  const [coreEntities, fetchCoreEntity] = useGetCoreEntityWithMethod(dataPodInfo?.id)

  const navigate = useNavigate()
  const onClickBack = () => {
    const dataProfilingRoute = generatePath(ROUTES.DataModelingEnterpriseModels, { dataPodId: dataPodInfo?.id })
    navigate(dataProfilingRoute)
  }
  const { deleteCoreEnrichedEntity } = useDeleteEnrichedEntity()
  const [showResultStatus,setShowResultStatus] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('success')
  const [statusMessage, setStatusMessage] = useState<string>()

  const tableColumns = useMemo(() => {
    return [
      {
        label: 'Entity Name',
        sortableColumn: true,
      },
      {
        label: 'Entity Description',
        sortableColumn: true,
      },
      {
        label: 'Entity Type',
        sortableColumn: true,
      },
      {
        label: 'View',
      },
      {
        label: 'Delete',
      },
    ]
  }, [])
  const coreEntityRows = useMemo(() => {
    if (!coreEntities.length) return []
    // console.log(coreEntities)
    return coreEntities.map((ce) => {
      let entityType = ce.entityType
      if (entityType === '1') {
        entityType = 'Reference'
      } else if (entityType === '2') {
        entityType = 'Transaction'
      }
      return {
        id: String(ce.id),
        values: [ce.dataEntityName, ce.description, entityType, 'VIEW', 'DELETE'],
      }
    })
  }, [coreEntities])

  const onButtonClick = async (rowId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Viewable) {
      //console.log({ rowId })
      const attributesRoute = generatePath(ROUTES.EditCustomEnrichedModel, {
        dataPodId: dataPodInfo?.id,
        customEntityId: rowId,
      })
      navigate(attributesRoute)
    }
    if (actionType === ShadowTableAction.Deletable) {
      // console.log("Shadow table delete is called,", rowId)
      if(dataPodInfo){
        const resp  = await deleteCoreEnrichedEntity(Number(rowId),dataPodInfo?.id)
        if(resp?.includes('Enriched entity and related records successfully deleted')){
          setSeverity('success')
          setStatusMessage(resp + ` ${coreEntities.find((ce:any)=> ce.id ==rowId)?.dataEntityName}`)
          setShowResultStatus(true)
          await fetchCoreEntity(dataPodInfo?.id)
        }else if (resp?.includes(`This core entity is used by the following core entities`)){
          setSeverity('warning')
          setStatusMessage(`${coreEntities.find((ce:any)=> ce.id ==rowId)?.dataEntityName} ` + resp)
          setShowResultStatus(true)
        }else if(resp?.includes('Enriched entity not found or already inactive')){
          setSeverity('error')
          setStatusMessage(`${coreEntities.find((ce:any)=> ce.id ==rowId)?.dataEntityName} ` + resp)
          setShowResultStatus(true)
        }
      }
    }
  }
  const addEnrichedModel = () => {
    // setOpenEnrichedModel(true)
    const customEnrichedModelRoute = generatePath(ROUTES.CustomEnrichedModel, {
      dataPodId: dataPodInfo?.id,
      // dataSystemID: selRow.dataSystemID,
      // entityID: selRow.dataEntityID,
    })
    navigate(customEnrichedModelRoute)
  }
  const onClickDataModel = () => {
    const enterpriseDataModels = generatePath(ROUTES.DataModelingEnterpriseModels, { dataPodId: dataPodInfo?.id })
    navigate(enterpriseDataModels)
  }
  return (
    <ActionPage>
      {/* <CardContent>
        <DataPodDetailRibbon
          dataPodId={dataPodInfo?.id}
          dataPodName={dataPodInfo?.name}
          dataPodIndustryName={dataPodInfo?.industryName}
        />
      </CardContent> */}
      <ResultStatus
        severtiy={severity}
        showStatus={showResultStatus}
        closeStatus={setShowResultStatus}
        alertMessage={statusMessage}
      />
      <Grid item xs={3} mb={4}>
        <PreviousPage onClick={() => navigate(generatePath(ROUTES.DataModelingEnterpriseModels, { dataPodId: dataPodInfo?.id }))} pageName={"Back"} />
      </Grid>
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <Button variant="contained" fullWidth onClick={onClickDataModel}>
              Show Data Model
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              startIcon={<AddOutlined fontSize="small" />}
              variant="contained"
              onClick={addEnrichedModel}
              fullWidth
            >
              Add Enriched Entities
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ShadowTable
              rows={coreEntityRows}
              columns={tableColumns}
              tableActionParams={{
                onButtonClick: onButtonClick,
                actions: [ShadowTableAction.Viewable, ShadowTableAction.Deletable],
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </ActionPage>
  )
}

export default CustomEnrichedModelTable
