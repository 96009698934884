import { useEffect, useMemo, useState } from 'react'
import {
  Entity,
  CoreEntity,
  RunEntityBody,
  RunResponseFormat,
  NewEntityMapBody,
  NewEntityMap,
  UpdateEntityNameBody,
  UpdateEntityMapBody,
  StatusResponse,
  EntityProfileOutput,
  NewEntity,
  EntityMapping,
  NewParent,
  DataLakeZone,
  NewEntityData,
  CustomEntityData,
  GenAIReconcilationResponse,
  EntityReconData,
  DataEntityFlow,
  RelationShipResponse,
  EntityWithAttrsDictionary,
  GenerateEntityCodeBody,
} from '../businessObjects'
import {
  runProfile,
  addEntityMap,
  updateEntityName,
  updateEntityMap,
  getEntityProfileStatus,
  getEntityProfileOutput,
  getEntity,
  getCoreEntity,
  getEntityMapping,
  addNewEntity,
  entityServices,
  getGenAiReconcilation,
} from '../apiServices/entities'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { AxiosError } from 'axios'
import { useGetAccountInfo } from './getAccountInfo'
import { applicationServices } from '../apiServices/application'
import { genServices } from 'apiServices/lucidGenAIServices'
import { stat } from 'fs'

type dataPodId = string
type runId = number
type RunEntityProfile = (body: RunEntityBody, datapodId: dataPodId) => Promise<void>
type AddNewEntityName = (body: NewEntityMapBody, datapodId: dataPodId) => Promise<void>
type AddNewEntityMap = (
  body: NewEntityMapBody,
  datapodId: dataPodId,
) => Promise<{ data: any; flag: boolean } | undefined>
type UpdateEntityName = (body: UpdateEntityNameBody) => Promise<void>
type UpdateEntityMap = (body: UpdateEntityMapBody) => Promise<void>
type GetEntityProfileStatus = (datapodId: dataPodId, runId: runId) => Promise<void>
type GetEntityProfileOutput = (datapodId: dataPodId, runId: runId) => Promise<void>

type entityDataPodId = string | undefined
type entityName = string | undefined
type GetEntity = (
  entityName?: entityName,
  dataPodId?: entityDataPodId,
  recordCount?: number,
  pageNumber?: number,
) => Promise<void>

type dataTableName = string | undefined
type dataEntityName = string | undefined
type dataEntityId = number | undefined
type dataTableId = number | undefined

type GetEntityMapping = (
  dataTableName?: dataTableName,
  dataEntityName?: dataEntityName,
  datapodId?: entityDataPodId,
  recordCount?: number,
  pageNumber?: number,
) => Promise<void>

type GetEntityByNameResponse = {
  message: string
  dataEntities: Entity[]
}

export const useRunEntityProfile = (): [
  RunEntityProfile,
  RunResponseFormat | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [runEntityProfileResponse, setEntityProfileResponse] = useState<RunResponseFormat>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const runEntityProfile = async (body: RunEntityBody, datapodId: dataPodId) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await runProfile(bearerToken, datapodId, body)
        if (status == 200) {
          setEntityProfileResponse(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [runEntityProfile, runEntityProfileResponse, loading, errorFetching]
}
export const useAddEntityMap = (): [
  AddNewEntityName,
  NewEntityMap | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [newEntityMap, setNewEntityMap] = useState<NewEntityMap>()
  const [loading, setLoading] = useState<boolean>()
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const addNewEntityMap = async (body: NewEntityMapBody, datapodId: dataPodId) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await addEntityMap(bearerToken, datapodId, body)
        if (status == 200) {
          setNewEntityMap(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  return [addNewEntityMap, newEntityMap, loading, createError]
}
export const useAddNewEntityMap = (): [
  AddNewEntityMap,
  NewEntityMap | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [newEntityMap, setNewEntityMap] = useState<NewEntityMap>()
  const [loading, setLoading] = useState<boolean>()
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const addNewEntityMap = async (body: NewEntityMapBody, datapodId: dataPodId) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await addEntityMap(bearerToken, datapodId, body)
        if (status == 200) {
          console.log(data)
          setNewEntityMap(data)
          setLoading(false)
          return { data: data, flag: true }
        } else {
          return { data: [], flag: false }
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
      return { data: [], flag: false }
    }
  }
  return [addNewEntityMap, newEntityMap, loading, createError]
}
export const useUpdateEntityName = (): [
  UpdateEntityName,
  Entity | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [updatedEntity, setUpdatedEntity] = useState<Entity>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateEntityProfileName = async (body: UpdateEntityNameBody) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await updateEntityName(bearerToken, body)
        if (status == 200) {
          setUpdatedEntity(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [updateEntityProfileName, updatedEntity, loading, errorFetching]
}
export const useUpdateEntityMap = (): [
  UpdateEntityMap,
  NewEntityMap | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [updatedEntityMap, setUpdatedEntityMap] = useState<NewEntityMap>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateEntityProfileMap = async (body: UpdateEntityMapBody) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await updateEntityMap(bearerToken, body)
        if (status == 200) {
          setUpdatedEntityMap(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [updateEntityProfileMap, updatedEntityMap, loading, errorFetching]
}

export const useGetEntityProfileOutput = (): [
  GetEntityProfileOutput,
  EntityProfileOutput | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [entityProfileOutput, setEntityProfileOutput] = useState<EntityProfileOutput>()
  const [loading, setLoading] = useState<boolean>()
  const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchEntityProfileOutput = async (datapodId: dataPodId, runId: runId) => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await getEntityProfileOutput(bearerToken, datapodId, runId)
        if (status == 200) {
          setEntityProfileOutput(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorFetching(true)
      setLoading(false)
    }
  }
  return [fetchEntityProfileOutput, entityProfileOutput, loading, errorFetching]
}
type Pagination = {
  updatePageCount?: (pages: number) => void
}
export const useGetEntity = (
  { updatePageCount }: Pagination,
  datapodId?: entityDataPodId,
  entityId?: number,
  dataEntityType?: number,
  byMapping: boolean = false,
): [Entity[], () => void] => {
  const [entity, setEntity] = useState<Entity[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const fetchEntity = async (
    entityId?: number,
    datpodId?: entityDataPodId,
    byMapping?: boolean,
    entityName?: entityName,
    recordCount?: number,
    pageNumber?: number,
  ) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status, headers } = await getEntity(
          bearerToken,
          entityId,
          entityName,
          datpodId,
          recordCount ?? 2600,
          pageNumber ?? 1,
          dataEntityType,
          undefined,
          byMapping,
        )
        if (status == 200) {
          setEntity(data)
          if (updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count']
            const totalPages = Math.round(totalRecords / recordCount)
            updatePageCount(totalPages)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (datapodId || entityId) {
      fetchEntity(entityId, datapodId, byMapping)
    }
  }, [datapodId, entityId, dataEntityType])

  const fetchEntityByMapping = () => {
    if (datapodId || entityId) {
      fetchEntity(entityId, datapodId, byMapping)
    }
  }

  return [entity, fetchEntityByMapping]
}

export const useGetEntityWithAttrs = ( datapodId?: string )  => {
  const [entity, setEntity] = useState<Entity[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const getEntityWithAttrs = async ( tableId: number ) : Promise<EntityWithAttrsDictionary | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId && tableId) {
        const {data, status, headers} = await entityServices.getEntityWithAttrs(bearerToken, datapodId, tableId)

        if (status == 200) {
          console.log(data as EntityWithAttrsDictionary)
          return data 
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {getEntityWithAttrs}
}

export const useGetEntityMapping = (
  { updatePageCount }: Pagination,
  dataentityId?: dataEntityId,
): [EntityMapping[]] => {
  const [entityMapping, setEntityMapping] = useState<EntityMapping[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const fetchEntityMapping = async (
    dataentityId?: dataEntityId,
    dataTableName?: dataTableName,
    dataEntityName?: entityName,
    datpodId?: entityDataPodId,
    dataTableID?: dataTableId,
    recordCount?: number,
    pageNumber?: number,
  ) => {
    const bearerToken = await getBearerToken()
    if (bearerToken) {
      const { data, status, headers } = await getEntityMapping(
        bearerToken,
        dataTableName,
        dataEntityName,
        datpodId,
        dataentityId,
        dataTableID,
        recordCount,
        pageNumber,
      )
      if (status == 200) {
        setEntityMapping(data)
        if (updatePageCount && recordCount) {
          const totalRecords = headers['x-total-count']
          const totalPages = Math.round(totalRecords / recordCount)
          updatePageCount(totalPages)
        }
      }
    }
  }

  useEffect(() => {
    if (dataentityId) {
      fetchEntityMapping(dataentityId)
    }
  }, [dataentityId])

  return [entityMapping]
}

export const useGetCoreEntity = (datapodId?: entityDataPodId): [CoreEntity[]] => {
  const [coreEntity, setCoreEntity] = useState<CoreEntity[]>([])

  const { getBearerToken } = useLucidAuthContext()
  const fetchCoreEntity = async (datpodId?: entityDataPodId) => {
    const bearerToken = await getBearerToken()
    if (bearerToken) {
      const { data, status, headers } = await getCoreEntity(bearerToken, datpodId)
      if (status == 200) {
        setCoreEntity(data)
      }
    }
  }

  useEffect(() => {
    if (datapodId) {
      fetchCoreEntity(datapodId)
    }
  }, [datapodId])

  return [coreEntity]
}

export const useGetCoreEntityWithMethod = (
  datapodId?: entityDataPodId
): [CoreEntity[], (datpodId?: entityDataPodId) => Promise<void>] => {
  const [coreEntity, setCoreEntity] = useState<CoreEntity[]>([]);

  const { getBearerToken } = useLucidAuthContext();

  const fetchCoreEntity = async (datpodId?: entityDataPodId) => {
    const bearerToken = await getBearerToken();
    if (bearerToken) {
      const { data, status } = await getCoreEntity(bearerToken, datpodId);
      if (status === 200) {
        setCoreEntity(data);
      }
    }
  };

  useEffect(() => {
    if (datapodId) {
      fetchCoreEntity(datapodId);
    }
  }, [datapodId]);

  // Return both the coreEntity state and the fetchCoreEntity function
  return [coreEntity, fetchCoreEntity];
};

export const useGetCoreEntityWithMethodImmediateData = (
  datapodId?: entityDataPodId
): [CoreEntity[], (datpodId?: entityDataPodId) => Promise<CoreEntity[] | undefined>] => {
  const [coreEntity, setCoreEntity] = useState<CoreEntity[]>([]);

  const { getBearerToken } = useLucidAuthContext();

  const fetchCoreEntity = async (datpodId?: entityDataPodId) => {
    const bearerToken = await getBearerToken();
    if (bearerToken) {
      const { data, status } = await getCoreEntity(bearerToken, datpodId);
      if (status === 200) {
        setCoreEntity(data);
        return data as CoreEntity[]
      }
    }
  };

  useEffect(() => {
    if (datapodId) {
      fetchCoreEntity(datapodId);
    }
  }, [datapodId]);

  // Return both the coreEntity state and the fetchCoreEntity function
  return [coreEntity, fetchCoreEntity];
};
export const useAddNewEntity = (
  dataPodId?: string,
): {
  saveNewEntity: (enity: NewEntity) => Promise<Entity | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const saveNewEntity = async (enity: NewEntity) => {
    const token = await getBearerToken()
    if (token && dataPodId) {
      const { data, status } = await addNewEntity(token, dataPodId, enity)
      if (status === 200) {
        return data as Entity
      }
    }
  }
  return { saveNewEntity }
}

export const useAddNewParentEntity = (
  dataPodId?: string,
): {
  saveNewEntity: (enity: NewParent) => Promise<Entity | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const saveNewEntity = async (enity: NewParent) => {
    const token = await getBearerToken()
    if (token && dataPodId) {
      const { data, status } = await addNewEntity(token, dataPodId, enity)
      if (status === 200) {
        return data as Entity
      }
    }
  }
  return { saveNewEntity }
}

export const useGetEntityByDataPodId = (datapodId?: string, byMapping?: boolean): [Entity[]] => {
  const [entity, setEntity] = useState<Entity[]>([])

  const { getBearerToken } = useLucidAuthContext()
  const fetchEntity = async (datpodId?: string, byMapping?: boolean) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datpodId) {
        const { data, status, headers } = await getEntity(bearerToken, undefined, undefined, datpodId, undefined, undefined, undefined, undefined, byMapping)
        if (status == 200) {
          setEntity(data)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (datapodId) {
      fetchEntity(datapodId, byMapping)
    }
  }, [datapodId, byMapping])

  return [entity]
}

export const useGetAllEntitiesByDataPodId = (): [
  ({ updatePageCount }: Pagination, 
    datapodId: string, 
    entityId?: number, 
    dataEntityType?: number,
    recordCount?: number,
    pageNumber?: number,
    byMapping?:boolean) => Promise<void>,
  Entity[],
] => {
  const [entity, setEntity] = useState<Entity[]>([])

  const { getBearerToken } = useLucidAuthContext()

  const fetchAllEntity = async (
    { updatePageCount }: Pagination,
    datapodId: string,
    entityId?: number,
    dataEntityType?: number,
    recordCount?:number,
    pageNumber?:number,
    byMapping?: boolean
  ) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId) {
        const { data, status, headers } = await getEntity(
          bearerToken,
          undefined,
          undefined,
          datapodId,
          recordCount ?? 2600,
          pageNumber ?? 1, 
          dataEntityType,
          undefined,
          byMapping
        )
        if (status === 200) {
          setEntity(data)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }

  return [fetchAllEntity, entity]
}

export const useGetAllEntitiesByDataPodIdByMethod = (): [
  ({ updatePageCount }: Pagination, 
    datapodId: string, 
    entityId?: number, 
    dataEntityType?: number,
    recordCount?: number,
    pageNumber?: number,
    byMapping?:boolean) => Promise< Entity[] | undefined>,
  Entity[],
] => {
  const [entity, setEntity] = useState<Entity[]>([])

  const { getBearerToken } = useLucidAuthContext()

  const fetchAllEntity = async (
    { updatePageCount }: Pagination,
    datapodId: string,
    entityId?: number,
    dataEntityType?: number,
    recordCount?:number,
    pageNumber?:number,
    byMapping?: boolean
  ) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId) {
        const { data, status, headers } = await getEntity(
          bearerToken,
          undefined,
          undefined,
          datapodId,
          recordCount ?? 2600,
          pageNumber ?? 1, 
          dataEntityType,
          undefined,
          byMapping
        )
        if (status === 200) {
          setEntity(data)
          return data as Entity[]
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }

  return [fetchAllEntity, entity]
}

export const useGetEntities = (datapodId?: string): { entities: Entity[] } => {
  const [entity, setEntity] = useState<Entity[]>([])

  const { getBearerToken } = useLucidAuthContext()
  const fetchEntity = async () => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId) {
        const { data, status, headers } = await getEntity(bearerToken, undefined, undefined, datapodId, 3000, 1)
        if (status == 200) {
          setEntity(data)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (datapodId) {
      fetchEntity()
    }
  }, [datapodId])

  return { entities: entity }
}

export const useUpdateEntity = (): { updateEntityFunc: (body: UpdateEntityNameBody) => Promise<Entity | void> } => {
  //const [updatedEntity, setUpdatedEntity] = useState<Entity>()

  const { getBearerToken } = useLucidAuthContext()
  const updateEntityProfileName = async (body: UpdateEntityNameBody) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await updateEntityName(bearerToken, body)
        if (status == 200) {
          return data as Entity
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }
  return { updateEntityFunc: updateEntityProfileName }
}
export const useUpdateEntityMapFunc = (): {
  updateEntityMapFunc: (body: UpdateEntityMapBody) => Promise<NewEntityMap | void>
} => {
  //const [updatedEntityMap, setUpdatedEntityMap] = useState<NewEntityMap>()
  // const [loading, setLoading] = useState<boolean>()
  // const [errorFetching, setErrorFetching] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateEntityProfileMap = async (body: UpdateEntityMapBody) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        //console.log({ body })
        const { data, status } = await updateEntityMap(bearerToken, body)
        if (status == 200) {
          return data as NewEntityMap
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }
  return { updateEntityMapFunc: updateEntityProfileMap }
}

export const useDeleteEntityMap = (): { deleteEntityMapId: (mapId: number) => Promise<boolean | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const deleteEntityMapId = async (mapId: number) => {
    try {
      const token = await getBearerToken()
      if (token) {
        const { status } = await entityServices.deleteEntityMapId(token, mapId)
        return status == 204
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }

  return { deleteEntityMapId }
}
export const useGetEntityMappingByEntityIdAndPodId = (
  dataPodId?: string,
): { fetchEntityMapping: (entityId?: number, tableId?: number) => Promise<EntityMapping[] | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const fetchEntityMapping = async (entityId?: number, tableId?: number) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await entityServices.getEntityMappingByPodIdAndEntityID(
          bearerToken,
          dataPodId,
          entityId,
          tableId,
        )

        if (status == 200) {
          return data as EntityMapping[]
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return { fetchEntityMapping }
}

export const useDeleteEntity = (): { deleteEntity: (entities: number[]) => Promise<boolean | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const deleteEntity = async (entities: number[]) => {
    try {
      const token = await getBearerToken()
      if (token) {
        const { status } = await entityServices.deleteEntity(token, entities)
        return status == 204
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }

  return { deleteEntity }
}

export const useGetDataLakeZones = (): { dataLakeZones: DataLakeZone[] } => {
  const [dataLakes, setDataLakes] = useState<DataLakeZone[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const getDataLakes = async () => {
    try {
      const token = await getBearerToken()
      if (token) {
        const { data, status } = await entityServices.getDataLakeZones(token)
        if (status == 200) {
          setDataLakes(data as DataLakeZone[])
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    getDataLakes()
  }, [])
  return { dataLakeZones: dataLakes }
}

export const useSaveEntityNB = (
  dataPodId?: string,
): {
  saveNoteBoook: (entitiyId: number, noteBookString: string, commitMessage: string) => Promise<boolean | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const saveNoteBoook = async (entitiyId: number, noteBookString: string, commitMessage: string) => {
    try {
      const token = await getBearerToken()
      if (token && dataPodId && entitiyId && noteBookString && commitMessage) {
        const { status } = await entityServices.saveEntityNotebook(
          token,
          dataPodId,
          entitiyId,
          noteBookString,
          commitMessage,
        )
        return status == 200
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }

  return { saveNoteBoook }
}

export const useUpdateEntityNB = (
  dataPodId?: string,
): {
  updateEntityNB: (entitiyId: number, noteBookString: string, commitMessage: string) => Promise<boolean | undefined>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const updateEntityNB = async (entitiyId: number, noteBookString: string, commitMessage: string) => {
    try {
      const token = await getBearerToken()
      if (token && dataPodId && entitiyId && noteBookString && commitMessage) {
        const { status } = await entityServices.updateEntityNotebook(
          token,
          dataPodId,
          entitiyId,
          noteBookString,
          commitMessage,
        )
        return status == 200
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }

  return { updateEntityNB }
}

export const useGetEntityNB = (
  dataPodId?: string,
  entitiyId?: number,
): { entityNBstring?: string; error?: boolean } => {
  const [entityNBstring, setEntityNBstring] = useState<string>()
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const getEntityNB = async () => {
    try {
      const token = await getBearerToken()
      if (token && dataPodId && entitiyId) {
        const { status, data } = await entityServices.getEntityNotebook(token, dataPodId, entitiyId)
        if (status == 200) {
          setEntityNBstring(data)
        }
      }
    } catch (error) {
      setError(true)
      const { message } = error as AxiosError
      console.log({ message })
    }
  }

  useEffect(() => {
    getEntityNB()
  }, [entitiyId])

  return { entityNBstring, error }
}

export const useAddCustomEntity = (
  dataPodId?: string,
): {
  saveNewCustomEntity: (entity: NewEntityData) => Promise<CustomEntityData | undefined>,
  addCustomEntityError: string,
} => {
  const [addCustomEntityError, setAddCustomEntityError] = useState<string>('')

  const { getBearerToken } = useLucidAuthContext()
  const saveNewCustomEntity = async (entity: NewEntityData) => {
    try {
      setAddCustomEntityError('')
      const token = await getBearerToken()
      if (token && dataPodId) {

      const { data, status } = await entityServices.addNewCustomEntity(token, dataPodId, entity)
      if (status === 200) {
        return data as CustomEntityData
      }
    }
    } catch (error){
      console.log(error)
      setAddCustomEntityError("Failed to add custom entity.")
    }
    
  }
  return { saveNewCustomEntity, addCustomEntityError }
}

export const useGetEntityByID = (
  datapodId?: string,
  entityId?: number,
): {
  entity?: Entity[]
} => {
  const [entity, setEntity] = useState<Entity[]>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchEntity = async () => {
    const bearerToken = await getBearerToken()
    if (bearerToken && datapodId && entityId) {
      const { data, status } = await getEntity(bearerToken, entityId, undefined, datapodId)
      if (status == 200) {
        setEntity(data)
      }
    }
  }

  useEffect(() => {
    fetchEntity()
  }, [datapodId, entityId])

  return { entity }
}

export const useGetCoreEntityByID = (
  datapodId?: string,
  entityId?: number,
): {
  entity?: Entity
} => {
  const [entity, setEntity] = useState<Entity>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchEntity = async () => {
    const bearerToken = await getBearerToken()
    if (bearerToken && datapodId && entityId) {
      const { data, status } = await getEntity(
        bearerToken,
        entityId,
        undefined,
        datapodId,
        undefined,
        undefined,
        undefined,
        true,
      )
      if (status == 200) {
        data.length && setEntity(data[0])
      }
    }
  }

  useEffect(() => {
    fetchEntity()
  }, [datapodId, entityId])

  return { entity }
}

export const useGetCustomEntityRecon = (
  dataPodId?: string,
): { fetchCustomEntityChildren: (entityId?: number) => Promise<EntityReconData[] | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const fetchCustomEntityChildren = async (entityId?: number): Promise<EntityReconData[] | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && entityId) {
        const { data, status } = await applicationServices.getEntityReconciliation(bearerToken, dataPodId, entityId)
        if (status == 200) {
          //console.log(status)
          return data as EntityReconData[]
        }
      }
    } catch (error) {
      console.log('error fetching customEntityRecon')
    }
  }

  return { fetchCustomEntityChildren }
}

export const useGetGenAiReconcilation = (): {
  fetchEntity: (datapodId: string) => Promise<GenAIReconcilationResponse | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchEntity = async (datapodId: string) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId) {
        const { data, status } = await getGenAiReconcilation(bearerToken, datapodId)
        if (status == 200) {
          return data as GenAIReconcilationResponse
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchEntity }
}

export const useUpdateCustomEntity = () => {
  const [errorUpdatingEntity, setErrorUpdatingEntity] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateCustomEntity = async (body: UpdateEntityNameBody) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { status } = await updateEntityName(bearerToken, body)
        if (status == 200) {
          return status == 200
        }
      }
    } catch (error) {
      setErrorUpdatingEntity(true)
    }
  }
  return { updateCustomEntity, errorUpdatingEntity }
}

export const useGetEntityByName = () => {
  const [fetchedEntityResponse, setEntity] = useState<GetEntityByNameResponse>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchEntityByName = async (datapodId: string, entityName: string) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId && entityName) {
        const { data, status } = await entityServices.getEntityName(
          bearerToken,
          undefined,
          undefined,
          entityName,
          datapodId,
          undefined,
          undefined,
          undefined,
          undefined,
        )
        if (status == 200 && data.message.length) {
          setEntity(data)
          if (data.dataEntities?.length) {
            return true
          } else {
            return false
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
  }
  return { fetchEntityByName, fetchedEntityResponse }
}

export const useAddDataEntityFlow = (dataPodId?: string) => {
  const [addedDataEntityFlow, setAddedDataEntityFlow] = useState<DataEntityFlow>()

  const [isDataEntityFlowAdding, setIsDataEntityFlowAdding] = useState<boolean>(false)
  const [errorAddingDataFlow, setErrorAddingDataFlow] = useState<string | undefined>()

  const { getBearerToken } = useLucidAuthContext()

  const addDataEntityFlow = async (dataEntityFlow: DataEntityFlow) => {
    try {
      const bearerToken = await getBearerToken()

      if (bearerToken && dataPodId && dataEntityFlow) {
        setIsDataEntityFlowAdding(true)

        const { data, status } = await entityServices.addDataEntityFlow(bearerToken, dataPodId, dataEntityFlow)

        if (status == 200) {
          setAddedDataEntityFlow(data)
        }
      }
    } catch (err) {
      setErrorAddingDataFlow('Failed to add data flow.')
      console.error(err)
    } finally {
      setIsDataEntityFlowAdding(false)
    }
  }
  return { addedDataEntityFlow, isDataEntityFlowAdding, errorAddingDataFlow, addDataEntityFlow }
}

export const useGetDataEntityFlow = (dataPodId?: string) => {
  const [dbDataEntityFlow, setDbDataEntityFlow] = useState<DataEntityFlow>()

  const [isDataEntityFlowFetching, setIsDataEntityFlowFetching] = useState<boolean>(false)
  const [errorFetchingDataFlow, setErrorFetchingDataFlow] = useState<string | undefined>()

  const { getBearerToken } = useLucidAuthContext()

  const getDataEntityFlow = async (dataEntityFlowId?: number) => {
    try {
      const bearerToken = await getBearerToken()

      if (bearerToken && dataPodId) {
        setIsDataEntityFlowFetching(true)

        const { data, status } = await entityServices.getDataEntityFlow(bearerToken, dataPodId, dataEntityFlowId)

        if (status == 200) {
          setDbDataEntityFlow(data)
        }
      }
    } catch (err) {
      setErrorFetchingDataFlow('Failed to get data flow.')
      console.error(err)
    } finally {
      setIsDataEntityFlowFetching(false)
    }
  }
  return { dbDataEntityFlow, isDataEntityFlowFetching, errorFetchingDataFlow, getDataEntityFlow }
}

export const useGenerateDataEntityRecommendation = (dataPodId?: string) => {
  // const [dbDataEntityFlow, setDbDataEntityFlow] = useState<DataEntityFlow>()

  const [isEntityRecommendationGenerating, setIsEntityRecommendationGenerating] = useState<boolean>(false)
  const [errorGeneratingEntityRecommendation, setErrorGeneratingEntityRecommendation] = useState<string | undefined>()


  const generateEntityRecommendation = async (dataEntityName: string, dataEntityType: string, dataEntityDescription: string) : Promise<number[] | undefined> => {
    try {
      setErrorGeneratingEntityRecommendation(undefined)
      if (dataPodId && dataEntityName && dataEntityType && dataEntityDescription) {
        const bearerToken = 'Lucid123'
        const payload = {
          dataPodId,
          dataEntityName,
          dataEntityType,
          dataEntityDescription
        }

        setIsEntityRecommendationGenerating(true)

        const { data, status } = await entityServices.generateEntityRecommendation(bearerToken, payload)

        if (status == 200) {
          return data.data
        }
      }
    } catch (err:any) {
      const errorMessage =  'Failed to get entity recommendations.'
      setErrorGeneratingEntityRecommendation(errorMessage)
      console.error(err)
    } finally {
      setIsEntityRecommendationGenerating(false)
    }
  }
  return { isEntityRecommendationGenerating, errorGeneratingEntityRecommendation, generateEntityRecommendation }
}



export const useGenerateDataEntityRelations = (dataPodId?: string) => {
    const [isEntityRelationsGenerating, setIsEntityRelationsGenerating] = useState<boolean>(false);
    const [errorGeneratingEntityRelations, setErrorGeneratingEntityRelations] = useState<string | undefined>();
  
    const generateEntityRelations = async (
      canonicalEntityIds: number[],
      coreAttributesList: string[]
    ): Promise<{ relationshipData: RelationShipResponse[]; enrichedResponse: any ; error?: string;} | undefined> => {
      try {
        if (dataPodId && canonicalEntityIds) {
          const bearerToken = 'Lucid123';
          const payload = {
            dataPodId,
            canonicalEntityIds,
            coreAttributesList,
          };
  
          setIsEntityRelationsGenerating(true);
  
          const { data, status } = await entityServices.generateEntityRelations(bearerToken, payload);
  
          if (status === 200) {
            // Construct enrichedResponse and return both data and enrichedResponse
            const enrichedResponse = {
              warningText: data.warningText, // Append warningText from the response
              entityIds: data.entityIds, // Append entityIds from the response
              columnProfileLog: data.columnProfileLog, // Append columnProfileLog from the response
            };
            const error = data?.error || undefined
  
            return {
              relationshipData: data.data,
              enrichedResponse,
              error:error
            };
          }
        }
      } catch (err) {
        setErrorGeneratingEntityRelations(String(err));
        console.error(err);
      } finally {
        setIsEntityRelationsGenerating(false);
      }
    };
  
    return {
      isEntityRelationsGenerating,
      errorGeneratingEntityRelations,
      generateEntityRelations,
    };
  };

export const useGetEntityById = (
): {
  fetchEntityById(datapodId?:string,entityId?:number):Promise<Entity | undefined | boolean>
  fetchedEntity?: Entity[],
  error?:unknown
} => {
  const [fetchedEntity, setEntity] = useState<Entity[]>()
  const [error, setError] = useState<any>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchEntityById = async (
    datapodId?: string,
    entityId?: number) => {
    const bearerToken = await getBearerToken()
    try{
      setError(undefined)
      if (bearerToken && datapodId && entityId) {
        const { data, status } = await getEntity(bearerToken, entityId, undefined, datapodId)
        if (status == 200) {
          setEntity(data)
          return data[0] as Entity
        }else{
          return false
        }
      }
    }catch(err){
      setError(err)
      return false
    }

  }

  return { fetchEntityById , fetchedEntity, error }
}

export const useGenerateCanonicalEntityCode = (dataPodId: string | undefined, entityId: number | undefined) => {
  const [generatingEntityCode, setGeneratingEntityCode] = useState(false)
  const { lucidUserId } = useLucidAuthContext()
  const generateCanonicalEntityCode = async (body: GenerateEntityCodeBody): Promise<{ data: string; status: number }> => {
    if (dataPodId && lucidUserId && entityId && body) {
      setGeneratingEntityCode(true)
      try {
        const { data, status } = await genServices.generateCanoncialEntityCode(dataPodId, lucidUserId, entityId, body)
        if (status === 200) {
          return { data, status }
        }
      } catch (err) {
        return { data: 'Error generating code.', status: 500 }
      } finally {
        setGeneratingEntityCode(false)
      }
    }
    return { data: 'Error: code generation failed. No custom attributes or data quality rules selected.', status: 500 }
  }

  return { generatingEntityCode, generateCanonicalEntityCode }
}