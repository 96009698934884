import { CreateJobParams, RunIdStatus, RunNewJobParams } from '../businessObjects'
import { sendRequest } from '../requestManager/sendRequest'

const databricksPath = {
  getRunStatus: 'Databricks/jobs/run/status/get',
  postRunNewJob: 'Databricks/jobs/status/run-new',
  postCreateJob: 'Databricks/jobs/create',
}

export const getRunStatus = async (token: string, runIdStatus: RunIdStatus[]) => {
  const url = databricksPath.getRunStatus
  return await sendRequest(url, 'POST', token, runIdStatus)
}

export const postRunNewJob = async (token: string, params: RunNewJobParams) => {
  const url = databricksPath.postRunNewJob
  return await sendRequest(url, 'POST', token, params)
}

export const postCreateJob = async (token: string, params: CreateJobParams) => {

    const searchParams = new URLSearchParams({
      datapodId: params.dataPodId,
    });

    const url = `${databricksPath.postCreateJob}?${searchParams.toString()}`;
    return await sendRequest(url, 'POST', token, params);
}

export const databricksServices = {
  getRunStatus,
  postRunNewJob,
  postCreateJob
}