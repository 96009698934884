import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable';
import { DataTableRow } from 'ui-components/DataTable/DataTableTypes';
import { generatePath, useNavigate } from 'react-router-dom';
import { useGetDataQualityRules, useDeleteDataQualityRule, useGetDataQualityRecommendations, useGetActiveRules } from 'hooks/dataQualityHooks';
import { ROUTES } from 'Routes/constants';
import { DataQualityRule, DataQualityRuleRecomm, ResultStatusSeverity } from 'businessObjects';
import { EditNote, VisibilityOutlined } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { useGetDataAttributesByEntityId } from 'hooks/dataAttrbutesHooks';
import ResultStatus from 'ui-components/ResultStatus';
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp';
import { LucidTextLabel } from 'ui-components/LucidTextLabel';
import { useGenerateDataQualityRecommendation } from 'hooks/generateDatapodHooks';
import { LoadingButton } from '@mui/lab';

type ActionsMenuProps = {
  id: string | number;
  selectionOptions: string[];
  onMenuSelection: (item: string, id: string | number) => void;
};

const ActionsMenu = ({ id, selectionOptions, onMenuSelection }: ActionsMenuProps) => {
  const [anchorEle, setAnchorEle] = useState<null | EventTarget & HTMLButtonElement>(null);

  const onSelection = (item: string) => {
    onMenuSelection(item, id);
    setAnchorEle(null);
  }

  return (
    <>
      <IconButton title="Filter" onClick={({ currentTarget }) => setAnchorEle(currentTarget)}>
        <EditNote fontSize="small" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEle}
        open={Boolean(anchorEle)}
        onClose={() => setAnchorEle(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          'aria-multiselectable': 'true',
        }}
        slotProps={{
          paper: {
            elevation: 2, 
          },
        }}
      >
        {selectionOptions.map((itm: string) => (
          <MenuItem
            key={itm}
            onClick={() => onSelection(itm)}
          >
            {itm}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

type DataQualityTableProps = {
  dataPodId: string;
  selectedEntityID: number | undefined;
  hubEntity: boolean;
  selectedDataQualityRules: string[];
  setSelectedDataQualityRules: React.Dispatch<React.SetStateAction<string[]>>;
};

const DataQualityTable: React.FC<DataQualityTableProps> = ({
  dataPodId,
  selectedEntityID,
  hubEntity,
  selectedDataQualityRules,
  setSelectedDataQualityRules,
}) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const navigate = useNavigate();

  const { dataQualityRules, isDataQualityRulesLoading, isDataQualityRulesError } = useGetDataQualityRules(selectedEntityID);
  const { isDeleteRuleSuccess, isDeleteRuleError, deleteDataQualityRule } = useDeleteDataQualityRule();
  const { existingRecommendations, loadingCurrent, getDataQualityRecommendations } = useGetDataQualityRecommendations(dataPodId, selectedEntityID);

  const [rules, setRules] = useState<DataQualityRule[]>([]);
  const [DQRulesRecommended, setDQRulesRecommended] = useState<DataTableRow[]>([]);

  const { attributeProfiles, refetchAttributes } = useGetDataAttributesByEntityId(selectedEntityID)

  const { activeRules, isActiveRulesloading, isActiveRulesError } = useGetActiveRules()

  const [info, setInfo] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('info')
  const [resultStatusMessage, setResultStatusMessage] = useState<string>(`Info: Select Entity to perform the action`)

  const [isOpenDeleteWarning, setIsOpenDeleteWarning] = useState<boolean>(false)
  const [selectedDataQualityRule, setSelectedDataQualityRule] = useState<number>()

  const [selectedRecommendation, setSelectedRecommendation] = useState<DataQualityRuleRecomm>()
  const [isViewDataQualityRecommendation, setIsViewDataQualityRecommendation] = useState<boolean>(false)
  const [combinedDataQualityRows, setCombinedDataQualityRows] = useState<DataTableRow[]>([])

  const {generateDataQualityRules, loadingDQRules } = useGenerateDataQualityRecommendation(dataPodId, selectedEntityID)

  useEffect(() => {
    setRules(dataQualityRules);
  }, [dataQualityRules]);

  useEffect(() => {
    if (selectedEntityID) {
      getDataQualityRecommendations();
    }
  }, [selectedEntityID]);

  //Type: {1: 'Transformation1', 2: 'Transformation2'}
  const ruleCategory: { [key: number]: string } = useMemo(() => {
    const result: { [key: number]: string } = {}

    if (!activeRules) {
      return result
    }

    Object.keys(activeRules).forEach((rule) => {
      const subRules = activeRules[rule]

      if (!subRules.length) {
        return
      }

      result[subRules[0].ruleCategoryId] = rule
    })

    return result
  }, [activeRules])

  const onRuleMenuSelect = (selectedOption: string, ruleId: string | number) => {
    ruleId = ruleId.toString();

    if (selectedOption === 'Edit Data Quality') {
      onDataQualityRuleSelect(ruleId.toString(), ShadowTableAction.Editable);
    } else if(selectedOption === 'Delete Data Quality') {
      onDataQualityRuleSelect(ruleId.toString(), ShadowTableAction.Deletable);
    }
  }

  const rulesRows = useMemo(() => {
    if (!rules.length || !activeRules) return [];

    return rules.map((rule) => {
      const suggestSubRules = activeRules[ruleCategory[rule.ruleCategoryId]];
      const subCategory = suggestSubRules.find(subRule => subRule.id === rule.ruleSubCategoryId);
      let attributeNames : string[] = [];

      rule.attributeIds.forEach((id) => {
        const name = attributeProfiles.find((attr) => {
          if (attr.id === id) {
            return attr.dataAttributeName
          }
        })

        if (name) {
          attributeNames.push(name.dataAttributeName)
        }
      })

      return {
        id: `${rule.id}`,
        values: [
          'CHECKBOX',
          rule.name,
          attributeNames.join(', '),
          ruleCategory[rule.ruleCategoryId],
          subCategory?.name || '',
          <CheckIcon fontSize="small" sx={{ color: 'green' }} />,
          <ActionsMenu
            id={rule.id}
            selectionOptions={['Edit Data Quality', 'Delete Data Quality']}
            onMenuSelection={onRuleMenuSelect}
          />,
        ] as string[],
        }})
  }, [rules, selectedEntityID, ruleCategory, activeRules, attributeProfiles]);

  const onRecommendationMenuSelect = (selectedOption: string, recommendationId: string | number) => {
    recommendationId = recommendationId.toString();
    if(selectedOption === 'Apply Recommendation') {
      onDataQualityRecommendationAdd(recommendationId, ShadowTableAction.Add);
    } else if(selectedOption === 'View Recommendation') {
      const categoryNameAndAttribute = recommendationId.split(/(\d+)/).filter((val) => val && val);
      const categoryName = categoryNameAndAttribute[0];
      const attributeId = Number(categoryNameAndAttribute[1]);

      const selectedRecommendation = parsedDQData.length && parsedDQData.find((dqr) => {
        if(dqr.CategoryName === categoryName && dqr.AttributeIDs[0] === attributeId) {
          return dqr
        }
      })

      if(selectedRecommendation) {
        setIsViewDataQualityRecommendation(true)
        setSelectedRecommendation(selectedRecommendation)
      } else {
        setInfo(true)
        setSeverity('error')
        setResultStatusMessage(`Error: Recommendation not found.`)
      }
    }
  }

  const parsedDQData = useMemo(() => {
    if (!existingRecommendations.length) return [];
    try {
      const allAtr = existingRecommendations.map((dqr) => dqr.dataQualityData);
      let allDQRules: DataQualityRuleRecomm[] = [];
  
      allAtr.forEach((at) => {
        const atrep: DataQualityRuleRecomm[] = JSON.parse(at);
        allDQRules = [...allDQRules, ...atrep];
      });
  
      return allDQRules;
    } catch (error) {
      console.error('Error parsing data quality recommendations:', error);
      return [];
    }
  }, [existingRecommendations]);
  
  const recommendationRows = useMemo(() => {
    if(!parsedDQData.length && !DQRulesRecommended.length) {
      return []
    }
    
    if(DQRulesRecommended.length) {
      return DQRulesRecommended
    }

    return parsedDQData.map((dqr) => ({
      id: dqr.CategoryName + dqr.AttributeIDs[0],
      values: [
        'CHECKBOX',
        dqr.Attributes[0] + '_' + dqr.SubcategoryName.replaceAll(' ', ''),
        dqr.Attributes.join(', '),
        dqr.CategoryName,
        dqr.SubcategoryName,
        'ADD',
        <IconButton size="small" onClick={() => onRecommendationMenuSelect('View Recommendation', dqr.CategoryName + dqr.AttributeIDs[0])}>
          <VisibilityOutlined fontSize="small" sx={{ color: 'green' }} />
        </IconButton>
      ] as string[],
    }));
  }, [parsedDQData, DQRulesRecommended]);

  const combinedDataQualityColumns = [
    { label: 'Select' },
    { label: 'Rule Name', sortableColumn: true },
    { label: 'Attributes', sortableColumn: false },
    { label: 'Category Name', sortableColumn: true },
    { label: 'SubCategory Name', sortableColumn: true },
    { label: 'Apply' },
    { label: 'Actions' },
  ]

  useEffect(() => {
    setCombinedDataQualityRows([...rulesRows, ...recommendationRows])
  }, [rulesRows, recommendationRows])

  const onDataQualityRuleSelect = async (rowId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Deletable) {
      setIsOpenDeleteWarning(true)
      setSelectedDataQualityRule(parseInt(rowId))
    }
    if (actionType === ShadowTableAction.Editable) {
      const dataQualityEditRoute = generatePath(ROUTES.DataModelingCanonicalModelEditDataQuality, {
        dataPodId: dataPodId,
        entityID: selectedEntityID,
        dataQualtiyID: rowId,
      });
      navigate(dataQualityEditRoute);
    }
  };

  const onDeleteDataQualityRule = async () => {
    if (!selectedDataQualityRule) return;

    const isDeleted: boolean = await deleteDataQualityRule(selectedDataQualityRule);

    if (isDeleted && selectedEntityID) {
      const filteredRules = rules.filter((rule) => rule.id !== selectedDataQualityRule);
      setRules(filteredRules);

      setSeverity('success')
      setInfo(true)
      setResultStatusMessage(`Success: Data quality rule deleted successfully.`)
    } else {
      setSeverity('error')
      setInfo(true)
      setResultStatusMessage(`Internal server error: Data quality rule deletion failed.`)
    }
  }

  const onClickDataQuality = () => {
    const dataQualityAddRoute = generatePath(ROUTES.DataModelingCanonicalModelAddDataQuality, {
      dataPodId: dataPodId,
      entityID: selectedEntityID,
    });
    navigate(dataQualityAddRoute, { state: {} });
  };

  const onClickDataQualityRecommendations = () => {
    const route = generatePath(ROUTES.DataQualityRecommendations, {
      dataPodId: dataPodId,
    });
    navigate(route, { state: {} });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedDataQualityRules([]); // Deselect all rows
    } else {
      const allRowIds = rules.map((rule) => rule.id.toString());
      setSelectedDataQualityRules(allRowIds); // Select all rows
    }
    setSelectAll(!selectAll);
  };

  const onGenerateRecommendations = async () => {
    let dt: DataTableRow[] = []
    if(attributeProfiles.length) {
      for (let i=0; i< attributeProfiles.length; i++) {
        const recomm = await generateDataQualityRules([attributeProfiles[i].id])
        if (recomm?.length) {
          const recommened = recomm.map((dqr) => ({
        // id must be unique. Best possible Unique id for this dataQualityRecommendation can be made with combination of categoryName and 
        // first attributeId from AttributeIDs, as there will be only one Attibute in AttributeIDs list.
        //  Useful to Extract attributeId when manipulating tableData
            id: dqr.CategoryName + dqr.AttributeIDs[0],
            values: [
              'CHECKBOX',
              dqr.Attributes[0] + '_' + dqr.SubcategoryName.replaceAll(' ', ''),
              dqr.Attributes.join(', '),
              dqr.CategoryName,
              dqr.SubcategoryName,
              'ADD',
              <IconButton size="small" onClick={() => onRecommendationMenuSelect('View Recommendation', dqr.CategoryName + dqr.AttributeIDs[0])}>
                <VisibilityOutlined fontSize="small" sx={{ color: 'green' }} />
              </IconButton>
            ] as string[],
          }))
          dt = [...dt, ...recommened]
        }
      }
      setDQRulesRecommended(dt)

      if(dt.length) {
        setSeverity('success')
        setInfo(true)
        setResultStatusMessage(`Success: Data quality recommendations generated successfully.`)
      }
    }
  }

  function onDataQualityRecommendationAdd(rowId: string, actionType: ShadowTableAction): void {
    const categoryNameAndAttribute = rowId.split(/(\d+)/).filter((val) => val && val);
    const categoryName = categoryNameAndAttribute[0];
    const attributeId = Number(categoryNameAndAttribute[1]);
    const addDataQualityRoute = generatePath(ROUTES.DataModelingCanonicalModelAddDataQuality, {
      dataPodId: dataPodId,
      entityID: selectedEntityID,
    });
    navigate(addDataQualityRoute + `?attributeId=${attributeId}&categoryName=${categoryName}`);
  }

  const onRecommendationPopUpClose = () => { 
    setIsViewDataQualityRecommendation(false)
    setSelectedRecommendation(undefined)
  } 

  return (
    <>
      <ResultStatus severtiy={severity} showStatus={info} closeStatus={setInfo} alertMessage={resultStatusMessage} />

      {/* Delete Data Quality Rule Pop Up */}
      <LucidPopUp
        openPopUp={isOpenDeleteWarning}
        closePopup={() => setIsOpenDeleteWarning(false)}
        headingText={
          <Typography variant="h4" color={'error'}>
            Data quality
          </Typography>
        }
        confirmText="Confirm"
        cancelText="Cancel"
        // disabledConfirm={dataTables?.some((ds) => ds.dataSystemId === deleteSystemId)}
        onConfirm={onDeleteDataQualityRule}
      >
        <Typography>
          Are you sure you want to delete the selected data quality rule?
        </Typography>
      </LucidPopUp>

      {/* Data Quality Recommendation View Pop Up */}
      <LucidPopUp
        openPopUp={isViewDataQualityRecommendation}
        closePopup={() => onRecommendationPopUpClose()}
        headingText={
          <Typography variant="h4" color={'error'}>
            Data quality Recommendation
          </Typography>
        }
        confirmText="Apply"
        cancelText="Close"
        onConfirm={() => {
          if (selectedRecommendation) {
            onDataQualityRecommendationAdd(selectedRecommendation.CategoryName + selectedRecommendation.AttributeIDs[0], ShadowTableAction.Add);
          }
          setIsViewDataQualityRecommendation(false)
        }}
      >
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <LucidTextLabel labelText={'Entity Name:'} />
              <Typography variant="body2">
                {selectedRecommendation?.EntityName}
              </Typography>
            </Box>

            <Box mb={2}>
              <LucidTextLabel labelText={'Category Name:'} />
              <Typography variant="body2">
                {selectedRecommendation?.CategoryName}
              </Typography>
            </Box>


            <Box mb={2}>
              <LucidTextLabel labelText={'Rule Description:'} />
              <Typography variant="body2">
                {selectedRecommendation?.RuleDescription}
              </Typography>
            </Box>

          </Grid>

          {/* Right Column */}
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <LucidTextLabel labelText={'Attributes:'} />
              <Typography variant="body2">
                {selectedRecommendation?.Attributes.join(", ")}
              </Typography>
            </Box>
            
            <Box mb={2}>
              <LucidTextLabel labelText={'Subcategory Name:'} />
              <Typography variant="body2">
                {selectedRecommendation?.SubcategoryName}
              </Typography>
            </Box>

            <Box mb={2}>
              <LucidTextLabel labelText={'Rule Explanation:'} />
              <Typography
                variant="body2"
                style={{
                  fontStyle: "italic",
                  textAlign: "justify",
                }}
              >
                {selectedRecommendation?.RuleExplanation}
              </Typography>
            </Box>
          </Grid>
          </Grid>
      </LucidPopUp>
      
      <Grid item xs={12} container display={'flex'} justifyContent={'space-between'} sx={{ mt: 6, mb: 2 }}>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom color="primary">
            Data Quality
          </Typography>
        </Grid>
        <Grid item xs={8} container display={'flex'} justifyContent={'flex-end'} spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={onClickDataQuality} disabled={hubEntity}>
              Add Data Quality
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={onGenerateRecommendations}
              loading={loadingDQRules}
              disabled={hubEntity}
            >
              Generate Recommendations
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ShadowTable
          rows={combinedDataQualityRows}
          columns={combinedDataQualityColumns}
          selectedRows={selectedDataQualityRules}
          setSelectedRows={setSelectedDataQualityRules}
          selectAll={selectAll}
          tableActionParams={{
            onButtonClick: onDataQualityRecommendationAdd,
            actions: [ShadowTableAction.Add],
          }}
        />
      </Grid>
    </>
  );
};

export default DataQualityTable;
