import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { DataTableActions, DataTableColumn, DataTableRow } from '../DataTable/DataTableTypes'
import { useEffect, useState } from 'react'
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { green } from '@mui/material/colors'
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { AddOutlined, CheckBox, CloudDownload, DownloadDone, EditNote } from '@mui/icons-material'
import AnimatedLoader from '../AnimatedLoader'
import { stableSort, getComparator } from 'ui-components/DataTable/logic'
export enum ShadowTableAction {
  Editable,
  Viewable,
  Deletable,
  Add,
  CheckBox,
  Select,
  SelectTarget,
  // EditLinkRelation,
  EditSelection,
  Toggle,
  DropDownSelection,
  FetchEntity,
}
type EditOptions = 'Edit current entity content' | 'Remap to existing entity' | 'New entity'
type ShadowTableActionParams = {
  actions: ShadowTableAction[]
  onButtonClick: (rowId: string, actionType: ShadowTableAction) => void
  onEditSelectionClick?: (rowId: string, actionType: ShadowTableAction, option: any) => void
  onEditDropDownSelectionClick?: (rowId: string, actionType: ShadowTableAction, option: any) => void
}
// type Props = {
//   rows: DataTableRow[]
//   columns: DataTableColumn[]
//   selectedRow?: string
//   selectRow?: (id: string) => void
//   tableActionParams?: ShadowTableActionParams
// }
type Props = {
  rows: DataTableRow[]
  columns: DataTableColumn[]
  selectionOptions?: string[]
  // dropDownOptions?: [{ id: number, label: string }][],
  dropDownOptions?: any
  dropDownDefaultValues?: any
  selectedRow?: string
  selectRow?: (id: string) => void
  tableActionParams?: ShadowTableActionParams
  sourceIndex?: number[]
  targetIndex?: number[]
  sourceToTarget?: boolean
  isDataLoading?: boolean
  isBusinessKey?: boolean
  selectedRows?: string[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>;
  selectAll?: boolean;

}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[500],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: !selected ? theme.palette.grey[200] : undefined,
    //selected: theme.palette.primary.dark,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  rowGap: '2px',
  //selected: theme.palette.primary.dark,
}))

const ShadowTable = React.memo(
  ({
    rows,
    columns,
    selectionOptions,
    dropDownOptions,
    dropDownDefaultValues,
    selectRow,
    selectedRow,
    tableActionParams,
    sourceIndex,
    targetIndex,
    sourceToTarget,
    isDataLoading = false,
    isBusinessKey,
    selectedRows = [], // Default to an empty array if not provided
    setSelectedRows = () => {}, // Default to a no-op function if not provided
    selectAll = false, // Default to false if not provided
  }: Props) => {
    // const [tRows, setTRows] = useState<DataTableRow[]>([])
    const [sortedRows, setSortedTRows] = useState<DataTableRow[]>([])
    const sortDescendingInitial = columns.map(() => false)
    const [sortDescending, setSortDescending] = React.useState<boolean[]>(sortDescendingInitial)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null)
    const [parentRow, setParentRow] = useState<null | HTMLElement>(null)
    const [filterApplied, setFilterApplied] = useState<EditOptions>('Edit current entity content')
    const [filter2Applied, setFilter2Applied] = useState<any>('')
    const [selectionRowId, setSelectionRowId] = useState<string>('')
    const [selectedOption, setSelectedOption] = useState<string>('')
    const [selectedOptions, setSelectedOptions] = useState<any>()
    const tcolumns = React.useMemo(() => {
      return columns
    }, [columns.length, columns])

    useEffect(() => {
      setSortedTRows(rows)
    }, [rows])

    // useEffect(() => {
    //   setTRows(rows)
    // },[rows])

    const handleRowSelect = (id: string) => {
      selectRow && selectRow(id)
    }

    useEffect(() => {
      if (selectAll) {
        // If selectAll is true, select all row IDs
        const allRowIds = rows.map(row => row.id);
        setSelectedRows(allRowIds);
      } else {
        // If selectAll is false, deselect all rows
        setSelectedRows([]);
      }
    }, [selectAll, rows, setSelectedRows]);

    const handleFilterSelect = (selection: EditOptions, rowId: string) => {
      // console.log({rowId})
      setFilterApplied(selection)
      tableActionParams &&
        tableActionParams.onEditSelectionClick &&
        tableActionParams.onEditSelectionClick(selectionRowId, ShadowTableAction.EditSelection, selection)
      setShowMenu(false)
    }

    const handleEditSelctionClick = (item: EventTarget & HTMLButtonElement, rowId: string) => {
      // console.log({rowId})
      setSelectionRowId(rowId)
      setFilterAnchor(item)
      setShowMenu(true)
    }
    const handleEditRowClick = (item: EventTarget & HTMLButtonElement) => {
      setFilterAnchor(item)
      const parentRow = item.closest('tr')
      if (parentRow) {
        // console.log(parentRow)
        setParentRow(parentRow)
      }
      // console.log(item)
    }
    const getSourceTargetColor = (sourceOrTargetIndex: number) => {
      if (sourceIndex?.includes(sourceOrTargetIndex)) {
        return '#f8f2ae'
      }
      if (targetIndex?.includes(sourceOrTargetIndex)) {
        return '#a4bffb8c'
      }
    }

    const handleChange = (value: string, rowid: string) => {
      // console.log('Inside the handle change')
      tableActionParams &&
        tableActionParams.onEditDropDownSelectionClick &&
        tableActionParams.onEditDropDownSelectionClick(rowid, ShadowTableAction.DropDownSelection, value)
    }
    // console.log({ sortedRows })
    const sortColumn = (columnIndex: number) => {
      doSort(columnIndex, sortDescending[columnIndex] ? 'desc' : 'asc')
      sortDescending[columnIndex] = !sortDescending[columnIndex]
      setSortDescending(sortDescending)
    }

    const toggleRowSelection = (rowId: string) => {
      // Check if setSelectedRows exists before calling it
      if (setSelectedRows) {
        setSelectedRows((prevSelectedRows) => {
          if (prevSelectedRows.includes(rowId)) {
            // If the row is already selected, deselect it
            return prevSelectedRows.filter((id) => id !== rowId);
          } else {
            // Otherwise, add it to the selected rows
            return [...prevSelectedRows, rowId];
          }
        });
      }
    };
    
    

    const doSort = (col: number, order: string): void => {
      const sRows = stableSort(
        rows,
        getComparator(order === 'asc' ? 'asc' : 'desc', col, columns[col].numeric || false),
      )

      setSortedTRows(sRows)
    }

    const tableCellValue = (cellValue: string, rowId: string, index?: number) => {
      if (cellValue === 'CHECKBOX') {
        return (
          <Checkbox
          checked={selectedRows ? selectedRows.includes(rowId) : false} // Check if selectedRows exists and use it
          onChange={() => toggleRowSelection(rowId)} // Toggle the selection state
          />
        );
      }
      if (cellValue === 'EDIT') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Editable)}
            startIcon={<EditOutlinedIcon sx={{ color: 'green' }} />}
          />
        )
      }
      if (cellValue === 'VIEW') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Viewable)}
            startIcon={<VisibilityIcon sx={{ color: 'green' }} />}
          />
        )
      }
      if (cellValue === 'TOGGLE ON') {
        return (
          <Button
            size="medium"
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Toggle)}
            startIcon={<ToggleOnIcon fontSize="medium" color="success" />}
          />
        )
      }
      if (cellValue === 'TOGGLE OFF') {
        return (
          <Button
            size="medium"
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Toggle)}
            startIcon={<ToggleOffIcon fontSize="medium" sx={{ color: '#d4d2d1' }} />}
          />
        )
      }
      if (cellValue === 'DELETE') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Deletable)}
            startIcon={<DeleteForeverIcon sx={{ color: 'red' }} />}
          />
        )
      }
      if (cellValue === 'ADD') {
        return (
          <IconButton
            size='small'
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Add)}
          >
            <AddOutlined fontSize="small" sx={{ color: 'green' }} />
          </IconButton>
        )
      }
      if (cellValue === 'CHECKED' || cellValue === 'UNCHECKED') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.CheckBox)}
            startIcon={
              <Checkbox
                sx={{
                  color: 'green',
                  '&.Mui-checked': {
                    color: green[600],
                  },
                }}
                edge="start"
                tabIndex={-1}
                checked={cellValue === 'CHECKED' ? true : false}
                disableRipple
              />
            }
          />
        )
      }
      if (cellValue === 'SELECT') {
        return (
          <Button onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.Select)}>
            Select
          </Button>
        )
      }
      if (cellValue === 'SELECTTARGET') {
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.SelectTarget)}
          >
            Select Target
          </Button>
        )
      }
      if (cellValue === 'EDITSELECTION' && selectionOptions?.length) {
        return (
          <>
            <IconButton title="Filter" onClick={({ currentTarget }) => handleEditSelctionClick(currentTarget, rowId)}>
              <EditNote fontSize="small" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={filterAnchor}
              open={Boolean(filterAnchor) && showMenu}
              onClose={handleFilterSelect}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                'aria-multiselectable': 'true',
              }}
              slotProps={{
                paper: {
                  elevation: 2, // Reduce the shadow (use values like 0, 1, etc.)
                  // sx: {
                  //   boxShadow: '-moz-initial', // Custom box shadow if needed
                  // },
                },
              }}
            >
              {selectionOptions.map((itm: any) => (
                <MenuItem
                  selected={filter2Applied === itm}
                  key={itm}
                  onClick={() => handleFilterSelect(itm as typeof filter2Applied, rowId)}
                >
                  {itm}
                </MenuItem>
              ))}
            </Menu>
          </>
        )
      }
      if (cellValue === 'DROPDOWN' && dropDownOptions && dropDownDefaultValues.length) {
        const defaultValueObject = dropDownDefaultValues.find((obj: any) => obj.hasOwnProperty(rowId))

        // Access the defaultValue using the rowId key
        const defaultValue = defaultValueObject ? defaultValueObject[rowId].defaultValue : ''
        let selectedoption = defaultValue
        return (
          <>
            {/* <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              size="medium"
              getOptionLabel={(option) => option}
              options={dropDownOptions as string[]}
              // value={}
              // value={options.find((option) => option.id === rowId) || null}
              value={selectedOption}
              // defaultValue={}
              onChange={(event, newValue) => handleChange(event, newValue, rowId)}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} />}
            /> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                select
                multiline
                sx={{ fontSize: 8 }}
                value={selectedoption ?? defaultValue}
                onChange={({ target }) => {
                  selectedoption = target.value
                  handleChange(target.value, rowId)
                }}
                required
              >
                {dropDownOptions.map((ddo: string, index: number) => (
                  <MenuItem sx={{ fontSize: 12 }} key={index} value={ddo}>
                    {ddo}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        )
      }
      if (cellValue === 'EDITINGINPUT') {
        return (
          <>
            <IconButton title="Filter" onClick={({ currentTarget }) => handleEditRowClick(currentTarget)}>
              <DriveFileRenameOutlineIcon fontSize="small" />
            </IconButton>
            {/* {parentRow &&
            } */}
          </>
        )
      }
      if(cellValue === 'FETCHENTITY'){
        return (
          <Button
            onClick={() => tableActionParams && tableActionParams.onButtonClick(rowId, ShadowTableAction.FetchEntity)}
            startIcon={<CloudDownload />}
          />
        )
      }
      if(cellValue=== 'COMPLETEDFETCH'){
        return(
          <Tooltip title="Already In Datapod">
          <DownloadDone></DownloadDone>
          </Tooltip>
        )
      }
      return cellValue
    }

    return (
      <TableContainer component={Paper} sx={{ backgroundColor: 'white' }}>
        <Table aria-label="customized table" size="small">
          <TableHead>      
            <TableRow>       
              {tcolumns.map((tc, ix) => (
                <StyledTableCell key={tc.label} align={sourceToTarget ? 'center' : 'justify'}>
                  {tc.sortableColumn ? (
                    <TableSortLabel direction={sortDescending[ix] ? 'desc' : 'asc'} onClick={() => sortColumn(ix)}>
                      <b>{tc.label}</b>
                    </TableSortLabel>
                  ) : (
                    <>{tc.label} </>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.length > 0 ? (
              sortedRows?.map((row, ix) => (
                <StyledTableRow
                  key={row.id + ix}
                  onClick={() => handleRowSelect(row.id)}
                  //color={selectedRow === row.id ? 'primary' : 'inherit'}
                  selected={selectedRow === row.id}
                  sx={{ backgroundColor: selectedRow === row.id ? 'primary.dark' : 'inherit' }}
                >
                  {row.values.map((rvalue, ix) => (
                    <StyledTableCell
                      key={ix}
                      align={sourceToTarget ? 'center' : 'justify'}
                      sx={{
                        paddingLeft: '30px',
                        backgroundColor: getSourceTargetColor(ix) ?? 'inherit',
                        color: !!getSourceTargetColor(ix) ? 'black' : 'inherit',
                      }}
                    >
                      {tableCellValue(rvalue, row.id, ix)}
                      {/* {selectedRow === row.id ? <ChevronRightOutlinedIcon fontSize="medium" /> : undefined} */}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <>
                {!isDataLoading && (
                  <>
                    <TableRow>
                      <TableCell colSpan={tcolumns.length} sx={{ textAlign: 'center' }}>
                        No Data
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
        <AnimatedLoader height="50%" width="50%" loading={isDataLoading} />
      </TableContainer>
    )
  },
)
ShadowTable.displayName = 'ShadowTable'

export default ShadowTable
