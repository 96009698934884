import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, TextField, Button } from '@mui/material';
import { AddCircleOutline, Edit } from '@mui/icons-material';
import LucidWidePopUp from 'ui-components/LucidWidePopUp';
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable';
import { DataColumn } from 'businessObjects';
import { useGetAttributesByTableId, useGetDataColumnsByTableId } from 'hooks/dataColumnHooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dataColumns: DataColumn[];
  targetTables: { id: string | number; name: string }[];
  dataTableName: string;
  onSaveForeignKey: (foreignKeyRelations: { [key: number]: number }) => Promise<void>
}

interface ExtendedDataColumn extends DataColumn {
  foreignColumnId?: number;
  foreignColumnName?: string;
  foreignTableName?: string;
  foreignTableId?: number;
}

interface ForeignKeyMapping {
  id: string;
  sourceColumn: ExtendedDataColumn;
  targetTable: string;
  targetColumn: DataColumn;
}

const ForeignKeyMappingComponent: React.FC<Props> = ({ 
  isOpen, 
  onClose,
  dataColumns,
  targetTables,
  dataTableName,
  onSaveForeignKey,
}) => {
  const [selectedSourceColumn, setSelectedSourceColumn] = useState<ExtendedDataColumn>();
  const [selectedTargetTable, setSelectedTargetTable] = useState('');
  const [selectedTargetColumn, setSelectedTargetColumn] = useState<DataColumn>();
  const [mappings, setMappings] = useState<ForeignKeyMapping[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [targetColumns, setTargetColumns] = useState<DataColumn[]>([]);
  const [isLoadingColumns, setIsLoadingColumns] = useState(false);

  const { getAttributes, attributes } = useGetAttributesByTableId();
  const { getDataColumns } = useGetDataColumnsByTableId();

  useEffect(() => {
    if (dataColumns && isOpen) {
      const initialMappings: ForeignKeyMapping[] = (dataColumns as ExtendedDataColumn[])
        .filter(col => col.isForeignKey)
        .map(col => {
          const tempTargetColumn: DataColumn = {
            id: col.foreignColumnId || 0,
            dataColumnName: col.foreignColumnName || '',
            dataPodId: '',
            datatype: 'string',
            isPrimary: false,
            isUnique: 0,
            isForeignKey: 0,
            isBusinessKey: false,
            dataTableId: col.foreignTableId || 0
          };

          return {
            id: String(col.id),
            sourceColumn: col,
            targetTable: col.foreignTableName || '',
            targetColumn: tempTargetColumn
          };
        });
      setMappings(initialMappings);
    }
  }, [dataColumns, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setMappings([]);
      resetForm();
    }
  }, [isOpen]);

  const handleTargetTableChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTableName = event.target.value;
    setSelectedTargetTable(selectedTableName);
    setSelectedTargetColumn(undefined);
    
    const selectedTable = targetTables.find(table => table.name === selectedTableName);
    
    if (selectedTable) {
      setIsLoadingColumns(true);
      try {
        const response = await getDataColumns(Number(selectedTable.id));
        if(response)
          setTargetColumns(response);
      } catch (error) {
        console.error('Error fetching target columns:', error);
        setTargetColumns([]);
      } finally {
        setIsLoadingColumns(false);
      }
    }
  };

  const handleAddMapping = () => {
    if (selectedSourceColumn && selectedTargetTable && selectedTargetColumn) {
      if (editingId) {
        setMappings(mappings.map(mapping =>
          mapping.id === editingId
            ? {
                ...mapping,
                sourceColumn: selectedSourceColumn,
                targetTable: selectedTargetTable,
                targetColumn: selectedTargetColumn,
              }
            : mapping
        ));
        setEditingId(null);
      } else {
        const newMapping: ForeignKeyMapping = {
          id: Date.now().toString(),
          sourceColumn: selectedSourceColumn,
          targetTable: selectedTargetTable,
          targetColumn: selectedTargetColumn,
        };
        setMappings([...mappings, newMapping]);
      }
      resetForm();
    }
  };

  const handleDeleteMapping = (rowId: string) => {
    setMappings(mappings.filter(mapping => mapping.id !== rowId));
    if (editingId === rowId) {
      resetForm();
    }
  };

  const handleEditMapping = async (rowId: string) => {
    const mappingToEdit = mappings.find(mapping => mapping.id === rowId);
    if (mappingToEdit) {
      setSelectedSourceColumn(mappingToEdit.sourceColumn);
      setSelectedTargetTable(mappingToEdit.targetTable);
      
      const selectedTable = targetTables.find(table => table.name === mappingToEdit.targetTable);
      if (selectedTable) {
        setIsLoadingColumns(true);
        try {
          const response = await getDataColumns(Number(selectedTable.id));
          if(response)
            setTargetColumns(response);
          setSelectedTargetColumn(mappingToEdit.targetColumn);
        } catch (error) {
          console.error('Error fetching target columns during edit:', error);
        } finally {
          setIsLoadingColumns(false);
        }
      }
      setEditingId(rowId);
    }
  };

  const handleConfirm = async () => {
    const body: { [key: number]: number } = {};
    mappings.forEach(mapping => {
      body[mapping.sourceColumn.id] = mapping.targetColumn.id;
    });
    await onSaveForeignKey(body);
    handleClose();
  };

  const resetForm = () => {
    setSelectedSourceColumn(undefined);
    setSelectedTargetTable('');
    setSelectedTargetColumn(undefined);
    setTargetColumns([]);
    setEditingId(null);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const shadowTableColumns = [
    { label: 'Source Column', sortableColumn: true },
    { label: 'Target Table', sortableColumn: true },
    { label: 'Target Column', sortableColumn: true },
    { label: 'Edit', sortableColumn: false },
    { label: 'Delete', sortableColumn: false }
  ];

  const tableActionParams = {
    actions: [ShadowTableAction.Editable, ShadowTableAction.Deletable],
    onButtonClick: (rowId: string, actionType: ShadowTableAction) => {
      if (actionType === ShadowTableAction.Deletable) {
        handleDeleteMapping(rowId);
      } else if (actionType === ShadowTableAction.Editable) {
        handleEditMapping(rowId);
      }
    }
  };

  const tableRows = mappings.map(mapping => ({
    id: mapping.id,
    values: [
      mapping.sourceColumn.dataColumnName,
      mapping.targetTable,
      mapping.targetColumn.dataColumnName,
      'EDIT',
      'DELETE'
    ]
  }));

  return (
    <LucidWidePopUp
      openPopUp={isOpen}
      closePopup={handleClose}
      showCloseIcon
      headingText="Foreign Key Mapping Configuration"
      confirmText="CONFIRM"
      cancelText="Cancel"
      onConfirm={handleConfirm}
    >
      <Grid container spacing={3}>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <TextField
              select
              fullWidth
              label="Source Column"
              value={selectedSourceColumn?.id || ''}
              onChange={(e) => {
                const selectedColumn = dataColumns.find(column => column.id === Number(e.target.value));
                setSelectedSourceColumn(selectedColumn as ExtendedDataColumn);
              }}
              size="small"
            >
              {dataColumns.map((column) => (
                <MenuItem key={column.id} value={column.id}>
                  {column.dataColumnName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              select
              fullWidth
              label="Target Table"
              value={selectedTargetTable}
              onChange={handleTargetTableChange}
              size="small"
            >
              {targetTables.map((table) => (
                <MenuItem key={table.id} value={table.name}>
                  {table.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              select
              fullWidth
              label="Target Column"
              value={selectedTargetColumn?.id || ''}
              onChange={(e) => {
                const selectedColumn = targetColumns.find(column => column.id === Number(e.target.value));
                setSelectedTargetColumn(selectedColumn);
              }}
              size="small"
              disabled={isLoadingColumns || !selectedTargetTable}
            >
              {targetColumns.map((column) => (
                <MenuItem key={column.id} value={column.id}>
                  {column.dataColumnName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              sx={{my:1}}
              startIcon={editingId ? <Edit /> : <AddCircleOutline />}
              onClick={handleAddMapping}
              disabled={!selectedSourceColumn || !selectedTargetTable || !selectedTargetColumn || isLoadingColumns}
            >
              {editingId ? 'Update Mapping' : 'Add Mapping'}
            </Button>
            {editingId && (
              <Button
                variant="outlined"
                onClick={resetForm}
                sx={{ ml: 1 }}
              >
                Cancel Edit
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <ShadowTable
            rows={tableRows}
            columns={shadowTableColumns}
            tableActionParams={tableActionParams}
            isDataLoading={isLoadingColumns}
          />
        </Grid>
      </Grid>
    </LucidWidePopUp>
  );
};

export default ForeignKeyMappingComponent;