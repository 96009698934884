import { useEffect, useState } from 'react'
import { DataColumn, DataColumnBody } from '../businessObjects'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import {
  createDataColumn,
  getDataColumnById,
  getDataColumnByName,
  getDataColumnByTableId,
  updatePrimaryColumnsService,
  SyncForeignKeyRelationshipsService,
} from '../apiServices/dataColumn'

type dataPodId = string
type AddNewDataColumn = (body: DataColumnBody, datapodId: dataPodId) => Promise<void>

type Id = number
type TableId = number
type dataColumnName = string
type DataColumnParams = Id | TableId | dataColumnName
type GetDataColumns = (params: DataColumnParams, datapodID: dataPodId) => Promise<void>

export const useAddNewDataAttribute = (): [
  AddNewDataColumn,
  DataColumnBody | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [newDataColumn, setNewDataColumn] = useState<DataColumn>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const addNewDataColumn = async (params: DataColumnBody, datapodId: dataPodId): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await createDataColumn(bearerToken, params, datapodId)
        // console.log(data)
        if (status == 200) {
          //console.log(status)
          setNewDataColumn(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  return [addNewDataColumn, newDataColumn, loading, createError]
}
export const useGetDataColumns = (
  byId: boolean,
  byDatapodId: boolean,
): [GetDataColumns, DataColumn | undefined, boolean | undefined, boolean | undefined] => {
  const [dataColumns, setDataColumns] = useState<DataColumn>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchDataColumns = async (params: DataColumnParams, datapodID: dataPodId): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        if (byId && typeof params == 'number') {
          const { data, status } = await getDataColumnById(bearerToken, params)
          if (status == 200) {
            //console.log(status)
            setDataColumns(data)
            setLoading(false)
          }
        } else if (!byId && typeof params == 'number') {
          const { data, status } = await getDataColumnByTableId(bearerToken, params)
          if (status == 200) {
            //console.log(status)
            setDataColumns(data)
            setLoading(false)
          }
        } else if (typeof params == 'string' && byDatapodId) {
          const { data, status } = await getDataColumnByName(bearerToken, params, datapodID)
          if (status == 200) {
            //console.log(status)
            setDataColumns(data)
            setLoading(false)
          }
        } else if (typeof params == 'string' && !byDatapodId) {
          const { data, status } = await getDataColumnByName(bearerToken, params)
          if (status == 200) {
            //console.log(status)
            setDataColumns(data)
            setLoading(false)
          }
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  return [fetchDataColumns, dataColumns, loading, createError]
}

export const useGetAllDataColumns = (
  dataTableId?: number,
  searchText?: string,
): { dataColumns?: DataColumn[]; loading?: boolean; error?: boolean } => {
  const [dataColumns, setDataColumns] = useState<DataColumn[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchDataColumns = async (): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataTableId) {
        const { data, status } = await getDataColumnByTableId(bearerToken, dataTableId, searchText)
        if (status == 200) {
          //console.log(status)
          setDataColumns(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchDataColumns()
  }, [dataTableId])
  return { dataColumns, loading, error: createError }
}
export const useGetAllDataColumnsByTableId = (
  searchText?: string,
): { getDataColumns: (id: number) => void; dataColumns: DataColumn[] } => {
  const { getBearerToken } = useLucidAuthContext()
  const [dataColumns, setDataColumns] = useState<DataColumn[]>([])
  const getDataColumns = async (tableId: number): Promise<void> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && tableId) {
      const { data, status } = await getDataColumnByTableId(bearerToken, tableId, !searchText? '': searchText)
      if (status == 200) {
        //console.log(status)
        setDataColumns(data)
        // return data as DataColumn[]
      } else {
        setDataColumns([])
      }
    }
    // return []
  }

  return { getDataColumns, dataColumns }
}
export const useGetDataColumnsByTable = (
): { getDataColumns: (id: number, searchText?: string) => void, dataColumns: DataColumn[] } => {
  const { getBearerToken } = useLucidAuthContext()
  const [dataColumns, setDataColumns] = useState<DataColumn[]>([])
  const getDataColumns = async (tableId: number, searchText?: string): Promise<void> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && tableId) {
      const { data, status } = await getDataColumnByTableId(bearerToken, tableId, !searchText? '': searchText)
      if (status == 200) {
        //console.log(status)
        setDataColumns(data)
        // return data as DataColumn[]
      } else {
        setDataColumns([])
      }
    }
    // return []
  }

  return { getDataColumns, dataColumns }
}

// ref : SourceToTargetComponent
export const useGetDataColumnsByTableId = (): { getDataColumns: (id: number) => Promise<DataColumn[] | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const getDataColumns = async (tableId: number): Promise<DataColumn[] | undefined> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && tableId) {
      const { data, status } = await getDataColumnByTableId(bearerToken, tableId)
      if (status == 200) {
        //console.log(status)
        return data as DataColumn[]
        // return data as DataColumn[]
      }
    }
    // return []
  }

  return { getDataColumns }
}

export const useUpdatePrimaryColumns = (datapodId: string | undefined, tableId: number | undefined) => {

  const { getBearerToken } = useLucidAuthContext()
  const updatePrimaryColumns = async (columnIds: number[]): Promise<number[] | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId && tableId && columnIds) {
        const { data, status } = await updatePrimaryColumnsService(bearerToken, datapodId, tableId, columnIds)

        if (status == 200) {
          return data 
        }
      }
    } catch (error) {

    }

  }
  return [updatePrimaryColumns]
}

export const useSyncForeignKey = (datapodId: string | undefined, tableId: number | undefined) => {
  const { getBearerToken } = useLucidAuthContext()
  
  const syncForeignKey = async (foreignKeysObject: {[key: number]: number}): Promise<number[] | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId && tableId && foreignKeysObject) {
        const { data, status } = await SyncForeignKeyRelationshipsService(bearerToken, datapodId, tableId, foreignKeysObject)
        if (status == 200) {
          return data
        }
      }
    } catch (error) {
      console.error('Error updating foreign key:', error)
    }
  }
  
  return {syncForeignKey}
}

export const useGetAttributesByTableId = (): {
  getAttributes: (id: number) => Promise<DataColumn[]>;
  attributes: DataColumn[];
} => {
  const { getBearerToken } = useLucidAuthContext();
  const [attributes, setAttributes] = useState<DataColumn[]>([]);

  const getAttributes = async (tableId: number): Promise<DataColumn[]> => {
    const bearerToken = await getBearerToken();
    if (bearerToken && tableId) {
      const { data, status } = await getDataColumnByTableId(bearerToken, tableId);
      if (status === 200) {
        setAttributes(data); // Update state
        return data; // Return fetched data
      } else {
        setAttributes([]);
        return [];
      }
    }
    return [];
  };

  return { getAttributes, attributes };
};

