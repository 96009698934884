import { fabricServices } from "apiServices/fabric"
import axios from "axios"
import { FabricJobStatusRequest, FabricJobStatusResponse, FabricNotebookCreateRequest, FabricRunNewJobRequest } from "businessObjects"
import { useLucidAuthContext } from "LucidAuthContext/LucidAuthContext"


export const usePostFabricJobRunNew = (): {fabricPostRunNewJob: (params: FabricRunNewJobRequest) => Promise<{status: number, data: string | FabricJobStatusResponse}>} => {
  const { getBearerToken, getFabricBearerToken, userAccount } = useLucidAuthContext()

  const fabricPostRunNewJob = async (params: FabricRunNewJobRequest): Promise<{status: number, data: string | FabricJobStatusResponse}> => {
    try {
      const bearerToken = await getBearerToken()
      const fabricToken = await getFabricBearerToken()
      if (bearerToken && fabricToken && userAccount) {
        params.token = fabricToken
        const { data, status } = await fabricServices.postRunNewJob(bearerToken, params)
        if (status == 200) {
          return { status, data};
        }
        else {
          return {status, data: "Internal Server Error: Run New Job Failed."};
        }
      } else {
        return {status: 404, data: "Internal Server Error: No bearer token found."};
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Axios-specific error handling
        console.error("Axios Error in getting Job Status:", {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
        });
        const data = JSON.stringify(error.response?.data?.errors || error.response?.data?.error || error.message)
        return {status: error.response?.status || 500, data};
      } else {
        // Non-Axios error
        console.error("Unexpected Error in getting Job Status:", error);
        return {status: 500, data: "An unexpected error occurred."};
      }
    }
  }
  return {fabricPostRunNewJob}
}

export const usePostFabricCreateAndRun = (): {fabricPostCreateAndRun: (params: FabricNotebookCreateRequest) => Promise<{status: number, data: string | FabricJobStatusResponse}>} => {
  const { getBearerToken, getFabricBearerToken, userAccount } = useLucidAuthContext()

  const fabricPostCreateAndRun = async (params: FabricNotebookCreateRequest):  Promise<{status: number, data: string | FabricJobStatusResponse}> => {
    try {
      const bearerToken = await getBearerToken()
      const fabricToken = await getFabricBearerToken()
      if (bearerToken && fabricToken && userAccount) {
        params.displayName = params.displayName + "_" + userAccount.email
        params.accessToken = fabricToken
        const { data, status } = await fabricServices.postCreateAndRun(bearerToken, params)
        if (status == 200) {
          return { status, data};
        }
        else {
          return {status, data: "Internal Server Error: Run New Job Failed."};
        }
      } else {
        return {status: 404, data: "Internal Server Error: No bearer token found."};
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Axios-specific error handling
        console.error("Axios Error in getting Job Status:", {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
        });
        const data = JSON.stringify(error.response?.data?.errors || error.response?.data?.error || error.message)
        return {status: error.response?.status || 500, data};
      } else {
        // Non-Axios error
        console.error("Unexpected Error in getting Job Status:", error);
        return {status: 500, data: "An unexpected error occurred."};
      }
    }
  }
  return {fabricPostCreateAndRun}
}


export const useGetFabricJobStatus = (): {fetchFabricJobStatus: (jobStatusPayload: FabricJobStatusRequest[]) => Promise<FabricJobStatusResponse[] | undefined>} => {
  const { getBearerToken, getFabricBearerToken } = useLucidAuthContext()

  const fetchFabricJobStatus = async (jobStatusPayload: FabricJobStatusRequest[]): Promise<FabricJobStatusResponse[] | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      const fabricToken = await getFabricBearerToken()
      if (bearerToken && fabricToken) {
          
        jobStatusPayload.forEach(job => job.token = fabricToken)

        const { data, status } = await fabricServices.getJobStatus(bearerToken, jobStatusPayload)
        if (status == 200) {
          return data;
        }
      }
    } catch (error) {
        console.log("Error in getting Job Status", error);
    }
  }
  return {fetchFabricJobStatus}
}