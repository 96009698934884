// CustomAttributesTable.js
import React, { useState } from 'react'
import { Grid, Typography, Button, TextField } from '@mui/material'
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable'
import { DataTableRow } from 'ui-components/ExtendedShadowTable'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import { useAddNewDataAttribute, useDeleteAttributeId, useGenerateCustomAttributes } from 'hooks/dataAttrbutesHooks'
import { useNotebookToQueryString } from 'hooks/notebookHooks'
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp'
import TextFormInput from 'ui-components/TextForm/TextFormInput'
import { NewAttribute, NewAttributeMap, NewAttributeMapBody, ResultStatusSeverity } from 'businessObjects'
import { useAddttributeMap } from 'hooks/attributeProfileHooks'
import { useAddNewCustomAttributeTransformer } from 'hooks/dataTransformerHooks'
import { LoadingButton } from '@mui/lab'
import ResultStatus from 'ui-components/ResultStatus'
import { set } from 'zod'

type customAttributeTableProps = {
  dataPodId: string
  entityId: number
  dataHubCustomAttributes: DataTableRow[] | undefined,
  compositeAttributes: any
  hubEntity: boolean
  refetchAttributes: () => void
  selectedCustomAttributes: string[]
  setSelectedCustomAttributes: React.Dispatch<React.SetStateAction<string[]>>
}

const CustomAttributesTable = ({
  dataPodId,
  entityId,
  dataHubCustomAttributes,
  compositeAttributes,
  hubEntity,
  refetchAttributes,
  selectedCustomAttributes,
  setSelectedCustomAttributes,
}: customAttributeTableProps) => {

  const [isAddCustomAttrClicked, setIsAddCustomAttrClicked] = useState<boolean>(false)

  const [addPromptText, setAddPromptText] = useState<string>('')

  const { loadCanonicalNotebookStringByEntityId, notebookResponse } = useNotebookToQueryString(dataPodId)
  const { deleteAttributeId } = useDeleteAttributeId()
  const { generateCustomAttributes } = useGenerateCustomAttributes()
  const [submitNewAttribute] = useAddNewDataAttribute();

  const [newAttributeFunc] = useAddNewDataAttribute()
  const { addAttributeMapFunc } = useAddttributeMap(dataPodId)
  const { addTransformer } = useAddNewCustomAttributeTransformer(dataPodId)
  const [isCustomAttributeGenerating, setIsCustomAttributeGenerating] = useState<boolean>(false)

  const [isShowStatus, setIsShowStatus] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('info')
  const [resultStatusMessage, setResultStatusMessage] = useState<string>(`Info: Select Entity to perform the action`)

  const navigate = useNavigate()

  const customAttrTableColumnsHub = [
    { label: 'Attribute Name', sortableColumn: true },
    { label: 'Attribute Description', sortableColumn: true },
    { label: 'Attribute Type', sortableColumn: true },
    { label: 'Data Type', sortableColumn: true },
    { label: 'Data Classification', sortableColumn: true },
    { label: 'isBusinessKey', sortableColumn: true },
  ]

  const customAttrTableColumns = [
    { label: '', sortableColumn: false },
    { label: 'Attribute Name', sortableColumn: true },
    { label: 'Attribute Description', sortableColumn: true },
    { label: 'Attribute Type', sortableColumn: true },
    { label: 'Data Type', sortableColumn: true },
    { label: 'Data Classification', sortableColumn: true },
    { label: 'isBusinessKey', sortableColumn: true },
    { label: 'View Details' },
    { label: 'Delete' },
  ]

  const hasAllNewAttributeKeys = (attribute: any): attribute is NewAttribute => {
    const requiredKeys: (keyof NewAttribute)[] = [
      'dataAttributeName',
      'dataAttributeDataType',
      'description',
      'isBusinessKey',
      'dataClassification',
      'promptText',
      'dataSourceAttributeId',
    ];
    if(requiredKeys.every(key => key in attribute)) {
      // If dataSourceAttributeId is an array, take the first element
      attribute.dataSourceAttributeId = 
    typeof attribute.dataSourceAttributeId === 'object' && attribute.dataSourceAttributeId !== null && Array.isArray(attribute.dataSourceAttributeId)
      ? attribute.dataSourceAttributeId[0]
      : attribute.dataSourceAttributeId

      return true;
    }
    return false;
  };

  const generateCustomAttrDetails = async () => {
    setIsCustomAttributeGenerating(true);
    setIsAddCustomAttrClicked(false);
    const response = await generateCustomAttributes(dataPodId, entityId, addPromptText);
    if (response.status == 200 && hasAllNewAttributeKeys(response.data)) {
      response.data.dataEntityId = entityId;

      await saveCustomAttribute(response.data);
      refetchAttributes();
    } else {
      setIsShowStatus(true)
      setSeverity('error')
      setResultStatusMessage(response.data)
    }
    setIsCustomAttributeGenerating(false);
  };

  const onClickCustomAttribute = () => {}

  const onCompositeButtonClick = async (rowId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Deletable) {
      const deleteMapSuccess = await deleteAttributeId(parseInt(rowId))
      if (deleteMapSuccess && entityId) {
        refetchAttributes()
      }
    }
    if (actionType === ShadowTableAction.Editable) {
      const dataAttributeDetailsRoute = generatePath(ROUTES.DataModelingCanonicalModelEditCustomAttribute, {
        dataPodId: dataPodId,
        entityID: entityId,
        dataAttributeID: rowId,
      })
      navigate(dataAttributeDetailsRoute)
      if (entityId) loadCanonicalNotebookStringByEntityId(entityId)
    }
  }

  const saveCustomAttribute = async (newAttribute: NewAttribute) => {
    if (newAttribute && dataPodId) {
      const tosave = {
        ...newAttribute,
        dataAttributeType: 'CustomAttribute',
        isBusinessKey: Boolean(newAttribute.isBusinessKey),
      }
      const dAtt = await newAttributeFunc(tosave, dataPodId)

      if(dAtt?.id) {
        const attribMap: NewAttributeMapBody = { 
          dataEntityId: Number(entityId),
          dataAttributeId: dAtt?.id,
          dataSourceAttributeId: newAttribute.dataSourceAttributeId,
          runid: 0,
        }
        const resp = await addAttributeMapFunc(attribMap)

        if (newAttribute.promptText && resp?.id) {
          const result = await addTransformer({
            dataAttributeMapId: resp.id,
            transformerText: newAttribute.promptText,
            transformerQuery: '',
            dataEntityId: Number(entityId),
            transformerTypeName: 'Composite',
          })
          if (result) {
            if(!result.success) {
              setIsShowStatus(true)
              setSeverity('error')
              setResultStatusMessage('Error: Failed to save the custom attribute transformer.')
              return false
            } else {
              setIsShowStatus(true)
              setSeverity('success')
              setResultStatusMessage('Success: Custom attribute saved successfully.')
              return true
            }
          }
        }
        else {
          setIsShowStatus(true)
          setSeverity('error')
          setResultStatusMessage('Error: Failed to save the custom attribute map.')
          return false
        }
      } else {
        setIsShowStatus(true)
        setSeverity('error')
        setResultStatusMessage('Error: Failed to save the custom attribute.')
        return false
      }
    }
    return false
  }

  const onClickAddCustomAttribute = () => {
    const dataAttributeDetailsRoute = generatePath(ROUTES.DataModelingCanonicalModelAddAttribute, {
      dataPodId: dataPodId,
      entityID: entityId,
    })
    navigate(dataAttributeDetailsRoute)
  }

  return (
    <>
      <ResultStatus 
        severtiy={severity} 
        showStatus={isShowStatus} 
        closeStatus={setIsShowStatus} 
        alertMessage={resultStatusMessage} 
      />

      <LucidPopUp
        openPopUp={isAddCustomAttrClicked}
        closePopup={setIsAddCustomAttrClicked}
        headingText={
          <Typography variant="h4" color={''}>
            Generate Custom Attribute
          </Typography>
        }
        confirmText="Confirm"
        cancelText="Cancel"
        disabledConfirm={addPromptText.length === 0}
        onConfirm={generateCustomAttrDetails}
      >
        <TextFormInput
          fullWidth
          required
          multiline={4}
          labelText="Prompt text"
          value={addPromptText}
          changeHandle={(value) => setAddPromptText(value)}
          validateErrorText={addPromptText.length === 0 ? 'Input required: Please fill in the prompt text.' : undefined}
        />
      </LucidPopUp>

      <Grid item xs={12} container>
        <Grid item xs={12} container display={'flex'} justifyContent={'space-between'} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom color="primary">
              Custom Attributes
            </Typography>
          </Grid>
          <Grid 
            item 
            container
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
            spacing={2}
            xs={8}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onClickAddCustomAttribute()}
                disabled={hubEntity}
                style={{ marginRight: '2' }}
              >
                Add Attribute
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={() => setIsAddCustomAttrClicked(true)}
                disabled={hubEntity}
                loading={isCustomAttributeGenerating}
              >
                Generate Attribute
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mb: 0 }} />

        <Grid item xs={12} container>
          <ShadowTable
            rows={dataHubCustomAttributes?.length ? dataHubCustomAttributes : compositeAttributes}
            columns={dataHubCustomAttributes?.length ? customAttrTableColumnsHub : customAttrTableColumns}
            selectedRows={selectedCustomAttributes}
            setSelectedRows={setSelectedCustomAttributes}
            tableActionParams={{
              onButtonClick: onCompositeButtonClick,
              actions: [ShadowTableAction.Viewable, ShadowTableAction.Deletable],
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CustomAttributesTable
