import React, { useState, useEffect, useMemo } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Chip,
    Box,
    TextField,
    Button,
    Menu,
    MenuItem,
    Tooltip,
    Autocomplete,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataAttribute, Entity, getCoreEntityAttributeResponse } from "businessObjects";
import { useGetCoreEntityAttributesDatapod } from "hooks/coreEntityAttributeHooks";
import { useGetAllEntitiesByDataPodIdByMethod, useGetCoreEntity, useGetCoreEntityWithMethodImmediateData, useGetEntities } from "hooks/entityHooks";
import { useGetDataHubEntityMaps, useGetEntityAttributes } from "hooks/dataHubHooks";
import { useGetDataAttributesByMultipleEntityId } from "hooks/dataAttrbutesHooks";

// Define a type for grouped attributes
type GroupedAttributes = {
    [entity: string]: string[];
};

interface CoreEntityAttribute {
    id: string;
    dataEntityName: string | undefined | null;
    dataAttributeName: string;
    description: string;
    dataAttributeType: string;
    dataEntityId: string;
}

type UpdatedDataAttribute = DataAttribute & {
    dataEntityName: string | undefined | null;
};

interface AttributeViewerProps {
    attributeString: string; // Input string in @entityName.attribute format 
    onAttributeStringChange: (newAttributeString: string) => void; // Callback to parent component
    podData: string | undefined
    coreEntityId: string | undefined
    updateNotificationToParent: React.Dispatch<React.SetStateAction<boolean>>
    beforeSave: boolean
    selectedEntityString: string
    refetchHappened:boolean
}

const TreeView: React.FC<AttributeViewerProps> = ({
    attributeString,
    onAttributeStringChange,
    podData,
    coreEntityId,
    updateNotificationToParent,
    beforeSave,
    selectedEntityString,
    refetchHappened

}) => {
    const [groupedAttributes, setGroupedAttributes] = useState<GroupedAttributes>({});
    const [globalAnchorEl, setGlobalAnchorEl] = useState<HTMLElement | null>(null); // For component-level button
    const [anchorEl, setAnchorEl] = useState<{ [entity: string]: HTMLElement | null }>({});
    const [localString, setLoacalString] = useState<string>('')
    const [fetchCoreAttributesDatapod, coreAttributesDatapod] = useGetCoreEntityAttributesDatapod(podData)
    // const [entityId, setEntityId] = useState<number[]>([])
    const [coreEntityAttribute, setCoreEntityAttributeData] = useState<UpdatedDataAttribute[]>([])
    const [entityDataAll, setEntityDataAll] = useState<Entity[]>([])
    const [entityDataNormal, setEntityDataNormal] = useState<Entity[]>([])
    const [coreEntities] = useGetCoreEntity(podData)
    const [coreEntity, fetchCoreEntity] = useGetCoreEntityWithMethodImmediateData()
    const { entities: fetchedEntities } = useGetEntities(podData)
    const [fetchAllEntity, entityData] = useGetAllEntitiesByDataPodIdByMethod()
    const [getDataHubEntityAttributes] = useGetEntityAttributes()
    const [getDataHubEntities] = useGetDataHubEntityMaps()
    const { refetchAttributes } = useGetDataAttributesByMultipleEntityId()
    const [isHavingEnriched, setIsHavingEnriched] = useState<boolean>(false)
    const [DataSystemEntityIds, setDataSystemEntityIds] = useState<any>([])
    const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([])
    // const [entityData, setEntityData] = useState<Entity[]>([])
    const [allEntIds, setAllEntIds] = useState<number[]>([])
    // useEffect(() => {
    //     if (entityId && podData) {
    //       const tempEntArray = Array.from(new Set(entityId.map((item) => item)))
    //       fetchAllAttributesOfEntities(tempEntArray, podData)
    //     }
    //   }, [entityId, entityId.length])
    useEffect(() => {
        if (!coreEntityId && attributeString && beforeSave) {
            parseAttributes(attributeString)
        }else if (!beforeSave && attributeString && refetchHappened){
            setLoacalString(attributeString)
            parseAttributes(attributeString)
        }
    }, [attributeString, coreEntityId]);
    useEffect(() => {
        if (podData) {
            getAllEntities(podData)
        }
    }, [])
    useEffect(() => {
        // console.log("Inside the use effect ")
        if (selectedEntityString && entityDataAll.length && podData) {
            // console.log({ selectedEntityString })
            const entityNames = selectedEntityString
                .split(" ")
                .map((name) => name.trim().replace("@", "")); // Remove '@'
            // console.log(entityNames)
            let matchedIds = entityDataAll
                .filter((entity) => entityNames.includes(entity.dataEntityName))
                .map((entity) => entity.id);
            // console.log({ matchedIds })
            setSelectedEntityIds(matchedIds)
            const tempEntArray = Array.from(new Set(matchedIds.map((item) => item)))
            fetchAllAttributesOfEntities(tempEntArray, podData, matchedIds)
        }
    }, [selectedEntityString, entityDataAll, coreEntityId])
    // useEffect(() => {
    //     if (coreEntityId) {
    //         console.log("Comes inside the if of the use effect 92")
    //         fetchCoreAttributes(Number(coreEntityId))
    //     } else {
    //         console.log("Is not having the coreEntityId value")
    //     }
    // }, [])
    // useEffect(()=>{
    //     if(coreEntities){
    //         console.log({coreEntities})
    //     }
    // },[coreEntities])
    useEffect(() => {
        if (coreEntityId) {
            // console.log("Fetch core being called by the if of use effect")
            fetchCoreAttributes(Number(coreEntityId))
        }
    }, [entityDataAll, entityDataAll.length])
    // useEffect(() => {
    //     if (coreEntities.length && fetchedEntities) {
    //         console.log("Comes in here inside the if of the use effect")
    //         const dataSystemEntityIds = fetchedEntities.map((entity) => entity.id)
    //         setDataSystemEntityIds(dataSystemEntityIds)
    //         const temp1 = [...fetchedEntities, ...coreEntities]
    //           console.log({temp1})
    //         const allEntIds = temp1.map((t) => { return t.id })
    //         //   console.log({allEntIds})
    //         setAllEntIds(allEntIds)
    //         //   setEntityData(temp1)
    //         setEntityDataAll(temp1)
    //         setIsHavingEnriched(true)
    //     } else {
    //         console.log("Inside the else of the ")
    //         const dataSystemEntityIds = fetchedEntities.map((entity) => entity.id)
    //         setDataSystemEntityIds(dataSystemEntityIds)
    //         const temp2 = [...fetchedEntities]
    //         //   console.log({temp2})
    //         //   setEntityData(temp2)
    //         setEntityDataNormal(temp2)
    //         setIsHavingEnriched(false)
    //     }
    // }, [coreEntities, coreEntities.length, fetchedEntities, fetchedEntities.length])

    // useEffect(() => {
    //     // console.log({allEntIds})
    //     // console.log({podData})
    //     // console.log({entityDataAll})
    //     if (allEntIds && podData && entityDataAll.length) {
    //         const tempEntArray = Array.from(new Set(allEntIds.map((item) => item)))
    //         fetchAllAttributesOfEntities(tempEntArray, podData)
    //     }
    // }, [allEntIds, allEntIds.length, entityDataAll, entityDataAll.length, selectedEntityIds, selectedEntityIds.length])

    const getAllEntities = async (podId: string) => {
        const entities = await fetchAllEntity({}, podId)
        const coreEntities = await fetchCoreEntity(podId)
        if (entities?.length && coreEntities?.length) {
            const temp1 = [...entities, ...coreEntities]
            const dataSystemEntityIds = temp1.map((entity) => entity.id)
            setDataSystemEntityIds(dataSystemEntityIds)
            // console.log({ temp1 })
            const allEntIds = temp1.map((t) => { return t.id })
            //   console.log({allEntIds})
            setAllEntIds(allEntIds)
            //   setEntityData(temp1)
            setEntityDataAll(temp1)
            setIsHavingEnriched(true)
        } else if (entities?.length && !coreEntities?.length) {
            const dataSystemEntityIds = entities.map((entity) => entity.id)
            setDataSystemEntityIds(dataSystemEntityIds)
            const temp2 = [...entities]
            //   console.log({temp2})
            //   setEntityData(temp2)
            setEntityDataNormal(temp2)
            setIsHavingEnriched(false)
        }
        // console.log({ entities })
        // console.log({ coreEntities })
    }

    const fetchCoreAttributes = async (coreEntityId: number) => {
        const coreAttributes = await fetchCoreAttributesDatapod(coreEntityId)
        if (coreAttributes?.length && entityDataAll.length) {
            const string = concatenateAttributeNames(coreAttributes)
            // console.log("Comes here")
            parseAttributes(string)
        }
    }

    const concatenateAttributeNames = (attributes: getCoreEntityAttributeResponse[]): string => {
        // console.log({ entityDataAll })
        // console.log({ attributes })
        const concatenatedString = attributes
            .map((attribute) => {
                if (entityDataAll.length) {
                    const entityName = attribute.dataEntityId
                        ? entityDataAll.find((e) => e.id === attribute.dataEntityId)?.dataEntityName
                        : entityDataAll.find((e) => e.id === attribute.dataCoreEntityId)?.dataEntityName;
                    return entityName ? `@${entityName}.${attribute.dataCoreAttributeName}` : `@${attribute.dataCoreAttributeName}`;
                }
            })
            .filter(Boolean)
            .join(' ');

        if (concatenatedString === localString) return concatenatedString;

        const difference = concatenatedString
            .split(' ')
            .filter((part) => !localString.includes(part))
            .join(' ');
        // console.log({difference}, {userInput3}, {concatenatedString})
        // Step 4: Append the difference to the original userInput3 and return it
        const updatedString = `${localString} ${difference}`.trim();
        setLoacalString(updatedString)
        // console.log(`updatedString: ${updatedString}`)
        return updatedString;
    };

    const fetchAllAttributesOfEntities = async (dataSystemEntityIds: number[], datapodId: string, selectedEntityIdData:number[]) => {
        if (!dataSystemEntityIds.length) {
            setCoreEntityAttributeData([]);
            return;
        }
        const enrichedFlag = entityDataAll.filter((fe) => fe.coreEntity == true && (fe.id === Number(coreEntityId) || selectedEntityIds.includes(fe.id)));
        // console.log({enrichedFlag})
        let poolAttributes: UpdatedDataAttribute[] = [];

        const hubFlag = entityDataAll.filter(fe => fe.dataHubMapId && fe.dataHubCloneTypeId && selectedEntityIds.includes(fe.id));
        // console.log({hubFlag})
        let newHubAttributes: any[] = [];
        let hubEntityData: { hubEntityId: number, sourceId: number, entityName: string }[] = [];

        for (const hubEnt of hubFlag) {
            const resp = await getDataHubEntityAttributes(datapodId, hubEnt.id);
            const hubMap = await getDataHubEntities(undefined, hubEnt.dataHubMapId);

            if (resp) {
                newHubAttributes = [...newHubAttributes, ...resp];
            }

            if (hubMap) {
                hubMap.forEach((hubMapEntry: any) => {
                    if (hubMapEntry.id === hubEnt.dataHubMapId) {
                        hubEntityData.push({
                            hubEntityId: hubEnt.id,
                            sourceId: hubMapEntry.dataEntityId,
                            entityName: hubEnt.dataEntityName,
                        });
                    }
                });
            }
        }

        // Map newHubAttributes to UpdatedDataAttribute format
        const updatedHubAttributes: UpdatedDataAttribute[] = newHubAttributes.map(attr => ({
            id: attr.id,
            dataEntityId: attr.dataEntityId,
            dataAttributeName: attr.dataAttributeName,
            dataAttributeDataType: attr.dataAttributeDataType,
            description: attr.description,
            isBusinessKey: attr.isBusinessKey,
            dataClassification: attr.dataClassification,
            dataAttributeType: attr.attributeDataType,
            dataPodId: attr.dataPodId,
            isActive: attr.isActive,
            dataEntityName: attr.entityName,
        }));

        // Add hub attributes that match sourceId in hubEntityData to poolAttributes
        updatedHubAttributes.forEach(attr => {
            const matchingHubEntity = hubEntityData.find(hub => hub.sourceId === attr.dataEntityId);
            if (matchingHubEntity) {
                poolAttributes.push(attr);
            }
        });
        // console.log({poolAttributes})

        // Fetch attribute profiles
        const attributeProfiles = await refetchAttributes(dataSystemEntityIds);
        if (attributeProfiles?.length) {
            const entityMap = new Map<number, string>();
            fetchedEntities.forEach((entity) => {
                entityMap.set(entity.id, entity.dataEntityName);
            });
            // console.log({ entityMap })

            const enrichedAttributes = attributeProfiles.map(attr => ({
                ...attr,
                dataEntityName: entityMap.get(attr.dataEntityId) || 'Unknown',
            }));

            // console.log({ enrichedAttributes })

            // Add enriched attributes to poolAttributes
            enrichedAttributes.forEach(attr => {
                if (dataSystemEntityIds.includes(attr.dataEntityId)) {
                    poolAttributes.push(attr);
                }
            });
            // console.log({poolAttributes})
        }

        if (enrichedFlag) {
            // console.log({enrichedFlag})
            const existingIds = new Set(coreEntityAttribute.map(attr => attr.dataEntityId || attr.id));
            // console.log({existingIds})
            const itemsToFetch = enrichedFlag.filter(id => !existingIds.has(id.id));
            // console.log({itemsToFetch})
            // console.log({selectedEntityIds})
            // const itemsToFetch = excludedItems
            //     .filter(id => !existingIds.has(id)) // Exclude ids already in existingIds
            //     .filter(id => selectedEntityIds.includes(id)); // Include only ids in selectedEntityIds
            // console.log({ itemsToFetch })

            let newCoreAttributes: getCoreEntityAttributeResponse[] = [];

            // Fetch core attributes for items that need to be fetched
            for (const id of itemsToFetch) {
                const fetchedAttributes = await fetchCoreAttributesDatapod(id.id);
                // console.log({id})
                if (fetchedAttributes) {
                    const filteredAttributes = fetchedAttributes.filter((fe)=> !fe.dataEntityId)
                    // console.log({filteredAttributes})
                    newCoreAttributes = [...newCoreAttributes, ...filteredAttributes];
                }
            }
            // console.log(newCoreAttributes)
            if (newCoreAttributes.length) {
                const entityNamesMap = new Map(entityDataAll.map(({ id, dataEntityName }) => [id, dataEntityName]));
                // console.log({ entityNamesMap })
                // console.log(entityNamesMap.get(dataCoreEntityId))

                const coreAttributes = newCoreAttributes.map(({
                    id, dataCoreEntityId, dataCoreAttributeName, description,
                    dataCoreAttributeDataType, dataCoreClassification, dataPodId,
                    isBusinessKey, isActive
                }) => ({
                    id,
                    dataEntityId: dataCoreEntityId ?? null,
                    dataAttributeName: dataCoreAttributeName,
                    dataAttributeDataType: dataCoreAttributeDataType,
                    dataPodId,
                    description: description ?? '',
                    isBusinessKey: isBusinessKey ?? false,
                    dataClassification: dataCoreClassification ?? '',
                    dataAttributeType: '',
                    isActive: isActive ?? true,
                    dataEntityName: entityNamesMap.get(dataCoreEntityId),
                }));

                // Add core attributes to poolAttributes
                // console.log({ coreAttributes })
                coreAttributes.forEach(attr => {
                    if (allEntIds.includes(attr.dataEntityId)) {
                        poolAttributes.push(attr);
                    }
                });
                // console.log({poolAttributes})
            }
            setIsHavingEnriched(false);
        }

        // Ensure unique attributes using a Map
        const uniqueAttributesMap = new Map<number, UpdatedDataAttribute>();
        poolAttributes.forEach(attr => {
            uniqueAttributesMap.set(attr.id, attr);  // This will ensure uniqueness by overwriting duplicates
        });

        const uniqueAttributes = Array.from(uniqueAttributesMap.values());

        // Set the final core entity attribute data
        // console.log({ uniqueAttributes })
        setCoreEntityAttributeData(uniqueAttributes);
    };


    const parseAttributes = (attributeStringSent: string) => {
        const attributeList = attributeStringSent
            .trim()
            .split(" ")
            .map((attr) => attr.trim().substring(1)) // Remove '@' and trim whitespace
            .filter((attr) => attr.length > 0); // Exclude empty strings
        // console.log({ attributeList })
        const grouped: GroupedAttributes = attributeList.reduce((acc, curr) => {
            if (curr.includes(".")) {
                // Attributes with entityName.attribute
                const [entity, attribute] = curr.split(".");
                if (!acc[entity]) acc[entity] = [];
                acc[entity].push(attribute);
            } else {
                // Attributes without entityName go to 'unknownEntity'
                if (!acc["unknownEntity"]) acc["unknownEntity"] = [];
                acc["unknownEntity"].push(curr);
            }
            return acc;
        }, {} as GroupedAttributes);
        // console.log({ grouped })

        setGroupedAttributes(grouped);
    };

    // const updateParentAttributeString = () => {
    //     const updatedAttributeString = Object.entries(groupedAttributes)
    //         .flatMap(([entity, attributes]) =>
    //             attributes.map((attr) => `@${entity === "unknownEntity" ? "" : `${entity}.`}${attr}`)
    //         )
    //         .join(" ");
    //     setLoacalString(updatedAttributeString)
    //     onAttributeStringChange(updatedAttributeString); // Notify parent only when finalized
    //     updateNotificationToParent(true)
    // };
    const updateParentAttributeString = (updatedGroupedAttributes: GroupedAttributes) => {
        const updatedAttributeString = Object.entries(updatedGroupedAttributes)
            .flatMap(([entity, attributes]) =>
                attributes.map((attr) => `@${entity === "unknownEntity" ? "" : `${entity}.`}${attr}`)
            )
            .join(" ");

        setLoacalString(updatedAttributeString); // Update local string
        onAttributeStringChange(updatedAttributeString); // Notify parent about the updated string
        updateNotificationToParent(true); // Notify parent that changes occurred
    };

    // Delete a chip (attribute) from the grouped attributes
    const handleDeleteChip = (entity: string, attribute: string) => {
        setGroupedAttributes((prev) => {
            const updated = { ...prev };

            // Remove the specific attribute from the entity
            updated[entity] = updated[entity].filter((attr) => attr !== attribute);

            // Remove the entity if no attributes are left
            if (updated[entity].length === 0) {
                delete updated[entity];
            }

            // Update localString and parent after updating groupedAttributes
            updateParentAttributeString(updated);

            return updated; // Return the updated state
        });
    };

    // Add a new chip (attribute) to the grouped attributes
    //   const handleSelectMention = (entity: string, selectedAttribute: UpdatedDataAttribute) => {
    //     setGroupedAttributes((prev) => {
    //       const updated = { ...prev };
    //       const effectiveEntity = selectedAttribute.dataEntityName || "unknownEntity";
    //       if (!updated[effectiveEntity]) {
    //         updated[effectiveEntity] = [];
    //       }
    //       if (!updated[effectiveEntity].includes(selectedAttribute.dataAttributeName)) {
    //         updated[effectiveEntity].push(selectedAttribute.dataAttributeName);
    //       }
    //       return updated;
    //     });
    //     updateParentAttributeString(); // Update parent after addition
    //   };
    const handleSelectMention = (entity: string, selectedAttribute: UpdatedDataAttribute) => {
        setGroupedAttributes((prev) => {
            const updated = { ...prev };
            const effectiveEntity = selectedAttribute.dataEntityName || "unknownEntity";

            // Add the selected attribute to the appropriate group
            if (!updated[effectiveEntity]) {
                updated[effectiveEntity] = [];
            }
            if (!updated[effectiveEntity].includes(selectedAttribute.dataAttributeName)) {
                updated[effectiveEntity].push(selectedAttribute.dataAttributeName);
            }

            // Update the localString and parent string
            updateParentAttributeString(updated);

            return updated;
        });
    };

    // Filter global options to exclude already selected attributes
    // const getGlobalOptions = () => {
    //     console.log({ coreEntityAttributes })
    //     const selectedAttributes = Object.values(groupedAttributes).flat();
    //     console.log({ selectedAttributes })
    //     return coreEntityAttributes.filter(
    //         (attr) => !selectedAttributes.includes(attr.dataAttributeName)
    //     );
    // };
    const getGlobalOptions = useMemo(() => {
        // console.log({ groupedAttributes })
        // console.log({ coreEntityAttribute })
        const selectedAttributes = Object.values(groupedAttributes).flat();
        // console.log(selectedAttributes)
        // console.log({coreEntityAttribute})
        return coreEntityAttribute.filter(
            (attr) => !selectedAttributes.includes(attr.dataAttributeName)
        );
    }, [groupedAttributes, coreEntityAttribute, groupedAttributes.length]);

    return (
        <Box sx={{ width: "100%", mt: 2 }}>
            {/* Component-Level  Button */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => setGlobalAnchorEl(event.currentTarget)}
                >
                    Add Attribute
                </Button>
                <Menu
                    anchorEl={globalAnchorEl}
                    open={Boolean(globalAnchorEl)}
                    onClose={() => setGlobalAnchorEl(null)}
                    PaperProps={{
                        style: {
                            width: "500px",
                        },
                    }}
                >
                    <Autocomplete
                        options={getGlobalOptions}
                        getOptionLabel={(option) =>
                            `${option.dataEntityName || "Unknown Entity"}:${option.dataAttributeName}`
                        }
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleSelectMention(newValue.dataEntityName || "unknownEntity", newValue);
                                setGlobalAnchorEl(null);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                        renderOption={(props, option) => (
                            <Tooltip title={`${option.description}`}>
                                <MenuItem
                                    {...props}
                                    key={option.id}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {option.dataEntityName || "Unknown Entity"}:{option.dataAttributeName}
                                </MenuItem>
                            </Tooltip>
                        )}
                        style={{ width: "100%" }}
                    />
                </Menu>
            </Box>

            {/* Grouped Attributes Accordion */}
            {Object.keys(groupedAttributes).map((entity) => (
                <Accordion key={entity} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${entity}-content`}
                        id={`${entity}-header`}
                    >
                        <Typography variant="h6">
                            {entity === "unknownEntity" ? "Unknown Entity" : entity}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                            {groupedAttributes[entity].map((attribute, index) => (
                                <Chip
                                    key={index}
                                    label={attribute}
                                    variant="outlined"
                                    onDelete={() => handleDeleteChip(entity, attribute)}
                                />
                            ))}
                        </Box>

                        {/* Group-Level Add Attribute Button */}
                        <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: 1 }}>
                            <Button
                                variant="outlined"
                                onClick={(event) => setAnchorEl((prev) => ({ ...prev, [entity]: event.currentTarget }))}
                            >
                                Add Attribute
                            </Button>
                            <Menu
                                anchorEl={anchorEl[entity]}
                                open={Boolean(anchorEl[entity])}
                                onClose={() => setAnchorEl((prev) => ({ ...prev, [entity]: null }))}
                                PaperProps={{
                                    style: {
                                        width: "500px",
                                    },
                                }}
                            >
                                <Autocomplete
                                    options={coreEntityAttribute.filter((attr) => {
                                        const isEntityMatch = entity === "unknownEntity"
                                            ? !attr.dataEntityName
                                            : attr.dataEntityName === entity;

                                        const isAlreadySelected =
                                            groupedAttributes[entity]?.includes(attr.dataAttributeName) ?? false;
                                        
                                        // const isDataEntityCore = attr.dataEntityId === Number(coreEntityId);

                                        return isEntityMatch && !isAlreadySelected ;
                                    })}
                                    getOptionLabel={(option) =>
                                        `${option.dataAttributeName}`
                                    }
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            handleSelectMention(entity, newValue);
                                            setAnchorEl((prev) => ({ ...prev, [entity]: null }));
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search..."
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <Tooltip title={`${option.description}`}>
                                            <MenuItem
                                                {...props}
                                                key={option.id}
                                                style={{
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {option.dataEntityName || "Unknown Entity"}:{option.dataAttributeName}
                                            </MenuItem>
                                        </Tooltip>
                                    )}
                                    style={{ width: "100%" }}
                                />
                            </Menu>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default TreeView;