import { generatePath, useNavigate, useParams } from 'react-router-dom'
import ResultStatus from '../../../ui-components/ResultStatus'
import { useEffect, useMemo, useState } from 'react'
import ActionPage from '../../../ui-components/ActionPage'

import { Button, Card, Container, Grid, IconButton, Link, Menu, MenuItem, TextField, Typography } from '@mui/material'
import { HomeOutlined, Insights } from '@mui/icons-material'
import { ROUTES } from '../../../Routes/constants'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import { useGetEntity } from '../../../hooks/entityHooks'
import { useGetDataAttributesByEntityId } from '../../../hooks/dataAttrbutesHooks'
import {
  useAddDataQualityNB,
  useAddDataQualityRule,
  useGetActiveRules,
  useGetDataQualityCode,
  useGetDataQualityNB,
  useGetDataQualityRecommendations,
  useGetDataQualityRuleById,
  useUpdateDataQualityNB,
  useUpdateDataQualityRule,
} from '../../../hooks/dataQualityHooks'
import {
  CreateJobParams,
  CreateJobResponse,
  DataQualityCodeRequest,
  DataQualityNBRequest,
  DataQualityRule,
  DataQualityRuleRecomm,
  FabricNotebookCreateRequest,
  JobClusterConfig,
  JobDetails,
  OperationState,
  RuleSubCategory,
  RunStatusResponse,
} from '../../../businessObjects'
import { LucidJupyter } from '../../../ui-components/JupyterComponents/LucidJupyter'
import constants from '../../constants'
import { LoadingButton } from '@mui/lab'
import { usePostCreateDatabricksJob } from '../../../hooks/databricksHooks'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { filterActiveRunIds, initiateActiveRunId } from '../../../features/notification/notificationSlice'
import { useGetServiceConnection } from '../../../hooks/dataEngineeringHooks'
import { RootState } from '../../../app/store'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import TextFormInput from 'ui-components/TextForm/TextFormInput'
import AnimatedLoader from 'ui-components/AnimatedLoader'
import { useGetQueryString } from 'hooks/queryStringHook'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { PreviousPage } from "ui-components/Button/PreviousPage"
import { useCreateAndRunNewJob, useGetCreateJobPayload } from 'hooks/executionHooks'

type DataQualityPageParams = {
  dataPodId: string
  dataSystemID: string
  entityID: string
  tableID: string
  dataQualtiyID?: string
}

type FormErrors = {
  ruleName?: string
  ruleCategory?: string
  ruleSubCategory?: string
  entity?: string
  attributes?: string
}

export const DataQuality = () => {
  const { dataPodId, entityID, dataQualtiyID } = useParams<DataQualityPageParams>()
  const attributeId = useGetQueryString('attributeId')
  const categoryName = useGetQueryString('categoryName')
  //  console.log({categoryName})

  const currentDataQualityId = dataQualtiyID ? Number(dataQualtiyID) : undefined

  // const [errorMessage, setErrorMessage] = useState<string>()
  // const [errorState, setErrorState] = useState<boolean>(false)

  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [popupPromiseResolver, setPopupPromiseResolver] = useState<((value: boolean) => void) | null>(null)

  const [commitMessage, setCommitMessage] = useState<string>(constants.initialCommitMessage)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isEntityMenuOpen, setIsEntityMenuOpen] = useState<boolean>(false)
  const [userInputEntity, setUserInputEntity] = useState<string>('')
  const [isAttrMenuOpen, setIsAttrMenuOpen] = useState<boolean>(false)
  const [userInputAttr, setUserInputAttr] = useState<string>('')
  const [notebookStr, setNotebookStr] = useState<string>()
  const [userInputRuleSubCategory, setUserInputRuleSubCategory] = useState<string>('')
  const [proccessingReq, setProcessingReq] = useState<boolean>(false)

  const [resultState, setResultState] = useState<OperationState>()

  const { activeRules, isActiveRulesloading, isActiveRulesError } = useGetActiveRules()
  const { dataQualityRule, isDataQualityRuleLoading, isDataQualityRuleError } = useGetDataQualityRuleById(
    Number(dataQualtiyID),
  )
  const { dataQualityNB, isGetDataQualityNBError, fetchDataQualityNB } = useGetDataQualityNB(
    dataPodId,
    Number(dataQualtiyID),
  )
  const { existingRecommendations, loadingCurrent } = useGetDataQualityRecommendations(
    dataPodId,
    entityID ? Number(entityID) : undefined,
    attributeId ? Number(attributeId) : undefined,
  )

  const DQfromDB = useMemo(() => {
    if (!existingRecommendations.length) return []
    try {
      const allAtr = existingRecommendations.map((dqr) => dqr.dataQualityData)
      let allDQRules: DataQualityRuleRecomm[] = []

      allAtr.forEach((at) => {
        const atrep: DataQualityRuleRecomm[] = JSON.parse(at)
        allDQRules = [...allDQRules, ...atrep]
      })

      // console.log({allDQRules})

      return allDQRules
    } catch (error) {
      return []
    }
  }, [existingRecommendations])

  const { addDataQualityRule } = useAddDataQualityRule()
  const { updateDataQualityRule } = useUpdateDataQualityRule()

  const { addDataQualityNB } = useAddDataQualityNB()
  const { updateDataQualityNB } = useUpdateDataQualityNB()

  const { getConnections } = useGetServiceConnection()

  const {getCreateJobPayload} = useGetCreateJobPayload()
  const {createAndRunNewJob} = useCreateAndRunNewJob()

  const [dataQualityId, setDataQualityId] = useState<number>()
  const [dataQualityName, setDataQualityName] = useState<string>()

  const { dataQualityCodeResponse, dataQualityCodeLoading, dataQualityCodeError, fetchDataQualityCode } =
    useGetDataQualityCode()

  const [entities] = useGetEntity({}, dataPodId, undefined, undefined)
  const { attributeProfiles, refetchAttributes } = useGetDataAttributesByEntityId(Number(entityID))
  const [suggestSubRules, setSuggestSubRules] = useState<RuleSubCategory[]>([])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const [formState, setFormState] = useState<DataQualityRule>({
    id: currentDataQualityId || 0,
    name: '',
    ruleCategoryId: 0,
    ruleSubCategoryId: 0,
    entityId: Number(entityID),
    attributeIds: [],
    targetAttribute: '',
    promptText: '',
  })
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null)

  const dispatch = useAppDispatch()

  //Type: {1: 'Transformation1', 2: 'Transformation2'}
  const ruleCategory: { [key: number]: string } = useMemo(() => {
    const result: { [key: number]: string } = {}

    if (!activeRules) {
      return result
    }

    Object.keys(activeRules).forEach((rule) => {
      const subRules = activeRules[rule]

      if (!subRules.length) {
        return
      }

      result[subRules[0].ruleCategoryId] = rule
    })

    return result
  }, [activeRules])

  const navigate = useNavigate()

  useEffect(() => {
    if (DQfromDB.length && attributeId && !userInputAttr.length) {
      const preSelectAttribute = DQfromDB[0].Attributes[0]

      const preSelect = preSelectAttribute.padStart(preSelectAttribute.length + 1, '@')

      setUserInputAttr(preSelect)
    }
  }, [DQfromDB, attributeId])

  useEffect(() => {
    if (categoryName && DQfromDB.length && activeRules) {
      console.log({ activeRules })
      const ruleCategory = DQfromDB.find((c) => c.CategoryName === categoryName)
      if (ruleCategory && activeRules[categoryName].length) {
        const selcat = activeRules[categoryName]
        const selsubCategor = selcat.find((sub) => sub.name === ruleCategory?.SubcategoryName)
        if (selsubCategor && ruleCategory) {
          setFormState((prev) => ({
            ...prev,
            ruleCategoryId: selsubCategor?.ruleCategoryId,
            ruleSubCategoryId: selsubCategor.id,
            promptText: ruleCategory.RuleDescription + '\n ' + ruleCategory.RuleExplanation,
          }))
        }
      }
    }
  }, [activeRules, DQfromDB, categoryName])
  useEffect(() => {
    if (dataQualityNB) {
      setNotebookStr(dataQualityNB)
    }
  }, [dataQualityNB])

  useEffect(() => {
    if (dataQualityCodeResponse) {
      setNotebookStr(dataQualityCodeResponse)
    }
  }, [dataQualityCodeResponse])

  useEffect(() => {
    if (currentDataQualityId && dataQualityRule) {
      setDataQualityId(currentDataQualityId)
      setFormState(dataQualityRule)
      let attrNames = ''
      dataQualityRule?.attributeIds.forEach((attributeId) => {
        const attr = attributeProfiles.find((attr) => attr.id == attributeId)

        attrNames = attr ? attrNames.concat(`@${attr.dataAttributeName} `) : attrNames
      })

      setUserInputAttr(attrNames)
      setDataQualityName(dataQualityRule.name)
      setIsEditMode(true)
    }
  }, [dataQualityRule, attributeProfiles])

  useEffect(() => {
    if (entities) {
      const defaultEntity = entities.find((ele) => ele.id == Number(entityID))

      if (defaultEntity) {
        setUserInputEntity(`@${defaultEntity.dataEntityName}`)
      }
    }
  }, [entities, entityID])

  useEffect(() => {
    let result: RuleSubCategory[] = []

    if (formState.ruleCategoryId && !(activeRules == undefined))
      result = activeRules[ruleCategory[formState.ruleCategoryId]]

    setSuggestSubRules(result)
  }, [formState.ruleCategoryId, activeRules])

  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
          const parsedJson = JSON.parse(jsonTemplate)
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
            jobId: 0,
            notebookParams: {}
          }
          setJobClusterParams(params)
        }
      }
    }

    fetchJobClusterParams()
  }, [])

  // const closeErrorMessage = () => {
  //   setErrorState(false)
  //   setErrorMessage(undefined)
  // }

  const onClickHome = () => {
    const dataAttributePage = generatePath(ROUTES.DataModelingCanonicalModel, {
      dataPodId: dataPodId,
    })
    navigate(dataAttributePage)
  }

  const onSelectRule = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(e.target.value)

    setFormState((prevValue) => ({ ...prevValue, ruleCategoryId: id }))
  }

  const onEntityTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    validateEntityField(value)

    setUserInputEntity(value)

    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
      setIsEntityMenuOpen(true)
    } else {
      setAnchorEl(null)
      setIsEntityMenuOpen(false)
    }
  }

  const handleSelectEntity = (entityName: string, id: number) => {
    const columnStr = userInputEntity.replace(/@$/, `@${entityName}`)

    if (validateEntityField(columnStr)) {
      // refetchAttributes(id)
    }

    setUserInputEntity(columnStr)
  }

  const validateEntityField = (value: string): boolean => {
    let errorMsg = ''

    if (!value) {
      errorMsg = 'This field is required'
    } else if (/[^@]*@[^@]*@/.test(value)) {
      errorMsg = 'Only one entity is allowed.'
    } else if (!/^@[a-zA-Z]+$/.test(value)) {
      errorMsg = 'Input must start with "@" and contain only letters.'
    }

    setFormErrors((prevValue) => ({
      ...prevValue,
      entity: errorMsg,
    }))

    return errorMsg === ''
  }

  const transformationText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // setIsEditMode(true)
    setUserInputAttr(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
      setIsAttrMenuOpen(true)
    } else {
      setAnchorEl(null)
      setIsAttrMenuOpen(false)
    }
    // if (currentAttribeId && !isEditMode) {
    //   setIsEditMode(true)
    // }
  }

  const handleSelectAttributeMention = (columnName: string) => {
    const columnStr = userInputAttr.replace(/@$/, `@${columnName}`).concat(' ')
    setUserInputAttr(columnStr)
    // if (currentAttribeId && !isEditMode) {
    //     setIsEditMode(true)
    // }
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setIsEntityMenuOpen(false)
    setIsAttrMenuOpen(false)
  }

  const hasFormError = (formField: keyof FormErrors, formErrorsState: FormErrors): boolean => {
    const error = formErrorsState[formField]

    return error !== undefined && error.length > 0
  }

  const onClickSave = async () => {
    const attributeId_list = getUserInputAttrIds()
    setProcessingReq(true)
    if (isEditMode) {
      // Wait till user inputs commits message
      if (notebookStr) {
        const isConfirmed = await waitForPopupConfirmation()

        if (!isConfirmed) return
      }

      const response = await updateDataQualityRule({ ...formState, attributeIds: attributeId_list })

      // if (response) {
      await saveNotebook(formState.id)
      setProcessingReq(false)
      // }
    } else {
      const response = await addDataQualityRule({ ...formState, attributeIds: attributeId_list, id: 0 })

      if (response) {
        setDataQualityId(response.id)
        setDataQualityName(response.name)
        setFormState({ ...response })
        setIsEditMode(true)
        await saveNotebook(response.id)
        setProcessingReq(false)
      } else {
        setResultState({
          result: 'error',
          display: true,
          message: 'error adding Data Quality',
        })
      }
    }
  }

  const saveNotebook = async (id: number) => {
    await new Promise((resolve) => setTimeout(resolve, 0))

    const payload: DataQualityNBRequest = {
      dataPodId: dataPodId || '',
      dataQualityRuleId: id,
      notebookToRun: notebookStr || constants.defaultNotebookString,
      commitMessage: commitMessage,
    }

    if (isEditMode && notebookStr && dataQualityNB) {
      const isUpdated = await updateDataQualityNB(payload)
      if(isUpdated) {
        setResultState({
          display: true,
          result: 'success',
          message: 'Updated Data Quality Notebook',
        })
      } else {
        setResultState({
          display: true,
          result: 'error',
          message: 'Internal server error. Update failed.',
        })
      }
    } else {
      const isAdded = await addDataQualityNB(payload)
      if(isAdded) {
        setResultState({
          display: true,
          result: 'success',
          message: 'Saved Data Quality & Notebook',
        })
      } else {
        setResultState({
          display: true,
          result: 'error',
          message: 'Internal server error. Notebook save failed.',
        })
      }
    }
  }

  const waitForPopupConfirmation = (): Promise<boolean> => {
    return new Promise((resolve) => {
      setPopupPromiseResolver(() => resolve)
      setShowPopUp(true)
    })
  }

  const handlePopupConfirm = () => {
    if (popupPromiseResolver) {
      popupPromiseResolver(true)
      setPopupPromiseResolver(null)
      // setProcessingReq(false)
    }
    setShowPopUp(false)
  }

  const handlePopupCancel = (close: boolean) => {
    if (popupPromiseResolver) {
      popupPromiseResolver(close)
      setPopupPromiseResolver(null)
      setProcessingReq(false)
    }
    setShowPopUp(close)
    setProcessingReq(false)
  }

  const onGenerateCode = async () => {
    const attributeId_list = getUserInputAttrIds()
    setProcessingReq(true)
    const subRule = suggestSubRules.find((rule) => rule.id === formState.ruleSubCategoryId)
    const cleansing_rule = `${ruleCategory[formState.ruleCategoryId]} - ${subRule?.name}:${subRule?.description}`
    const payload: DataQualityCodeRequest = {
      dataPodId: dataPodId || '',
      entityId: formState.entityId,
      attributeId_list,
      cleansing_rule,
      promptText: formState.promptText,
      targetAttributeName: formState.targetAttribute || '',
      language: 'pyspark',
    }

    await fetchDataQualityCode(payload)
    setProcessingReq(false)
  }

  const getUserInputAttrIds = () => {
    const attributeIds: number[] = []
    const attrNames = userInputAttr.split('@').map((attrName) => attrName.trim())
    attrNames.forEach((attrName) => {
      const attr = attributeProfiles.find((attr) => attr.dataAttributeName === attrName)
      if (attr) {
        attributeIds.push(attr.id)
      }
    })

    setFormState((prevValue) => ({ ...prevValue, attributeIds }))

    return attributeIds
  }

  const executeNotebook = async () => {
    setProcessingReq(true)
    if (dataPodId && formState.id && dataQualityName && notebookStr) {
      const databricksParams: CreateJobParams = {
        dataPodId,
        measureID: formState.id
      }

      const fabricParams: FabricNotebookCreateRequest = {
        workspaceId: '',
        displayName: dataPodId + "_" + dataQualityName || '',
        description: `Executing measure ${dataQualityName} for datapodId ${dataPodId}`,
        accessToken: '',
        code: notebookStr,
        executionData: {},
        environmentId: '',
      }

      const routePage = generatePath(ROUTES.DataModelingCanonicalModelEditDataQuality, {
        dataPodId: dataPodId,
        entityID: entityID,
        dataQualtiyID: dataQualityId,
      })

      const jobDetails: JobDetails = {
        triggerType: 'Data Quality Code Execution',
        parameters: {
          id: dataQualityId,
          name: dataQualityName,
          route: routePage,
        },
      }

      try {
        const payload = await getCreateJobPayload(dataPodId, databricksParams, fabricParams)
  
        if(payload) {
          await createAndRunNewJob(jobDetails, payload)
        } else {
          setResultState({
            display: true,
            message: 'Failed to execute notebook.',
            result: 'error',
          })
        }
      } catch (error: any) {
        setResultState({
          display: true,
          message: error.message,
          result: 'error',
        })
      } finally {
        setProcessingReq(false)
      }
    }
  }

  const updateRunIdState = (result: CreateJobResponse) => {
    const activeRunId = activeRunIds.find((element) => element.runId === result.runId)
    const routePage = generatePath(ROUTES.DataModelingCanonicalModelEditDataQuality, {
      dataPodId: dataPodId,
      entityID: entityID,
      dataQualtiyID: dataQualityId,
    })
    if (!activeRunId) {
      const newRunId: RunStatusResponse = {
        RunStaturesponsesId: Date.now(),
        runId: result.runId,
        state: {
          lifeCycleState: 'INITIATED',
          resultState: 'LOADING',
        },
        params: {
          runId: result.runId,
          url: result.url,
          token: result.token,
        },
        jobDetails: {
          triggerType: 'Data Quality Code Execution',
          parameters: {
            id: dataQualityId,
            name: dataQualityName,
            route: routePage,
          },
        },
      }

      dispatch(filterActiveRunIds())
      dispatch(initiateActiveRunId(newRunId))
    }
  }
  const onClickBack = (event: any): void => {
    onClickHome()
  }

  const onPreviewButtonClick = async () => {
    const columProfileRoute = generatePath(ROUTES.DataModelingCanonicalDataPreview, {
      dataPodId: dataPodId,
      dataEntityID: Number(entityID),
    })
    navigate(columProfileRoute)
  }

  return (
    <>
      <ResultStatus
        showStatus={!!resultState?.display}
        alertMessage={resultState?.message}
        severtiy={resultState?.result}
        closeStatus={() => setResultState({ display: undefined, message: undefined, result: undefined })}
      />

      <ActionPage>
        <AnimatedLoader height={'30%'} width={'40%'} loading={proccessingReq} />
        {/* <Grid item xs={12} container justifyContent={'space-between'}>
          <Grid item xs={2}>
            <HeadingLabel headingText={'Data Quality'} />
          </Grid>
          <Grid item xs={1.5}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>

        </Grid> */}
        <Grid
          item
          container
          xs={15}
          alignItems="center"
          spacing={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-start'}
        >
          <Grid item xs={2}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h3" color="primary" gutterBottom>
              Data Quality
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container sx={{mr:1, ml:1}} item xs={16} > */}
        <Grid container item xs={16} alignItems={'stretch'} alignContent={'center'} spacing={2} paddingTop={'10px'}>
          <Grid item xs={15} />

          {/* <Grid item xs={6.5} sx={{mr:-10, pl: 1}} alignItems="stretch" container spacing={2}> */}
          <Grid
            item
            xs={5}
            container
            alignItems={'stretch'}
            alignContent={'baseline'}
            spacing={1}
            sx={{ pl: 5, flex: 2 }}
          >
            <Grid item xs={9}>
              <Typography variant="h5" color="primary" gutterBottom>
                Entity Name
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-entity"
                color="secondary"
                size="small"
                rows={1}
                disabled={true}
                error={hasFormError('entity', formErrors)}
                helperText={hasFormError('entity', formErrors) ? formErrors.entity : ''}
                multiline
                value={userInputEntity}
                onChange={onEntityTextChange}
                required
              />
              <Menu anchorEl={anchorEl} open={isEntityMenuOpen} onClose={handleMenuClose}>
                {entities
                  .filter((cm) => !userInputEntity.split('@').includes(cm.dataEntityName))
                  ?.map((ea, ix) => (
                    <MenuItem
                      key={ea.id + ix + ea.dataEntityName}
                      onClick={() => {
                        handleSelectEntity(ea.dataEntityName, ea.id)
                        handleMenuClose()
                      }}
                    >
                      {ea.dataEntityName}
                    </MenuItem>
                  ))}
              </Menu>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5" color="primary" gutterBottom>
                Attributes <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-attribute"
                color="secondary"
                size="small"
                rows={2}
                helperText={'Type @ to mention'}
                multiline
                value={userInputAttr}
                onChange={transformationText}
                required
              />
              <Menu anchorEl={anchorEl} open={isAttrMenuOpen} onClose={handleMenuClose}>
                {attributeProfiles
                  .filter((cm) => !userInputAttr.split('@').includes(cm.dataAttributeName))
                  ?.map((ea, ix) => (
                    <MenuItem
                      key={ea.id + ix + ea.dataAttributeName}
                      onClick={() => {
                        handleSelectAttributeMention(ea.dataAttributeName)
                        handleMenuClose()
                      }}
                    >
                      {ea.dataAttributeName}
                    </MenuItem>
                  ))}
              </Menu>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5" color="primary" gutterBottom>
                Rule Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                value={formState.name}
                onChange={({ target }) => setFormState((prevValue) => ({ ...prevValue, name: target.value }))}
                required
                disabled={isEditMode}
              />
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5" color="primary" gutterBottom>
                Rule Category <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-ruleCategory"
                color="secondary"
                size="small"
                select
                value={formState.ruleCategoryId || ''}
                onChange={onSelectRule}
                required
              >
                <MenuItem></MenuItem>
                {Object.keys(ruleCategory).length > 0 &&
                  Object.keys(ruleCategory).map((id) => (
                    <MenuItem key={id} value={Number(id)}>
                      {ruleCategory[Number(id)]}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5" color="primary" gutterBottom>
                Rule Sub-Category <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-ruleSubCategory"
                color="secondary"
                size="small"
                select
                value={formState.ruleSubCategoryId || ''}
                // error={!isValidEntry(newAttibute.dataAttributeName)}
                onChange={({ target }) =>
                  setFormState((prevValue) => ({ ...prevValue, ruleSubCategoryId: Number(target.value) }))
                }
                required
              >
                <MenuItem></MenuItem>
                {suggestSubRules.map((subRule) => (
                  <MenuItem key={subRule.id} value={subRule.id}>
                    {subRule.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5" color="primary" gutterBottom>
                Target attribute
              </Typography>
              <TextField
                fullWidth
                id="outlined-text-attribute"
                color="secondary"
                size="small"
                value={formState.targetAttribute}
                onChange={({ target }) =>
                  setFormState((prevValue) => ({ ...prevValue, targetAttribute: target.value }))
                }
                required
              />
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5" color="primary" gutterBottom>
                <span style={{ color: 'red' }}>*</span> Enter Prompt Text
              </Typography>
              <TextField
                multiline
                rows={5}
                fullWidth
                value={formState.promptText}
                onChange={({ target }) => setFormState((prevValue) => ({ ...prevValue, promptText: target.value }))}
              />
            </Grid>

            <Grid item xs={10} container spacing={2}>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <Button variant="contained" fullWidth onClick={onClickHome}>
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={4}>
                <Button
                  variant="contained"
                  disabled={
                    dataQualityCodeLoading 
                    || !formState.promptText
                    || !formState.name
                  }
                  fullWidth
                  onClick={onClickSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={6.5} sx={{ml:-3, pr:1}} container alignContent="baseline" > */}
          <Grid item xs={7} alignItems={'stretch'}>
            <Card sx={{ width: '100%' }}>
              {/* <Grid item xs={12}>
                <Typography variant="h5" color="primary" gutterBottom>
                  Code Editor
                </Typography>
              </Grid> */}

              {/* <Card sx={{ width: '100%', height: '90%', maxHeight: "100%" }}> */}
              {/* <AnimatedLoader height="100%" width="100%" loading={dataQualityCodeLoading} /> */}
              {/* <Grid item container xs={15} sx={{ pt: 1 }} spacing={0.5} alignItems="baseline"> */}

              {/* <Grid item container xs={10} flexDirection={'row'} spacing={0.5}>
                  <Grid item xs={7} alignContent={'flex-end'}>
                    <LoadingButton
                      loading={dataQualityCodeLoading}
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={!dataQualityId}
                      onClick={onGenerateCode}
                      sx={{width:'50%'}}
                    >
                      Generate code
                    </LoadingButton>

                  </Grid>
                </Grid> */}
              <Grid display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <LoadingButton
                    loading={dataQualityCodeLoading}
                    variant="contained"
                    color="primary"
                    disabled={!dataQualityId}
                    onClick={onGenerateCode}
                  >
                    Generate code
                  </LoadingButton>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="outlined" onClick={onPreviewButtonClick} disabled={!entityID}>
                    Data Preview
                  </Button>
                </Grid>
              </Grid>
              <Container>
                <LucidJupyter
                  executeNotebook={executeNotebook}
                  noteBookString={notebookStr || constants.defaultNotebookString}
                  headingText={constants.codeHeaderText}
                  updateNoteBookString={setNotebookStr}
                  commitMessage={commitMessage}
                  updateCommitMessage={setCommitMessage}
                />
              </Container>
              {/* </Grid> */}

              {/* </Card> */}
            </Card>
          </Grid>
        </Grid>

        <LucidPopUp
          showCloseIcon
          closePopup={handlePopupCancel}
          openPopUp={showPopUp}
          onConfirm={handlePopupConfirm}
          headingText=""
          confirmText="Commit"
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Link href={'https://github.com/Lucid-Data-Hub/gen-datalake-dev'} target="blank">
                Git Hub Repository
              </Link>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter Commit Message"
                variant="outlined"
                color="secondary"
                required
                rows={2}
                multiline
                value={commitMessage}
                onChange={({ target }) => setCommitMessage(target.value)}
              />
            </Grid>
          </Grid>
        </LucidPopUp>
      </ActionPage>
    </>
  )
}
