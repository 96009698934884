import { Grid, IconButton, Menu, MenuItem, TextField, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'

import { Entity, EntityMapping, ResultStatusSeverity } from 'businessObjects'

import { isValidDescription, isValidEntry } from 'utils/constants'

import { useDeleteEntityMap, useUpdateEntity } from 'hooks/entityHooks'
import ResultStatus from 'ui-components/ResultStatus'
import { DeletePopUp } from 'pages/DataProcess/DataMapping/DataMappingPopUps'
import { EditNote } from '@mui/icons-material'
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp'
import { set } from 'zod'

type Props = {
  dataPodId: string
  dataSystemId: number
  tableId: number
  entityMap: EntityMapping
  setEntityMap: React.Dispatch<React.SetStateAction<EntityMapping | undefined>>
}
type EntityMenuOptions = 'Edit current entity content' | 'Remap to existing entity' | 'Add New' | 'Delete'

export const DataProfilingEntityMenu = ({ dataPodId, dataSystemId, tableId, entityMap, setEntityMap }: Props) => {
  const [showPopUp, setShowPop] = useState<boolean>(false)
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [editWarning, setEditWarning] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
  const [formEntityMap, setFormEntityMap] = useState<EntityMapping>()

  const [entityMenuAnchor, setEntityMenuAnchor] = useState<null | HTMLElement>(null)

  const [isShowStatus, setIsShowStatus] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('info')
  const [resultStatusMessage, setResultStatusMessage] = useState<string>(`Info: Select Entity to perform the action`)

  const entityMenuOptions: EntityMenuOptions[] = [
    'Edit current entity content',
    'Remap to existing entity',
    'Add New',
    'Delete',
  ]

  const { deleteEntityMapId } = useDeleteEntityMap()

  const navigate = useNavigate()

  const { updateEntityFunc } = useUpdateEntity()

  useEffect(() => {
    setFormEntityMap(entityMap)
  }, [entityMap])

  const onEntitySelectionClick = (option: EntityMenuOptions) => {
    setEntityMenuAnchor(null)
    switch (option) {
      case 'Edit current entity content':
        setFormEntityMap(entityMap)
        setEditWarning(true)
        setShowPop(true)
        break
      case 'Add New':
        const page = generatePath(ROUTES.DataMappingEditNewEntity, {
          dataPodId: dataPodId,
          dataSystemID: dataSystemId,
          entityID: entityMap.dataEntityId,
          dataTableId: entityMap.dataTableId,
        })
        navigate(page)
        break
      case 'Remap to existing entity':
        const RemapPage = generatePath(ROUTES.DataMappingEditExistingRemap, {
          dataPodId: dataPodId,
          dataSystemID: dataSystemId,
          entityID: entityMap.dataEntityId,
          dataTableId: entityMap.dataTableId,
        })
        navigate(RemapPage)
        break
      case 'Delete':
        setShowDeleteWarning(true)
        break
    }
  }

  const closePopup = (close: boolean) => {
    setFormEntityMap(entityMap)
    setShowPop(close)
    setEditWarning(close)
  }

  const updateEntity = async () => {
    if (entityMap) {
      const updatedEntity = await updateEntityFunc({
        id: entityMap.dataEntityId,
        dataEntityName: formEntityMap?.dataEntityName ?? '',
        description: formEntityMap?.description ?? '',
        profileName: 'ManualAdjustment',
        profileState: 'Success',
        coreEntity: false,
        entityType: entityMap.entityType,
        dataLakeZone: 1,
      })
      if (updatedEntity) {
        setEntityMap({
          ...entityMap,
          dataEntityName: updatedEntity.dataEntityName,
          description: updatedEntity.description,
          entityType: entityMap.entityType,
        })
        setSaveSuccess(true)
        setEditWarning(false)
        setTimeout(() => setSaveSuccess(false), 5000)
        return true
      }
      return false
    }
  }

  const onSubmit = async () => {
    const success = await updateEntity()
    if (success) {
      setShowPop(false)
      setEditWarning(false)
      setEntityMenuAnchor(null)
      // setEditOptions('')
    }
  }

  const onChange = (name: string, value: string) => {
    setFormEntityMap((prv) => ({ ...prv, [name]: value } as EntityMapping))
  }

  const confirmDeleteEntityMap = async () => {
    if (entityMap.id) {
      const deletedMapId = await deleteEntityMapId(entityMap.id)
      if (deletedMapId) {
        setDeleteSuccess(true)
        setEntityMap(undefined)
        setFormEntityMap(undefined)
        closeDeleteWarning()
      } else {
        setSeverity('error')
        setResultStatusMessage(`Internal Server Error: Failed to delete Entity Map - ${entityMap.dataEntityName}`)
        setIsShowStatus(true)
      }
    }
  }

  const closeDeleteWarning = () => {
    setShowDeleteWarning(false)
  }

  const invalidEntries = useMemo(() => {
    return !isValidDescription(formEntityMap?.description) || !isValidEntry(formEntityMap?.dataEntityName)
  }, [formEntityMap?.dataEntityName, formEntityMap?.description])

  return (
    <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
      <ResultStatus 
        severtiy={severity} 
        showStatus={isShowStatus} 
        closeStatus={setIsShowStatus} 
        alertMessage={resultStatusMessage} 
      />
      <ResultStatus
        severtiy="success"
        showStatus={deleteSuccess}
        closeStatus={setDeleteSuccess}
        alertMessage={`SUCCESS: Deleted Entity Map : ${entityMap.dataEntityName}`}
      />
      <ResultStatus
        severtiy="success"
        showStatus={saveSuccess}
        closeStatus={() => setSaveSuccess(false)}
        alertMessage="Successfully saved!"
      />
      {showPopUp && (
        <ResultStatus
          severtiy="warning"
          showStatus={editWarning}
          closeStatus={setEditWarning}
          alertMessage={`Warning: Editing Entity "${entityMap.dataEntityName}" will impact associated attributes.`}
        />
      )}
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={confirmDeleteEntityMap}
        closeDeleteWarning={closeDeleteWarning}
        itemToDelete={entityMap.dataEntityName}
      />
      <>
        <IconButton title="Filter" onClick={({ currentTarget }) => setEntityMenuAnchor(currentTarget)} size="small">
          <EditNote fontSize="small" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={entityMenuAnchor}
          open={Boolean(entityMenuAnchor)}
          onClose={() => setEntityMenuAnchor(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            'aria-multiselectable': 'true',
          }}
          slotProps={{
            paper: {
              elevation: 2,
            },
          }}
        >
          {entityMenuOptions.map((itm: any) => (
            <MenuItem
              // selected={filter2Applied === itm}
              key={itm}
              onClick={() => onEntitySelectionClick(itm)}
            >
              {itm}
            </MenuItem>
          ))}
        </Menu>
      </>
      <>
        <LucidPopUp
          openPopUp={showPopUp}
          onConfirm={onSubmit}
          closePopup={() => closePopup(!showPopUp)}
          headingText={<Typography variant="h4">Edit and Fine-Tune</Typography>}
          confirmText="Save"
          disabledConfirm={invalidEntries}
          showCloseIcon
        >
          <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={1.5}>
            <Grid item xs={10}>
              <Typography color={'text.primary'} variant="caption">
                <span style={{ color: 'red' }}>*</span> Indicates a required field
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography color={'text.primary'}>Table Name</Typography>
            </Grid>

            <Grid item xs={10}>
              <TextField
                fullWidth
                id="outlined-select-industry"
                placeholder="Table Name"
                color="secondary"
                size="small"
                disabled
                // rows={2}
                // multiline
                value={entityMap?.dataTableName ?? ''}
                //onChange={({ target }) => onChange('dataSystemDesc', target.value)}
              />
            </Grid>

            <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={1.5}>
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Entity Name
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="edit-current-entityName-id"
                  placeholder="Enter Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onChange={({ target }) => onChange('dataEntityName', target.value)}
                  value={formEntityMap?.dataEntityName ?? ''}
                  error={!isValidEntry(formEntityMap?.dataEntityName)}
                  helperText={!isValidEntry(formEntityMap?.dataEntityName) ? ' Invalid Entry' : ''}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Entity Description
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="edit-entity-description-id"
                  placeholder="Enter Description"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  rows={3}
                  multiline
                  error={!isValidDescription(formEntityMap?.description)}
                  helperText={!isValidDescription(formEntityMap?.description) ? ' Invalid Entry' : ''}
                  onChange={({ target }) => onChange('description', target.value)}
                  value={formEntityMap?.description ?? ''}
                />
              </Grid>
            </Grid>
          </Grid>
        </LucidPopUp>
      </>
    </Grid>
  )
}
