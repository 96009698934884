import { useState } from "react"
import { useLucidAuthContext } from "../LucidAuthContext/LucidAuthContext"
import { databricksServices } from "../apiServices/databricks"
import { CreateJobParams, CreateJobResponse, NotebookParams, RunIdStatus, RunNewJobParams, RunStatusResponse } from "../businessObjects"
import axios, { AxiosError } from "axios"


export const useGetDatabricksRunStatus = (): {fetchRunStatus: (runIdStatus: RunIdStatus[]) => Promise<RunStatusResponse[] | undefined>} => {
    const { getBearerToken } = useLucidAuthContext()

    const fetchRunStatus = async (runIdStatus: RunIdStatus[]): Promise<RunStatusResponse[] | undefined> => {
      try {
        const bearerToken = await getBearerToken()
        if (bearerToken) {
            const { data, status } = await databricksServices.getRunStatus(bearerToken, runIdStatus)
            if (status == 200) {
              return data;
            }
          }
        } catch (error) {
            console.log("Error in getting Job Status", error);
        }
    }
    return {fetchRunStatus}
  }

export const usePostDatabricksJobRunNew = (): {postRunNewJob: (params: RunNewJobParams) => Promise<{status: number, data: string | number}>} => {
    const { getBearerToken } = useLucidAuthContext()

    const postRunNewJob = async (params: RunNewJobParams): Promise<{status: number, data: string | number}> => {
      try {
        const bearerToken = await getBearerToken()
        if (bearerToken) {
            const { data, status } = await databricksServices.postRunNewJob(bearerToken, params)
            if (status == 200) {
              return { status, data};
            }
            else {
              return {status, data: "Internal Server Error: Run New Job Failed."};
            }
          } else {
            return {status: 404, data: "Internal Server Error: No bearer token found."};
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // Axios-specific error handling
            console.error("Axios Error in getting Job Status:", {
              message: error.message,
              status: error.response?.status,
              data: error.response?.data,
            });
        
            return {status: error.response?.status || 500, data: error.response?.data?.error || error.message};
          } else {
            // Non-Axios error
            console.error("Unexpected Error in getting Job Status:", error);
            return {status: 500, data: "An unexpected error occurred."};
          }
        }
    }
    return {postRunNewJob}
  }

export const usePostCreateDatabricksJob = (): {postCreateDatabricksJob: (params: CreateJobParams) => Promise<{status: number, data: string | CreateJobResponse}>} => {
    const { getBearerToken } = useLucidAuthContext()

    const postCreateDatabricksJob = async (params: CreateJobParams): Promise<{status: number, data: string | CreateJobResponse}> => {
      try {
        const bearerToken = await getBearerToken()
        if (bearerToken) {
            const { data, status } = await databricksServices.postCreateJob(bearerToken, params)
            if (status == 200) {
              return { status, data};
            }
            else {
              return {status, data: "Internal Server Error: Run New Job Failed."};
            }
          } else {
            return {status: 404, data: "Internal Server Error: No bearer token found."};
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // Axios-specific error handling
            console.error("Axios Error in getting Job Status:", {
              message: error.message,
              status: error.response?.status,
              data: error.response?.data,
            });
        
            return {status: error.response?.status || 500, data: error.response?.data?.error || error.message};
          } else {
            // Non-Axios error
            console.error("Unexpected Error in getting Job Status:", error);
            return {status: 500, data: "An unexpected error occurred."};
          }
        }
    }
    return {postCreateDatabricksJob}
  }