import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import SearchIcon from '@mui/icons-material/Search'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useLucidAuthContext } from 'LucidAuthContext/LucidAuthContext'
import TuneIcon from '@mui/icons-material/Tune'
import { useGetCoreEntityAttributes } from 'hooks/coreEntityAttributeHooks'
import { useGetCoreEntity } from 'hooks/entityHooks'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'
import {
  AnalyticsGroup,
  AnalyticsMeasure,
  AnalyticsMeasureType,
  CoreEntity,
  DataAttribute,
  DimensionData,
  OperationState,
} from '../../businessObjects'
import { useGetAnalyticGroups } from '../../hooks/analyticsGroupHooks'
import {
  useGetAnalyticMeasure,
  useGetAnalyticMeasureByGroupMeasureId,
  useGetGenerateKPI,
  useGetMeasureDimensions,
  useSynchronizeMeasureDimensions,
} from '../../hooks/analyticsMeasureHooks'
import { useGetQueryString } from '../../hooks/queryStringHook'
import ActionPage from '../../ui-components/ActionPage'
import ResultStatus from '../../ui-components/ResultStatus'
import { isValidDescription, isValidEntry } from '../../utils/constants'
import GenerateMeasureNotebook from './GenerateMeasureNotebook'
import GroupEntities from './GroupEntities'
import SearchDropDown from 'ui-components/SearchDropDown'

interface UpdatedDataAttribute {
  id: number
  dataAttributeName: string
  dataEntityName: string | undefined | null
  combinedName: string
}

interface MeasureDimensionsProps {
  inputValue: string
  onInputChange: (value: string) => void
  attributesSuggestion: UpdatedDataAttribute[]
  coreEntityAttributes: UpdatedDataAttribute[]
}

interface Measure {
  analyticsMeasureId: number
  analyticsMeasureName: string
  analyticsGroupId: number
}

type ViewResultsParams = {
  dataPodId: string
}

const normalizeMeasureId = (measureId: string | null) => {
  return measureId && measureId.length > 0 ? Number(measureId) : undefined
}

const containsText = (text: string, searchText: string) => text.toLowerCase().includes(searchText.toLowerCase())

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

const AddAnalyticsMeasure = () => {
  const groupId = useGetQueryString('groupId')
  const measureId = useGetQueryString('measureId')
  const isEdit = useGetQueryString('action')
  const { dataPodId } = useParams<ViewResultsParams>()
  const [collapseForm, setCollapseForm] = useState<boolean>(true)
  const [groupSelected, setGroupSelected] = useState<number[]>([]);

  const [selectedGroup, selectGroup] = useState<string>('')
  const [measureName, setMeasureName] = useState<string>('')
  const [measureDescription, setMeasureDescription] = useState<string>('')
  const [relationsInput, setRelationsInput] = useState('')
  const [relationsGroupAnchorEl, setRelationsGroupAnchorEl] = useState(null)
  const [expandedGroup, setExpandedGroup] = useState<number | null>(null)
  const [selectedMeasures, setSelectedMeasures] = useState<{ [id: number]: number[] }>({})
  const [searchText, setSearchText] = useState('')
  const [analyticsGroupListData, setAnalyticsGroupListData] = useState<{ id: number; analyticsGroupName: string }[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null)
  const [filterOption, setFilterOption] = useState('')
  const { getBearerToken } = useLucidAuthContext()
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)

  const { analyticsGroupList } = useGetAnalyticGroups(dataPodId)

  const { analyticsMeasureList, analyticsGroupMeasureLoading } = useGetAnalyticMeasureByGroupMeasureId(
    dataPodId,
    groupId,
    measureId,
  )

  const [fetchCoreAttributes, coreAttributes] = useGetCoreEntityAttributes(dataPodId)


  const { analyticsMeasureLoading, updateAnalyticsMeasure, addAnalyticsMeasure, postMeasure, measureError } =
    useGetAnalyticMeasure(dataPodId)
  const data = useGetAnalyticMeasure(dataPodId)
  const [selectedAnalyticMeasure, setSelectAnalyticMeasure] = useState<AnalyticsMeasure>()
  const [resultState, setResultState] = useState<OperationState>()

  const navigate = useNavigate()

  const [inputValue, setInputValue] = useState<string>('')
  const [group_Id, setGroup_Id] = useState<string>()

  const [coreEntity, setCoreEntity] = useState<CoreEntity>()

  const { generateKPIResponse, generateKPILoading, generateKPIError, generateKPI } = useGetGenerateKPI(
    dataPodId,
    selectedAnalyticMeasure?.analyticsMeasureId,
    measureDescription.length > 0 ? measureDescription : undefined,
  )

  const { syncDimensionsError, synchronizeDimensions } = useSynchronizeMeasureDimensions(
    dataPodId,
    normalizeMeasureId(measureId),
  )


  const { getDimensionsResponse, getDimensionsError, getDimensionsLoading } = useGetMeasureDimensions(
    dataPodId,
    normalizeMeasureId(measureId),
  )

  const [groupEntityAttributes, setGroupEntityAttributes] = useState<UpdatedDataAttribute[]>([])

  const [coreEntities] = useGetCoreEntity(dataPodId)

  const castStringToDimensionalData = (input: string): DimensionData[] => {
    if (!input.length) return []

    const tempDimData: DimensionData[] = []

    if (coreEntity) {
      input.split('@').forEach((element) => {
        if (element.length == 0) {
          return
        }
        element = element.trim()

        const index = tempDimData.findIndex((entity) => entity.entityName === coreEntity.dataEntityName)

        if (index == -1) {
          tempDimData.push({ entityName: coreEntity.dataEntityName, attributes: [element] })
        } else {
          tempDimData[index].attributes.push(element)
        }
      })
    }
    return tempDimData
  }

  const castDimensionalDataToString = (dimensions: DimensionData[]) => {
    return dimensions.reduce((acc, dim) => {
      let tempAcc = acc
      dim.attributes.forEach((attr) => {
        tempAcc += `@${attr} `
      })

      return tempAcc
    }, '')
  }

  useEffect(() => {
    if (selectedGroup && coreEntities.length && analyticsGroupList.length) {
      const group = analyticsGroupList.find((group) => group.id == Number(selectedGroup))
      if (group) {
        const entity = coreEntities.find((entity) => entity.dataEntityName == group.analyticsGroupName)

        if (entity) {
          setCoreEntity(entity)
          fetchCoreAttributes(entity.id)
        }
      }
    }
  }, [selectedGroup, coreEntities, analyticsGroupList])

  useEffect(() => {
    if (coreAttributes) {
      if (coreEntity) {
        const mappedDimensions = coreAttributes.map((attr) => ({
          id: attr.id,
          dataPodId: attr.dataPodId,
          isActive: attr.isActive,
          dataEntityId: attr.dataCoreEntityId,
          dataAttributeName: attr.dataCoreAttributeName,
          dataAttributeDataType: attr.dataCoreAttributeDataType,
          description: attr.description,
          isBusinessKey: attr.isBusinessKey,
          dataClassification: attr.dataCoreClassification,
          dataAttributeType: attr.dataAttributeType,
          dataEntityName: coreEntity.dataEntityName,
          combinedName: `${coreEntity.dataEntityName}.${attr.dataCoreAttributeName}`,
        }))

        setGroupEntityAttributes(mappedDimensions)
      }
    }
  }, [coreAttributes])

  useEffect(() => {
    if (measureError) {
      setResultState({ result: 'error', display: true, message: measureError?.measure })
    } else if (generateKPIError) {
      setResultState({ result: 'error', display: true, message: generateKPIError?.measure })
    } else if (syncDimensionsError) {
      setResultState({ result: 'error', display: true, message: syncDimensionsError?.measure })
    } else if (getDimensionsError) {
      setResultState({ result: 'error', display: true, message: getDimensionsError?.measure })
    }
  }, [measureError, generateKPIError])



  useEffect(() => {
    if (measureId && analyticsMeasureList.length) {
      const { analyticsMeasureName, analyticsMeasureDescription, analyticsGroupId } = analyticsMeasureList[0]
      setSelectAnalyticMeasure(analyticsMeasureList[0])
      selectGroup(analyticsGroupId?.toString())
      setMeasureDescription(analyticsMeasureDescription)
      setMeasureName(analyticsMeasureName)
    }
  }, [analyticsMeasureList])

  useEffect(() => {
    if (groupId && analyticsGroupList.length) {
      selectGroup(groupId)
      setGroup_Id(groupId)
    }
  }, [groupId, analyticsGroupList])

  useEffect(() => {
    if (postMeasure?.id && groupId) {
      setResultState({ display: true, result: 'success', message: 'Added/Updated measure successfully' })
      //below selects mesaure details + group details
      setSelectAnalyticMeasure({ ...selectedAnalyticMeasure, ...postMeasure, analyticsMeasureId: postMeasure?.id })
    }
  }, [postMeasure])

  useEffect(() => {
    if (getDimensionsResponse) {
      const dimensionsString = castDimensionalDataToString(getDimensionsResponse)
      setInputValue(dimensionsString)
    }
  }, [getDimensionsResponse])

  const attributesSuggestion: UpdatedDataAttribute[] = useMemo(() => {
    if (inputValue && groupEntityAttributes.length) {
      const dimensions = castStringToDimensionalData(inputValue)

      return dimensions.flatMap((dimension) =>
        groupEntityAttributes.filter((attr) => !dimension.attributes.includes(attr.dataAttributeName)),
      )
    }

    return []
  }, [groupEntityAttributes, inputValue])

  const addNewMeasure = async () => {
    const payload: AnalyticsMeasureType = {
      measureGroupId: parseInt(selectedGroup),
      analyticsMeasureName: measureName,
      analyticsMeasureDescription: measureDescription,
      runId: 0,
      measureQuery: '',
    }
    if (selectedAnalyticMeasure?.analyticsMeasureId) {
      updateAnalyticsMeasure({
        id: selectedAnalyticMeasure?.analyticsMeasureId,
        ...payload,
      })
    } else {
      await addAnalyticsMeasure(payload)
    }

    callSyncDimensions()
  }

  useEffect(() => {
    if (measureError) {
      setResultState({ result: 'error', display: true, message: 'Failed to add New measure' })
      console.log('Failed to add New measure')
    }
  }, [measureError, addNewMeasure ])

  const callSyncDimensions = () => {
    const result = castStringToDimensionalData(inputValue)

    synchronizeDimensions(result)
  }

  const callGenerateKPI = () => {
    const result = castStringToDimensionalData(inputValue)

    if (result) {
      generateKPI(result)
    }
  }

  const onClickBack = () => {
    if (isEdit) {
      let measurePage = generatePath(ROUTES.DataAnalyticsMeasuresResults, {
        dataPodId: dataPodId,
      })

      if (groupId) {
        measurePage = measurePage + '?groupId=' + groupId
      }

      if (measureId) {
        measurePage = measurePage + '&measureId=' + measureId
      }

      navigate(measurePage)
    } else {
      let groupPage = generatePath(ROUTES.DataAnalyticsGroupResults, {
        dataPodId: dataPodId,
      })

      if (groupId) {
        groupPage = groupPage + '?groupId=' + groupId
      }

      navigate(groupPage)
    }
  }

  const handleSelectGroup = (groupVal: any) => {
    selectGroup(groupVal)
  }

  type ViewResultsParams = {
    dataPodId: string
  }

  const { getAnalyticsMeasure, analyticsMeasureList: myData } = useGetAnalyticMeasure(dataPodId)

  const mappedParentList = useMemo(() => 
    analyticsGroupListData.map(group => ({
      id: group.id,
      name: group.analyticsGroupName
    })),
    [analyticsGroupListData]
  );
  
  const mappedFilteredChildren = useMemo(() => 
    myData.map(measure => ({
      childId: measure.analyticsMeasureId,
      childName: measure.analyticsMeasureName,
      parentId: measure.analyticsGroupId
    })),
    [myData]
  );

  useEffect(() => {
    if (dataPodId) {
      getAnalyticsMeasure()
    }
  }, [dataPodId])

  useEffect(() => {
    setAnalyticsGroupListData(analyticsGroupList)
  }, [analyticsGroupList])

  
  const handleRelationsInputChange = (event: any) => {
    const value = event.target.value

    if (value.length < relationsInput.length) {
      if(relationsInput.endsWith('@')) {
        setRelationsInput(relationsInput.slice(0, relationsInput.length-2))
        return
      }

      // setRelationsInput(relationsInput?.trim())
      return
    }

    if (value.endsWith('@')) {

      if(!relationsInput.endsWith('@'))
        setRelationsInput(`${relationsInput}`)

      setRelationsGroupAnchorEl(event.currentTarget)
    } else {
      // setRelationsInput(value)
    }
  }

  // const handleRelationsInputChange = useCallback(
  //   (event: any) => {

  //     const value = event.target.value
  //     if (value.length < relationsInput.length) {
  //       setRelationsInput(value?.trim())
  //       return
  //     }

  //     if (value.endsWith('@')) {
  //       setRelationsInput(value)
  //       setRelationsGroupAnchorEl(event.currentTarget)
  //     } else {
  //       setRelationsInput(value)
  //     }
  //   },
  //   [relationsInput],
  // )

  const toggleGroupExpand = useCallback((groupId: any) => {
    setExpandedGroup((prevGroupId) => {
      setSearchText('')

      if (prevGroupId === groupId) {
        return null
      }
      return groupId
    })
  }, [])




  const handleFilterChange = (filterValue: string) => {
    setFilterOption(filterValue)
    setFilterAnchorEl(null) 
  }

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget)
  }

  const handleCloseFilterMenu = () => {
    setFilterAnchorEl(null)
  }

  const handleSelectMention = (attributeName: string) => {
    const promptText = inputValue.replace(/@$/, `@${attributeName}`)
    setInputValue(promptText)
    setAnchorEl(null) 
  }

  const transformationTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)

    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget) 
    } else {
      setAnchorEl(null) 
    }
  }

  return (
    <ActionPage>
      <Grid xs={3}>
        <Button variant="outlined" onClick={onClickBack} startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}>
          Back
        </Button>
      </Grid>
      <Grid item container xs={14} spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
        <CardHeader
          disableTypography
          title={
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
              <Typography variant="h4" color="primary">
                {measureId ? 'Update Analytics Measure' : 'Add Analytics Measure'}
              </Typography>
            </Stack>
          }
        />
      </Grid>
      <ResultStatus
        severtiy={resultState?.result}
        showStatus={!!resultState?.display}
        alertMessage={resultState?.message}
        closeStatus={() => setResultState(undefined)}
      />

      <Grid item container justifyContent={'center'} xs={14} spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={collapseForm ? 8 : 4}>
          <Card sx={{ p: 2 }}>
            <CardHeader
              sx={{ p: 0, mb: 2 }}
              title={
                <Typography variant="h5">
                  {selectedAnalyticMeasure?.analyticsMeasureId
                    ? 'Update Analytics Measure'
                    : 'Add New Analytics Measure'}
                </Typography>
              }
            />
            {analyticsGroupMeasureLoading ? (
              <>
                <Skeleton variant="rectangular" height={30} sx={{ p: 1, mb: 1 }} />
                <Skeleton variant="rectangular" height={30} sx={{ p: 1, mb: 1 }} />
                <Skeleton variant="rectangular" height={50} sx={{ p: 1, mb: 1 }} />
              </>
            ) : (
              <>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Measure Group
                  </Typography>
                  <Select
                    fullWidth
                    id="measure-groupId"
                    value={selectedGroup}
                    size="small"
                    onChange={({ target }) => handleSelectGroup(target?.value as any)}
                  >
                    {analyticsGroupList?.map((data: AnalyticsGroup) => (
                      <MenuItem key={data?.id} value={data?.id?.toString()}>
                        {data?.analyticsGroupName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Measure (KPI) Name
                  </Typography>
                  <TextField
                    rows={4}
                    fullWidth
                    id="measure-name"
                    variant="outlined"
                    size="small"
                    value={measureName}
                    onChange={({ target }) => setMeasureName(target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Prompt Text (Measure Description)
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    id="measure-description"
                    variant="outlined"
                    size="small"
                    value={measureDescription}
                    onChange={({ target }) => setMeasureDescription(target.value)}
                  />
                </Grid>
                <Grid>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          textAlign: 'justify',
                        },
                      },
                    }}
                    title="This source KPI related to measures"
                    placement={'right'}
                  >
                    <Typography component="span" variant="subtitle1">
                      Source KPIs
                    </Typography>
                  </Tooltip>
                  <Grid>
                    <TextField
                      value={relationsInput}
                      helperText={'Type @ to mention'}
                      placeholder="Enter measures to relate using '@' or leave blank."
                      fullWidth
                      multiline
                      rows={4}
                      onChange={handleRelationsInputChange}

                    />

                    <Menu
                      anchorEl={relationsGroupAnchorEl}
                      open={Boolean(relationsGroupAnchorEl)}
                      onClose={() => setRelationsGroupAnchorEl(null)}
                      PaperProps={{ style: { width: '40%' } }}
                    >
                      <SearchDropDown
                        parentList={mappedParentList}
                        filteredChildren={mappedFilteredChildren}
                        loading={loading}
                        error={error}
                        selectionInput={relationsInput}
                        setSelectionInput={setRelationsInput}
                        selectedChildren={selectedMeasures}
                        setSelectedChildren={setSelectedMeasures}
                        selectedParents={groupSelected}
                        setSelectedParents={setGroupSelected}
                        filterIcon={true}
                      />
                    </Menu>

                    <Menu
                      anchorEl={filterAnchorEl}
                      open={Boolean(filterAnchorEl)}
                      onClose={handleCloseFilterMenu}
                      PaperProps={{ style: { width: '25%' } }}
                    >
                      <MenuItem onClick={() => handleFilterChange('groups')}>Groups</MenuItem>
                      <MenuItem onClick={() => handleFilterChange('measures')}>Measures</MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          textAlign: 'justify',
                        },
                      },
                    }}
                    title="This measure is calculated against the following dimensions."
                    placement={'right'}
                  >
                    <Typography component="span" variant="subtitle1">
                      Measure Dimensions
                    </Typography>
                  </Tooltip>

                  <TextField
                    value={inputValue}
                    helperText={!inputValue ? 'Type @ to mention' : ''}
                    placeholder="Please enter the dimensions using the '@' operator or leave the field blank."
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{ style: { textAlign: 'justify' } }}
                    onChange={transformationTextChangeHandler}
                  />

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    PaperProps={{ style: { width: '20%' } }}
                  >
                    <Autocomplete
                      options={groupEntityAttributes}
                      getOptionLabel={(option) => `${option.dataAttributeName}`}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleSelectMention(newValue.dataAttributeName)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Search..." variant="outlined" size="small" fullWidth />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem
                          {...props}
                          key={option.id}
                          onClick={() => handleSelectMention(option.dataAttributeName)}
                        >
                          {option.dataAttributeName}
                        </MenuItem>
                      )}
                      style={{ width: '100%' }}
                    />
                  </Menu>
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }} textAlign={'end'}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addNewMeasure}
                    disabled={!selectedGroup || !isValidEntry(measureName) || !isValidDescription(measureDescription)}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Card>
        </Grid>

        <Grid item container xs={collapseForm ? 4 : 8}>
          <Grid item xs={12}>
            <GenerateMeasureNotebook
              measureId={selectedAnalyticMeasure?.analyticsMeasureId}
              measureName={selectedAnalyticMeasure?.analyticsMeasureName}
              groupId={group_Id}
              measureDescription={measureDescription}
              callSyncDimensions={callSyncDimensions}
              generateKPI={callGenerateKPI}
              generateKPILoading={generateKPILoading}
              generateKPIResponse={generateKPIResponse}
              isEditOrAddMode={true}
              collapseForm={collapseForm}
              setCollapseForm={setCollapseForm}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <GroupEntities />
          </Grid>
        </Grid>
      </Grid>
    </ActionPage>
  )
}

export default AddAnalyticsMeasure
