import AzureSvg from 'ui-icons/Azure.svg'
import AWSsvg from 'ui-icons/AWS.svg'
import GoogleCloudSvg from 'ui-icons/googleCloud.svg'
import AzureDataLakeSVG from 'ui-icons/azure-data-lake.svg'
import SQLsvg from 'ui-icons/sql.svg'
import AmazonS3 from 'ui-icons/amazon-s3.svg'
import Fabric from 'ui-icons/Fabric.svg'
import Workspace from 'ui-icons/Workspace.svg'

const constants = {
  defaultNotebookString: "# Code generation incomplete.\n# Please click 'Generate Code' to proceed.",
  codeHeaderText:
    'Code is generated using a Gen AI LLM Model and may contain errors. Please review carefully before committing to the source code repository or deploying it.',
  initialCommitMessage: 'Initial Commit',
  EMAIL_PATTERN: /^\w+([\.-]?\w+)+@([\.-]?\w+)+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/,
  defaultRoleId: 1,
  defaultEntityNotebook : (datapodId: string, entityId: number) => {
    return `# Save notebook before execution.

from luciddatahub.dataLoad import loadCanonical
execfile('/Workspace/LucidJobs/setup.py')

dataPodId = '${datapodId}'
dataEntityId = ${entityId}

canoncical = loadCanonical.LoadCanonicalZoneByEntity(dataPodId)
canoncical.loadCanonicalEntity(dataPodId,dataEntityId)`
  }
}

export enum DataPodCreationComponents {
  DataSource,
  DeployDataLake,
  'Deee',
}

export const DataPodCreation = {
  steps: [
    'Data Pod Details',
    // 'Data Source Location Details',
    // 'Target Data Lake',
    'Data Domains',
    'Service Connection Details',
    'Data Source Details',
    'Summary',
  ],

  iconMapping: {
    default: AzureSvg,
    'Azure Storage': AzureSvg,
    'Amazon Web Services': AWSsvg,
    'Google Cloud': GoogleCloudSvg,
    'Azure Data lake gen 2': AzureDataLakeSVG,
    'Azure SQL Database': SQLsvg,
    'Amazon S3': AmazonS3,
    'Microsoft Fabric': Fabric,
    Workspace: Workspace,
  } as any,
}

export const SignUp = {
  steps : [
    { label: 'Email Address', },
    { label: 'Account Details', },
    { label: 'User Details',  },
    { label: 'API Key', },
    { label: 'Confirmation', },
    { label: 'Complete', },
  ]
}

export default constants

Object.freeze(constants)
Object.freeze(DataPodCreation)
