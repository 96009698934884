import React, { useEffect, useState } from 'react';
import constants from 'pages/constants'; // Adjust the import path as necessary
import { Box, Button, Grid, Link, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LucidJupyter } from 'ui-components/JupyterComponents/LucidJupyter';
import { AddOutlined, KeyboardBackspaceOutlined } from '@mui/icons-material';
import { LucidTextLabel } from 'ui-components/LucidTextLabel';
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp';
import { LoadingButton } from '@mui/lab';
import { useAddNoteBook, useGetNoteBook, useUpdateNoteBook } from 'hooks/dataLoadHooks';
import { addNotebookRequest, CreateJobParams, Entity, FabricNotebookCreateRequest, JobDetails, ResultStatusSeverity } from 'businessObjects';
import ResultStatus from 'ui-components/ResultStatus';
import { ROUTES } from 'Routes/constants';
import { generatePath } from 'react-router-dom';
import { useCreateAndRunNewJob, useGetCreateJobPayload } from 'hooks/executionHooks';
// import { fetchEntityCode } from '../../api'; // Assume this function fetches the code from the repository

interface EntityCodeViewerProps {
  dataPodId: string;
  entity: Entity;
  setIsEntityCodeReview: (value: React.SetStateAction<boolean>) => void
  fetchedEntityCode: string | undefined;
  entityCode: string | undefined;
  setEntityCode: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const EntityCodeViewer: React.FC<EntityCodeViewerProps> = ({ dataPodId, entity, setIsEntityCodeReview, fetchedEntityCode, entityCode, setEntityCode }) => {
  const [commitMessage, setCommitMessage] = useState<string>(constants.initialCommitMessage);
  const [commitIds, setCommitIds] = useState<string[]>([]);
  const [selectedCommitId, setSelectedCommitId] = useState<string>('');
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  const {addNoteBookConvertedScript, flag: isNoteBookAdded} = useAddNoteBook(dataPodId)
  const {updateNotebook, flag: isNoteBookUpdated} = useUpdateNoteBook(dataPodId)
  
  const [isShowStatus, setIsShowStatus] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('info')
  const [resultStatusMessage, setResultStatusMessage] = useState<string>(`Info: Select Entity to perform the action`)

  const {getCreateJobPayload} = useGetCreateJobPayload()
  const {createAndRunNewJob} = useCreateAndRunNewJob()
  
  // Fetch the commit IDs and code
  useEffect(() => {
    const fetchCommitIds = async () => {
      // Replace with your logic to fetch commit IDs
      // const fetchedCommitIds = await fetchEntityCommitIds(entity); // Define this function separately
      // setCommitIds(fetchedCommitIds);
      // if (fetchedCommitIds.length > 0) {
      //   setSelectedCommitId(fetchedCommitIds[0]); // Set default to the first commit ID
      // }
    };
    const getCode = async () => {
      if (selectedCommitId) {
        // Fetch code for the selected commit ID
        // const fetchedCode = await fetchEntityCode(entity, selectedCommitId); // Define this function separately
        // setCode(fetchedCode);
      }
    };

    fetchCommitIds();
    getCode();
  }, [entity, selectedCommitId]);

  const onCommitChange = (event: SelectChangeEvent<string>) => {
    setSelectedCommitId(event.target.value as string);
  };

  const onTextChange = (value: string) => {
    setCommitMessage(value);
  };

  const onClickBack = () => {
    setIsEntityCodeReview(false);
  };

  function handleNext(): void {
    throw new Error('Function not implemented.');
  }

  const handleNotebookString = async () => {
    setShowPopUp(false)
    if(!fetchedEntityCode && entityCode) {
      const body: addNotebookRequest = {
        filePath: `${dataPodId}/entities/${entity.dataEntityName}.py`,
        notebook: entityCode,
        commitMessage: commitMessage ? commitMessage : constants.initialCommitMessage
      }
      const response = await addNoteBookConvertedScript(body)
      if(response) {
        setIsShowStatus(true)
        setSeverity('success')
        setResultStatusMessage('Notebook added successfully.')
      } else {
        setIsShowStatus(true)
        setSeverity('error')
        setResultStatusMessage('Error adding notebook.')
      }
    } else if(fetchedEntityCode && entityCode) {
      const body: addNotebookRequest = {
        filePath: `${dataPodId}/entities/${entity.dataEntityName}.py`,
        notebook: entityCode,
        commitMessage: commitMessage ? commitMessage : constants.initialCommitMessage
      }
      const response = await updateNotebook(body)
      if(response) {
        setIsShowStatus(true)
        setSeverity('success')
        setResultStatusMessage('Notebook updated successfully.')
      } else {
        setIsShowStatus(true)
        setSeverity('error')
        setResultStatusMessage('Error updating notebook.')
      }
    }
  }

  const executeNotebook = async () => {
    if (dataPodId && entityCode) {
      const databricksParams: CreateJobParams = {
        dataPodId,
        entityId: entity.id,
      }

      const fabricParams: FabricNotebookCreateRequest = {
        workspaceId: '',
        displayName: dataPodId + "_" + entity.dataEntityName || '',
        description: `Executing entity: ${entity.dataEntityName} for datapodId ${dataPodId}`,
        accessToken: '',
        code: entityCode,
        executionData: {},
        environmentId: '',
      }

      const routePage = generatePath(ROUTES.DataModelingCanonicalModel, {
        dataPodId: dataPodId,
      })

      const jobDetails: JobDetails = {
        triggerType: 'Entity',
        parameters: {
          id: entity.id,
          name: entity.dataEntityName,
          route: routePage,
        },
      }

      
      try {
        const payload = await getCreateJobPayload(dataPodId, databricksParams, fabricParams)
        if(payload) {
          await createAndRunNewJob(jobDetails, payload)
        } else {
          setIsShowStatus(true)
          setSeverity('error')
          setResultStatusMessage("Failed to execute notebook.")
        }
      } catch (error: any) {
        setIsShowStatus(true)
        setSeverity('error')
        setResultStatusMessage(error.message)
      }
    }
  }

  return (
    <>
      <ResultStatus 
        severtiy={severity} 
        showStatus={isShowStatus} 
        closeStatus={setIsShowStatus} 
        alertMessage={resultStatusMessage} 
      />
      <Box sx={{ mt: 2 }}>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          {/* <Box >
            <LucidTextLabel labelText="Commit Id:" />
            <Select
              value={selectedCommitId}
              onChange={onCommitChange}
              displayEmpty
              size="small"
              sx={{ minWidth: 300}}
                >
              <MenuItem value="" disabled>
                <em>Select a commit</em>
              </MenuItem>
              {commitIds.map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </Box> */}
          <Box>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => setShowPopUp(true)}
              startIcon={<AddOutlined />}
              disabled={!entityCode}
              loading={false}
              sx={{ mr: 2 }}
            >
              {fetchedEntityCode ? 'Update Notebook' : 'Save Notebook'}
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlined fontSize="small" />}
            >
              Back
            </Button>
          </Box>
        </Box>
        <LucidJupyter
          executeNotebook={executeNotebook}
          noteBookString={entityCode}
          headingText={constants.codeHeaderText}
          updateNoteBookString={(text) => setEntityCode(text)}
          commitMessage={commitMessage}
          updateCommitMessage={setCommitMessage}
        />
        <LucidPopUp
          showCloseIcon
          closePopup={setShowPopUp}
          openPopUp={showPopUp}
          onConfirm={handleNotebookString}
          headingText=""
          confirmText="Commit"
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Link href={'https://github.com/Lucid-Data-Hub/gen-datalake-dev'} target="blank">
                Git Hub Repository
              </Link>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter Commit Message"
                variant="outlined"
                color="secondary"
                required
                rows={2}
                multiline
                value={commitMessage}
                onChange={({ target }) => onTextChange(target.value)}
              />
            </Grid>
          </Grid>
          </LucidPopUp>
      </Box>
    </>
  );
};

export default EntityCodeViewer;